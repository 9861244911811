import React, { useState, useEffect } from 'react';
import {
  Row,
} from "reactstrap";
import classNames from "classnames";

export default function SelectionRow({list, setList}) {
  const [selected, setSelected] = useState(list);

  useEffect(() => {
    setList(selected);
  }, [selected])

  return(
    <Row className="selection-row">
      {_.map(list, (x,i) => (
        <div
          key={`sr-${i}`}
          className={classNames("pill", {"selected-pill": x.selected})}
          onClick={() => {  
            let selectedTemp = selected.slice();
            selectedTemp[i].selected = !selectedTemp[i].selected;
            setSelected(selectedTemp);
          }}
        >
          <div className="mr-2">{x.icon}</div>
          <div>{x.label}</div>
        </div>))}
    </Row>
  );
}