import { FormGroup, FormControl, TextField } from "@mui/material";
import cx from "classnames";
import { DebounceInput } from "react-debounce-input";
import { FormFeedback, Input } from "reactstrap";
import { StyledFormLabel } from "./";

export default function StyledInput(props) {
  let inputElement = props.debounceTimeout ? (
    <DebounceInput
      type={props.type ?? "text"}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      className={cx(props.className, "form-control")}
      placeholder={props.placeholder}
      inputRef={props.ref}
      name={props.name}
      autoComplete="new-password"
      id={props.id}
      maxLength={props.maxLength}
      debounceTimeout={props.debounceTimeout}
      min={props.min}
    />
  ) : (
    <Input
      type={props.type ?? "text"}
      value={props.value}
      disabled={props.disabled}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      innerRef={props.ref}
      invalid={props.errorMessage}
      valid={props.valid}
      className={cx(props.className, "form-control")}
      placeholder={props.placeholder}
      name={props.name}
      id={props.id}
      maxLength={props.maxLength}
      autoComplete="new-password"
      min={props.min}
    />
  );
  return (
    <FormControl component="fieldset">
      <StyledFormLabel {...props} />
      <FormGroup aria-label="position" row>
        {inputElement}

        <FormFeedback invalid={props.errorMessage}>
          {props.errorMessage}
        </FormFeedback>
      </FormGroup>
    </FormControl>
  );
}