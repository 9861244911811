import React, { useState } from 'react';
import ReactTooltip from "react-tooltip";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {StyledButton} from "./";

export default function ValueSetToolTip(props){
    const {idName, description} = props;
    const [showTooltip, setShowToolTip] = useState(true);
    return (
        <>
            <AiOutlineInfoCircle 
                size="1.5em" 
                className="colorPurple"
                data-tip data-for={idName}
                // onClick={()=>setShowToolTip(!showTooltip)}
            />
            {showTooltip ?
                <ReactTooltip  border clickable id={idName} delayHide="250" place="top" type="light" effect="solid" multiline>
                    <>
                    {`${description ?? 'No definition provided'}`}
                    </>
                </ReactTooltip>
            :   null}
            
        </>);
}