import _ from "lodash";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdExpandMore,
  MdExtension,
} from "react-icons/md";
import {
  HiChevronDoubleUp,
  HiOutlineChevronDoubleDown,
  HiChevronDown,
  HiChevronUp,
} from "react-icons/hi";
import { TbSquareDot } from "react-icons/tb";
import { RiFolderAddLine, RiFolderReduceLine } from "react-icons/ri";

export default function ProductTree(props) {
  const {
    nodes,
    checked,
    setChecked,
    expanded,
    setExpanded,
    onlyLeafCheckboxes,
    noCascade,
    hideCheckboxes
  } = props;

  // component must have nodes, checked/expanded must be at least an empty array
  // and setChecked and setExpanded must both be functions passed to this function
  // other wise this component will not work
  if (
    !(
      nodes &&
      nodes.length > 0 &&
      _.isArray(checked) &&
      _.isArray(expanded) &&
      _.isFunction(setChecked) &&
      _.isFunction(setExpanded)
    )
  ) {
    console.error('You need to pass the necessary props to the ProductTree.js')
    return null;
  }
  return (
    <CheckboxTree
      nodes={nodes}
      checked={checked}
      expanded={expanded}
      onCheck={(checked) => setChecked(checked)}
      onExpand={(expanded) => setExpanded(expanded)}
      onlyLeafCheckboxes={onlyLeafCheckboxes}
      noCascade={noCascade}
      icons={{
        check: hideCheckboxes ? null : <MdCheckBox size="2em" />,
        uncheck: hideCheckboxes ? null : <MdCheckBoxOutlineBlank size="2em" />,
        // halfCheck: <TbSquareDot size="2em" />, // like this one better but it was acting weird
        halfCheck: hideCheckboxes ? null : <MdCheckBoxOutlineBlank size="2em" />,
        expandOpen: <RiFolderReduceLine size="2em" />,
        expandClose: <RiFolderAddLine size="2em" />,
        expandAll: <HiOutlineChevronDoubleDown size="2em" />,
        collapseAll: <HiChevronDoubleUp size="2em" />,
        parentClose: null, //<HiOutlineChevronDoubleDown size="1.5em" />,
        parentOpen: null, //<HiOutlineChevronDoubleDown size="1.5em" />,
        leaf: null,
      }}
      {...props}
    />
  );
}
