//import { InputGroup, FormControl, Alert, Button, Container, Table } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Col,
  Row,
  ButtonGroup,
  Label,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import {
  LongBreadCrumb,
  PageContainer,
  StyledFormLabel,
  StyledInput,
  StyledTD,
} from ".";
import { MdOutlineUnfoldLess, MdOutlineUnfoldMore } from "react-icons/md";
import Table from "react-bootstrap/Table";
import { api, constants, helpers } from "../utils";
import _ from "lodash";
import Switch from "react-switch";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox, StyledButton, Loader, StyledSelect } from ".";
import { FormControl, FormGroup } from "@mui/material";
import { toast } from "react-toastify";
import StyledToggle from "./StyledToggle";

const { ACCESS_VIEW_OPTIONS, ACCESS_VIEWS, ROUTES } = constants;

export default function EditUser() {
  let { userId } = useParams();
  const navigate = useNavigate();
  userId = userId ? parseInt(userId, 10) : null;

  let emptyUser = {
    id: userId,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    phone: "",
    views: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [buildingTable, setBuildingTable] = useState(false);
  const [tainted, setTainted] = useState(false);
  const [availableClients, setAvailableClients] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAllClientSwitch, setSelectAllClientSwitch] = useState(false);
  const [selectAllProductSwitch, setSelectAllProductSwitch] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [user, setUser] = useState(Object.assign({}, emptyUser));
  const [tableDictionary, setTableDictionary] = useState(null);
  const [dictionaryReady, setDictionaryReady] = useState(false);
  const [productHierarchy, setProductHierarchy] = useState(null);
  const [hierarchyFlatList, setHierarchyFlatList] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState(null);
  const [viewSelection, setViewSelection] = useState(null);
  const [allViews, setAllViews] = useState([]); // ACCESS_VIEW_OPTIONS
  const isNewUser = userId === 0;

  useEffect(() => {
    getHierarchy();
    let apiCalls = [];
    apiCalls.push(api.getClients());
    apiCalls.push(api.getProducts());
    apiCalls.push(getViews());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.views) {
          setAllViews(aggResults.views);
        }
        if (aggResults.clients) {
          setAvailableClients(aggResults.clients);
        }
        if (aggResults.products) {
          setAvailableProducts(aggResults.products);
        }
        if (aggResults.clients && aggResults.products) {
          buildDictionary(aggResults.clients, aggResults.products);
          refreshData(aggResults.views);
        }
      })
      .catch((error) => {
        console.error("error message", error);
        return setAlertMessage({
          flavor: "alert-danger",
          text: "There was an error loading user data",
        });
      });
  }, []);

  function getViews() {
    return api
      .securePost("UserAdmin/ViewsList", { Page: 1, ActiveOnly: true })
      .then((response) => {
        return {
          views: helpers.addLabelValueToList(response.data.message),
        };
      });
  }

  function getHierarchy() {
    api.secureFetch("SavingsLever/SLHierarchy", {}).then((response) => {
      if (response.data && response.data.success) {
        setProductHierarchy(response.data.message);
        let dProducts = _.chain(response.data.message.children)
          .map((m, index) => {
            m.sortByParentId = m.parent.id;
            m.sortById = m.parent.id;
            return m;
          })
          .sortBy(["sortByParentId"])
          .value();
        setDisplayedProducts(dProducts);
        flattenHierarchy(dProducts);
      }
    });
  }

  // recursively flattens hierachy into a list for easy searching
  function flattenHierarchy(hierarchy) {
    if (!hierarchy || !hierarchy.length) return;
    _.each(hierarchy, (n) => {
      if (!n.sortByParentId && n.parent?.parentSavingsLeverId) {
        n.sortById = n.parent?.id;
        n.sortByParentId = n.parent?.parentSavingsLeverId;
      }
      addToFlatList(n);
      if (n.children && n.children.length > 0) {
        flattenHierarchy(n.children);
      }
    });
  }

  function addToFlatList(item) {
    if (!item) return;
    let temp = hierarchyFlatList;
    temp.push(item);
    setHierarchyFlatList(temp);
  }

  function refreshData(views) {
    if (isLoading || userId === 0) return;
    setIsLoading(true);

    api
      .secureFetch(`UserAdmin/User/${userId}`, {})
      .then((response) => {
        if (response.data) {
          let retrievedUser = response.data;
          retrievedUser.views = _.filter(views, (v) =>
            _.some(retrievedUser.views, (uv) => uv.typeOfView === v.value)
          );
          setUser(retrievedUser);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (
      user &&
      user.clients &&
      user.levers &&
      tableDictionary &&
      !dictionaryReady
    ) {
      fillDictionaryFromUser(user);
    }
  }, [user, tableDictionary, dictionaryReady]);

  //checks user object for if all levers, all clients, or intersections between client/lever and checks boxes accordingly
  function fillDictionaryFromUser(passedUser) {
    let allLeversList = [];
    if (tableDictionary) {
      _.each(passedUser.levers, (l) => {
        if (l.allClients) {
          allLeversList.push(l.leverId);
          onSelectProductAll(l.leverId, !l.allClients);
        }
      });
      _.each(passedUser.clients, (c) => {
        if (c.allLevers) {
          onSelectClientAll(c.clientId, !c.allLevers);
          return true;
        }
        _.each(c.levers, (cl) => {
          if (!_.includes(allLeversList, cl.leverId)) {
            onSelectIntersect(c.clientId, cl.leverId);
          }
        });
      });
    }
    setDictionaryReady(true);
  }

  const keyName = (clientId, productId) =>
    `client${clientId}product${productId}`;

  function buildDictionary(clients, products) {
    if (clients && products) {
      let temp = {};
      _.each(clients, (c) => {
        _.each(products, (p) => {
          temp[keyName(c.id, p.id)] = {
            clientId: c.id,
            productId: p.id,
            value: false,
          };
        });
      });
      setTableDictionary(temp);
      if (tableDictionary) {
        setBuildingTable(false);
      }
    }
  }

  //positionally replaces parent node with all children nodes in displayed products
  function expandParent(parentNode) {
    let temp = displayedProducts;
    let replaceIndex = _.indexOf(temp, parentNode);
    temp = _.filter(temp, (p) => p.parent.id !== parentNode.parent.id);
    _.each(parentNode.children, (c) => {
      temp.splice(replaceIndex, 0, c);
    });
    temp = _.chain(temp)
      .reject((d) => !d)
      .sortBy(["sortByParentId", "sortById"])
      .value();
    setDisplayedProducts(temp);
  }

  //finds all nodes with same parent node as the passed child node and removes them then adds the parent node
  function collapseChild(childNode) {
    let temp = displayedProducts;
    let replaceIndex = _.indexOf(temp, childNode);
    temp = _.filter(
      temp,
      (p) =>
        p.parent.parentSavingsLeverId !== childNode.parent.parentSavingsLeverId
    );
    let parentOfChildNode = _.find(
      hierarchyFlatList,
      (p) => p.parent.id === childNode.parent.parentSavingsLeverId
    );
    temp.splice(replaceIndex, 0, parentOfChildNode);
    temp = _.chain(temp)
      .reject((d) => !d)
      .sortBy(["sortByParentId", "sortById"])
      .value();
    setDisplayedProducts(temp);
  }

  // function onToggleSelectAllClients(selected) {
  //   let list = selectedClients.slice();
  //   if (selected) {
  //     list = _.map(availableClients, x => {
  //       const existing = _.find(selectedClients, sc => sc.id === x.id);
  //       return (existing)
  //         ? existing
  //         : {id: x.id, all: false};
  //     });
  //   } else {
  //     list = [];
  //   }
  //   setSelectedClients(list);
  //   setSelectAllClientSwitch(selected);
  // }

  // function onToggleSelectAllProducts(selected) {
  //   let list = selectedProducts.slice();
  //   if (selected) {
  //     list = _.map(availableProducts, x => {
  //       const existing = _.find(availableProducts, sc => sc.id === x.id);
  //       return (existing)
  //         ? existing
  //         : {id: x.id, all: false};
  //     });
  //   } else {
  //     list = [];
  //   }
  //   setSelectedProducts(list);
  //   setSelectAllProductSwitch(selected);
  // }

  // function onSelectClient(clientId) {
  //   let list = selectedClients.slice();
  //   if (_.some(selectedClients, x => x.id === clientId)) {
  //     list = _.reject(list, x => x.id === clientId);
  //   } else {
  //     list.push({id: clientId});
  //   }
  //   setSelectedClients(list);
  // }

  // function onSelectProduct(productId) {
  //   let list = selectedProducts.slice();
  //   if (_.some(selectedProducts, x => x.id === productId)) {
  //     list = _.reject(list, x => x.id === productId);
  //   } else {
  //     list.push({id: productId});
  //   }
  //   setSelectedProducts(list);
  // }

  function onSelectClientAll(clientId, selected) {
    let td = Object.assign({}, tableDictionary);
    if (selected) {
      _.each(availableProducts, (p) => {
        td[keyName(clientId, p.id)].value = false;
      });
    } else {
      _.each(availableProducts, (p) => {
        td[keyName(clientId, p.id)].value = true;
      });
    }
    setTableDictionary(td);
  }

  function onSelectProductAll(productId, selected) {
    let td = Object.assign({}, tableDictionary);
    if (selected) {
      _.each(availableClients, (c) => {
        td[keyName(c.id, productId)].value = false;
      });
    } else {
      let currentNode = _.find(
        hierarchyFlatList,
        (p) => p.parent.id === productId
      );
      _.each(availableClients, (c) => {
        td[keyName(c.id, productId)].value = true;
        if (currentNode.children && currentNode.children.length > 0) {
          _.each(currentNode.children, (child) => {
            onSelectProductAll(child.parent.id, selected);
          });
        }
      });
    }
    setTableDictionary(td);
  }

  function onSelectIntersect(clientId, productId) {
    let val = tableDictionary[keyName(clientId, productId)].value;
    let td = Object.assign({}, tableDictionary);
    td[keyName(clientId, productId)].value = !val;

    //if checkbox is false and were setting to true - set all all children to true
    if (!val) {
      let currentNode = _.find(
        hierarchyFlatList,
        (p) => p.parent.id === productId
      );
      if (currentNode.children && currentNode.children.length > 0) {
        _.each(currentNode.children, (child) => {
          onSelectIntersect(clientId, child.parent.id);
        });
      }
    }
    setTableDictionary(td);
  }

  function onChangeUser(field, value) {
    let tempUser = Object.assign({}, user);
    tempUser[field] = value;
    setUser(tempUser);
  }

  function buildUserPayload() {
    let uPayload = Object.assign({}, user);
    let productPowerLever;
    let clientPowerLever;
    let checkedProducts = [];
    let checkedClients = [];
    _.each(availableProducts, (p) => {
      productPowerLever = _.every(
        availableClients,
        (client) => tableDictionary[keyName(client.id, p.id)].value
      );
      if (productPowerLever) {
        checkedProducts.push({
          leverId: p.id,
          allClients: true,
        });
        return;
      }
      let checkedP = _.some(availableClients, (cl) => {
        return tableDictionary[keyName(cl.id, p.id)].value;
      });
      if (checkedP) {
        checkedProducts.push({
          leverId: p.id,
          allClients: false,
        });
      }
    });
    _.each(availableClients, (c) => {
      clientPowerLever = _.every(
        availableProducts,
        (prod) => tableDictionary[keyName(c.id, prod.id)].value
      );
      if (clientPowerLever) {
        checkedClients.push({
          clientId: c.id,
          allLevers: true,
        });
        return;
      }
      let leversList = _.map(availableProducts, (pr) => {
        if (tableDictionary[keyName(c.id, pr.id)].value) {
          return pr.id;
        }
      });
      let clensedList = _.filter(leversList, (p) => p);
      if (clensedList.length > 0) {
        checkedClients.push({
          clientId: c.id,
          allLevers: false,
          LeverIdList: clensedList,
        });
      }
    });
    uPayload.clients = checkedClients;
    uPayload.levers = checkedProducts;
    uPayload.views = _.map(uPayload.views, (u) => {
      return {
        userId: uPayload.id,
        typeOfView: u.value,
      };
    });
    return uPayload;
  }

  function saveChanges() {
    let payload = buildUserPayload();
    api.securePost(`UserAdmin/SaveUser`, payload).then((response) => {
      if (response.data && response.data.success) {
        setAlertMessage({
          flavor: "success",
          text: "User saved successfully",
        });
        setTimeout(() => navigate(ROUTES.Admin.UserAdmin), 3000);
      } else {
        setAlertMessage({
          flavor: "danger",
          text: response.data.message,
        });
      }
    });
  }

  if (isLoading) {
    return <Loader />;
  }

  const saveButtons = (
    <ButtonGroup className="float-right">
      <StyledButton
        color="primary"
        onClick={saveChanges}
        size="lg"
        showSaveIcon
      >
        Save
      </StyledButton>
      <StyledButton tag={Link} to={ROUTES.Admin.UserAdmin} size="lg">
        Cancel
      </StyledButton>
    </ButtonGroup>
  );

  function adjustUserViews(selections) {
    if (
      _.includes(selections, ACCESS_VIEWS.PATIENT_CHART) &&
      _.includes(selections, ACCESS_VIEWS.PATIENT_CHART_READONLY)
    ) {
      return toast.error(
        `Please select either Patient Chart or Patient Chart Readonly.`
      );
    }
    onChangeUser("views", [...selections]);
  }

  function addViewToUser(selection) {
    if (selection) {
      let copyList = Object.assign([], user.views);
      copyList.push(selection);
      onChangeUser("views", copyList);
      // setViewSelection(null);
    }
  }

  function removeViewFromUser(view) {
    onChangeUser(
      "views",
      _.reject(user.views, (x) => x.value === view.value)
    );
  }

  function calculateBackgroundClass(item, hasChildren, hasParent) {
    if (hasChildren && !hasParent) {
      return `bg-purple70 clientLeverTHs`;
    } else if (hasChildren) {
      return `bg-purple50 clientLeverTHs`;
    } else {
      return `bg-purple30 clientLeverTHs`;
    }
  }
  return availableClients &&
    availableProducts &&
    tableDictionary &&
    productHierarchy &&
    displayedProducts ? (
    <PageContainer>
      <Col
        className={"m-0 p-0 w-100  h-100 flex-column justify-content-between"}
      >
        {alertMessage ? (
          <Alert color={alertMessage.flavor}>{alertMessage.text}</Alert>
        ) : null}
        <LongBreadCrumb
          page={"User Management"}
          context={
            isNewUser ? "New User" : `Edit ${user.firstName} ${user.lastName}`
          }
          trailing={saveButtons}
        />
        <Row lg={12} className={"m-0 p-0 w-100"}>
          <Card className={"w-100"}>
            <CardBody>
              <Row className="w-100  mb-4">
                <Col xs="3">
                  <StyledInput
                    type="text"
                    maxLength="50"
                    id="userFirstName"
                    value={user.firstName}
                    onChange={(e) => onChangeUser("firstName", e.target.value)}
                    name="firstName"
                    label="First Name"
                  />
                </Col>
                <Col xs="3">
                  <StyledInput
                    type="text"
                    maxLength="50"
                    id="userLastName"
                    value={user.lastName}
                    onChange={(e) => onChangeUser("lastName", e.target.value)}
                    name="lastName"
                    label="Last Name"
                  />
                </Col>
                <Col xs="3">
                  <StyledInput
                    type="text"
                    maxLength="50"
                    id="phone"
                    value={user.phone}
                    onChange={(e) => onChangeUser("phone", e.target.value)}
                    name="phone"
                    label="Phone"
                  />
                </Col>
                {/*<Col className="pt-3" xs="3">*/}
                {/*  <StyledToggle*/}
                {/*    label={"Admin"}*/}
                {/*    value={user.isAdmin}*/}
                {/*    onClick={() => onChangeUser("isAdmin", !user.isAdmin)}*/}
                {/*  />*/}
                {/*</Col>*/}
              </Row>
              <Row className="w-100">
                <Col xs="3">
                  <StyledInput
                    type="text"
                    maxLength="50"
                    id="username"
                    value={user.username}
                    onChange={(e) => onChangeUser("username", e.target.value)}
                    name="username"
                    label="Username"
                  />
                </Col>
                <Col xs="3">
                  <StyledInput
                    type="text"
                    maxLength="50"
                    id="email"
                    value={user.email}
                    onChange={(e) => onChangeUser("email", e.target.value)}
                    name="email"
                    label="Email"
                  />
                </Col>
                <Col xs={{ size: 4 }}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <StyledFormLabel label={"View Access"} />
                      <StyledSelect
                        isMulti
                        isClearable={false}
                        options={_.reject(allViews, (v) =>
                          _.some(user.views, (uv) => uv.value === v.value)
                        )}
                        id="views"
                        value={user.views}
                        onChange={adjustUserViews}
                        name="views"
                      />
                    </FormGroup>
                  </FormControl>
                </Col>
                {/*<Col xs="2">*/}
                {/*  <StyledButton*/}

                {/*      color="primary"*/}
                {/*      onClick={addViewToUser}*/}
                {/*      size="lg"*/}
                {/*      showPlusIcon*/}
                {/*      iconClassName="mr-1 ml-1"*/}
                {/*      className="mt-4 float-left"*/}
                {/*  />*/}
                {/*</Col>*/}
              </Row>
              {/*{user && user.views && user.views.length > 0*/}
              {/*    ? _.map(user.views, (v, index) => (*/}
              {/*        <Row className="w-100" key={`userView${index}`}>*/}
              {/*          <Col xs={{size: 4, offset: 6}}*/}
              {/*               style={{textAlign: "left"}}*/}
              {/*               className="pt-1 pl-2">{v.label}</Col>*/}
              {/*          <Col className="my-1">*/}
              {/*            <StyledButton*/}
              {/*                onClick={() => removeViewFromUser(v)}*/}
              {/*                showDeleteIcon*/}
              {/*                iconClassName="m-0"*/}
              {/*                className="float-left"*/}
              {/*            />*/}
              {/*          </Col>*/}
              {/*        </Row>*/}
              {/*    ))*/}
              {/*    : null}*/}
              <Row className="w-100 mt-3">
                <Col xs="12" className="text-center">
                  <h4>Client / Product Access</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  {tableDictionary ? (
                    <Table className="edit-user__table" bordered>
                      <tbody>
                        <tr>
                          <th width="14%"></th>
                          {_.map(displayedProducts, (d) => {
                            let p = d.parent;
                            let hasChildren =
                              d.children && d.children.length > 0;
                            let hasParent = d.parent.parentSavingsLeverId;
                            let isProductSelected = _.every(
                              availableClients,
                              (client) =>
                                tableDictionary[keyName(client.id, p.id)].value
                            );
                            const bgClassName = calculateBackgroundClass(
                              d,
                              hasChildren,
                              hasParent
                            );
                            return (
                              <th
                                key={`header${p.id}`}
                                width={`${_.floor(
                                  88 / displayedProducts.length
                                )}%`}
                                className={`${bgClassName} pl-2 pr-0`}
                              >
                                <Row className="w-100 mr-0 pl-1 minHeight50 align-items-start clientLeverBtns">
                                  <Col
                                    className="px-0"
                                    xs="3"
                                    onClick={() => {
                                      hasParent && collapseChild(d);
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        hasParent
                                          ? `Collapse to parent: ${p?.parentSavingsLever?.name}`
                                          : `No parent to collapse`
                                      }
                                      className={`float-left ${
                                        hasParent ? "cursorPointer" : ""
                                      }`}
                                      disabled={!hasParent}
                                    >
                                      <div
                                        className={`float-left collapsibleColButton ${
                                          hasParent ? "" : "disabled"
                                        }`}
                                      >
                                        <MdOutlineUnfoldLess
                                          size="2.5em"
                                          className=" float-left"
                                        />
                                      </div>
                                    </Tooltip>
                                  </Col>
                                  <Col xs="6" className="px-0">
                                    <Checkbox
                                      selected={isProductSelected}
                                      onChange={() =>
                                        onSelectProductAll(
                                          p.id,
                                          isProductSelected
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col
                                    className="px-0"
                                    onClick={() => {
                                      hasChildren && expandParent(d);
                                    }}
                                    xs="3"
                                  >
                                    <Tooltip
                                      title={"Expand to see children"}
                                      className={`float-left ${
                                        hasChildren ? "cursorPointer" : ""
                                      }`}
                                      disabled={!hasChildren}
                                    >
                                      <div
                                        className={`float-left collapsibleColButton ${
                                          hasChildren ? "" : "disabled"
                                        }`}
                                      >
                                        <MdOutlineUnfoldMore
                                          size="2.5em"
                                          className=" float-right"
                                        />
                                      </div>
                                    </Tooltip>
                                  </Col>
                                </Row>
                                <Row className="w-100 mr-0 pl-1 align-items-end">
                                  <Col xs="12">
                                    <span>{p.name}</span>
                                  </Col>
                                </Row>
                              </th>
                            );
                          })}
                        </tr>
                        {/*client headers and table data */}
                        {_.map(availableClients, (c, index) => {
                          let isClientSelected = _.every(
                            availableProducts,
                            (prod) =>
                              tableDictionary[keyName(c.id, prod.id)].value
                          );
                          return (
                            <tr>
                              <th
                                style={{ width: "10rem" }}
                                key={`client-header${c.id}`}
                                className="text-left"
                              >
                                <Checkbox
                                  selected={isClientSelected}
                                  onChange={() =>
                                    onSelectClientAll(c.id, isClientSelected)
                                  }
                                  divClassName="d-inline align-items-left mr-2"
                                />
                                {c.name}
                              </th>
                              {_.map(displayedProducts, (d, idx) => {
                                let p = d.parent;
                                const isSelected =
                                  tableDictionary[keyName(c.id, p.id)].value;
                                return (
                                  <StyledTD key={`td${p.id}${c.id}`} textCenter>
                                    <Checkbox
                                      selected={isSelected}
                                      onChange={() =>
                                        onSelectIntersect(c.id, p.id)
                                      }
                                    />
                                  </StyledTD>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : null}
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs="12">{saveButtons}</Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Col>
    </PageContainer>
  ) : null;
}