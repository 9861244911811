import React, { useState, useEffect } from "react";
import _ from "lodash";
import { api, helpers, constants, storage } from "../utils";
import { Loader, LongBreadCrumb, PageContainer, Pager } from "./";
import {
  Badge,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardGroup,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const { ROUTES } = constants;
let currentUser = storage.getItem("currentUser");
currentUser = currentUser ? JSON.parse(currentUser) : null;

const SkeletonCard = (props) => {
  return (
    <Col key={`skele-${props.index}`} xs="12" lg="4" className={"m-0 p-0"}>
      <Card className={`cards-body mb-4 backgroundClients`}>
        <CardBody>
          <Row
            lg={"auto"}
            className={"m-0 p-0 h-100 w-100 d-flex justify-content-start"}
          >
            <Col xs="3" className={"m-0 px-1 py-0 d-flex align-items-center"}>
              <Skeleton
                height={"100"}
                width={"100"}
                containerClassName={"skeleton-def w-100 h-100"}
                circle
                count={1}
              />
            </Col>
            <Col className={"m-0 p-0 ml-3  d-flex align-items-center"}>
              <Skeleton
                width={"100"}
                height={"100"}
                containerClassName={"skeleton-def w-75 h-25"}
                count={1}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

const defaultPictureSize = 125;

const ClientCard = (props) => {
  const [pictureSize, setPictureSize] = useState(
    defaultPictureSize / window.devicePixelRatio
  );

  const buildLink = (link) => {
    let newPath = link;
    return newPath.replace(":clientId", props.client.id);
  };

  useEffect(() => {
    if (pictureSize !== defaultPictureSize / window.devicePixelRatio) {
      setPictureSize(defaultPictureSize / window.devicePixelRatio);
    }
  }, [window.devicePixelRatio]);
  const hasCampaignView = helpers.hasView(constants.ACCESS_VIEWS.CAMPAIGNS);

  function getClientLogo() {
    api
      .secureFetch(`Client/Logo/${props.client.id}`)
      .then((r) => {
        if (!r) return;
        if (!r.data) return null; //Change to default logo
        return r.data;
      })
      .catch(api.catchHandler);
  }

  return (
    <Col
      key={`dashClient${props.client.id}${props.index}`}
      md="12"
      lg="6"
      xl="4"
      className={"m-0 p-0"}
    >
      <Card
        className={`cards-body mb-4 backgroundClients`}
        tag={Link}
        to={buildLink(
          hasCampaignView
            ? ROUTES.Client.PlanDetails
            : ROUTES.Client.MemberManagement
        )}
      >
        <CardBody className="cursorPointer">
          <Row className={"m-0 p-0 d-flex align-items-center h-100"}>
            <Col xs="4">
              <img
                className={"client__img"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/missing-logo.png";
                }}
                src={`/api/Client/Logo/${props.client.id}`}
              />
              {/*<div*/}
              {/*  style={helpers.clientLogoStyle(props.client.id, pictureSize)}*/}
              {/*/>*/}
            </Col>
            <Col>
              <div className="cardTitle">{props.client.name}</div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default function ClientDashboard(props) {
  let currentUser = storage.getItem("currentUser");
  currentUser = currentUser ? JSON.parse(currentUser) : null;
  const [loading, setLoading] = useState(false);
  const [activeOnly, setActiveOnly] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedList, setPaginatedList] = useState(null);

  function getClients() {
    return api
      .securePost(`Client/PaginatedList`, {
        MaxResults: constants.DEFAULT_PAGE_SIZE,
        ActiveOnly: activeOnly,
        SortField: "Name",
        SortDirection: "ASC",
        PageNumber: pageNumber,
      })
      .then((response) => {
        if (response && response.data && response.data.success) {
          console.debug(response.data);
          return { clients: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function refreshData() {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      apiCalls.push(getClients());
      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));
          if (aggResults.clients) {
            setPaginatedList(aggResults.clients);
          }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function buildClientDeck(clientList, user) {
    if (!loading) {
      return _.map(clientList, (c, idx) => {
        return _.some(user.clients, (uc) => uc.clientId === c.id) ? (
          <ClientCard key={`client-card-${c.id}`} index={idx} client={c} />
        ) : null;
      });
    }
    return _.times(9, (num) => (
      <SkeletonCard key={`skele-client-${num}`} index={num} />
    ));
  }

  useEffect(() => {
    refreshData();
  }, [pageNumber, activeOnly]);

  return (
    <>
      <PageContainer>
        <Col className={"m-0 p-0"}>
          <LongBreadCrumb context={"Dashboard"} page={"Clients"} />
          <Row className={"m-0 px-0 py-2 d-flex justify-content-evenly"}>
            <CardDeck className={"w-100"}>
              {buildClientDeck(paginatedList?.list, currentUser)}
            </CardDeck>
          </Row>
        </Col>
        <Col xs="12">
          <Pager
            pageNumber={
              paginatedList?.pageNumber ? paginatedList.pageNumber : 1
            }
            totalPages={
              paginatedList?.totalPages ? paginatedList.totalPages : 0
            }
            callBack={(newPageNumber) => setPageNumber(newPageNumber)}
          />
        </Col>
      </PageContainer>
    </>
  );
}