import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, helpers } from "../utils";
import {
  StyledSingleDatePicker,
  StyledButton,
  ProductTree,
  StyledTD,
  PageContainer,
  LongBreadCrumb,
} from "./";
import { AiOutlinePlus } from "react-icons/ai";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import { ButtonGroup } from "react-bootstrap";
import { IoReturnUpBackOutline } from "react-icons/io5";

const EmptyDiagnostic = {
  id: 0,
  name: "",
};

export default function DiagnosticAdmin() {
  const [diagnostics, setDiagnostics] = useState(null);
  let { clientId, clientName } = useParams();
  const [message, setMessage] = useState(null);
  const [reloading, setReloading] = useState(false);
  const [productHierarchy, setProductHierarchy] = useState(null);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [startOn, setStartOn] = useState(false);
  const [endOn, setEndOn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getHierarchy();
  }, []);

  function getHierarchy() {
    api.secureFetch("SavingsLever/SLHierarchy", {}).then((response) => {
      if (response.data && response.data.success) {
        setProductHierarchy(response.data.message);
      }
    });
  }

  useEffect(() => {
    let nodeList = null;
    if (productHierarchy) {
      nodeList = helpers.getCheckboxTreeNodes(productHierarchy, checked);
    }
    if (!_.isEqual(nodeList, nodes)) {
      setNodes(nodeList);
    }
  }, [productHierarchy, checked]);

  useEffect(() => {
    let checkedChanges = [];
    if (nodes && nodes.length) {
      checkedChanges = helpers.findSelectedNodes(nodes);
    }
    checkedChanges = _.sortBy(checkedChanges, ["parentId", "index"]);
    if (!_.isEqual(checkedChanges, diagnostics)) {
      setDiagnostics(checkedChanges);
    }
  }, [nodes]);

  function saveDiagnostic() {
    setReloading(true);
    // TODO -> iterate through the diagnostics to create diagnostics shape
    // for all savings levers
    /// will also need to verify that all _value items are saved as pennies
    // probably need to adjust NumberFormats below.
    let payload = {};
    api
      .securePost("SavingsLever/SaveDiagnostic", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setMessage({
            flavor: "success",
            text: "Diagnostic saved.",
          });
        }
      })
      .finally(() => setReloading(false));
  }

  function editDiagnostic(field, value, index) {
    let newList = Object.assign([], diagnostics);
    let newItem = newList[index];
    newItem[field] = value;
    newList[index] = newItem;
    setDiagnostics(newList);
  }

  function goBack() {
    navigate(-1);
  }

  let showProductTree = productHierarchy && nodes && nodes.length > 0;
  let showEditArea = diagnostics && diagnostics.length > 0;
  return (
    <PageContainer>
      <Col className={"m-0 p-0  h-100 flex-column justify-content-between"}>
        <LongBreadCrumb
          page={"Campaign Diagnostic"}
          context={clientName}
          trailing={
            <StyledButton onClick={goBack} icon={IoReturnUpBackOutline}>
              Back
            </StyledButton>
          }
        />
        {/*<div className="w-100 mt-3">*/}
        <Row lg={10} className={"m-0 p-0 h-100 d-flex justify-content-between"}>
          <Col xs="3" className={"m-0 p-0 h-100"}>
            {showProductTree ? (
              <>
                <Card className={"p-3 h-100 sm-drop-shadow"}>
                  <h5 className="mb-3">Savings Levers</h5>
                  <ProductTree
                    nodes={nodes}
                    checked={checked}
                    expanded={expanded}
                    setChecked={setChecked}
                    setExpanded={setExpanded}
                    onlyLeafCheckboxes
                  />
                </Card>
              </>
            ) : null}
          </Col>
          <Col xs="8" className={"h-100 m-0 p-0"}>
            {/*<div className="product-display-table p-3">*/}
            <Card className={"h-100 p-1 sm-drop-shadow"}>
              <CardBody>

              {showEditArea ? (
                <>
                  <Row className="mb-3 w-100 d-flex justify-content-between">
                    <Col className={"d-flex justify-content-center"}>
                      <StyledSingleDatePicker
                        type="text"
                        name="startOn"
                        id="startOn"
                        maxLength="30"
                        onChangeCallback={(event) => setStartOn(event.value)}
                        value={startOn || ""}
                        debounceTimeout={300}
                        label={"Start On"}
                      />
                    </Col>
                    <Col className={"d-flex justify-content-center"}>
                      <StyledSingleDatePicker
                        type="text"
                        name="endOn"
                        id="endOn"
                        maxLength="30"
                        onChangeCallback={(event) => setEndOn(event.value)}
                        value={endOn || ""}
                        label={"End On"}
                      />
                    </Col>
                  </Row>
                  <Table
                    bordered
                    size="sm"
                    responsive
                    striped
                    id={"diagnostic-table"}
                  >
                    <thead>
                      <tr>
                        <th
                          width="34%"
                          className={
                            "diagnostic-table-header header-align-left"
                          }
                        >
                          Savings Level
                        </th>
                        <th
                          width="33%"
                          className={
                            "diagnostic-table-header header-align-left"
                          }
                        >
                          Potential Value
                        </th>
                        <th
                          width="33%"
                          className={
                            "diagnostic-table-header header-align-left"
                          }
                        >
                          Opportunity Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(diagnostics, (product, index) => (
                        <tr key={`${product.key}${index}`}>
                          <StyledTD>{product.label}</StyledTD>
                          <StyledTD textRight>
                            <NumberFormat
                              className={`form-control`}
                              prefix="$"
                              thousandSeparator={true}
                              decimalSeparator="."
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onValueChange={(vals) => {
                                if (!vals.value) {
                                  editDiagnostic("potential_value", 0, index);
                                } else {
                                  editDiagnostic(
                                    "potential_value",
                                    parseFloat(vals.value),
                                    index
                                  );
                                }
                              }}
                              value={product.potential_value}
                            />
                          </StyledTD>
                          <StyledTD textRight>
                            <NumberFormat
                              className={`form-control`}
                              prefix="$"
                              thousandSeparator={true}
                              decimalSeparator="."
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onValueChange={(vals) => {
                                if (!vals.value) {
                                  editDiagnostic("opportunity_value", 0, index);
                                } else {
                                  editDiagnostic(
                                    "opportunity_value",
                                    parseFloat(vals.value),
                                    index
                                  );
                                }
                              }}
                              value={product.opportunity_value}
                            />
                          </StyledTD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <ButtonGroup className="bottomSavingsLeverButton">
                    <StyledButton
                      color="primary"
                      disabled={reloading}
                      size="lg"
                      showSaveIcon
                      onClick={saveDiagnostic} //TODO
                    >
                      SAVE
                    </StyledButton>
                    <StyledButton disabled={reloading} size="lg">
                      CANCEL
                    </StyledButton>
                  </ButtonGroup>
                </>
              ) : (
                <h5>
                  Select at least one savings lever on the left to continue...
                </h5>
              )}{" "}
              </CardBody>
            </Card>
            {/*</div>*/}
          </Col>
        </Row>
      </Col>
      {/*</div>*/}
    </PageContainer>
  );
}
