import {
  FormGroup,
  FormControl,
} from "@mui/material";
import cx from "classnames";
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { StyledFormLabel, ValueSetToolTip } from "./"
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';

// Future development --> allow Creatable???

export default function StyledSelect(props) {
  return (
    <FormControl component="fieldset">
      <StyledFormLabel {...props} />
      <FormGroup aria-label="position" row>
        <span className={props.description ? "selectWithToolTipSpacing" : "w-100"}>
        <Select
          isMulti={props.isMulti}
          name={props.name}
          id={props.id}
          placeholder={props.placeholder || ''}
          isClearable={props.isClearable}
          options={props.options}
          value={props.value}
          values={props.values}
          isDisabled={props.isDisabled}
          onChange={props.onChange}
          makeAnimated={makeAnimated}
          defaultValue={props.defaultValue}
          classNamePrefix='wcitySelect'
          className={cx("w-100", props.className)}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
        </span>
        {props.description 
        ? <ValueSetToolTip key={`${props.value}`} idName={props.idName} description={props.description}/>
        : null}
      </FormGroup>
    </FormControl>
  );
}
