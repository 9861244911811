import { LogLevel } from "@azure/msal-browser";
import * as dotenv from "dotenv";
import { api } from "./utils"; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
dotenv.config();

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process?.env.REACT_APP_CLIENT_ID, //Dev Client
    authority:
      "https://login.microsoftonline.com/b206d24e-61ef-4a1c-aff8-515f12be55ce",
    postLogoutRedirectUri: `https://${window.location.host}/logout`,
    navigateToLoginRequestUrl: true,
    redirectUri: `https://${window.location.host}/loginRedirect`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    piiLoggingEnabled: true,
    pollIntervalMilliseconds: 1000,
    navigateFrameWait: 1000,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        // console.debug();
        // const logObject = {
        //   message: message,
        //   occurredAt: new Date(),
        //   browserInfo: window.navigator.userAgent,
        //   logLevel: null,
        // };

        // if (
        //   message === "Error - PopupHandler.monitorPopupForHash - window closed"
        // ) {
        //   //ignore this message
        //   return;
        // }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            // logObject.logLevel = "error";
            // api.logMsal("public/LogClientEvents", logObject);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // api
            //   .logMsal("public/LogClientEvents", logObject)
            //   .catch(api.catchHandler);
            console.debug(message);
            return;
          case LogLevel.Warning:
            // logObject.logLevel = "warning";
            // api
            //   .logMsal("public/LogClientEvents", logObject)
            //   .catch(api.catchHandler);
            console.warn(message);
            return;
        }
      },
    },
  },
  telemetry: {
    application: {
      appName: "Campaign Services",
      appVersion: "1.1",
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read", "profile"],
  },
  wcityApi: {
    // endpoint: `https://${window.location.host}/api`,
    scopes: ["api://945bc011-041f-4e67-9327-4ea67394851c/api/access_as_user"], // e.g. api://xxxxxx/access_as_user
  },
};