import { Card, Col, Row } from "reactstrap";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

const NavigationTab = (props) => {
  const { activeTab, onSelectTab, children } = props;
  // const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (tabIndex) => {
    if (activeTab === tabIndex) return;
    onSelectTab(tabIndex);
  };

  return (
    <Card className={"mx-0 my-3"} style={{ background: "#fff", width: "100%" }}>
      <Row className={"px-4"}>{children}</Row>
    </Card>
  );
};

const NavigationTabItem = (props) => {
  const { isActive, onClick, title, tabIndex } = props;

  return (
    <Col
      lg={1}
      sm={12}
      className={
        "d-flex align-items-lg-stretch align-items-sm-baseline justify-content-center p-0 px-1"
      }
    >
      <button
        onClick={() => onClick(tabIndex)}
        style={{ height: "48px" }}
        className={classNames(
          isActive ? "client-tab-active" : "client-tab-inactive",
          "client-tab"
        )}
      >
        <span style={{ whiteSpace: "nowrap" }} className={"text-black mx-2"}>
          {title}
        </span>
      </button>
    </Col>
  );
};

NavigationTab.Item = NavigationTabItem;

export default NavigationTab;