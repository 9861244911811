import { useMsal } from "@azure/msal-react";
// import { DropdownButton, Button} from "react-bootstrap";
import Button from "@mui/material/Button";
import { helpers } from "../utils";
import { useContext } from "react";
import userContext from "../utils/userContext";
let microsoft = "microsoft";
let redirect = "redirect";
/**
 * Renders a sign-out button
 */
const SignOutButton = () => {
  const { instance } = useMsal();
  const { logout } = useContext(userContext);

  return (
    // <>
    // <Button className="ml-auto" onClick={() => handleLogout("microsoft")}>Sign out using Popup</Button>
    // </>
    <Button color="primary" variant="outlined" onClick={() => logout()}>
      Logout
    </Button>
    // <DropdownButton variant="outline-light" className="ml-auto" drop="left" title="Sign Out">
    //     <Dropdown.Item as="button" onClick={() => handleLogout("microsoft")}>Confirm</Dropdown.Item>
    //     <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</Dropdown.Item>
    // </DropdownButton>
  );
};

export default SignOutButton;
