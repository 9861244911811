import React, { useContext, useState } from "react";
import {
  LongBreadCrumb,
  PageContainer,
  NuStyledAsyncSelect,
  StyledButton,
  NuStyledInput,
  StyledSection,
  NuStyledSelect,
  StyledModal,
  StyledTD,
  Loader,
  StyledFormLabel,
} from "../components";
import { FormControl } from "@mui/material";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  api,
  constants,
  dateHelpers,
  formatPhoneField,
  formatPostalCode,
  formatSSNField,
  formatDateField,
  helpers,
  UserContext,
  properCaseText,
} from "../utils";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDebugConsole } from "../hooks";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

const phoneTypes = {
  mobile: "Mobile",
  home: "Home",
  work: "Work",
};
// const phoneOption = {
//   id: null,
//   phoneNumber: null,
//   type: { value: phoneTypes.mobile, label: phoneTypes.mobile },
// };

let newPatient = {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  ssn: null,
  ignoreSsn: false,
  employerId: null,
  insuranceMemberId: null,
  insuranceSubscriberId: null,
  gender: null,
  phoneList: [],
  homePhone: null,
  mobilePhone: null,
  workPhone: null,
  workPhoneExt: null,
  email: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zip: null,
  country: null,
  relationshipTypeId: null,
  subscriberDetails: {
    firstName: null,
    lastName: null,
    ignoreDob: false,
    dateOfBirth: null,
    ssn: null,
    ignoreSsn: false,
  },
};

const phoneTypeList = [
  { value: phoneTypes.mobile, label: phoneTypes.mobile },
  { value: phoneTypes.home, label: phoneTypes.home },
  { value: phoneTypes.work, label: phoneTypes.work },
];

export default function NewPatient(props) {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  let { clientId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  const [errors, setErrors] = useState([]);
  const [patient, setPatient] = useState(newPatient);
  const [countryOption, setCountryOption] = useState(null);
  const [relationshipOption, setRelationshipOption] = useState(null);
  const [genderOption, setGenderOption] = useState(null);
  const [foundPeople, setFoundPeople] = useState(null);
  const [showFoundPeopleModal, setShowFoundPeopleModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const maxPhones = 5;

  function isValidBirthDate(s) {
    if (!s) return false;
    const m = dateHelpers.verifyDate(s);
    if (!m) return false;
    return !m.isAfter();
  }

  function isValidPhone(s) {
    if (!s) return true;
    return s.length === 12;
  }

  function validateFields() {
    let errorList = [];
    if (!patient.firstName || _.trim(patient.firstName).length < 2) {
      errorList.push({
        field: "firstName",
        message: "First name can not be left blank.",
      });
    } else {
      removeErrorIfExists("firstName");
    }
    if (!patient.lastName || _.trim(patient.lastName).length < 2) {
      errorList.push({
        field: "lastName",
        message: "Last name can not be left blank.",
      });
    } else {
      removeErrorIfExists("lastName");
    }
    if (patient.email && !helpers.emailIsValid(patient.email)) {
      errorList.push({
        field: "email",
        message: "Email is not in the right format.",
      });
    } else {
      removeErrorIfExists("email");
    }
    if (!isValidBirthDate(patient.dateOfBirth)) {
      errorList.push({
        field: "dateOfBirth",
        message: "You must provide a valid date of birth.",
      });
    } else {
      removeErrorIfExists("dateOfBirth");
    }
    if (!patient.ignoreSsn && !helpers.socialIsValid(patient.ssn)) {
      errorList.push({
        field: "ssn",
        message: "Social is either in an incorrect format or it is blank.",
      });
    } else {
      removeErrorIfExists("ssn");
    }
    if (!genderOption) {
      errorList.push({
        field: "gender",
        message: "You must select a Gender.",
      });
    } else {
      removeErrorIfExists("gender");
    }

    if (isValidPhone(patient.mobilePhone)) {
      removeErrorIfExists("mobilePhone");
    } else {
      errorList.push({
        field: "mobilePhone",
        message:
          "Either provide a valid, 10-digit phone number of leave it blank.",
      });
    }

    if (isValidPhone(patient.homePhone)) {
      removeErrorIfExists("homePhone");
    } else {
      errorList.push({
        field: "homePhone",
        message:
          "Either provide a valid, 10-digit phone number of leave it blank.",
      });
    }

    if (isValidPhone(patient.workPhone)) {
      removeErrorIfExists("workPhone");
    } else {
      errorList.push({
        field: "workPhone",
        message:
          "Either provide a valid, 10-digit phone number of leave it blank.",
      });
    }

    if (shouldShowSubscriberFields()) {
      if (
        !patient?.subscriberDetails?.firstName ||
        _.trim(patient?.subscriberDetails?.firstName).length < 2
      ) {
        errorList.push({
          field: "subFirstName",
          message: "Subscriber first name can not be left blank.",
        });
      } else {
        removeErrorIfExists("subFirstName");
      }
      if (
        !patient?.subscriberDetails?.lastName ||
        _.trim(patient?.subscriberDetails?.lastName).length < 2
      ) {
        errorList.push({
          field: "subLastName",
          message: "Subscriber last name can not be left blank.",
        });
      } else {
        removeErrorIfExists("subLastName");
      }
      if (
        !patient.subscriberDetails.ignoreDob &&
        !isValidBirthDate(patient.subscriberDetails.dateOfBirth)
      ) {
        errorList.push({
          field: "subDateOfBirth",
          message: "You must provide a valid date of birth.",
        });
      } else {
        removeErrorIfExists("subDateOfBirth");
      }
      if (
        !patient.subscriberDetails.ignoreSsn &&
        !helpers.socialIsValid(patient.subscriberDetails.ssn)
      ) {
        errorList.push({
          field: "subSsn",
          message:
            "Social is either in an incorrect format or it is blank. The correct format is xxx-xx-xxxx",
        });
      } else {
        removeErrorIfExists("subSsn");
      }
    }

    if (errorList.length > 0) {
      setErrors(errorList);
      return;
    }
    setErrors([]);
    savePatient();
  }

  function savePatient(forceSave) {
    if (showFoundPeopleModal) setShowFoundPeopleModal(false);
    if (isSaving && !forceSave) return;
    setIsSaving(true);
    let payload = _.cloneDeep(patient);
    payload.clientId = clientId;
    payload.country = countryOption ? countryOption.label : null;
    payload.relationshipTypeId = relationshipOption.value;
    payload.relationshipType = relationshipOption;
    payload.gender = genderOption.label;
    payload.dateOfBirth = payload.dateOfBirth
      ? dateHelpers.getMomentFromString(payload.dateOfBirth)
      : null;
    payload.subscriberDetails.dateOfBirth = payload.subscriberDetails
      .dateOfBirth
      ? dateHelpers.getMomentFromString(payload.subscriberDetails.dateOfBirth)
      : null;
    payload.state = patient.state?.label ?? null;
    payload.forceSave = forceSave;
    if (payload.ignoreSsn) {
      payload.ssn = null;
    }
    if (payload.subscriberDetails.ignoreSsn) {
      payload.subscriberDetails.ssn = null;
    }
    api
      .securePost("participant/create", payload)
      .then((res) => {
        if (!res || !res.data) return;
        if (res.data.message.foundPeople) {
          //Show Dialog with found people to choose
          setFoundPeople(res.data.message?.foundPeople);
          setShowFoundPeopleModal(true);
          return;
        }
        const delay = 7 * 1000;
        if (res.data.message.createdPersonId) {
          const personId = res.data.message.createdPersonId;
          toast.success(
            "Successfully Created Patient. Navigating to Patient Chart.",
            {
              delay,
              onClose: () =>
                navigate(`/clients/${clientId}/patient-chart/${personId}`),
            }
          );
        }
        if (!res.data.success) {
          setIsSaving(false);
          toast.error(
            res.data.message ??
              "There was an issue saving this member. If this issue persists please contact and administrator."
          );
        }
      })
      .catch(() => {
        setIsSaving(false);
        toast.error(
          "There was an issue creating this member. If this issue persists, please contact an administrator."
        );
      });
  }

  function removeErrorIfExists(fieldName) {
    if (!errors || errors.length == 0) return;
    let newErrors = [...errors];
    if (getFieldErrorValue(fieldName)) {
      setErrors(_.reject(newErrors, (n) => n.field === fieldName));
    }
    return errors.find((x) => x.field == fieldName);
  }

  function onChangePatient(field, value, subField) {
    let newPatient = _.cloneDeep(patient);
    if (subField) {
      newPatient[subField][field] = value;
    } else {
      newPatient[field] = value;
    }
    setPatient(newPatient);
  }

  function getFieldErrorValue(fieldName) {
    if (!errors || errors.length == 0) return false;
    return errors.find((x) => x.field == fieldName);
  }

  function shouldShowSubscriberFields() {
    if (!relationshipOption) return false;
    return relationshipOption.label !== "Self";
  }

  function onBlur(value, fieldName, subField) {
    if (!subField) {
      switch (fieldName) {
        case "dateOfBirth":
          setPatient({ ...patient, [fieldName]: formatDateField(value) });
          break;
        case "firstName":
        case "lastName":
          onChangePatient(fieldName, properCaseText(value));
          break;
        case "ssn":
          setPatient({ ...patient, [fieldName]: formatSSNField(value) });
          break;
        case "mobilePhone":
        case "homePhone":
        case "workPhone":
          setPatient({ ...patient, [fieldName]: formatPhoneField(value) });
          break;
        case "zip":
          useDebugConsole(value);
          onChangePatient(fieldName, formatPostalCode(value));
      }
    } else {
      switch (fieldName) {
        case "ssn":
          onChangePatient(fieldName, formatSSNField(value), subField);
          break;
        case "firstName":
        case "lastName":
          onChangePatient(fieldName, properCaseText(value), subField);
          break;
      }
    }
  }

  function addNewPhoneItem() {
    if (patient.phoneList?.length < maxPhones) {
      let newPatient = _.cloneDeep(patient);
      let phoneList = patient.phoneList.splice();
      let id = uuidv4();
      useDebugConsole(id);
      newPatient.phoneList = [
        ...patient.phoneList,
        {
          id: uuidv4(),
          phoneNumber: null,
          type: phoneTypeList[0],
        },
      ];
      setPatient(newPatient);
    } else {
      toast.warning(`You can only have a max of ${maxPhones} entries.`);
    }
  }

  function handleFoundPeople() {
    if (isSaving) setIsSaving(false);
    setShowFoundPeopleModal(false);
  }

  const showOtherCountry = countryOption && countryOption.value === -1;
  return (
    <PageContainer>
      <Col>
        <div className="my-3">
          <LongBreadCrumb page={"New Member"} client />
        </div>
        <Card>
          <CardBody>
            {/*{isSaving ? (*/}
            {/*  <Loader />*/}
            {/*) : (*/}
            <Row>
              <Col>
                <form autoComplete="off">
                  <Row>
                    <Col>
                      <StyledSection title="Member Information">
                        <Row className="mb-1">
                          <Col sm={"12"} lg={"4"}>
                            <NuStyledInput
                              name={"firstName"}
                              required
                              disabled={isSaving}
                              onBlur={(e) =>
                                onBlur(e.target.value, "firstName")
                              }
                              errorMessage={
                                getFieldErrorValue("firstName")?.message
                              }
                              onChange={({ target }) =>
                                onChangePatient(target.name, target.value)
                              }
                              label={"First Name"}
                              value={patient?.firstName || ""}
                            />
                          </Col>
                          <Col sm={"12"} lg={"4"}>
                            <NuStyledInput
                              name={"lastName"}
                              required
                              disabled={isSaving}
                              onBlur={(e) =>
                                onBlur(e.target.value, e.target.name)
                              }
                              errorMessage={
                                getFieldErrorValue("lastName")?.message ?? ""
                              }
                              label={"Last Name"}
                              onChange={({ target }) =>
                                onChangePatient(target.name, target.value)
                              }
                              value={patient?.lastName || ""}
                            />
                          </Col>
                          <Col sm={"12"} lg={"4"}>
                            <NuStyledInput
                              name={"dateOfBirth"}
                              required
                              disabled={isSaving}
                              onBlur={(e) =>
                                onBlur(e.target.value, e.target.name)
                              }
                              errorMessage={
                                getFieldErrorValue("dateOfBirth")?.message ?? ""
                              }
                              label="Date of Birth"
                              onChange={({ target }) =>
                                onChangePatient(target.name, target.value)
                              }
                              value={patient?.dateOfBirth || ""}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col sm={"12"} lg={"4"}>
                            <NuStyledInput
                              name={"email"}
                              disabled={isSaving}
                              errorMessage={
                                getFieldErrorValue("email")?.message || ""
                              }
                              onChange={({ target }) =>
                                onChangePatient(target.name, target.value)
                              }
                              label={"Email"}
                              value={patient?.email ?? ""}
                            />
                          </Col>
                          <Col sm={"12"} lg={"4"}>
                            <CheckBoxInput
                              name={"ssn"}
                              id={"ssn"}
                              onBlur={(e) => onBlur(e.target.value, "ssn")}
                              disabled={isSaving || patient.ignoreSsn}
                              errorMessage={
                                getFieldErrorValue("ssn")?.message || ""
                              }
                              onChange={({ target }) =>
                                onChangePatient(target.name, target.value)
                              }
                              label={"SSN"}
                              maxLength={11}
                              value={patient?.ssn ?? ""}
                              checkedValue={patient.ignoreSsn}
                              tooltip={"Social not provided."}
                              onChecked={({ target }) =>
                                onChangePatient("ignoreSsn", target.checked)
                              }
                            />
                          </Col>
                          {/*<Col xs="4" className="mt-4">*/}
                          {/*  <div className="d-flex justify-content-center align-items-end h-75">*/}
                          {/*    <Checkbox*/}
                          {/*      className={"mr-1 h-75"}*/}
                          {/*      onChange={({ target }) =>*/}
                          {/*        onChangePatient(*/}
                          {/*          "ignoreSsn",*/}
                          {/*          target.checked*/}
                          {/*        )*/}
                          {/*      }*/}
                          {/*      value={patient.ignoreSsn}*/}
                          {/*    />*/}
                          {/*    <div className={"ml-2 align-middle h-75"}>*/}
                          {/*      Not Provided*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*</Col>*/}

                          <Col sm={"12"} lg={"4"}>
                            <NuStyledSelect
                              id="gender"
                              required
                              isDisabled={isSaving}
                              label="Gender"
                              errorMessage={
                                getFieldErrorValue("gender")?.message || ""
                              }
                              onChange={(e) => setGenderOption(e)}
                              value={genderOption}
                              options={constants.FHIR_GENDER_TYPES}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col xl="4">
                            <div className="mb-0">
                              <NuStyledAsyncSelect
                                id="relationship"
                                isClearable
                                required
                                label="Relationship"
                                isDisabled={isSaving}
                                onChange={(e) => setRelationshipOption(e)}
                                value={relationshipOption}
                                loadOptions={(typedValue, callback) =>
                                  api.loadInternalValueSets(
                                    typedValue,
                                    callback,
                                    constants.INTERNAL_VALUE_SET_URLS
                                      .RELATIONSHIP_TYPE,
                                    clientId,
                                    constants.VALUE_SET_TYPE_IDS
                                      .RELATIONSHIP_TYPE,
                                    false
                                  )
                                }
                                idName="relationshipType"
                                description={
                                  relationshipOption?.category?.description ||
                                  relationshipOption?.category?.text
                                }
                              />
                            </div>
                          </Col>
                          <Col xl="4">
                            <div className="mb-0">
                              <NuStyledInput
                                disabled={isSaving}
                                name={"employerId"}
                                onChange={({ target }) =>
                                  onChangePatient(target.name, target.value)
                                }
                                label={"Employee Number"}
                                value={patient?.employerId || ""}
                              />
                            </div>
                          </Col>
                          <Col xl="4">
                            <div className="mb-0">
                              <NuStyledInput
                                disabled={isSaving}
                                name={"insuranceMemberId"}
                                onChange={({ target }) =>
                                  onChangePatient(target.name, target.value)
                                }
                                label={"Insurance Member Id"}
                                value={patient?.insuranceMemberId || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                      </StyledSection>
                    </Col>
                  </Row>
                  {shouldShowSubscriberFields() ? (
                    <StyledSection title="Subscriber Information">
                      <Row>
                        <Col xl="4">
                          <NuStyledInput
                            required
                            disabled={isSaving}
                            name={"firstName"}
                            onBlur={(e) =>
                              onBlur(
                                e.target.value,
                                e.target.name,
                                "subscriberDetails"
                              )
                            }
                            errorMessage={
                              getFieldErrorValue("subFirstName")?.message ?? ""
                            }
                            onChange={({ target }) =>
                              onChangePatient(
                                target.name,
                                target.value,
                                "subscriberDetails"
                              )
                            }
                            label={"First Name"}
                            value={patient?.subscriberDetails.firstName ?? ""}
                          />
                        </Col>
                        <Col xl="4">
                          <NuStyledInput
                            required
                            disabled={isSaving}
                            name={"lastName"}
                            onBlur={(e) =>
                              onBlur(
                                e.target.value,
                                e.target.name,
                                "subscriberDetails"
                              )
                            }
                            errorMessage={
                              getFieldErrorValue("subLastName")?.message ?? ""
                            }
                            onChange={({ target }) =>
                              onChangePatient(
                                target.name,
                                target.value,
                                "subscriberDetails"
                              )
                            }
                            label={"Last Name"}
                            value={patient?.subscriberDetails.lastName ?? ""}
                          />
                        </Col>
                        <Col xl="4">
                          <Row>
                            <Col>
                              <NuStyledInput
                                name="subDateOfBirth"
                                required
                                disabled={
                                  isSaving ||
                                  patient?.subscriberDetails.ignoreDob
                                }
                                onBlur={(e) =>
                                  onBlur(e.target.value, e.target.name)
                                }
                                errorMessage={
                                  getFieldErrorValue("subDateOfBirth")
                                    ?.message ?? ""
                                }
                                label="Date of Birth"
                                onChange={({ target }) =>
                                  onChangePatient(
                                    "dateOfBirth",
                                    target.value,
                                    "subscriberDetails"
                                  )
                                }
                                value={
                                  patient?.subscriberDetails.dateOfBirth || ""
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="4">
                          <Row>
                            <Col>
                              <CheckBoxInput
                                name={"ssn"}
                                id={"subSSN"}
                                onBlur={(e) =>
                                  onBlur(
                                    e.target.value,
                                    "ssn",
                                    "subscriberDetails"
                                  )
                                }
                                disabled={
                                  isSaving ||
                                  patient.subscriberDetails.ignoreSsn
                                }
                                errorMessage={
                                  getFieldErrorValue("subSsn")?.message ?? ""
                                }
                                onChange={({ target }) =>
                                  onChangePatient(
                                    target.name,
                                    target.value,
                                    "subscriberDetails"
                                  )
                                }
                                label={"SSN"}
                                maxLength={11}
                                tooltip={"Social not provided."}
                                value={patient?.subscriberDetails.ssn ?? ""}
                                checkedValue={
                                  patient.subscriberDetails?.ignoreSsn
                                }
                                onChecked={({ target }) =>
                                  onChangePatient(
                                    "ignoreSsn",
                                    target.checked,
                                    "subscriberDetails"
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4">
                          <div className="mb-0">
                            <NuStyledInput
                              name={"insuranceSubscriberId"}
                              disabled={isSaving}
                              onChange={({ target }) =>
                                onChangePatient(target.name, target.value)
                              }
                              label={"Insurance Subscriber Id"}
                              value={patient?.insuranceSubscriberId ?? ""}
                            />
                          </div>
                        </Col>
                      </Row>
                    </StyledSection>
                  ) : null}
                  <StyledSection title="Contact Information">
                    <Col className={"p-0"}>
                      <Row className={"d-flex justify-content-between"}>
                        <Col xl={"5"}>
                          <Row>
                            <Col>
                              <div className="mb-0">
                                <NuStyledInput
                                  name={"addressLine1"}
                                  disabled={isSaving}
                                  invalid={_.includes(errors, "addressLine1")}
                                  errorMessage={errors["addressLine1"]}
                                  onChange={({ target }) =>
                                    onChangePatient(target.name, target.value)
                                  }
                                  label={"Address"}
                                  value={patient?.addressLine1 || ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-0">
                                <NuStyledInput
                                  name={"addressLine2"}
                                  disabled={isSaving}
                                  onChange={({ target }) =>
                                    onChangePatient(target.name, target.value)
                                  }
                                  label={"Address Line 2"}
                                  value={patient?.addressLine2 || ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={"6"}>
                              <div className="mb-0">
                                <NuStyledInput
                                  name={"city"}
                                  disabled={isSaving}
                                  onChange={({ target }) =>
                                    onChangePatient(target.name, target.value)
                                  }
                                  label={"City"}
                                  value={patient?.city || ""}
                                />
                              </div>
                            </Col>
                            <Col xl="3">
                              <div className="mb-0">
                                <NuStyledSelect
                                  id="state"
                                  isDisabled={isSaving}
                                  label="State"
                                  onChange={(e) =>
                                    useDebugConsole(e) ||
                                    onChangePatient("state", e)
                                  }
                                  value={patient?.state}
                                  options={constants.US_STATE_ABBR_OPTIONS}
                                />
                              </div>
                            </Col>
                            <Col xl="3">
                              <div className="mb-0">
                                <NuStyledInput
                                  name={"zip"}
                                  disabled={isSaving}
                                  onBlur={(e) =>
                                    onBlur(e.target.value, e.target.name)
                                  }
                                  onChange={({ target }) =>
                                    onChangePatient(target.name, target.value)
                                  }
                                  label={"Zip Code"}
                                  value={patient?.zip || ""}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {showOtherCountry ? (
                              <Col xl="7">
                                <div className="mb-0">
                                  <NuStyledInput
                                    name={"country"}
                                    disabled={isSaving}
                                    required
                                    onChange={({ target }) =>
                                      onChangePatient(target.name, target.value)
                                    }
                                    label={"Other Country"}
                                    value={patient?.country || ""}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            <Col xl={showOtherCountry ? "5" : "6"}>
                              <div className="mb-0">
                                <NuStyledSelect
                                  id="country"
                                  label="Country"
                                  disabled={isSaving}
                                  onChange={(e) => setCountryOption(e)}
                                  value={countryOption}
                                  options={constants.COUNTRY_OPTION_LIST}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          sm={"12"}
                          xl={"5"}
                          className={
                            "d-flex flex-column justify-content-start align-content-end"
                          }
                        >
                          <PhoneRow
                            onBlur={(e) =>
                              onBlur(e.target.value, "mobilePhone")
                            }
                            disabled={isSaving}
                            phoneType={phoneTypes.mobile}
                            errorMessage={
                              getFieldErrorValue("mobilePhone")?.message
                            }
                            value={patient.mobilePhone}
                            onChange={(v) => onChangePatient("mobilePhone", v)}
                          />
                          <PhoneRow
                            onBlur={(e) => onBlur(e.target.value, "homePhone")}
                            disabled={isSaving}
                            phoneType={phoneTypes.home}
                            errorMessage={
                              getFieldErrorValue("homePhone")?.message
                            }
                            value={patient.homePhone}
                            onChange={(v) => onChangePatient("homePhone", v)}
                          />
                          <PhoneRow
                            onBlur={(e) => onBlur(e.target.value, "workPhone")}
                            disabled={isSaving}
                            phoneType={phoneTypes.work}
                            errorMessage={
                              getFieldErrorValue("workPhone")?.message
                            }
                            value={patient.workPhone}
                            extValue={patient.workPhoneExt}
                            onExtChange={(v) =>
                              onChangePatient("workPhoneExt", v)
                            }
                            onChange={(v) => onChangePatient("workPhone", v)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </StyledSection>
                </form>
              </Col>
            </Row>
            {/*)}*/}
            <Row>
              <Col>
                <ButtonGroup className="float-right">
                  <StyledButton
                    color="primary"
                    loading={isSaving}
                    disabled={isSaving}
                    onClick={(e) => validateFields()}
                    // disabled={isSaving}
                  >
                    CREATE PATIENT
                  </StyledButton>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <StyledModal
          show={showFoundPeopleModal}
          onHide={() => handleFoundPeople()}
          scrollable
          size="xl"
          title={"Duplicate Patients Found"}
          showCancel
          disabled={false}
          onSave={() => savePatient(true)}
          savePrompt={"Not a duplicate - Save Patient"}
          // savePrompt={isDeleting ? "Delete Contact Log" : "Close"}
          // disabled={loading}
        >
          <Table>
            <thead>
              <tr>
                <th
                  className={
                    "diagnostic-table-header font-weight-bold header-align-left"
                  }
                >
                  Name
                </th>
                <th
                  className={
                    "diagnostic-table-header font-weight-bold header-align-left"
                  }
                >
                  Birthdate
                </th>
                <th
                  className={
                    "diagnostic-table-header font-weight-bold header-align-left"
                  }
                >
                  Gender
                </th>
                <th
                  className={
                    "diagnostic-table-header font-weight-bold header-align-left"
                  }
                >
                  Last 4 of Social
                </th>
                <th
                  className={
                    "diagnostic-table-header font-weight-bold header-align-left"
                  }
                >
                  Status
                </th>
                <th className={"diagnostic-table-header"} />
              </tr>
            </thead>
            <tbody>
              {foundPeople && foundPeople.length
                ? _.map(foundPeople, (fp) => {
                    return (
                      <tr>
                        <StyledTD className={"py-1 align-middle"}>
                          {fp.fullName}
                        </StyledTD>
                        <StyledTD className={"py-1 align-middle"}>
                          {dateHelpers.toMDYDateString(fp.birthDate)}
                        </StyledTD>
                        <StyledTD className={"py-1 align-middle"}>
                          {fp.gender}
                        </StyledTD>
                        <StyledTD className={"py-1 align-middle"}>
                          {fp.lastFour}
                        </StyledTD>
                        <StyledTD className={"py-1 align-middle"}>
                          {fp.active ? "Active" : "Inactive"}
                        </StyledTD>
                        <StyledTD className={"d-flex justify-content-end"}>
                          <StyledButton
                            color="primary"
                            to={`/clients/${clientId}/patient-chart/${fp.personId}`}
                          >
                            Discard &amp; use this patient
                          </StyledButton>
                        </StyledTD>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </StyledModal>
      </Col>
    </PageContainer>
  );
}

const PhoneRow = (props) => {
  const { onChange, onBlur } = props;

  const mainColSize = props.onExtChange ? "9" : "12";
  return (
    <Row className={"d-lg-flex justify-content-center align-items-center"}>
      <Col sm={mainColSize} xl={mainColSize}>
        <NuStyledInput
          disabled={props.disabled}
          onBlur={onBlur}
          label={`${props.phoneType} Number`}
          errorMessage={props.errorMessage}
          value={props.value}
          onChange={({ target }) => onChange(target.value)}
        />
      </Col>
      {props.onExtChange && (
        <Col xs={"3"}>
          <NuStyledInput
            disabled={props.disabled}
            label={"Ext"}
            value={props.extValue}
            onChange={({ target }) => props.onExtChange(target.value)}
          />
        </Col>
      )}
    </Row>
  );
};

const CheckBoxInput = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <FormControl component="fieldset">
      {/*<fieldset>*/}
      <StyledFormLabel {...props} />
      <div className={"checked-input-container"}>
        <div className={"container-checked-input"}>
          <Input
            type={props.type ?? "text"}
            value={props.value}
            disabled={props.disabled}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            innerRef={props.ref}
            invalid={props.errorMessage}
            valid={props.valid}
            className={cx(props.className, "checked-input")}
            placeholder={props.placeholder}
            name={props.name}
            id={props.id}
            maxLength={props.maxLength}
          />
        </div>
        <div className={"check-input-checkbox-container"}>
          <Input
            id={props.name}
            addon
            className={"input-checkbox"}
            checked={props.checkedValue}
            onChange={props.onChecked}
            aria-label="Checkbox for following text input"
            type="checkbox"
          />

          {props.tooltip ? (
            <Tooltip
              isOpen={tooltipOpen}
              target={props.id}
              toggle={toggleTooltip}
            >
              {props.tooltip}
            </Tooltip>
          ) : null}
        </div>
      </div>
      <FormFeedback invalid={props.errorMessage}>
        {props.errorMessage}
      </FormFeedback>
    </FormControl>
  );
};