import Skeleton from "react-loading-skeleton";
import React, { Fragment, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row } from "reactstrap";
import { api, constants, dateHelpers, helpers, UserContext } from "../utils";
import { WidgetCard } from "./index";
import MergeItemCard from "./MergeItemCard";
import cx from "classnames";

function PatientDataRow({ left, right, loading }) {
  if (loading) {
    return (
      <Row className="p-1 text-left">
        <Col className="col-6">
          <Skeleton />
        </Col>
        {right ? (
          <Col className="col-6">
            <Skeleton />
          </Col>
        ) : null}
      </Row>
    );
  }
  const ignoreRight = !right;
  return (
    <Row className="p-1 text-left">
      <Col lg={ignoreRight ? "12" : "6"} md={"12"} className="col-6">
        {left}
      </Col>
      {!ignoreRight ? <Col className="col-6">{right}</Col> : null}
    </Row>
  );
}

export default function RecordMergeWindow(props) {
  const userCtx = useContext(UserContext);
  const {
    title,
    participantRecord,
    subTitle,
    prefix,
    clientId,
    personId,
    onInclude,
  } = props;
  const [loading, setLoading] = useState(false);
  const [participantInfo, setParticipantInfo] = useState(null);
  const [personInfo, setPersonInfo] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [medicalCoverage, setMedicalCoverage] = useState({});
  const [dentalCoverage, setDentalCoverage] = useState({});
  const [visionCoverage, setVisionCoverage] = useState({});

  useEffect(() => {
    if (props.mergeItem && props.participantInfo) {
      setParticipantInfo(props.participantInfo);
    }
  }, [props.participantInfo]);

  useEffect(() => {
    if (personId) {
      getParticipantData();
    }
  }, [personId]);

  let participantFullName = "";
  let pageTitle = "";
  if (participantInfo) {
    participantFullName = `${_.startCase(
      _.toLower(participantInfo.firstName)
    )} ${_.capitalize(participantInfo.lastName)}`;
    pageTitle = participantFullName + " " + participantInfo.wellnecityId;
  }

  function getUIFriendlyDate(date) {
    return dateHelpers.toMDYDateString(date, dateHelpers.YMDHMS);
  }

  // function testGetFhirRecords() {
  //   api
  //     .secureFetch(`Participant/GetFhirMergeItems/${clientId}/${personId}`)
  //     .then((res) => {
  //       if (!res) return;
  //     })
  //     .catch(api.catchHandler);
  // }

  function getParticipantData() {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      if (personId) {
        apiCalls.push(getParticipantInfo());
        //apiCalls.push(getCarePlanReferenceInfo());
        //apiCalls.push(getPrimaryDiagnosisReferenceInfo());
        //apiCalls.push(getGoalReferenceInfo());
        //apiCalls.push(getRecentReferralReferenceInfo());
        //apiCalls.push(getTaskReferenceInfo());
        // dummy until apis are finished/know where to get data:
      }
      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));
          if (aggResults.participantInfoData) {
            const { med, vis, dent } = helpers.splitCoverageList(
              aggResults.participantInfoData.coverages
            );
            setMedicalCoverage(med);
            setDentalCoverage(dent);
            setVisionCoverage(vis);
            if (aggResults.participantInfoData.coverages) {
              aggResults.participantInfoData.groupedCoverages = _.groupBy(
                aggResults.participantInfoData.coverages,
                (c) => c.name
              );
              aggResults.participantInfoData.coverages = _.reject(
                aggResults.participantInfoData.coverages,
                (c) =>
                  _.some(
                    constants.COVERAGE_REJECT_LIST,
                    (crl) => crl.toLowerCase() === c.name.toLowerCase()
                  )
              );
            }
            setParticipantInfo(aggResults.participantInfoData);
          }
          if (aggResults.participantInfoData?.patientReferences) {
            const patientReferences = _.map(
              aggResults.participantInfoData.patientReferences,
              (r, i) => {
                return {
                  label: `${r} ${_.startCase(
                    _.toLower(aggResults.participantInfoData?.firstName)
                  )} 
              ${_.capitalize(aggResults.participantInfoData?.lastName)}`,
                  value: r,
                };
              }
            );
            // setSubjectOptions(patientReferences);
          }
          // if (aggResults.recentLabReferenceInfo) {
          //   setObservationOptions(aggResults.recentLabReferenceInfo);
          // }
          // if (aggResults.diagnosisReferenceInfo) {
          //   setConditionOptions(aggResults.diagnosisReferenceInfo);
          // }
          // if (aggResults.labTypeList) {
          //   setLabTypeOptions(aggResults.labTypeList);
          // }
          // if(aggResults.primaryDiagnosisReferenceInfo) {
          //   setPrimaryConditionOptions(aggResults.primaryDiagnosisReferenceInfo);
          // }
          // if(aggResults.goalReferenceInfo) {
          //   setGoalOptions(aggResults.goalReferenceInfo);
          // }
          // if(aggResults.recentLabReferenceInfo) {
          //   setServiceRequestOptions(aggResults.recentReferralReferenceInfo);
          // }
          // if(aggResults.carePlanReferenceInfo) {
          //   setCarePlanOptions(aggResults.carePlanReferenceInfo);
          // }
          // if(aggResults.taskReferenceInfo) {
          //   setTaskOptions(aggResults.taskReferenceInfo);
          // }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function getParticipantInfo() {
    return api
      .secureFetch(`Participant/ParticipantInfo/${clientId}/${personId}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { participantInfoData: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function captionedRowData(caption, data) {
    return (
      <Row>
        <Col>
          <b>{caption}</b>
          <p>{data}</p>
        </Col>
      </Row>
    );
  }

  function buildContactNumbers(contactNumbers) {
    if (!contactNumbers || !contactNumbers.length) return "";
    return contactNumbers.map((number, idx) => {
      return <ContactBadge key={`${number.value}-${idx}`} number={number} />;
    });
  }

  const entryTypeClass = `merge-item-${prefix.toLowerCase()}`;

  if (loading || !participantInfo) {
    return (
      <div className={"merge-window-container"}>
        <div className={"merge-window-title-container"}>
          <span className={"merge-window-title"}></span>
          <span>
            <Skeleton containerClassName={"skeleton-def w-75"} />
          </span>
        </div>
        <div className={cx("merge-window-body", entryTypeClass)}></div>
        {/*<div className={"merge-window-footer"}></div>*/}
      </div>
    );
  }
  const recordTitle = `${prefix}: ${participantInfo.id}`;
  return (
    <div className={"merge-window-container"}>
      <div className={"merge-window-title-container"}>
        <div className={"merge-window-title"}>{prefix}</div>
        <span className={"merge-window-subtitle"}>{participantInfo.id}</span>
      </div>
      <div className={cx("merge-window-body", entryTypeClass)}>
        <MergeItemCard
          title="Patient Information"
          startOpen={true}
          mergeItem={() => props.onInclude("person", participantInfo)}
          rejectItem={props.onReject}
        >
          <Fragment>
            <PatientDataRow
              left={captionedRowData("Name", participantFullName)}
              right={captionedRowData(
                "Hire Date",
                getUIFriendlyDate(participantInfo?.hireDate)
              )}
            />
            <PatientDataRow
              left={captionedRowData(
                "DOB",
                getUIFriendlyDate(participantInfo?.birthDate)
              )}
              right={captionedRowData(
                "Employee Termination Date",
                getUIFriendlyDate(participantInfo?.terminationDate)
              )}
            />
            <PatientDataRow
              left={captionedRowData(
                "SSN",
                !userCtx?.showSsn && participantInfo?.ssn ? (
                  <p>{`*****${participantInfo?.ssn.substr(
                    participantInfo?.ssn.length - 4
                  )}`}</p>
                ) : (
                  <p>{participantInfo?.ssn}</p>
                )
              )}
            />
            <PatientDataRow
              left={captionedRowData(
                "Gender",
                _.capitalize(participantInfo?.gender)
              )}
              // right={captionedRowData(
              //   "Medical Coverage Termination Date",
              //   getUIFriendlyDate(medicalCoverage?.endOn)
              // )}
            />
            <PatientDataRow
              left={captionedRowData(
                "Phone Number(s)",
                buildContactNumbers(participantInfo?.contactNumbers)
                // participantInfo?.contactNumbers &&
                //   participantInfo.contactNumbers.length &&
                //   participantInfo.contactNumbers[0].value
                //   ? helpers.formatPhoneNumber(
                //       participantInfo.contactNumbers[0].value
                //     )
                //   : ""
              )}
              // right={captionedRowData(
              //   "Dental Coverage Effective Date",
              //   getUIFriendlyDate(dentalCoverage?.startOn)
              // )}
            />
            <PatientDataRow
              left={captionedRowData(
                "Email",
                participantInfo?.emails &&
                  participantInfo.emails.length &&
                  participantInfo.emails[0].value
                  ? participantInfo.emails[0].value.toLowerCase()
                  : ""
              )}
              // right={captionedRowData(
              //   "Dental Coverage Termination Date",
              //   getUIFriendlyDate(dentalCoverage?.endOn)
              // )}
            />
            <PatientDataRow
              left={
                <Row>
                  <Col>
                    <b>Address</b>
                    <p>
                      {participantInfo?.addressLine1 ? (
                        <>
                          {_.startCase(
                            _.toLower(participantInfo?.addressLine1)
                          )}
                          <br />
                        </>
                      ) : null}
                      {participantInfo?.addressLine2 ? (
                        <>
                          {_.startCase(
                            _.toLower(participantInfo?.addressLine2)
                          )}
                          <br />
                        </>
                      ) : null}
                      {_.startCase(_.toLower(participantInfo?.city))}{" "}
                      {participantInfo?.state} {participantInfo?.zipCode}
                    </p>
                  </Col>
                </Row>
              }
              // right={captionedRowData(
              //   "Vision Coverage Effective Date",
              //   getUIFriendlyDate(visionCoverage?.startOn)
              // )}
            />
            <PatientDataRow
              left={captionedRowData(
                "Relationship",
                _.capitalize(participantInfo?.relation)
              )}
              // right={captionedRowData(
              //   "Vision Coverage Termination Date",
              //   getUIFriendlyDate(visionCoverage?.endOn)
              // )}
            />
            <PatientDataRow
              left={captionedRowData(
                "Insurance",
                <p>
                  {_.map(_.keys(participantInfo?.groupedCoverages), (key) => (
                    <div key={key}>{key}</div>
                  ))}
                </p>
              )}
              right={captionedRowData(
                "Eligibility Status",
                helpers.resolveEligibilityStatus(
                  participantInfo?.hireDate,
                  participantInfo?.terminationDate
                )
              )}
            />
            <PatientDataRow
              left={captionedRowData(
                "Subscriber Name",
                participantInfo?.subscriberName
              )}
              right={null}
            />
            {/* <b>Insurance Effective</b>
                    <p>{participantInfo?.coverages && participantInfo.coverages.length ?
                      // _.map(participantInfo.coverages, x => getUIFriendlyDate(x.startOn))
                        getUIFriendlyDate(participantInfo.coverages[0].startOn)
                        : ""}</p> */}
          </Fragment>
        </MergeItemCard>
        {participantInfo?.coverages && participantInfo.coverages.length
          ? _.map(participantInfo.coverages, (cov) => (
              <MergeItemCard
                startOpen={false}
                title={cov.name}
                mergeItem={() => props.onInclude("coverage", cov)}
              ></MergeItemCard>
            ))
          : null}
      </div>
      {/*<div className={"merge-window-footer"}></div>*/}
    </div>
  );
}

const ContactBadge = (props) => {
  const [tooltipOpen, setToolTipOpen] = useState(false);
  const { number, tooltip } = props;
  const toggleTooltip = () => setToolTipOpen(!tooltipOpen);
  const keyId = `${number.use}-badge`;
  return (
    // <>
    //   <Badge className={"contact__badge"} id={keyId}>
    <div>
      <div className={"d-flex flex-row align-items-center"}>
        <div className={"contact__phone__badge"}>
          ({number.use.substring(0, 1)})
        </div>
        <div className={"contact__separator__badge"} />
        <div className={"contact__phone__value"}>{number.value}</div>
      </div>
    </div>
    // {/*</Badge>*/}
    // <Tooltip
    //   placement={"bottom"}
    //   isOpen={tooltipOpen}
    //   target={keyId}
    //   toggle={toggleTooltip}
    // >
    //   {number.use}
    // </Tooltip>
    // {/*</>*/}
  );
};