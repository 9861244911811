import React, { useState, useContext } from "react";
import { api, constants, dateHelpers, helpers, UserContext } from "../utils";
import { Row, Col, Table, Tooltip } from "reactstrap";
import {
  StyledAsyncSelect,
  StyledModal,
  StyledSingleDatePicker,
  StyledSelect,
  StyledInput,
  StyledSection,
  FhirChoiceVariable,
  PatientChartNotes,
  ValueSetToolTip,
} from ".";
import { toast } from "react-toastify";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

export default function PatientChartGoalModals({
  goalEditModalOpen,
  goalViewModalOpen,
  reset,
  isEditing,
  currentGoal = {},
  loading,
  subjectOptions = [],
  conditionOptions = [],
  observationOptions = [],
  serviceRequestOptions = [],
  isDeleting,
  isArchiving,
  setCurrentGoal,
  setLoading,
  refreshData,
  refreshReferenceData,
  clientId,
}) {
  const userCtx = useContext(UserContext);
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [editingGoalNoteIndices, setEditingGoalNoteIndicies] = useState([]);

  function onChangeGoal(field, value) {
    let tempGoal = Object.assign({}, currentGoal);
    tempGoal[field] = value;
    setCurrentGoal(tempGoal);
  }

  function updateGoal(payloadChange, action) {
    setLoading(true);
    const payload = {
      ...currentGoal,
      clientId: clientId,
      subjectReference:
        currentGoal?.subjectReference?.value || _.head(subjectOptions).value,
      lifecycleStatus: currentGoal?.lifecycleStatus?.value,
      addressesReference: currentGoal?.addressesReference?.value,
      ...payloadChange,
    };
    helpers.addChoiceVariable(
      payload,
      "detail",
      currentGoal?.detailType,
      currentGoal.detail
    );
    api
      .securePost("Participant/UpdateGoal", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "Goal",
            "Goal",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          setEditingGoalNoteIndicies([]);
          reset();
          refreshData();
          refreshReferenceData();
          toast.success(`Goal successfully ${action}`);
        } else {
          toast.error(`Goal could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Goal could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveGoal() {
    if (!goalValidated()) return;

    if (currentGoal.note) {
      let currentGoalTemp = Object.assign({}, currentGoal);
      currentGoalTemp.notes.push(currentGoalTemp.note);
      currentGoalTemp.note = { text: "" };
      setCurrentGoal(currentGoalTemp);
    }
    updateGoal({}, "saved");
  }

  function deleteGoal() {
    updateGoal(
      {
        lifecycleStatus: _.find(constants.FHIR_GOAL_LIFECYCLE_STATUSES, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function archiveGoal() {
    updateGoal(
      {
        lifecycleStatus: _.find(constants.FHIR_GOAL_LIFECYCLE_STATUSES, {
          label: "Completed",
        })?.value,
      },
      "archived"
    );
  }

  function goalValidated() {
    if (!currentGoal) {
      toast.error("No Goal found. Please try again");
      return false;
    }
    if (!currentGoal.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    if (!currentGoal.description || currentGoal.description.length <= 0) {
      toast.warning("Description is required");
      return false;
    }
    if (!currentGoal.lifecycleStatus) {
      toast.warning("Lifecycle Status is required");
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentGoal.detailRange.units &&
      currentGoal.detailRange.low !== null &&
      !isNaN(currentGoal.detailRange.low)
    ) {
      toast.warning("Units are required with Detail Range");
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentGoal.detailRange.units &&
      currentGoal.detailRange.high !== null &&
      !isNaN(currentGoal.detailRange.high)
    ) {
      toast.warning("Units are required with Detail Range");
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value &&
      currentGoal.detailRatio?.numerator &&
      !currentGoal.detailRatio?.denominator
    ) {
      toast.warning(
        "Detail Ratio Denominator is required if Detail Ratio Numerator is present"
      );
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value &&
      currentGoal.detailRatio?.denominator &&
      !currentGoal.detailRatio?.numerator
    ) {
      toast.warning(
        "Detail Ratio Numerator is required if Detail Ratio Denominator is present"
      );
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value &&
      !currentGoal.detailRatio.units &&
      currentGoal.detailRatio.numerator !== null &&
      !isNaN(currentGoal.detailRatio.numerator)
    ) {
      toast.warning("Units are required with Detail Ratio");
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.INTEGER.value &&
      !isNaN(currentGoal.detailInteger) &&
      currentGoal.detailInteger % 1 !== 0
    ) {
      toast.warning("Only integers are allowed with Detail Integer");
      return false;
    }
    if (
      currentGoal.detailType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY.value &&
      !currentGoal.detailQuantity.units &&
      currentGoal.detailQuantity.value !== null &&
      !isNaN(currentGoal.detailQuantity.value)
    ) {
      toast.warning("Units are required with Detail Quantity");
      return false;
    }
    /* FHIR RULE: if Goal.target.detail is populated, Goal.target.measure is required */
    if (
      currentGoal[`detail${currentGoal.detailType?.label}`] &&
      !currentGoal.targetMeasure
    ) {
      toast.warning("Target Measure is required if Target Detail is populated");
      return false;
    }
    return true;
  }

  function getGoalDetail(goal) {
    // note: detail is a choice variable in Goal resource (could be multiple types within FHIR; each type might need to be displayed differently)
    if (!goal || !goal.detail) return;
    const detailType = goal.detail.type;
    if (
      detailType === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value
    ) {
      return `${goal.detail?.low ?? ""}-${goal.detail?.high ?? ""}`;
    }
    if (
      detailType === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY.value
    ) {
      return `${goal.detail.quantity?.value ?? ""} ${
        goal.detail.quantity?.units
      }`;
    }
    if (
      detailType === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value
    ) {
      return `${goal.detail?.numerator} / ${goal.detail?.denominator}`;
    }
    if (
      detailType === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING.value
    ) {
      return `${goal.detail?.string}`;
    }

    return;
  }

  return (
    <>
      {/* Goal Edit Modal  */}
      <StyledModal
        show={goalEditModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={
          isEditing
            ? `Update Goal ${
                currentGoal?.archived
                  ? `ARCHIVED (${currentGoal?.lifecycleStatus?.label})`
                  : ""
              }`
            : "Create Goal"
        }
        onSave={saveGoal}
        savePrompt={"Save Goal"}
        showRequiredFieldsMessage={true}
        disabled={loading}
      >
        <StyledSection title="Info">
          <Row className="mb-4">
            <Col>
              <StyledSelect
                label="Subject"
                required
                isClearable
                options={subjectOptions}
                name="subject"
                id="subject"
                value={currentGoal?.subjectReference || ""}
                onChange={(selection) =>
                  onChangeGoal("subjectReference", selection)
                }
              />
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="About">
          <Row className="mb-4">
            <Col xs="5">
              <StyledAsyncSelect
                id="category"
                isClearable
                label="Category"
                onChange={(e) => onChangeGoal("category", e)}
                value={currentGoal?.category}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.GOAL_CATEGORIES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.GOAL_CATEGORIES,
                    false
                  )
                }
                idName="goalCategory"
                description={
                  currentGoal?.category?.description ??
                  currentGoal?.category?.text
                }
              />
            </Col>
            <Col>
              <StyledInput
                id="description"
                name="Description"
                label="Description"
                maxLength="500"
                value={currentGoal?.description}
                onChange={(e) => onChangeGoal("description", e.target.value)}
                required
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="5">
              <StyledAsyncSelect
                id="priority"
                isClearable
                label="Priority"
                onChange={(e) => onChangeGoal("priority", e)}
                value={currentGoal?.priority}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.GOAL_PRIORITIES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.GOAL_PRIORITIES,
                    false
                  )
                }
                idName="goalPriority"
                description={currentGoal?.priority?.description}
              />
            </Col>
            <Col>
              <StyledSelect
                id="lifecycleStatus"
                label="Lifecycle Status"
                required
                onChange={(e) => onChangeGoal("lifecycleStatus", e)}
                value={currentGoal?.lifecycleStatus}
                options={constants.FHIR_GOAL_LIFECYCLE_STATUSES}
                isClearable
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSingleDatePicker
                label="Start Date"
                name="Start Date"
                id="startDate"
                selected={currentGoal?.startDate}
                onChangeCallback={(e) => onChangeGoal("startDate", e)}
              />
            </Col>
            <Col>
              <StyledAsyncSelect
                id="achievementStatus"
                isClearable
                label="Achievement Status"
                onChange={(e) => onChangeGoal("achievementStatus", e)}
                value={currentGoal?.achievementStatus}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.GOAL_ACHIEVEMENTS,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.GOAL_ACHIEVEMENTS,
                    false
                  )
                }
                idName="goalAchievementStatus"
                description={currentGoal?.achievementStatus?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="addressesReference"
                label="Addresses"
                onChange={(e) => onChangeGoal("addressesReference", e)}
                value={currentGoal?.addressesReference}
                options={conditionOptions.concat(
                  observationOptions,
                  serviceRequestOptions
                )}
                isClearable
              />
            </Col>
            <Col>
              <StyledInput
                id="outcomeCode"
                name="outcomeCode"
                label="Outcome Code"
                maxLength="500"
                value={currentGoal?.outcomeCode}
                onChange={(e) => onChangeGoal("outcomeCode", e.target.value)}
              />
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Target">
          <Row className="mb-4">
            <Col xs="6">
              <StyledInput
                id="targetMeasure"
                name="Target Measure"
                label="Target Measure"
                maxLength="500"
                value={currentGoal?.targetMeasure}
                onChange={(e) => onChangeGoal("targetMeasure", e.target.value)}
              />
            </Col>
            <Col>
              <StyledSingleDatePicker
                label="Target Due"
                name="Target Due"
                id="targetDue"
                selected={currentGoal?.targetDue}
                onChangeCallback={(e) => onChangeGoal("targetDue", e)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="12">
              <FhirChoiceVariable
                id="detailType"
                name="detail"
                label="Choose Target Detail Type"
                obj={currentGoal}
                valueType={currentGoal?.detailType}
                onChangeType={(e) => onChangeGoal("detailType", e)}
                value={currentGoal?.detail}
                onChangeCallback={(val, type) =>
                  onChangeGoal(`detail${type}`, val)
                }
                options={[
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.BOOLEAN,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.INTEGER,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO,
                ]}
              />
            </Col>
          </Row>
        </StyledSection>
        <PatientChartNotes
          obj={currentGoal}
          setObj={setCurrentGoal}
          editingNoteIndices={editingGoalNoteIndices}
          setEditingNoteIndicies={setEditingGoalNoteIndicies}
          onChange={onChangeGoal}
        />
      </StyledModal>

      {/* Goal View Only Modal */}
      <StyledModal
        show={goalViewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        onHide={reset}
        size="xl"
        title={
          isDeleting
            ? `Delete Goal?`
            : isArchiving
            ? `Archive Goal?`
            : `Goal Display`
        }
        onSave={isDeleting ? deleteGoal : isArchiving ? archiveGoal : reset}
        savePrompt={
          isDeleting ? "Delete Goal" : isArchiving ? "Archive Goal" : "Close"
        }
        disabled={loading}
      >
        <StyledSection title="Info">
          {currentGoal?.archived ? (
            <div className="mb-3 red center-text">
              ARCHIVED ({currentGoal?.lifecycleStatus?.label})
            </div>
          ) : null}
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentGoal?.subjectReference?.label}
          </div>
        </StyledSection>
        <StyledSection title="About">
          <div className="mb-1">
            <b>Description:&nbsp;</b>
            {currentGoal?.description}
          </div>
          <div className="mb-1">
            <b>Category:&nbsp;</b>
            {currentGoal?.category?.label}
          </div>
          <div className="mb-1">
            <b>Priority:&nbsp;</b>
            {currentGoal?.priority?.label}
          </div>
          <div className="mb-1">
            <b>Lifecycle Status:&nbsp;</b>
            {currentGoal?.lifecycleStatus?.label}
          </div>
          <div className="mb-1">
            <b>Achievement Status:&nbsp;</b>
            {currentGoal?.achievementStatus?.label}
          </div>
          <div className="mb-1">
            <b>Start Date:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentGoal?.startDate,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Addresses:&nbsp;</b>
            {currentGoal?.addressesReference?.label}
          </div>
          <div className="mb-1">
            <b>Outcome Code:&nbsp;</b>
            {currentGoal?.outcomeCode}
          </div>
        </StyledSection>
        <StyledSection title="Target">
          <div className="mb-1">
            <b>Target Measure:&nbsp;</b>
            {currentGoal?.targetMeasure}
          </div>
          <div className="mb-1">
            <b>Target Due:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentGoal?.targetDue,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Result:&nbsp;</b>
            {getGoalDetail(currentGoal)}
          </div>
        </StyledSection>
        <StyledSection title="Notes">
          {_.map(currentGoal?.notes, (note, i) => {
            return (
              <>
                <div key={`note${i}`} className="mb-1">
                  {note.text}
                </div>
                <hr />
              </>
            );
          })}
        </StyledSection>
      </StyledModal>
    </>
  );
}