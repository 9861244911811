import { useState, useCallback } from "react";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import { GoTrashcan } from "react-icons/go";
import { useEffect } from "react";
import { StyledInput, StyledSelect } from "./";
import { constants } from "../utils";

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  width: 50,
  height: 50,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function DropzoneUpload(props) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      let duplicatesExist = _.chain([...files, ...acceptedFiles])
        .groupBy((f) => f.name)
        .map((list, key) => list)
        .filter((l) => l.length > 1)
        .value()?.length;
      if (duplicatesExist >= 1) {
        //TODO
        // warningToast("A file with this same name is already in the list to import.")
      } else {
        let uploadList = _.chain([...files, ...acceptedFiles])
          .uniqBy((f) => f.name)
          .map((f) => {
            // add this back if you want to have a mini preview of the file upload
            f = Object.assign(f, {
              preview: URL.createObjectURL(f),
            });
            return f;
          })
          .value();
        if (uploadList.length <= props.maxFiles) {
          setFiles(uploadList);
        }
      }
    },
    [files]
  );

  useEffect(() => {
    props.onUpload(files);
  }, [files]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png"],
      "application/pdf": [".pdf"],
    },
  });

  function handleFileDelete(file) {
    const filtered = files.filter((f) => f.path !== file.path);
    setFiles(filtered);
  }

  let dropzoneInputProps = getInputProps();
  dropzoneInputProps.className = "d-none";
  return (
    <div className="mt-2">
      <div
        {...getRootProps({
          className: "dropzone render-dropzone p-5 text-center",
        })}
      >
        <StyledInput {...dropzoneInputProps} />
        <p className="text-primary">Select file(s)</p>
        <p className="text-muted">
          <p style={{ textAlign: "center" }}>
            <FaFileUpload size="4em" />
          </p>
          <small>or drop file(s) here</small>
        </p>
      </div>
      {files && files.length > 0 && (
        <>
          <div>
            <ul className="render-upload-list">
              {files.map((file) => (
                <li
                  key={file.path}
                  className="d-flex justify-content-between align-items-center"
                >
                  {file.preview && (
                    <div style={thumb} key={file.name}>
                      <div style={thumbInner}>
                        <img
                          src={file.preview}
                          style={img}
                          // Revoke data uri after image is loaded
                          onLoad={() => {
                            URL.revokeObjectURL(file.preview);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {file.name}
                  <div role="button" onClick={() => handleFileDelete(file)}>
                    <GoTrashcan />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
