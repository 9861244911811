import { Modal, ButtonGroup, Row, Col } from "react-bootstrap";
import { StyledButton } from "./";

export default function StyledModal(props) {
  return (
    <Modal
      scrollable
      show={props.show}
      onHide={props.onHide}
      size={props.size}
      centered
      dialogClassName={"p-0"}
    >
      <Modal.Header closeButton={props.showCloseButton}>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col xs="3">
            {props.showRequiredFieldsMessage ? (
              <span className="text-danger float-left fontSize85">
                Required fields *
              </span>
            ) : null}
          </Col>
          <Col xs="9">
            <ButtonGroup className="float-right">
              {props.showCancel ? (
                <StyledButton onClick={props.onHide} className="mr-2">
                  CANCEL
                </StyledButton>
              ) : null}
              <StyledButton
                color="primary"
                onClick={(e) => props.onSave(e)}
                disabled={props.disabled}
                // showSaveIcon
              >
                {_.toUpper(props.savePrompt)}
              </StyledButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
