import { Col, Container, Row } from "reactstrap";
import { SideNavigation, Header } from "./";
import classNames from "classnames";

export default function PageContainer({ children, classes, ...rest }) {
  return (
    <Container fluid className={"p-0 m-0 back-drop vh-100"}>
      {/*<Col lg={'12'} className={'m-0 p-0 vw-100 vh-100'}>*/}
      <Row lg={2} id={"header-container"} className={"m-0 w-100"}>
        <Header />
      </Row>
      <Row
        id={"content-container"}
        className={classNames("m-0", "px-5", "py-2", {
          classes: classes,
        })}
      >
        {children}
      </Row>
      {/*</Col>*/}
    </Container>
  );
}