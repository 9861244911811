import React, { Fragment, useState, useEffect } from "react";
import Switch from "react-switch";
import { StyledFormLabel, StyledInput, StyledToggle } from "./index";

export default function StyledFilterToggle(props) {
    const [value, setValue] = useState(false);

    useEffect(() => {
        setValue(props.value || false);
    }, [props.value]);

    function onChange(checked) {
        console.debug(checked);
        const filteredDisplay = checked ? props.displayName : "";
        props.onChangeCallback({
            filterName: props.filterName,
            value: checked === true,
            filteredDisplay,
        });
        setValue(checked);
    }

    return (
        <Fragment>
            {props.displayName ? (
                <StyledFormLabel {...props} label={props.displayName} />
            ) : null}
            <StyledToggle
                labelPlacement={"left"}
                onClick={onChange}
                value={value || false}
            />
        </Fragment>
    );
}
