import React, { useEffect, useState, Fragment } from "react";
import { helpers, api } from "../utils";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import _ from "lodash";
import { LongBreadCrumb, PageContainer } from "./";
import {
  Col,
  Row,
  ButtonGroup,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import { StyledButton, ProductTree, StyledInput, StyledSelect } from "./";
import { MdOutlineAccountTree } from "react-icons/md";
import classNames from "classnames";

const emptyProduct = {
  id: 0,
  name: "",
  parentSavingsLeverId: null,
  formulaFileKey: null,
};

const VIEWS = {
  EDIT: "Edit",
  HIERARCHY: "Hierarchy",
};

export default function ProductConfiguration(props) {
  const [availableProducts, setAvailableProducts] = useState(null);
  const [productHierarchy, setProductHierarchy] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [viewMode, setViewMode] = useState(VIEWS.EDIT);

  useEffect(() => {
    let apiCalls = [api.getProducts()];
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.products) {
          let leverlist = _.map(aggResults.products, (p) => ({
            value: p.id,
            label: p.name,
          }));
          leverlist.unshift({ label: "No parent", value: null });
          setAvailableProducts(leverlist);
        }
      })
      .catch((error) => {
        helpers.catchHandler(error);
      });
  }, []);

  useEffect(() => {
    if (!loading) return;
    GetHierarchy();
  }, [loading]);

  const GetHierarchy = () => {
    setLoading(true);
    api
      .secureFetch("SavingsLever/SLHierarchy", {})
      .then((response) => {
        if (response.data && response.data.success) {
          setProductHierarchy(response.data.message);
        }
      })
      .catch((error) => {
        helpers.catchHandler(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let nodeList = null;
    if (productHierarchy) {
      nodeList = helpers.getCheckboxTreeNodes(productHierarchy, checked);
    }
    if (!_.isEqual(nodeList, nodes)) {
      setNodes(nodeList);
    }
  }, [productHierarchy, checked]);

  function onChangeProduct(field, value) {
    let tempProduct = Object.assign({}, selectedProduct);
    tempProduct[field] = value;
    setSelectedProduct(tempProduct);
  }

  function isInvalid() {
    let errors = [];
    if (!selectedProduct.name) {
      errors.push("Please enter a name for the product.");
    }
    let errorMessage = _.join(errors, " ");
    if (errorMessage.length > 0) {
      setAlertMessage({
        flavor: "danger",
        text: errorMessage,
      });
      return true;
    } else {
      return false;
    }
  }

  function saveProduct() {
    if (isInvalid()) return;
    // TO DO --> shape the payload
    // let payload = Object.assign({}, selectedProduct);
    api
      .securePost(`SavingsLever/SaveSavingsLever`, selectedProduct)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setAlertMessage({
            flavor: "success",
            text: "Product saved.",
          });
          setTimeout(() => {
            setLoading(true);
            setAlertMessage(null);
            setSelectedProduct(null);
          }, 2000);
        } else {
          setAlertMessage({
            flavor: "danger",
            text: "Error saving product",
          });
        }
      });
  }

  function selectProduct(product) {
    setSelectedProduct(null);
    setChecked([]);
    setViewMode(VIEWS.EDIT);
    let productKey = product.key || product.value;
    setSelectedProduct(product);
    setChecked([productKey]);
  }

  useEffect(() => {
    if (checked && checked.length > 0) {
      onCheckboxTreeClick(checked[0]);
    }
  }, [checked]);

  function onCheckboxTreeClick(checkedKey) {
    let parentProduct = helpers.findFirstParent(nodes, checkedKey);
    if (parentProduct) {
      setSelectedProduct(parentProduct);
    } else {
      if (!selectedProduct) {
        console.warning(
          "may be missing scenario to find node here",
          helpers.findFirstParent(nodes, checkedKey)
        );
      }
    }
  }

  const StyledNode = styled.button`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #009879;
    cursor: default !important;
  `;
  function renderChildren(parent) {
    return _.map(parent.children, (c) => (
      <TreeNode
        label={
          <StyledNode
          // onClick={() => {
          //   selectProduct(c.parent);
          // }}
          >
            {c.parent.name}
          </StyledNode>
        }
      >
        {c.children.length > 0 ? renderChildren(c) : null}
      </TreeNode>
    ));
  }
  function createNewProduct(parent = null) {
    setViewMode(VIEWS.EDIT);
    let newProduct = Object.assign({}, emptyProduct);
    if (parent) {
      newProduct.parentSavingsLeverId = parent.id;
    }
    setSelectedProduct(newProduct);
  }

  function closeOutEditing() {
    setSelectedProduct(null);
    setChecked([]);
  }

  function toggleViewMode() {
    if (viewMode === VIEWS.EDIT) {
      setViewMode(VIEWS.HIERARCHY);
    } else {
      setViewMode(VIEWS.EDIT);
    }
  }

  return (
    <PageContainer>
      <Col className={"m-0 p-0  h-100 flex-column justify-content-between"}>
        <LongBreadCrumb
            context={"Products"}
          page={"Configuration"}
          loading={loading}
          trailing={
            <ButtonGroup>
              <Button
                className={classNames("plan-toggle-buttons", {
                  "plan-btn-active": viewMode === VIEWS.EDIT,
                })}
                outline
                onClick={() =>
                  viewMode === VIEWS.HIERARCHY ? setViewMode(VIEWS.EDIT) : null
                }
              >
                Edit
              </Button>
              <Button
                className={classNames("plan-toggle-buttons", {
                  "plan-btn-active": viewMode === VIEWS.HIERARCHY,
                })}
                outline
                onClick={() =>
                  viewMode === VIEWS.EDIT ? setViewMode(VIEWS.HIERARCHY) : null
                }
              >
                Hierarchy
              </Button>
            </ButtonGroup>
            // <ButtonGroup>
            //   <StyledButton
            //     color={viewMode === VIEWS.EDIT ? "primary" : ""}
            //     onClick={toggleViewMode}
            //     showEditIcon
            //   >
            //     Edit Mode
            //   </StyledButton>
            //   <StyledButton
            //     color={viewMode === VIEWS.HIERARCHY ? "primary" : ""}
            //     onClick={toggleViewMode}
            //     icon={MdOutlineAccountTree}
            //   >
            //     Hierarchy
            //   </StyledButton>
            // </ButtonGroup>
          }
        />
        <Row lg={10} className={"m-0 p-0 h-100"}>
          {viewMode === VIEWS.EDIT ? (
            <ProductEdit
              productHierarchy={productHierarchy}
              setSelectedProduct={setSelectedProduct}
              checked={checked}
              setViewMode={setViewMode}
              setChecked={setChecked}
              nodes={nodes}
              selectedProduct={selectedProduct}
            />
          ) : null}
          {viewMode === VIEWS.HIERARCHY ? (
            <Hierarchy productHierarchy={productHierarchy} />
          ) : null}
          {/*<>*/}
          {/*  <Col lg={3} className={"m-0 pe-1 h-100"}>*/}
          {/*    <Card className={"h-100 p-4"}>*/}
          {/*{availableProducts ? (*/}
          {/*  // <>*/}
          {/*  //     {viewMode === VIEWS.EDIT ? (*/}
          {/*  //*/}
          {/*  //     ) : (*/}
          {/*  <>*/}
          {/*    {productHierarchy ? (*/}
          {/*      <Tree*/}
          {/*        lineWidth={"2px"}*/}
          {/*        lineColor={"#58539d"}*/}
          {/*        // lineColor={'rgba(204, 213, 226, 1)'}*/}
          {/*        lineBorderRadius={"10px"}*/}
          {/*        label={<StyledNode>Savings Libraries</StyledNode>}*/}
          {/*      >*/}
          {/*        {renderChildren(productHierarchy)}*/}
          {/*      </Tree>*/}
          {/*    ) : null}*/}
          {/*  </>*/}
          {/*) : null}*/}
          {/*</>) : null}*/}
          {/*</Card>*/}
          {/*  </Col>*/}
          {/*  <Col lg={9} className={"m-0 p-0 vh-75"}>*/}
          {/*    <Card className={"h-100 p-3"}>*/}
          {/*      {viewMode === VIEWS.EDIT && selectedProduct ? (*/}
          {/*        <>*/}
          {/*          <Row>*/}
          {/*            <Col>*/}
          {/*              <h3>*/}
          {/*                Edit{" "}*/}
          {/*                {selectedProduct.name*/}
          {/*                  ? selectedProduct.name*/}
          {/*                  : "New Product"}*/}
          {/*              </h3>*/}
          {/*              {alertMessage ? (*/}
          {/*                <Alert color={alertMessage.flavor}>*/}
          {/*                  {alertMessage.text}*/}
          {/*                </Alert>*/}
          {/*              ) : null}*/}
          {/*            </Col>*/}
          {/*          </Row>*/}
          {/*          <Row>*/}
          {/*            <Col>*/}
          {/*              <StyledInput*/}
          {/*                type="text"*/}
          {/*                maxLength="50"*/}
          {/*                id="productName"*/}
          {/*                value={selectedProduct.name || ""}*/}
          {/*                onChange={(e) =>*/}
          {/*                  onChangeProduct("name", e.target.value)*/}
          {/*                }*/}
          {/*                name="productName"*/}
          {/*                label="Name"*/}
          {/*              />*/}
          {/*            </Col>*/}
          {/*            <Col>*/}
          {/*              <StyledSelect*/}
          {/*                id="parentSavingsLeverId"*/}
          {/*                isSingleSelect={true}*/}
          {/*                filterName="user_lever"*/}
          {/*                label="Parent Product"*/}
          {/*                options={availableProducts}*/}
          {/*                onChange={(e) =>*/}
          {/*                  onChangeProduct("parentSavingsLeverId", e.value)*/}
          {/*                }*/}
          {/*                value={_.find(*/}
          {/*                  availableProducts,*/}
          {/*                  (l) =>*/}
          {/*                    l.value === selectedProduct.parentSavingsLeverId*/}
          {/*                )}*/}
          {/*                isDisabled={true}*/}
          {/*                defaultValue={{ label: "No parent", value: null }}*/}
          {/*                // clear={clear}*/}
          {/*              />*/}
          {/*            </Col>*/}
          {/*          </Row>*/}
          {/*          <Row>*/}
          {/*            <Col xs="6" className="mt-2">*/}
          {/*              {selectedProduct && selectedProduct.id ? (*/}
          {/*                <StyledButton*/}
          {/*                  onClick={() => createNewProduct(selectedProduct)}*/}
          {/*                  color="warning"*/}
          {/*                  showPlusIcon*/}
          {/*                >*/}
          {/*                  New Child Product*/}
          {/*                </StyledButton>*/}
          {/*              ) : null}*/}
          {/*            </Col>*/}
          {/*            <Col xs="6" className="mt-2">*/}
          {/*              <ButtonGroup className="float-right">*/}
          {/*                <StyledButton*/}
          {/*                  color="primary"*/}
          {/*                  className="text-light"*/}
          {/*                  onClick={saveProduct}*/}
          {/*                  showSaveIcon*/}
          {/*                >*/}
          {/*                  {selectedProduct.parentSavingsLeverId*/}
          {/*                    ? "Save Child"*/}
          {/*                    : "Save Parent"}*/}
          {/*                </StyledButton>*/}
          {/*                <StyledButton onClick={closeOutEditing}>*/}
          {/*                  Cancel*/}
          {/*                </StyledButton>*/}
          {/*              </ButtonGroup>*/}
          {/*            </Col>*/}
          {/*          </Row>*/}
          {/*        </>*/}
          {/*      ) : (*/}
          {/*        <>*/}
          {/*          <Row className={"d-flex justify-content-end m-0 pe-2"}>*/}
          {/*            {viewMode === VIEWS.EDIT && (*/}
          {/*              <StyledButton*/}
          {/*                color="warning"*/}
          {/*                className="float-right"*/}
          {/*                onClick={createNewProduct}*/}
          {/*                showPlusIcon*/}
          {/*              >*/}
          {/*                New Parent Product*/}
          {/*              </StyledButton>*/}
          {/*            )}*/}
          {/*          </Row>*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*    </Card>*/}
          {/*  </Col>*/}
          {/*</>*/}
          {/*)}*/}
        </Row>
      </Col>
    </PageContainer>
  );
}

const ProductEdit = (
  props
  // {
  // nodes,
  // setViewMode,
  // setSelectedProduct,
  // productHierarchy,
  // selectedProduct,
  // }
) => {
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState(null);
  const [productHierarchy, setProductHierarchy] = useState(null);
  const [checked, setChecked] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [availableProducts, setAvailableProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.nodes) setNodes(props.nodes);
    if (props.productHierarchy) setProductHierarchy(props.productHierarchy);
  }, [props]);

  const handleClick = (item) => {
    props.setViewMode(VIEWS.EDIT);
    if (checked && checked.length > 0 && checked[0] === item.value) {
      setChecked([]);
      setSelectedProduct(null);
    } else {
      setChecked([item.value]);
    }
  };

  useEffect(() => {
    if (checked && checked.length > 0) {
      onCheckboxTreeClick(checked[0]);
    }
  }, [checked]);

  function isInvalid() {
    let errors = [];
    if (!selectedProduct.name) {
      errors.push("Please enter a name for the product.");
    }
    let errorMessage = _.join(errors, " ");
    if (errorMessage.length > 0) {
      setAlertMessage({
        flavor: "danger",
        text: errorMessage,
      });
      return true;
    } else {
      return false;
    }
  }

  function saveProduct() {
    if (isInvalid()) return;
    // TO DO --> shape the payload
    // let payload = Object.assign({}, selectedProduct);
    api
      .securePost(`SavingsLever/SaveSavingsLever`, selectedProduct)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setAlertMessage({
            flavor: "success",
            text: "Product saved.",
          });
          setTimeout(() => {
            setLoading(true);
            setAlertMessage(null);
            setSelectedProduct(null);
          }, 2000);
        } else {
          setAlertMessage({
            flavor: "danger",
            text: "Error saving product",
          });
        }
      });
  }

  function closeOutEditing() {
    setSelectedProduct(null);
    setChecked([]);
  }

  function onCheckboxTreeClick(checkedKey) {
    let parentProduct = helpers.findFirstParent(nodes, checkedKey);
    if (parentProduct) {
      setSelectedProduct(parentProduct);
    } else {
      if (!selectedProduct) {
        console.log(
          "may be missing scenario to find node here",
          helpers.findFirstParent(nodes, checkedKey)
        );
      }
    }
  }

  function onChangeProduct(field, value) {
    let tempProduct = Object.assign({}, selectedProduct);
    tempProduct[field] = value;
    setSelectedProduct(tempProduct);
  }

  function createNewProduct(parent = null) {
    // setViewMode(VIEWS.EDIT);
    let newProduct = Object.assign({}, emptyProduct);
    if (parent) {
      newProduct.parentSavingsLeverId = parent.id;
    }
    setSelectedProduct(newProduct);
  }

  return (
    <>
      <Col lg={3} className={"m-0 ps-0 pe-1 h-100"}>
        <Card className={"h-100 ps-0 styled-card"}>
          <CardHeader
            className={"styled-card-header d-flex justify-content-start"}
          >
            Savings Library
          </CardHeader>
          {/*{productHierarchy ? (*/}
          {/*  <Tree*/}
          {/*    lineWidth={"2px"}*/}
          {/*    lineColor={"#58539d"}*/}
          {/*    // lineColor={'rgba(204, 213, 226, 1)'}*/}
          {/*    lineBorderRadius={"10px"}*/}
          {/*    label={<StyledNode>Savings Libraries</StyledNode>}*/}
          {/*  >*/}
          {/*    {renderChildren(productHierarchy)}*/}
          {/*  </Tree>*/}
          {/*) : null}*/}
          <CardBody>
            {nodes ? (
              <ProductTree
                nodes={nodes}
                checked={checked}
                expanded={expanded}
                setChecked={() => {}}
                setExpanded={(expanded) => setExpanded(expanded)}
                onlyLeafCheckboxes={false}
                noCascade={true}
                // onCheck={(checkedItems) => {
                //   // since it always sends back list of checkboxes, only ever want 1
                //   checkedItems = _.reject(checkedItems, n => _.some(checked, x => x === n));
                //   onProductCheck(checkedItems)
                //   setChecked(checkedItems);
                // }}
                onClick={handleClick}
                hideCheckboxes={true}
              />
            ) : null}
          </CardBody>

          {/*/>*/}
        </Card>
      </Col>
      <Col lg={9} className={"m-0 p-0 vh-75"}>
        <Card className={"h-100 styled-card"}>
          <CardHeader
            className={"styled-card-header d-flex justify-content-start"}
          >
            <Row lg={"auto w-100"}>
              <Col lg={6} className={"d-flex justify-content-start"}>
                {selectedProduct && selectedProduct.name
                  ? selectedProduct.name
                  : "New Product"}
              </Col>
              <Col lg={6} className={"d-flex justify-content-end"}>
                {selectedProduct ? null : (
                  <StyledButton
                    color="warning"
                    className="float-right"
                    onClick={createNewProduct}
                    showPlusIcon
                  >
                    New Parent Product
                  </StyledButton>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody className={"p-3"}>
            {selectedProduct ? (
              <>
                <Row className={"m-0 p-0"}>
                  <Col>
                    <StyledInput
                      type="text"
                      maxLength="50"
                      id="productName"
                      value={selectedProduct.name || ""}
                      onChange={(e) => onChangeProduct("name", e.target.value)}
                      name="productName"
                      label="Name"
                    />
                  </Col>
                  <Col>
                    <StyledSelect
                      id="parentSavingsLeverId"
                      isSingleSelect={true}
                      filterName="user_lever"
                      label="Parent Product"
                      options={availableProducts}
                      onChange={(e) =>
                        onChangeProduct("parentSavingsLeverId", e.value)
                      }
                      value={_.find(
                        availableProducts,
                        (l) => l.value === selectedProduct.parentSavingsLeverId
                      )}
                      isDisabled={true}
                      defaultValue={{ label: "No parent", value: null }}
                      // clear={clear}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" className="mt-2">
                    {selectedProduct && selectedProduct.id ? (
                      <StyledButton
                        onClick={() => createNewProduct(selectedProduct)}
                        color="warning"
                        showPlusIcon
                      >
                        New Child Product
                      </StyledButton>
                    ) : null}
                  </Col>
                  <Col xs="6" className="mt-2">
                    <ButtonGroup className="float-right">
                      <StyledButton
                        color="primary"
                        className="text-light"
                        onClick={saveProduct}
                        showSaveIcon
                      >
                        {selectedProduct.parentSavingsLeverId
                          ? "Save Child"
                          : "Save Parent"}
                      </StyledButton>
                      <StyledButton onClick={closeOutEditing}>
                        Cancel
                      </StyledButton>
                    </ButtonGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className={"d-flex justify-content-end m-0 pe-2"}></Row>
              </>
            )}
          </CardBody>
          {/*{selectedProduct ? (*/}
          {/*  <>*/}
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <h3>*/}
          {/*      Edit{" "}*/}
          {/*      */}
          {/*    </h3>*/}
          {/*    {alertMessage ? (*/}
          {/*      <Alert color={alertMessage.flavor}>*/}
          {/*        {alertMessage.text}*/}
          {/*      </Alert>*/}
          {/*    ) : null}*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          {/*  </>*/}
          {/*) : (*/}
          {/* */}
          {/*)}*/}
        </Card>
      </Col>
    </>
  );
};

const Hierarchy = (props) => {
  const StyledNode = styled.button`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #009879;
    cursor: default !important;
  `;

  // function selectProduct(product) {
  //   setSelectedProduct(null);
  //   setChecked([]);
  //   setViewMode(VIEWS.EDIT);
  //   let productKey = product.key || product.value;
  //   setSelectedProduct(product);
  //   setChecked([productKey]);
  // }

  function renderChildren(parent) {
    return _.map(parent.children, (c) => (
      <TreeNode
        label={
          <StyledNode
          // onClick={() => {
          //   selectProduct(c.parent);
          // }}
          >
            {c.parent.name}
          </StyledNode>
        }
      >
        {c.children.length > 0 ? renderChildren(c) : null}
      </TreeNode>
    ));
  }

  return (
    <>
      <Col className={"m-0 p-0 w-100 h-100"}>
        <Card className={"h-100 p-4"}>
          {props.productHierarchy ? (
            <Tree
              lineWidth={"2px"}
              lineColor={"#58539d"}
              // lineColor={'rgba(204, 213, 226, 1)'}
              lineBorderRadius={"10px"}
              label={<StyledNode>Savings Libraries</StyledNode>}
            >
              {renderChildren(props.productHierarchy)}
            </Tree>
          ) : null}
        </Card>
      </Col>
    </>
  );
};
