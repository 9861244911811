import React, { useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import cx from "classnames";
import { ActionButtonRow } from "./index";
import _ from "lodash";
import { AiOutlineCheck } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

export default function MergeItemCard(props) {
  const [collapse, setCollapse] = useState(
    _.isBoolean(props.startOpen) ? props.startOpen : false
  );
  const toggle = () => {
    setCollapse(!collapse);
  };

  const isIncluded = props.mergeItem ?? true;
  const isRejected = props.rejectedItem;
  return (
    <Card
      className={classNames(
        { "merge-item-merge": isIncluded },
        { "merge-item-reject": isRejected },
        "mb-2 mt-0 mx-0"
      )}
    >
      <CardHeader className="expand-md bg-white">
        <div className={"merge-item-title-container"}>
          <div className="float-left d-flex justify-content-evenly align-items-center">
            <b>{props.title}</b>
          </div>
          <div className={"d-flex align-items-center"}>
            <div>
              {/*<MergeButtons*/}
              {/*  onMerge={props.mergeItem}*/}
              {/*  onReject={props.rejectItem}*/}
              {/*/>*/}
            </div>
            <div className="text-right">
              {props.hideCollapse ? null : collapse ? (
                <IoIosArrowUp
                  onClick={() => {
                    if (props.onClose) props.onClose();
                    toggle();
                  }}
                  className="clickable-icon"
                />
              ) : (
                <IoIosArrowDown
                  onClick={() => {
                    if (props.onOpen) props.onOpen();
                    toggle();
                  }}
                  className="clickable-icon"
                />
              )}
            </div>
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={props.hideCollapse || collapse}>
        <div className={classNames({ scrollhost: props.isScrollable })}>
          <CardBody className={props.cardBodyClass || "card-widget"}>
            {props.children}
          </CardBody>
        </div>
      </Collapse>

      {props.createNewOptions && (
        <ReactTooltip
          className="tooltip"
          border
          clickable
          id={`createNew${props.title}Tip`}
          delayHide="250"
          place="bottom"
          type="light"
          effect="solid"
        >
          <>
            {_.map(props.createNewOptions, (x) => {
              return (
                <div
                  className="tooltip-option"
                  onClick={() => props.onCreateNew(x)}
                >
                  {x?.label}
                </div>
              );
            })}
          </>
        </ReactTooltip>
      )}
    </Card>
  );
}

function MergeButtons(props) {
  const { onMerge, onReject, idx, disabled, mergeDisabled, rejectDisabled } =
    props;

  const mergeId = idx + "-merge";
  const mergeTooltipId = idx + "-merge";
  const rejectId = idx + "-reject";
  const rejectTooltipId = rejectId + "-tooltip";

  function handleMergeClick() {
    if (disabled) return;
    onMerge();
  }

  const disableButton = disabled ? "disabled" : "";
  return (
    <div className={"action-row-container"}>
      {/*<Tooltip title>*/}
      <button
        className={cx("action-button", { hover: !mergeDisabled })}
        id={mergeId}
        title={mergeDisabled ? "" : "Merge Item"}
        disabled={mergeDisabled}
        onClick={handleMergeClick}
      >
        <AiOutlineCheck className={"merge-item-button"} />
      </button>
      <button
        className={cx("action-button", { hover: !rejectDisabled })}
        id={rejectId}
        title={"Reject Record"}
        onClick={onReject}
        aria-disabled={disabled}
      >
        <TiDeleteOutline className={"reject-button"} />
      </button>
    </div>
  );
}