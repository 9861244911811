import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {api, helpers} from '../utils';
import { NavLink, useNavigate } from "react-router-dom";

// Icons
import {
  MdSpaceDashboard,
  MdInventory2,
  MdAssignmentInd,
  MdPaid,
  MdAssignment,
  MdFolderShared,

} from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
  BsFillPeopleFill,
  BsDiagram2Fill,
  BsStack
} from "react-icons/bs";
import { RiProductHuntFill } from "react-icons/ri";
import { FaHospitalUser, FaRegChartBar } from "react-icons/fa";

// Styles and Logos
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../authConfig";


const SidebarLink = styled(NavLink)`
  display: flex;
  color: #394a64;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 35px;
  text-decoration: none;
  margin: 2px 0px;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    
  background: rgba(98, 145, 255, 0.353);
    cursor: pointer;
    text-decoration: none; 
  }
  &:focus {
    
  background: rgba(98, 145, 255, 0.353);
  }
`;

const DropdownLink = styled(NavLink)`
  background: #fff;
  height: 35px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #4a3857;
  font-size: 16px;
  &:hover {
    
  background: rgba(98, 145, 255, 0.353);
    cursor: pointer;
    text-decoration: none;
  }
  &:focus {
    
  background: rgba(98, 145, 255, 0.353);
  }
`;

const SidebarLabel = styled.span`
  font-size: 16px;
  color: var(--grey-color-500);
  margin-left: 15px;
`;

export const SideSubMenu = ({ item, stickyBottom, onClick }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <div className={stickyBottom ? 'stickyBottomSideLink' : ''}>
      <SidebarLink
        className="sidenav-links"
        to={item.path || ''}
        onClick={() => onClick && _.isFunction(onClick) ? onClick() : (item.subNav && showSubnav())}
      >
        <div className="sidenav-icons">
          {item.icon}
          <SidebarLabel className="sidenav-icons text">
            {item.name}
          </SidebarLabel>
        </div>
        {item.subNav && subnav
          ? item.iconOpened
          : item.subNav
          ? item.iconClosed
          : null}
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.name}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </div>
  );
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <MdSpaceDashboard />,
  },
  {
    path: "/patient/chart/dashboard",
    name: "Patient Chart",
    icon: <FaRegChartBar />
  },
  {
    path: "#",
    name: "Administration",
    icon: <MdAssignmentInd />,
    iconOpened: <IoMdArrowDropup />,
    iconClosed: <IoMdArrowDropdown />,
    subNav: [
      {
        path: "/clientAdmin",
        name: "Clients",
        icon: <FaHospitalUser />
      },
      {
        path: "/userAdmin",
        name: "Users",
        icon: <BsFillPeopleFill />
      }, 
      {
        path: "/productAdmin",
        name: "Products",
        icon: <RiProductHuntFill />,
      },
    
    ],
  },
  {
    path: "/toolbox",
    name: "Toolbox",
    icon: <MdInventory2 />,
  }
];

const SideNav = styled.nav`
  background: rgba(204, 213, 226, 0.23);
  height: 100%;
  width: 220px;
  border: 1px solid #f0efef;
  border-top: none;
  position: relative;
  //top: 3rem;
  box-shadow: 2px 4px 4px rgba(103, 103, 104, 0.103);
  left: ${({ isOpen }) => (isOpen ? "50" : "50px")};
  width: ${({ isOpen }) => (isOpen ? "190" : "0")};
  transition: 150ms;
  z-index: 10;
`;

let microsoft = 'microsoft';
let redirect = 'redirect';

export default function SideNavigation() {
  const [isOpen, setIsOpen] = useState(true);
  const { instance, accounts, inProgress,  } = useMsal();

  const navigate = useNavigate();

  return (
    <>
      <SideNav className="sidebar" isOpen={isOpen}>
        <div>
          <br />
        </div>
        {routes.map((item, index) => {
          return <SideSubMenu item={item} key={index} />;
        })}
        <SideSubMenu item={{
            path: "/logout",        
            name: "Logout",
            icon: <IoLogOut />
          }} 
          onClick={() => helpers.handleLogout(microsoft)}
          key={100}
        />
      </SideNav>
      {/*<div onClick={() => setIsOpen(!isOpen)} className="toggle-menu_btn">*/}
      {/*  {isOpen ? (*/}
      {/*    <BsFillArrowLeftSquareFill />*/}
      {/*  ) : (*/}
      {/*    <BsFillArrowRightSquareFill />*/}
      {/*  )}*/}
      {/*</div>     */}
        <div>
      </div>
    </>
  );
};
