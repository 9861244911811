import React, {useContext} from 'react';
import Switch from "react-switch";
import { UserContext, constants } from "../utils";

export default function PHIHider() {
  const userCtx = useContext(UserContext);

  return(
    <div style={{display: "flex", alignItems: "center"}}>
       <Switch
        handleDiameter={30}
        uncheckedIcon={<div className="pr-5 switch">PHI</div>}
        checkedIcon={<div className="pl-5 switch">PHI</div>}
        height={25}
        width={95}
        onChange={() => userCtx.setShowSsn(!userCtx.showSsn)}
        checked={userCtx.showSsn}
        onColor="#58539d"
        onHandleColor="#3f3e66"
      />
    </div>
  );
}