import { Grid } from "react-loader-spinner";
import { Row, Col } from "reactstrap";

export default function Loader(props) {
  return (
      <div className={`w-100 h-100 d-flex justify-content-center
       align-items-center text-center ${props.center}`}>
    <Row className="m-5">
      <Col>
        <Grid ariaLabel="loading-indicator" color="#58539d" />
      </Col>
    </Row>
    </div>
  );
}
