import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  FilterText,
  Loader,
  LongBreadCrumb,
  NavigationTab,
  PageContainer,
  StyledButton,
  StyledFilterToggle,
  StyledFormLabel,
  StyledInput,
  StyledModal,
  StyledPager,
  StyledSection,
  StyledTD,
  StyledToggle,
} from "./index";
import cx from "classnames";
import { Col, Form, Row } from "react-bootstrap";
import {
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { api, constants, filterHelpers, helpers } from "../utils";
import _ from "lodash";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const emptyNewClientGroup = {
  id: 0,
  name: "",
};

const { VALUE_SET_TYPE_IDS, VALUE_SET_TYPES } = constants;

export default function ClientGroupAdmin(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [clientGroupName, setClientGroupName] = useState(null);
  const [paginatedList, setPaginatedList] = useState(null);
  const [selectedClientGroup, setSelectedClientGroup] = useState(null);
  const [activeOnly, setActiveOnly] = useState(true);
  const [filters, setFilters] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortField, setSortField] = useState("name");
  const [maxPageSize, setMaxPageSize] = useState(constants.DEFAULT_PAGE_SIZE);
  const [clientGroupValueSetSettings, setClientGroupValueSetSettings] =
    useState(null);
  const [clientGroupSettingsLoading, setClientGroupSettingsLoading] =
    useState(false);

  useEffect(() => {
    if (!loading) {
      refreshList();
    }
  }, [filters, pageNumber, activeOnly]);

  useEffect(() => {
    if (selectedClientGroup !== null) {
      console.debug("client name length: ", selectedClientGroup.name.length);
      setClientGroupName(selectedClientGroup.name.trim());
      fetchClientGroupSettings();
    }
    return () => {};
  }, [selectedClientGroup]);

  function fetchClientGroupSettings() {
    if (!selectedClientGroup) return;
    setClientGroupSettingsLoading(true);
    api
      .secureFetch(`client/GetClientGroupSettings/${selectedClientGroup.id}`)
      .then((res) => {
        if (!res || !res.data) return;
        if (res.data.success === true) {
          setClientGroupValueSetSettings(res.data.message);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setClientGroupSettingsLoading(false));
  }

  function createPayload() {
    let payload = {
      sortField: sortField,
      sortDirection: sortDirection,
      maxResults: constants.DEFAULT_PAGE_SIZE,
      pageNumber: pageNumber,
      activeOnly: activeOnly,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    return payload;
  }

  function refreshList() {
    if (loading) return;
    setLoading(true);
    api
      .securePost("Client/PaginatedClientGroups", createPayload())
      .then((response) => {
        if (response && response.data && response.data.success) {
          setPaginatedList(response.data.message);
        } else {
          console.error("Error getting back list of clients");
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function createNewClientGroup() {
    let newItem = Object.assign({}, emptyNewClientGroup);
    setSelectedClientGroup(newItem);
  }

  function onFilterChange(changedFilter) {
    const filterList = filterHelpers.getFilters(filters, changedFilter);
    setPageNumber(1);
    setFilters(filterList);
  }

  function toggleClientGroup(clientGroupId) {
    api
      .secureFetch(`Client/ToggleActiveClientGroup/${clientGroupId}`)
      .then((response) => {
        if (!response) return;
        if (response.data.success) {
          toast.success("Successfully toggled the client. Refreshing..");
          setTimeout(() => refreshList(), 500);
        }
      })
      .catch((e) => console.error(e));
  }

  function saveClientGroup(e) {
    e.preventDefault();
    if (clientGroupName === null || clientGroupName.length < 1) {
      toast.error("Client group must have a name.");
    } else {
      let payload = Object.assign({}, selectedClientGroup);
      payload["name"] = clientGroupName.trim();
      api
        .securePost("Client/SaveClientGroup", payload)
        .then((response) => {
          if (response.data && response.data.success) {
            toast.success("Client Group saved successfully");
            refreshList();
          }
        })
        .catch((error) => {
          helpers.catchHandler(error);
        })
        .finally(() => {
          setLoading(false);
          setSelectedClientGroup(null);
        });
    }
  }

  function changeClientGroupName(value) {
    setClientGroupName(value);
  }

  function onChangeClientGroup(field, value) {
    let tempClientGroup = Object.assign({}, selectedClientGroup);
    tempClientGroup[field] = value;
    setSelectedClientGroup(tempClientGroup);
  }

  return (
    <PageContainer>
      <Col
        className={"m-0 p-0 h-100 w-100 flex-column justify-content-between"}
      >
        <LongBreadCrumb
          context={"Client Groups"}
          page={"Administration"}
          trailing={
            <StyledButton
              color="primary"
              className="float-right"
              onClick={createNewClientGroup}
              showPlusIcon
            >
              New Group
            </StyledButton>
          }
        />
        <Row className={"m-0 p-0 w-100 h-100"}>
          <div
            className={
              "overflow-hidden w-100 h-100 px-4 pt-2 pb-0 rounded bg-white d-flex flex-column space-between"
            }
          >
            <Col sm="12" className={"h-100 w-100"}>
              <div
                sm="3"
                style={{ height: "10%" }}
                className="w-100 mb-3 d-flex"
              >
                <Col xl={3} className={"w-25"}>
                  <FilterText
                    placeHolder={"Name"}
                    filterName="name"
                    label="Name"
                    onChangeCallback={onFilterChange}
                    value={filterHelpers.getValue("name", filters)}
                  />
                </Col>
                <Col className={"w-25"}>
                  <StyledFilterToggle
                    displayName={"Active"}
                    filterName="activeOnly"
                    onChangeCallback={() => setActiveOnly(!activeOnly)}
                    value={activeOnly}
                  />
                </Col>
              </div>
              <div
                style={{ height: "80%" }}
                className={"m-0 p-0 w-100 overflow-auto"}
              >
                <Table bordered className={"w-100"}>
                  <thead>
                    <tr>
                      <th
                        className={"diagnostic-table-header header-align-left"}
                      >
                        Name
                      </th>
                      <th
                        width={"2%"}
                        className={"diagnostic-table-header header-align-left"}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      _.times(maxPageSize, (idx) => (
                        <tr key={`user-skeleton-row-${idx}`}>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td width={30}>
                            <Skeleton
                              width={50}
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                        </tr>
                      ))
                    ) : paginatedList?.list &&
                      paginatedList?.list.length > 0 ? (
                      _.map(paginatedList.list, (clientGroup) => {
                        return (
                          <tr key={clientGroup.id}>
                            <StyledTD className={cx("py-1 align-middle")}>
                              {clientGroup.deactivatedAt ? (
                                <del>{clientGroup.name}</del>
                              ) : (
                                <span>{clientGroup.name}</span>
                              )}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              <EditMenu
                                onEdit={() =>
                                  setSelectedClientGroup(clientGroup)
                                }
                                itemId={clientGroup.id}
                                onToggleStatus={toggleClientGroup}
                                showRevive={clientGroup.deactivatedAt}
                              />
                            </StyledTD>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>No results</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div sm="3" style={{ height: "10%" }} className={"m-0 p-0 w-100"}>
                <StyledPager
                  loading={loading}
                  showPager
                  callBack={setPageNumber}
                  totalPages={paginatedList?.totalPages ?? 0}
                  pageNumber={pageNumber}
                />
              </div>
            </Col>
          </div>
        </Row>

        <StyledModal
          show={selectedClientGroup}
          onHide={() => setSelectedClientGroup(null)}
          size={selectedClientGroup?.id === 0 ? "md" : "xl"}
          showCancel
          title={selectedClientGroup?.id > 0 ? "Edit Group" : "New Group"}
          onSave={(e) => saveClientGroup(e)}
          // disabled={saving}
          savePrompt={"Save Changes"}
        >
          <Form>
            <Row className="mb-4">
              <Col xs={selectedClientGroup?.id === 0 ? "10" : "6"}>
                <StyledInput
                  name="name"
                  id="name"
                  onChange={(e) => changeClientGroupName(e.target.value)}
                  value={clientGroupName}
                  className="form-control"
                  label="Name"
                  required
                />
              </Col>
            </Row>
            {selectedClientGroup && selectedClientGroup.id === 0 ? null : (
              <ValueSettingList
                loadingList={clientGroupSettingsLoading}
                clientGroupId={selectedClientGroup?.id ?? null}
                settings={clientGroupValueSetSettings}
              />
            )}
          </Form>
        </StyledModal>
      </Col>
    </PageContainer>
  );
}

const ValueSetSettingRow = (props) => {
  const { valueSetType, setting, clientGroupId } = props;
  const [loading, setLoading] = useState(false);
  const [clientValueSetting, setClientValueSetting] = useState(setting);

  function changeSettingValue() {
    let payload = {
      id: clientValueSetting.id,
      clientGroupId: clientGroupId,
      valueSetId: clientValueSetting.valueSetId,
      value: clientValueSetting.id !== null,
    };
    setLoading(true);
    api
      .securePost("client/SaveClientGroupSetting", payload)
      .then((res) => {
        if (!res || !res.data) return;
        if (res.data.success) {
          setClientValueSetting(res.data.message);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }
  let valueType = _.find(
    VALUE_SET_TYPES,
    (vs) => vs.id === clientValueSetting.valueSetId
  );
  return (
    // <Row xl={"1"} sm={"2"}>
    //   <Col>{name}</Col>
    //   <Col className={"d-flex justify-content-end"}>
    <Row className={"d-flex justify-content-between"}>
      <Col className={"bold"}>{valueType?.label ?? ""}</Col>
      <Col>
        {/*{loading ? (*/}
        {/*  <Loader />*/}
        {/*) : (*/}
        <StyledToggle
          disabled={loading}
          value={clientValueSetting?.value ?? false}
          onClick={(e) => changeSettingValue()}
        />
        {/*)}*/}
      </Col>
    </Row>

    //   </Col>
    // </Row>
  );
};

const ValueSettingList = (props) => {
  const { loadingList, settings, clientGroupId } = props;

  return (
    <StyledSection title={"Override Value Set Settings"}>
      <p className={"text-muted"}>
        If you override a Value Set you must define custom value sets, otherwise
        nothing will be returned.
      </p>
      <Row className={"overflow-y-scroll"}>
        {loadingList ? (
          <Loader />
        ) : (
          <>
            {/*<>*/}
            {settings && settings.length
              ? _.map(settings, (s) => {
                  return (
                    <Col xl={6} sm={12} key={s.valueSetId} id={s.valueSetId}>
                      <ValueSetSettingRow
                        setting={s}
                        clientGroupId={clientGroupId}
                      />
                    </Col>
                  );
                })
              : null}
            {/*<ValueSetSettingRow*/}
            {/*  setting={*/}
            {/*    settings && settings.length*/}
            {/*      ? _.find(*/}
            {/*          settings,*/}
            {/*          (s) => s.valueSetId === VALUE_SET_TYPE_IDS.BODY_SITES*/}
            {/*        )*/}
            {/*      : null*/}
            {/*  }*/}
            {/*  valueSetType={VALUE_SET_TYPES[VALUE_SET_TYPE_IDS.BODY_SITES]}*/}
            {/*/>*/}
            {/*</>*/}
            {/*<Col></Col>*/}
          </>
        )}
      </Row>
    </StyledSection>
  );
};

const EditMenu = (props) => {
  const { showRevive, itemId, onToggleStatus, onEdit } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ButtonDropdown
      direction="left"
      isOpen={menuOpen}
      style={{ zIndex: 1000 }}
      toggle={() => setMenuOpen(!menuOpen)}
    >
      <DropdownToggle id={"table-edit"}>
        <FontAwesomeIcon icon={"ellipsis-v"} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onEdit}>Edit</DropdownItem>
        {showRevive ? (
          <DropdownItem
            className={"text-success"}
            onClick={() => onToggleStatus(itemId)}
          >
            Revive
          </DropdownItem>
        ) : (
          <DropdownItem
            className={"text-danger"}
            onClick={() => onToggleStatus(itemId)}
          >
            Deactivate
          </DropdownItem>
        )}
        {/*{showRevive ? (*/}
        {/*  <DropdownItem*/}
        {/*    className={"text-success"}*/}
        {/*    onClick={() => onToggleStatus(itemId)}*/}
        {/*  >*/}
        {/*    Revive*/}
        {/*  </DropdownItem>*/}
        {/*) : (*/}
        {/*  <DropdownItem*/}
        {/*    className={"text-danger"}*/}
        {/*    onClick={() => onToggleStatus(itemId)}*/}
        {/*  >*/}
        {/*    Deactivate*/}
        {/*  </DropdownItem>*/}
        {/*)}*/}
      </DropdownMenu>
    </ButtonDropdown>
  );
};