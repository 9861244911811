import React from "react";

const UserContext = React.createContext({
  currentUser: null,
  // theme: null,
  // authCurrentUser: i => {},
  // setTheme: i => {},
  // signIn: (user, token) => {},
  // signOut: i => {},
  clearImpersonation: (i) => {},
  updateCurrentUser: (i) => {},
  showSsn: null,
  setShowSsn: () => {},
  logout: () => {},
  login: () => {},
  //  currentUserContext: null,
  //  setCurrentUserContext: i => { },
});

export default UserContext;
