import React, { useState, useEffect, Fragment, useContext } from "react";
import { api, constants, dateHelpers, helpers, UserContext } from "../utils";
import {
  StyledAsyncSelect,
  StyledModal,
  StyledSingleDatePicker,
  StyledSelect,
  StyledInput,
  StyledTD,
  StyledSection,
  WidgetCard,
  FhirChoiceVariable,
  Upload,
  Loader,
  PatientChartNotes,
  StyledFormLabel,
} from ".";
import { toast } from "react-toastify";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { BsClipboardCheck } from "react-icons/bs";
import classNames from "classnames";
import _ from "lodash";
import {
  Button,
  CardBody,
  CardTitle,
  Card,
  Col,
  Row,
  Table,
  ButtonGroup,
} from "reactstrap";

const EMPTY_RECENT_LAB = {
  id: "",
  clientId: 0,
  code: "",
  status: null,
  category: null,
  interpretations: [],
  result: null,
  resultType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
  note: { text: "" },
  notes: [],
};

const NotificationPill = ({
  icon,
  display,
  isEditing,
  onClick,
  onDelete,
  onEdit,
}) => {
  return (
    <div className={classNames("notification-pill")}>
      <div
        onClick={onClick}
        className={"notification-pill-message cursorPointer clickableRow"}
      >
        <div className="mr-3">{icon}</div>
        <div>{display}</div>
      </div>
      {isEditing && (
        <div className="px-3 notification-pill-menu">
          <FiEdit onClick={onEdit} className="clickable-icon" />
          <FiMinusCircle onClick={onDelete} className="clickable-icon red" />
        </div>
      )}
    </div>
  );
};

function RenderRecentLabs({
  labs,
  isEditing,
  editModalOpenCallback,
  viewModalOpenCallback,
  currentRecentLabCallback,
  isDeletingCallback,
  recentLabValueCallback,
}) {
  return (
    <Row>
      <Col>
        {labs && labs.length && (
          <div>
            {_.map(labs, (l, index) => (
              <NotificationPill
                key={`rl-${index}`}
                icon={<BsClipboardCheck size={30} />}
                display={l.code}
                isEditing={isEditing}
                onClick={() => {
                  if (!isEditing) {
                    viewModalOpenCallback(true);
                    currentRecentLabCallback(l);
                  }
                }}
                onEdit={() => {
                  editModalOpenCallback(true);
                  currentRecentLabCallback(l);
                }}
                onDelete={() => {
                  isDeletingCallback(true);
                  currentRecentLabCallback(l);
                  viewModalOpenCallback(true);
                }}
              />
            ))}
          </div>
        )}
      </Col>
    </Row>
  );
}

export default function PatientChartRecentLabs({
  clientId,
  participantId,
  subjectOptions,
  labTypeOptions,
  refreshReferenceData,
}) {
  const userCtx = useContext(UserContext);
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [recentLabs, setRecentLabs] = useState([]);
  const [recentLabsGrouped, setRecentLabsGrouped] = useState([]);
  const [currentRecentLab, setCurrentRecentLab] = useState(
    Object.assign(JSON.parse(JSON.stringify(EMPTY_RECENT_LAB)))
  );
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingNoteIndices, setEditingNoteIndicies] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [labCategories, setLabCategories] = useState([]);

  useEffect(refreshData, []);
  useEffect(() => {
    if (!currentRecentLab.subjectReference && subjectOptions?.length === 1) {
      let tempRecentLab = {
        ...currentRecentLab,
        subjectReference: subjectOptions[0],
      };
      setCurrentRecentLab(tempRecentLab);
    }
  }, [currentRecentLab]);

  function recentLabCategories() {
    const payload = {
      maxResults: 250,
      // display: typedValue,
      // code: showCode ? typedValue : null,
      clientId: clientId,
      displayAndCode: false,
      activeOnly: true,
    };
    return api
      .securePost(
        constants.INTERNAL_VALUE_SET_URLS.OBSERVATION_CATEGORIES,
        payload
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          let list = _.map(response.data.message, (d) => {
            return {
              ...d,
              value: d.id,
              label: d.display,
              description: d.description,
            };
          });
          return { categoryList: list };
        }
      })
      .catch(api.catchHandler);
  }

  function refreshData(link) {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      apiCalls.push(getRecentLabInfo());
      apiCalls.push(recentLabCategories());

      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResultsORIG = {};
          _.each(arrayResults, (x) => Object.assign(aggResultsORIG, x));
          if (aggResultsORIG.recentLabInfo) {
            let aggResults = {};
            aggResults.recentLabInfo = _.map(
              aggResultsORIG.recentLabInfo,
              (lab) => {
                let subjectRef = _.find(subjectOptions, (x) =>
                  _.includes(x.label, lab.subjectReference)
                );
                if (
                  !subjectRef &&
                  subjectOptions &&
                  subjectOptions.length === 1
                ) {
                  subjectRef = subjectOptions[0];
                }
                return {
                  ...lab,
                  labType: _.find(labTypeOptions, (x) => x.label === lab.code),
                  referenceRangeHigh: {
                    label: lab.referenceRangeHigh,
                    value: lab.referenceRangeHigh,
                    description: "",
                  },
                  referenceRangeLow: {
                    label: lab.referenceRangeLow,
                    value: lab.referenceRangeLow,
                    description: "",
                  },
                  category: lab.category
                    ? {
                        ...lab.category,
                        id: lab.category?.id || 0,
                        value: lab.category?.code,
                        label: lab.category?.display,
                        description: lab.category?.text,
                        clientGroupId: null,
                      }
                    : null,
                  status: _.find(
                    constants.FHIR_OBSERVATION_STATUSES,
                    (x) => x.value === lab.status
                  ),
                  subjectReference: subjectRef,
                  interpretations: _.map(lab.interpretations, (x) => {
                    return {
                      ...x,
                      value: x.code,
                      label: x.display,
                      description: x.text,
                      id: x.id || 0,
                    };
                  }),
                  resultType: lab.resultType
                    ? _.find(
                        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
                        (x) => x.value === lab.resultType
                      )
                    : constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.INTEGER,
                  temperature: lab.temperature
                    ? {
                        ...lab.temperature,
                        label: lab.temperature.unit,
                        value: lab.temperature.unit,
                      }
                    : null,
                  bloodPressure: lab.bloodPressure
                    ? {
                        ...lab.bloodPressure,
                        label: lab.bloodPressure.unit,
                        value: lab.bloodPressure.unit,
                      }
                    : null,
                  bloodSystolicPressure: lab.bloodSystolicPressure
                    ? {
                        ...lab.bloodSystolicPressure,
                        label: lab.bloodSystolicPressure.unit,
                        value: lab.bloodSystolicPressure.unit,
                      }
                    : null,
                  bloodDiastolicPressure: lab.bloodDiastolicPressure
                    ? {
                        ...lab.bloodDiastolicPressure,
                        label: lab.bloodDiastolicPressure.unit,
                        value: lab.bloodDiastolicPressure.unit,
                      }
                    : null,
                  bloodPressureUnit: lab.bloodSystolicPressure
                    ? {
                        label: lab.bloodSystolicPressure.unit,
                        value: lab.bloodSystolicPressure.unit,
                      }
                    : null,
                  height: lab.height
                    ? {
                        ...lab.height,
                        label: lab.height.unit,
                        value: lab.height.unit,
                      }
                    : null,
                  weight: lab.weight
                    ? {
                        ...lab.weight,
                        label: lab.weight.unit,
                        value: lab.weight.unit,
                      }
                    : null,
                };
              }
            );
            const grouped = _.chain(aggResults.recentLabInfo)
              .reject((rl) => !rl.category)
              .groupBy((rl) => rl.category.display)
              .value();
            setRecentLabs(aggResults.recentLabInfo);
            setRecentLabsGrouped(grouped);
          }
          if (aggResultsORIG.categoryList) {
            setLabCategories(aggResultsORIG.categoryList);
          }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function renderBloodPressure() {
    if (
      _.isNil(currentRecentLab) ||
      _.isNil(currentRecentLab.bloodSystolicPressure) ||
      _.isNil(currentRecentLab.bloodDiastolicPressure)
    )
      return "";

    let bp =
      currentRecentLab.bloodSystolicPressure?.amount +
      "/" +
      currentRecentLab.bloodDiastolicPressure?.amount;
    if (currentRecentLab.bloodPressureUnit) {
      bp += ` ${currentRecentLab.bloodPressureUnit?.value}`;
    }

    return bp;

    // {
    //   currentRecentLab?.bloodSystolicPressure?.amount &&
    //   currentRecentLab?.bloodDiastolicPressure?.amount
    //     ? `${currentRecentLab?.bloodSystolicPressure?.amount || ""} / ${
    //         currentRecentLab?.bloodDiastolicPressure?.amount || ""
    //       } ${currentRecentLab?.bloodSystolicPressure?.unit || ""}`
    //     : null;
    // }
  }

  function getRecentLabInfo() {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
    };
    return api
      .securePost("Participant/RecentLabInfo", payload)
      .then((response) => {
        if (response && response.data) {
          return { recentLabInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function validated(recentLab) {
    if (!recentLab) {
      toast.error("No Recent Observation found. Please try again");
      return false;
    }
    if (!recentLab.code || recentLab.code.length <= 0) {
      toast.warning("Name is required");
      return false;
    }
    if (!recentLab.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    if (!recentLab.status) {
      toast.warning("Status is required");
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !recentLab.resultRange.units &&
      recentLab.resultRange.low !== null &&
      !isNaN(recentLab.resultRange.low)
    ) {
      toast.warning("Units are required with Result Range");
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !recentLab.resultRange.units &&
      recentLab.resultRange.high !== null &&
      !isNaN(recentLab.resultRange.high)
    ) {
      toast.warning("Units are required with Result Range");
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value &&
      recentLab.resultRatio.numerator &&
      !recentLab.resultRatio.denominator
    ) {
      toast.warning(
        "Result Ratio Denominator is required if Result Ratio Numerator is present"
      );
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value &&
      recentLab.resultRatio.denominator &&
      !recentLab.resultRatio.numerator
    ) {
      toast.warning(
        "Result Ratio Numerator is required if Result Ratio Denominator is present"
      );
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value &&
      !recentLab.resultRatio.units &&
      recentLab.resultRatio.numerator !== null &&
      !isNaN(recentLab.resultRatio.numerator)
    ) {
      toast.warning("Units are required with Result Ratio");
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY.value &&
      !recentLab.resultQuantity.units &&
      recentLab.resultQuantity.value !== null &&
      !isNaN(recentLab.resultQuantity.value)
    ) {
      toast.warning("Units are required with Result Quantity");
      return false;
    }
    if (
      recentLab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.INTEGER.value &&
      !isNaN(recentLab.resultInteger) &&
      recentLab.resultInteger % 1 !== 0
    ) {
      toast.warning("Only integers are allowed with Result Integer");
      return false;
    }
    if (
      recentLab.referenceRangeLow !== null &&
      !isNaN(recentLab.referenceRangeLow) &&
      !recentLab.referenceRangeUnits
    ) {
      toast.warning("Units are required with Reference Range");
      return false;
    }
    if (
      recentLab.referenceRangeHigh !== null &&
      !isNaN(recentLab.referenceRangeHigh) &&
      !recentLab.referenceRangeUnits
    ) {
      toast.warning("Units are required with Reference Range");
      return false;
    }
    if (
      (!_.isNil(recentLab.bloodSystolicPressure) &&
        !_.isNil(recentLab.bloodSystolicPressure.amount)) ||
      (!_.isNil(recentLab.bloodSystolicPressure) &&
        recentLab.bloodSystolicPressure.amount !== 0)
    ) {
      if (
        _.isNil(recentLab.bloodDiastolicPressure) ||
        _.isNull(recentLab.bloodDiastolicPressure.value) ||
        recentLab.bloodDiastolicPressure.value === 0
      ) {
        toast.warning("Diastolic is required if you have a Systolic reading");
        return false;
      }
    }
    if (
      (!_.isNil(recentLab.bloodDiastolicPressure) &&
        !_.isNil(recentLab.bloodDiastolicPressure.amount)) ||
      (!_.isNil(recentLab.bloodDiastolicPressure) &&
        recentLab.bloodDiastolicPressure.amount !== 0)
    ) {
      if (
        _.isNil(recentLab.bloodSystolicPressure) ||
        _.isNull(recentLab.bloodSystolicPressure.amount) ||
        recentLab.bloodSystolicPressure.amount === 0
      ) {
        toast.warning("Systolic is required if you have a Diastolic reading");
        return false;
      }
    }
    return true;
  }

  function reset() {
    setEditModalOpen(false);
    setViewModalOpen(false);
    setCurrentRecentLab(JSON.parse(JSON.stringify(EMPTY_RECENT_LAB)));
    setEditingNoteIndicies([]);
    setIsEditing(false);
    setIsDeleting(false);
  }

  function updateRecentLab(payloadChange, action) {
    setLoading(true);
    let payload = {
      ...currentRecentLab,
      clientId: clientId,
      subjectReference:
        currentRecentLab?.subjectReference?.value ||
        _.head(subjectOptions).value,
      status: currentRecentLab?.status?.value,
      effectiveAt: currentRecentLab?.effectiveAt,
      referenceRangeLow: currentRecentLab?.referenceRangeLow?.value,
      referenceRangeHigh: currentRecentLab?.referenceRangeHigh?.value,
      ...payloadChange,
      //fileEventStatus: eventStatusDictionary
    };

    helpers.addChoiceVariable(
      payload,
      "result",
      payload?.resultType,
      payload.result
    );

    payload = api.buildRecentLabFormPayload(payload, uploadedFiles);
    if (
      currentRecentLab?.category &&
      currentRecentLab.category.display ===
        constants.FHIR_OBSERVATION_CATEGORIES.LABORATORY
    ) {
      if (currentRecentLab.interpretations.length) {
        payload.delete("interpretations");
        for (let i = 0; i < currentRecentLab.interpretations.length; i++) {
          if (!currentRecentLab.interpretations[i].clientGroupId) {
            delete currentRecentLab.interpretations[i].clientGroupId;
          }
          helpers.appendObjectToForm(
            payload,
            `interpretations[${i}]`,
            currentRecentLab.interpretations[i]
          );
        }
      }
    }
    if (currentRecentLab.category) {
      payload.delete("category");
      if (!currentRecentLab.category.clientGroupId) {
        delete currentRecentLab.category.clientGroupId;
      }
      console.debug(currentRecentLab.category);
      helpers.appendObjectToForm(
        payload,
        "category",
        currentRecentLab.category
      );
      // _.each(currentRecentLab.category, (value, key) => {
      //   payload.append(`category[${key}]`, value);
      // });
      //payload.append(`category[${}]`, currentRecentLab.category);
    }
    if (currentRecentLab.notes) {
      payload.delete("notes");
      _.each(currentRecentLab.notes, (value, key) => {
        helpers.appendObjectToForm(payload, `notes[${key}]`, value);
      });
    }
    if (currentRecentLab.uploadedFileLinks) {
      payload.delete("uploadedFileLinks");
      _.each(currentRecentLab.uploadedFileLinks, (value, key) => {
        helpers.appendObjectToForm(payload, `uploadedFileLinks[${key}]`, value);
      });
    }
    if (
      currentRecentLab?.category?.display ===
      constants.FHIR_OBSERVATION_CATEGORIES.VITAL_SIGNS
    ) {
      if (currentRecentLab?.temperature) {
        payload.delete("temperature");
        helpers.appendObjectToForm(
          payload,
          `temperature`,
          currentRecentLab?.temperature
        );
      }
      if (currentRecentLab?.bloodSystolicPressure) {
        currentRecentLab.bloodSystolicPressure.unit = _.isNil(
          currentRecentLab.bloodSystolicPressure.unit
        )
          ? _.first(constants.OBSERVATION_VITAL_SIGNS_BLOOD_OPTIONS).value
          : currentRecentLab.bloodSystolicPressure.unit;
        console.debug(currentRecentLab?.bloodSystolicPressure);
        payload.delete("bloodSystolicPressure");
        helpers.appendObjectToForm(
          payload,
          `bloodSystolicPressure`,
          currentRecentLab?.bloodSystolicPressure
        );
      }
      if (currentRecentLab?.bloodDiastolicPressure) {
        currentRecentLab.bloodDiastolicPressure.unit = _.isNull(
          currentRecentLab.bloodDiastolicPressure.unit
        )
          ? _.first(constants.OBSERVATION_VITAL_SIGNS_BLOOD_OPTIONS).value
          : currentRecentLab.bloodDiastolicPressure.unit;
        payload.delete("bloodDiastolicPressure");
        helpers.appendObjectToForm(
          payload,
          `bloodDiastolicPressure`,
          currentRecentLab?.bloodDiastolicPressure
        );
      }
      if (currentRecentLab?.height) {
        payload.delete("height");
        helpers.appendObjectToForm(payload, `height`, currentRecentLab?.height);
      }
      if (currentRecentLab?.weight) {
        payload.delete("weight");
        helpers.appendObjectToForm(payload, `weight`, currentRecentLab?.weight);
      }
    }

    api
      .securePostFormData("Participant/UpdateRecentLab", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "Observation",
            "Observation",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData();
          refreshReferenceData();
          toast.success(`Recent Observation successfully ${action}`);
        } else {
          toast.error(`Recent Observation could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Recent Observation could not be ${action}`);
      })
      .finally(() => {
        setUploadedFiles([]);
        setLoading(false);
      });
  }

  function saveRecentLab() {
    let adjustedData = Object.assign({}, currentRecentLab);
    let changed = false;
    if (adjustedData.note && adjustedData.note?.text.length) {
      changed = true;
      adjustedData.notes.push(adjustedData.note);
      adjustedData.note = { text: "" };
    }
    if (currentRecentLab.labType) {
      changed = true;
      adjustedData.code = currentRecentLab.labType.label;
      adjustedData.resultType =
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING;
      delete adjustedData.labType;
    }
    if (changed) {
      setCurrentRecentLab(adjustedData);
    }
    if (!validated(adjustedData)) return;
    setLoading(true);
    updateRecentLab(
      {
        code: adjustedData.code,
        resultType: adjustedData.resultType,
        notes: adjustedData.notes,
      },
      "saved"
    );
  }

  function deleteRecentLab() {
    updateRecentLab(
      {
        status: _.find(constants.FHIR_OBSERVATION_STATUSES, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function onChangeRecentLab(field, value) {
    let tempRecentLab = Object.assign({}, currentRecentLab);
    if (field === "bloodPressureUnit") {
      tempRecentLab["bloodSystolicPressure"] = {
        ...tempRecentLab.bloodSystolicPressure,
        unit: value.value,
      };
      tempRecentLab["bloodDiastolicPressure"] = {
        ...tempRecentLab.bloodDiastolicPressure,
        unit: value.value,
      };
    } else {
      tempRecentLab[field] = value === "" ? null : value;
    }
    if (field === "labType") {
      // apply data from the chosen lab type to other fields for this special selection
      tempRecentLab.referenceRangeUnits = value.units;
      tempRecentLab.referenceRangeHigh = {
        label: value.maxRange.toFixed(value.rangePrecision),
        value: value.maxRange.toFixed(value.rangePrecision),
        description: value.highDescription,
      };
      tempRecentLab.referenceRangeLow = {
        label: value.minRange.toFixed(value.rangePrecision),
        value: value.minRange.toFixed(value.rangePrecision),
        description: value.lowDescription,
      };
      tempRecentLab.highDescription = value.highDescription;
      tempRecentLab.lowDescription = value.lowDescription;
    }
    if (
      (field === "resultString" || field === "labType") &&
      currentRecentLab.category.display ===
        constants.FHIR_OBSERVATION_CATEGORIES.LABORATORY
    ) {
      try {
        // try to parse the string to a value, compare to the low and high reference range and set an interpretation
        const resultString =
          field === "resultString" ? value : tempRecentLab.resultString;
        const resultFloat = parseFloat(resultString);
        const low = parseFloat(tempRecentLab.referenceRangeLow.value);
        const high = parseFloat(tempRecentLab.referenceRangeHigh.value);
        if (!isNaN(resultFloat) && !isNaN(low) && !isNaN(high)) {
          if (resultFloat < low) {
            tempRecentLab.interpretations = [
              {
                label: "Low",
                value: "Low",
                code: "Low",
                display: "Low",
                description: "Low",
              },
            ];
            tempRecentLab.interpretationDescription =
              tempRecentLab.lowDescription;
          } else if (resultFloat >= low && resultFloat <= high) {
            tempRecentLab.interpretations = [
              {
                label: "Normal",
                value: "Normal",
                code: "Normal",
                display: "Normal",
                description: "Normal",
              },
            ];
            tempRecentLab.interpretationDescription = "";
          } else {
            tempRecentLab.interpretations = [
              {
                label: "High",
                value: "High",
                code: "High",
                display: "High",
                description: "High",
              },
            ];
            tempRecentLab.interpretationDescription =
              tempRecentLab.highDescription;
          }
        } else {
          tempRecentLab.interpretations = [
            {
              label: "Other",
              value: "Other",
              code: "Other",
              display: "Other",
              description: "Other",
            },
          ];
          tempRecentLab.interpretationDescription = "";
        }
      } catch (err) {
        console.error("failed to recalculate range", err);
        tempRecentLab.interpretationDescription = "";
        // tempRecentLab.interpretations = ['Other'];
      }
    }
    setCurrentRecentLab(tempRecentLab);
  }

  function getRecentLabValue(lab) {
    // note: value is a choice variable in Observation resource (could be multiple types within FHIR; each type might need to be displayed differently)
    if (lab && lab.result !== null && lab.result !== undefined) {
      if (
        lab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD.value
      ) {
        return `${dateHelpers.toMDYDateString(
          lab.result?.start,
          dateHelpers.YMDHMS
        )}-${dateHelpers.toMDYDateString(lab.result?.end, dateHelpers.YMDHMS)}`;
      } else if (
        lab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME.value
      ) {
        return `${dateHelpers.toMDYDateString(lab.result, dateHelpers.YMDHMS)}`;
      } else if (
        lab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value
      ) {
        return `${lab.result?.low ?? ""}-${lab.result?.high ?? ""}`;
      } else if (
        lab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY.value
      ) {
        return `${lab.result?.value ?? ""}`;
      } else if (
        lab.resultType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO.value
      ) {
        return `${lab.result?.numerator} / ${lab.result?.denominator}`;
      } else {
        return `${lab.result}`;
      }
    }
  }

  function onUpload(files) {
    setUploadedFiles(files);
  }

  function handleDeleteFileLink(link) {
    // console.log(currentRecentLab.uploadedFileLinks, link);
    setCurrentRecentLab({
      ...currentRecentLab,
      uploadedFileLinks: currentRecentLab.uploadedFileLinks.filter(
        (x) => x.link !== link.link
      ),
    });
  }

  // function resolveSubtitle(labCategory){
  //   return (labCategory?.length
  //     ? (<span className="ml-2">
  //         <span class="count-circle">{labCategory.length}</span>
  //       </span>)
  //     : null);
  // }

  function showFiles() {
    return (
      (currentRecentLab?.category &&
        currentRecentLab.category.display ===
          constants.FHIR_OBSERVATION_CATEGORIES.LABORATORY) ||
      (currentRecentLab?.category &&
        currentRecentLab.category.display ===
          constants.FHIR_OBSERVATION_CATEGORIES.SURVEY) ||
      (currentRecentLab?.category &&
        currentRecentLab.category.display ===
          constants.FHIR_OBSERVATION_CATEGORIES.VITAL_SIGNS)
    );
  }

  return (
    <>
      <WidgetCard
        title="Observations"
        startOpen
        isScrollable
        canEdit={!isReadonly}
        onEdit={() => setIsEditing(!isEditing)}
        canCreateNew={!isReadonly}
        createNewOptions={labCategories}
        onCreateNew={(selection) => {
          if (!selection) return;
          onChangeRecentLab("category", selection);
          setIsEditing(false);
          setEditModalOpen(true);
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col>
              <Fragment>
                {_.map(labCategories, (lc, idx) => {
                  // const isAllowed = _.some(["Social History", "Vital Signs", "Laboratory", "Survey", "Activity"],
                  const isAllowed = _.some(
                    ["Social History", "Vital Signs", "Laboratory", "Activity"],
                    (allowableCategory) =>
                      allowableCategory.toLowerCase() === lc.label.toLowerCase()
                  );
                  return isAllowed ? (
                    <WidgetCard
                      key={`rlwc-${idx}`}
                      title={`${lc.label}`}
                      isScrollable
                      totalCount={recentLabsGrouped[lc.label]?.length}
                      // subtitle={resolveSubtitle(recentLabsGrouped[lc.label])}
                      // startOpen={_.some(recentLabsGrouped[lc.label])}
                    >
                      <RenderRecentLabs
                        labs={recentLabsGrouped[lc.label]}
                        isEditing={isEditing}
                        editModalOpenCallback={setEditModalOpen}
                        viewModalOpenCallback={setViewModalOpen}
                        currentRecentLabCallback={setCurrentRecentLab}
                        isDeletingCallback={setIsDeleting}
                        recentLabValueCallback={getRecentLabValue}
                      />
                    </WidgetCard>
                  ) : null;
                })}
              </Fragment>
            </Col>
          </Row>
        )}
      </WidgetCard>

      {/* Recent Observation View Only Modal */}
      <StyledModal
        show={viewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        onHide={reset}
        size="xl"
        title={
          isDeleting
            ? `Delete Recent Observation?`
            : `Recent Observation Display`
        }
        onSave={
          isDeleting
            ? deleteRecentLab
            : () => {
                setViewModalOpen(false);
                setCurrentRecentLab(
                  JSON.parse(JSON.stringify(EMPTY_RECENT_LAB))
                );
              }
        }
        savePrompt={isDeleting ? "Delete Recent Observation" : "Close"}
        disabled={loading}
      >
        <StyledSection title="About">
          <div className="mb-1">
            <b>Name:&nbsp;</b>
            {currentRecentLab?.code}
          </div>
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentRecentLab?.subjectReference?.label}
          </div>
          <div className="mb-1">
            <b>Category:&nbsp;</b>
            {currentRecentLab?.category?.label}
          </div>
          <div className="mb-1">
            <b>Effective:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentRecentLab?.effectiveAt,
              dateHelpers.YMDHMS
            )}
          </div>

          <div className="mb-1">
            <b>Status:&nbsp;</b>
            {currentRecentLab?.status?.label}
          </div>
        </StyledSection>
        {currentRecentLab?.category &&
        currentRecentLab.category.display ===
          constants.FHIR_OBSERVATION_CATEGORIES.LABORATORY ? (
          <StyledSection title="Result">
            <div>
              <b>Interpretation(s)&nbsp;:</b>
              <ul className="ml-5">
                {_.map(currentRecentLab?.interpretations, (x, i) => {
                  return <li key={`interpretation${i}`}>{x.label}</li>;
                })}
              </ul>
            </div>
            <div className="mb-1">
              <b>Result:&nbsp;</b>
              {getRecentLabValue(currentRecentLab)}
            </div>
            <div className="mb-1">
              <b>Result Type:&nbsp;</b>
              {currentRecentLab?.resultType?.label}
            </div>
            <div className="mb-1">
              <b>Reference Range Low:&nbsp;</b>
              {currentRecentLab?.referenceRangeLow?.label}
            </div>
            <div className="mb-1">
              <b>Reference Range High:&nbsp;</b>
              {currentRecentLab?.referenceRangeHigh?.label}
            </div>
            <div className="mb-1">
              <b>Reference Range Units:&nbsp;</b>
              {currentRecentLab?.referenceRangeUnits}
            </div>
          </StyledSection>
        ) : null}
        {currentRecentLab?.category &&
        currentRecentLab.category.display ===
          constants.FHIR_OBSERVATION_CATEGORIES.VITAL_SIGNS ? (
          <StyledSection title="Result">
            <div className="mb-1">
              <b>Temperature:&nbsp;</b>
              {`${currentRecentLab?.temperature?.amount || ""} ${
                currentRecentLab?.temperature?.unit || ""
              }`}
            </div>
            <div className="mb-1">
              <b>Blood Pressure:&nbsp;</b>
              {renderBloodPressure()}
            </div>
            <div className="mb-1">
              <b>Weight:&nbsp;</b>
              {`${currentRecentLab?.weight?.amount || ""} ${
                currentRecentLab?.weight?.unit || ""
              }`}
            </div>
            <div className="mb-1">
              <b>Height:&nbsp;</b>
              {`${currentRecentLab?.height?.amount || ""} ${
                currentRecentLab?.height?.unit || ""
              }`}
            </div>
          </StyledSection>
        ) : null}
        {currentRecentLab?.category &&
        currentRecentLab.category.display ===
          constants.FHIR_OBSERVATION_CATEGORIES.SOCIAL_HISTORY ? (
          <StyledSection title="Result">
            <div className="mb-1">
              <b>Marital Status:&nbsp;</b>
              {
                _.find(
                  constants.MARITAL_STATUSES,
                  (ms) => ms.value === currentRecentLab?.maritalStatus
                )?.label
              }
            </div>
            <div className="mb-1">
              <b>Spouse Name:&nbsp;</b>
              {currentRecentLab?.spouseName}
            </div>
            <div className="mb-1">
              <b>Number Of Children:&nbsp;</b>
              {currentRecentLab?.numberOfChildren}
            </div>
            <div className="mb-1">
              <b>Years Of Education:&nbsp;</b>
              {currentRecentLab?.yearsOfEducation}
            </div>
            <div className="mb-1">
              <b>Highest Level Of Education:&nbsp;</b>
              {currentRecentLab?.highestLevelOfEducation}
            </div>
            <div className="mb-1">
              <b>Occupation:&nbsp;</b>
              {currentRecentLab?.occupation || ""}
            </div>
            <div className="mb-1">
              <b>Smoking Status:&nbsp;</b>
              {currentRecentLab?.smokingStatus ? "Yes" : "No"}
            </div>
            <div className="mb-1">
              <b>Smokeless Tobacco:&nbsp;</b>
              {currentRecentLab?.smokelessTobacco ? "Yes" : "No"}
            </div>
            <div className="mb-1">
              <b>Smoking History:&nbsp;</b>
              {currentRecentLab?.smokingHistory}
            </div>
            <div className="mb-1">
              <b>Alcohol Use:&nbsp;</b>
              {currentRecentLab?.alcoholUse ? "Yes" : "No"}
            </div>
            <div className="mb-1">
              <b>Drug Use:&nbsp;</b>
              {currentRecentLab?.drugUse ? "Yes" : "No"}
            </div>
            <div className="mb-1">
              <b>Sexual Activity:&nbsp;</b>
              {currentRecentLab?.sexualActivity ? "Yes" : "No"}
            </div>
          </StyledSection>
        ) : null}
        <StyledSection title="Notes">
          {_.map(currentRecentLab?.notes, (note, i) => {
            return (
              <>
                <div key={`note${i}`} className="mb-1">
                  {note.text}
                </div>
                <hr />
              </>
            );
          })}
        </StyledSection>
        <StyledSection title="Files">
          <Row className="mb-4">
            <Col>
              <Card className={"w-100"}>
                <CardBody>
                  <CardTitle className="text-center">
                    {/* Uploaded Files */}
                  </CardTitle>
                  {_.map(currentRecentLab.uploadedFileLinks, (link) => {
                    return (
                      <p>
                        {link.link ? (
                          <a
                            href={link.link}
                            rel="noreferrer"
                            target={"_blank"}
                          >
                            {link.name}
                          </a>
                        ) : (
                          <div>{link.name}</div>
                        )}
                      </p>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </StyledSection>
      </StyledModal>

      {/* Recent Observation Edit Modal */}
      {editModalOpen ? (
        <StyledModal
          show
          showCancel
          onHide={reset}
          size="xl"
          title={
            isEditing
              ? "Update Recent Observation"
              : "Create Recent Observation"
          }
          onSave={saveRecentLab}
          savePrompt={"Save Recent Observation"}
          showRequiredFieldsMessage
          disabled={loading}
        >
          <StyledSection title="About">
            <Row className="mb-4">
              <Col xs="6">
                {currentRecentLab?.category &&
                currentRecentLab.category.display ===
                  constants.FHIR_OBSERVATION_CATEGORIES.LABORATORY ? (
                  <StyledSelect
                    label="Name"
                    required
                    isClearable
                    options={labTypeOptions}
                    name="labType"
                    id="labType"
                    value={currentRecentLab?.labType}
                    onChange={(selection) =>
                      onChangeRecentLab("labType", selection)
                    }
                    description={currentRecentLab?.labType?.description || null}
                  />
                ) : (
                  <StyledInput
                    id="name"
                    name="Name"
                    label="Name"
                    maxLength="50"
                    value={currentRecentLab?.code}
                    onChange={(e) => onChangeRecentLab("code", e.target.value)}
                    required
                  />
                )}
              </Col>
              <Col>
                <StyledSelect
                  label={"Subject"}
                  required
                  isClearable
                  options={subjectOptions}
                  name="subject"
                  id="subject"
                  value={currentRecentLab?.subjectReference || ""}
                  onChange={(selection) =>
                    onChangeRecentLab("subjectReference", selection)
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="8">
                <StyledAsyncSelect
                  id="observationCategory"
                  isClearable
                  label={"Category"}
                  value={currentRecentLab?.category}
                  idName="observationCategory"
                  // description={currentRecentLab?.category?.description}
                  // onChange={(e) => onChangeRecentLab("category", e)}
                  // loadOptions={(typedValue, callback) =>
                  //   api.loadInternalValueSets(
                  //     typedValue,
                  //     callback,
                  //     constants.INTERNAL_VALUE_SET_URLS.OBSERVATION_CATEGORIES,
                  //     false
                  //   )
                  // }
                />
              </Col>
              <Col>
                <StyledSingleDatePicker
                  label="Effective"
                  type="text"
                  name="effectiveAt"
                  id="effectiveAt"
                  maxLength="200"
                  selected={currentRecentLab?.effectiveAt}
                  onChangeCallback={(e) =>
                    onChangeRecentLab(
                      "effectiveAt",
                      dateHelpers.toMDYDateString(e)
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <StyledSelect
                  id="observationStatus"
                  label={"Observation Status"}
                  required
                  onChange={(e) => onChangeRecentLab("status", e)}
                  value={currentRecentLab?.status}
                  options={constants.FHIR_OBSERVATION_STATUSES}
                  isClearable
                />
              </Col>
            </Row>
          </StyledSection>
          {currentRecentLab?.category &&
          currentRecentLab.category.display ===
            constants.FHIR_OBSERVATION_CATEGORIES.LABORATORY ? (
            <StyledSection title="Result">
              <Row className="mb-4">
                <Col xs="6">
                  <StyledAsyncSelect
                    isMulti
                    label={"Observation Interpretation(s)"}
                    placeholder="Observation Interpretation Lookup"
                    loadOptions={(typedValue, callback) =>
                      api.loadInternalValueSets(
                        typedValue,
                        callback,
                        constants.INTERNAL_VALUE_SET_URLS
                          .OBSERVATION_INTERPRETATIONS,
                        clientId,
                        constants.VALUE_SET_TYPE_IDS
                          .OBSERVATION_INTERPRETATIONS,
                        false
                      )
                    }
                    onChange={(e) => onChangeRecentLab("interpretations", e)}
                    value={currentRecentLab?.interpretations}
                    idName="observationInterpretations"
                    description={
                      currentRecentLab?.interpretationDescription || ""
                    }
                  />
                </Col>
                <Col xs="6">
                  <FhirChoiceVariable
                    id={"resultType"}
                    name={"result"}
                    hideTypeSelection
                    label="Choose Result Type"
                    obj={currentRecentLab}
                    valueType={
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING
                    }
                    onChangeType={(e) => onChangeRecentLab("resultType", e)}
                    value={currentRecentLab?.result}
                    onChangeCallback={(val, type) =>
                      onChangeRecentLab(`result${type}`, val)
                    }
                    options={[
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.INTEGER,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.BOOLEAN,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RATIO,
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.TIME,
                    ]}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs="4">
                  <StyledAsyncSelect
                    id="referenceRangeLow"
                    name="referenceRangeLow"
                    label="Low Reference Range"
                    value={currentRecentLab?.referenceRangeLow}
                    // description={currentRecentLab?.lowDescription}
                    disabled={true}
                    onChange={(e) =>
                      onChangeRecentLab(
                        "referenceRangeLow",
                        e.target.valueAsNumber
                      )
                    }
                  />
                </Col>
                <Col xs="4">
                  <StyledAsyncSelect
                    id="referenceRangeHigh"
                    name="referenceRangeHigh"
                    label="High Reference Range"
                    value={currentRecentLab?.referenceRangeHigh}
                    // description={currentRecentLab?.highDescription}
                    disabled={true}
                    onChange={(e) =>
                      onChangeRecentLab(
                        "referenceRangeHigh",
                        e.target.valueAsNumber
                      )
                    }
                  />
                </Col>
                <Col xs="4">
                  <StyledInput
                    id="referenceRangeUnits"
                    name="referenceRangeUnits"
                    label="Units"
                    value={currentRecentLab?.referenceRangeUnits}
                    maxLength="10"
                    disabled={true}
                    onChange={(e) =>
                      onChangeRecentLab("referenceRangeUnits", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </StyledSection>
          ) : null}
          {currentRecentLab?.category &&
          currentRecentLab.category.display ===
            constants.FHIR_OBSERVATION_CATEGORIES.VITAL_SIGNS ? (
            <StyledSection title="Result">
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="temperatureAmount"
                    name="temperatureAmount"
                    label="Temperature"
                    type="number"
                    value={currentRecentLab?.temperature?.amount}
                    onChange={(e) => {
                      onChangeRecentLab("temperature", {
                        ...currentRecentLab?.temperature,
                        amount: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <StyledSelect
                    id="temperatureUnit"
                    name="temperatureUnit"
                    label="Temperature Unit"
                    value={currentRecentLab?.temperature}
                    options={constants.OBSERVATION_VITAL_SIGNS_TEMP_OPTIONS}
                    onChange={(e) => {
                      onChangeRecentLab("temperature", {
                        ...currentRecentLab?.temperature,
                        ...e,
                        unit: e.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <StyledFormLabel label={"Blood Pressure"} />
                <Col lg={3}>
                  <StyledInput
                    id="bloodSystolicPressure"
                    name="bloodSystolicPressure"
                    label="Systolic"
                    type="number"
                    min="0"
                    value={currentRecentLab?.bloodSystolicPressure?.amount}
                    onChange={(e) =>
                      onChangeRecentLab("bloodSystolicPressure", {
                        ...currentRecentLab?.bloodSystolicPressure,
                        amount: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col lg={3}>
                  <StyledInput
                    id="bloodDiastolicPressure"
                    name="bloodDiastolicPressure"
                    label="Diastolic"
                    type="number"
                    min="0"
                    value={currentRecentLab?.bloodDiastolicPressure?.amount}
                    onChange={(e) =>
                      onChangeRecentLab("bloodDiastolicPressure", {
                        ...currentRecentLab?.bloodDiastolicPressure,
                        amount: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <StyledSelect
                    id="bloodPressureUnit"
                    name="bloodPressureUnit"
                    label="Blood Pressure Unit"
                    value={currentRecentLab?.bloodPressureUnit}
                    options={constants.OBSERVATION_VITAL_SIGNS_BLOOD_OPTIONS}
                    onChange={(e) => {
                      onChangeRecentLab("bloodPressureUnit", {
                        ...currentRecentLab?.bloodPressureUnit,
                        ...e,
                        unit: e.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="weightAmount"
                    name="weightAmount"
                    label="Weight"
                    type="number"
                    value={currentRecentLab?.weight?.amount}
                    onChange={(e) =>
                      onChangeRecentLab("weight", {
                        ...currentRecentLab?.weight,
                        amount: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <StyledSelect
                    id="weightUnit"
                    name="weightUnit"
                    label="Weight Unit"
                    value={currentRecentLab?.weight}
                    options={constants.OBSERVATION_VITAL_SIGNS_WEIGHT_OPTIONS}
                    onChange={(e) => {
                      onChangeRecentLab("weight", {
                        ...currentRecentLab?.weight,
                        ...e,
                        unit: e.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="height"
                    name="height"
                    label="Height"
                    type="text"
                    value={currentRecentLab?.height?.amount}
                    onChange={(e) =>
                      onChangeRecentLab("height", {
                        ...currentRecentLab?.height,
                        amount: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <StyledSelect
                    id="heightUnit"
                    name="heightUnit"
                    label="Height Unit"
                    value={currentRecentLab?.height}
                    options={constants.OBSERVATION_VITAL_SIGNS_HEIGHT_OPTIONS}
                    onChange={(e) => {
                      onChangeRecentLab("height", {
                        ...currentRecentLab?.height,
                        ...e,
                        unit: e.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </StyledSection>
          ) : null}
          {currentRecentLab?.category &&
          currentRecentLab.category.display ===
            constants.FHIR_OBSERVATION_CATEGORIES.SOCIAL_HISTORY ? (
            <StyledSection title="Result">
              <Row className="mb-4">
                <Col>
                  <StyledSelect
                    label={"Marital Status"}
                    required
                    isClearable
                    options={constants.MARITAL_STATUSES}
                    name="maritalStatus"
                    id="maritalStatus"
                    value={
                      _.find(
                        constants.MARITAL_STATUSES,
                        (ms) => ms.value === currentRecentLab?.maritalStatus
                      ) || ""
                    }
                    onChange={(selection) =>
                      onChangeRecentLab("maritalStatus", selection.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="spouseName"
                    name="spouseName"
                    label="Spouse Name"
                    type="text"
                    value={currentRecentLab?.spouseName}
                    onChange={(e) =>
                      onChangeRecentLab("spouseName", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="numberOfChildren"
                    name="numberOfChildren"
                    label="Number Of Children"
                    type="text"
                    value={currentRecentLab?.numberOfChildren}
                    onChange={(e) =>
                      onChangeRecentLab("numberOfChildren", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="yearsOfEducation"
                    name="yearsOfEducation"
                    label="Years Of Education"
                    type="text"
                    value={currentRecentLab?.yearsOfEducation}
                    onChange={(e) =>
                      onChangeRecentLab("yearsOfEducation", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="highestLevelOfEducation"
                    name="highestLevelOfEducation"
                    label="Highest Level Of Education"
                    type="text"
                    value={currentRecentLab?.highestLevelOfEducation}
                    onChange={(e) =>
                      onChangeRecentLab(
                        "highestLevelOfEducation",
                        e.target.value
                      )
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="occupation"
                    name="occupation"
                    label="Occupation"
                    type="text"
                    value={currentRecentLab?.occupation}
                    onChange={(e) =>
                      onChangeRecentLab("occupation", e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <div className="mr-2">Smoking Status:</div>
                  <ButtonGroup>
                    <Button
                      id={`${
                        currentRecentLab?.smokingStatus
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("smokingStatus", true)}
                      active={currentRecentLab?.smokingStatus}
                    >
                      Yes
                    </Button>
                    <Button
                      id={`${
                        !currentRecentLab?.smokingStatus
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("smokingStatus", false)}
                      active={!currentRecentLab?.smokingStatus}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col>
                  <div className="mr-2">Alcohol Use:</div>
                  <ButtonGroup>
                    <Button
                      id={`${
                        currentRecentLab?.alcoholUse
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("alcoholUse", true)}
                      active={currentRecentLab?.alcoholUse}
                    >
                      Yes
                    </Button>
                    <Button
                      id={`${
                        !currentRecentLab?.alcoholUse
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("alcoholUse", false)}
                      active={!currentRecentLab?.alcoholUse}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <div className="mr-2">Smokeless Tobacco:</div>
                  <ButtonGroup>
                    <Button
                      id={`${
                        currentRecentLab?.smokelessTobacco
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() =>
                        onChangeRecentLab("smokelessTobacco", true)
                      }
                      active={currentRecentLab?.smokelessTobacco}
                    >
                      Yes
                    </Button>
                    <Button
                      id={`${
                        !currentRecentLab?.smokelessTobacco
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() =>
                        onChangeRecentLab("smokelessTobacco", false)
                      }
                      active={!currentRecentLab?.smokelessTobacco}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col>
                  <div className="mr-2">Drug Use:</div>
                  <ButtonGroup>
                    <Button
                      id={`${
                        currentRecentLab?.drugUse
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("drugUse", true)}
                      active={currentRecentLab?.drugUse}
                    >
                      Yes
                    </Button>
                    <Button
                      id={`${
                        !currentRecentLab?.drugUse
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("drugUse", false)}
                      active={!currentRecentLab?.drugUse}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <StyledInput
                    id="smokingHistory"
                    name="smokingHistory"
                    label="Smoking History"
                    type="text"
                    value={currentRecentLab?.smokingHistory}
                    onChange={(e) =>
                      onChangeRecentLab("smokingHistory", e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <div className="mr-2">Sexual Activity:</div>
                  <ButtonGroup>
                    <Button
                      id={`${
                        currentRecentLab?.sexualActivity
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("sexualActivity", true)}
                      active={currentRecentLab?.sexualActivity}
                    >
                      Yes
                    </Button>
                    <Button
                      id={`${
                        !currentRecentLab?.sexualActivity
                          ? "wellnecityRadioSelected"
                          : "wellnecityRadioUnselected"
                      }`}
                      onClick={() => onChangeRecentLab("sexualActivity", false)}
                      active={!currentRecentLab?.sexualActivity}
                    >
                      No
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </StyledSection>
          ) : null}
          <PatientChartNotes
            obj={currentRecentLab}
            setObj={setCurrentRecentLab}
            editingNoteIndices={editingNoteIndices}
            setEditingNoteIndicies={setEditingNoteIndicies}
            onChange={onChangeRecentLab}
          />
          {showFiles() &&
          currentRecentLab?.uploadedFileLinks &&
          currentRecentLab.uploadedFileLinks.length ? (
            <StyledSection title="Files">
              <Row className="mb-4">
                <Col>
                  <Card className={"w-100"}>
                    <CardBody>
                      <CardTitle className="text-center"></CardTitle>
                      {_.map(currentRecentLab.uploadedFileLinks, (link) => {
                        return (
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FiMinusCircle
                              className="clickable-icon red mr-2"
                              onClick={() => {
                                handleDeleteFileLink(link);
                              }}
                            />
                            <a
                              href={link.link}
                              rel="noreferrer"
                              target={"_blank"}
                            >
                              {link.name}
                            </a>
                          </p>
                        );
                      })}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </StyledSection>
          ) : null}
          <StyledSection title="Upload File">
            <Row className="mb-4">
              <Col>
                <Card className={"w-100"}>
                  <CardBody>
                    {/* <CardTitle className="text-center">
                        Drop the file here or click on the icon below to choose it
                      </CardTitle> */}
                    <Upload onUpload={onUpload} maxFiles={3} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </StyledSection>
        </StyledModal>
      ) : null}
    </>
  );
}