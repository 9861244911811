import AuthButton from "./AuthButton";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { BrowserUtils } from "@azure/msal-browser";
import _ from "lodash";
import { storage, constants } from "../utils";
import { useNavigate } from "react-router-dom";
const { localStorageKeys } = constants;

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    removeSessionData();
    setTimeout(() => navigate("/"), 500);
  }, []);

  function removeSessionData() {
    const keys = [...storage.getKeys()];
    if (keys && keys.length) {
      const filteredKeys = _.filter(
        keys,
        (k) => k !== localStorageKeys.appVersion
      );
      for (let i = 0; i < filteredKeys.length; i++) {
        storage.removeItem(filteredKeys[i]);
      }
    }
  }

  return <div></div>;
}
