import {Breadcrumbs, Loader, LongBreadCrumb, PageContainer, StyledButton, PlaceholderMessage} from "./";
import {api, dateHelpers, helpers, constants} from "../utils";
import {Link, useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import React, {useState, useEffect} from 'react';
import {AiOutlineAppstoreAdd} from "react-icons/ai";
import {Badge, ButtonGroup, Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import {IoReturnUpBackOutline} from "react-icons/io5";
import classnames from "classnames";

function getUIFriendlyDate(date) {
    return dateHelpers.toMDYDateString(date, dateHelpers.YMDHMS);
}

function cardDeck(
    list,
    getChildLink,
    type,
    childrenType,
    gchildrenType,
    showHomeScreen,
    showClientScreen,
    loading
) {
    const [pictureSize, setPictureSize] = useState(defaultPictureSize / window.devicePixelRatio)

    useEffect(() => {
        if (pictureSize !== defaultPictureSize / window.devicePixelRatio) {
          setPictureSize(defaultPictureSize / window.devicePixelRatio)
        }
    }, [window.devicePixelRatio])

    if (loading) {
        return <Loader />;
    }
    return list && list.length > 0 ? (
        _.map(list, (item, index) => {
            let cardLabel = _.startCase(type.label);
            return (
                <Col key={`dash${cardLabel}${item.id}${index}`} xs="12" lg="4">
                    <Card
                        className={`cards-body mb-4 background${cardLabel}`}
                        tag={Link}
                        // to={getChildLink(item)}
                        to={'/'}
                    >
                        <CardBody className="cursorPointer">
                            <Row>
                                {showHomeScreen ? (
                                    <Col xs="4">
                                        <div style={helpers.clientLogoStyle(item.id, pictureSize)} />
                                    </Col>
                                ) : null}
                                <Col>
                                    <div className="cardTitle">{item.name}</div>
                                    <div className="cardSubtitle">
                                        {/*{childrenType && gchildrenType ? (*/}
                                        {/*  <>*/}
                                        {/*    {_.startCase(gchildrenType.label)}s (*/}
                                        {/*    {item.childrenCount})*/}
                                        {/*  </>*/}
                                        {/*) : null}*/}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        {/*{showClientScreen ? (*/}
                            <CardFooter>
                                <Row>
                                    <Col xs="5">
                    <span className="dateRangeDashboard">{`${getUIFriendlyDate(
                        item.startOn
                    )} to ${getUIFriendlyDate(item.endOn)}`}</span>
                                    </Col>
                                    <Col xs="7">
                                        <Badge className="float-right campaignStatusBadge">
                                            {item.campaignStatusName}
                                        </Badge>
                                    </Col>
                                </Row>
                            </CardFooter>
                        {/*) : null}*/}
                    </Card>
                </Col>
            );
        })
    ) : (
        <Col className="cards-body" xs="6">
            <div className="cardTitle">
                {`${_.startCase(type?.label)} has no ${childrenType?.label}s.`}
            </div>
        </Col>
    );
}

export default function CampaignDashboard(props) {
    let { type, clientId, campaignId, caseId } = useParams();
    clientId = clientId ? parseInt(clientId, 10) : null;
    campaignId = campaignId ? parseInt(campaignId, 10) : null;
    caseId = campaignId ? parseInt(caseId, 10) : null;

    const [client, setClient] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const [currentCases, setCurrentCases] = useState([]);
    const [currentCase, setCurrentCase] = useState(null);
    const [loading, setLoading] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [activeOnly, setActiveOnly] = useState(true);
    const [showNewCaseModal, setShowNewCaseModal] = useState(false);
    const [newCase, setNewCase] = useState(null);
    const [caseManagerOptions, setCaseManagerOptions] = useState([]);
    const [subtitle, setSubtitle] = useState(null);
    const [navigateBack, setNavigateBack] = useState(null);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const navigate = useNavigate();

    function getClient() {
        return api
            .secureFetch(`Client/Client/${clientId}`)
            .then((response) => {
                if (response && response.data) {
                    return { client: response.data };
                }
            })
            .catch(api.catchHandler);
    }

    function getCampaigns() {
        return api
            .securePost(`Campaign/PaginatedCampaignList`, {
                clientId: clientId,
                maxResults: 100,
                activeOnly: activeOnly,
            })
            .then((response) => {
                if (response && response.data && response.data.list) {
                    return { campaigns: response.data.list };
                }
            })
            .catch(api.catchHandler);
    }

    useEffect(() => {
        refreshData();
    }, []);

    function refreshData() {
        let apiCalls = [];

        apiCalls.push(getCampaigns());
        apiCalls.push(getClient());

        setLoading(true);
        Promise.all(apiCalls)
            .then((arrayResults) => {
                let aggResults = {};
                _.each(arrayResults, (x) => Object.assign(aggResults, x));
                if(aggResults.client) setClient(aggResults.client);
                if(aggResults.campaigns)  setCampaigns(aggResults.campaigns);
            }).finally(() => setLoading(false))
            .catch(api.catchHandler);
    }


    function cardDeck(
        list,

        // getChildLink,
        // type,
        // childrenType,
        // gchildrenType,
        // showHomeScreen,
        // showClientScreen,
        loading,
        clientName,
    ) {
        if (loading) {
            return <Loader />;
        }
        return list && list.length > 0 ? (
            _.map(list, (item, index) => {
                // let cardLabel = _.startCase(type.label);
                return (
                    <Col key={`dash-campaign-${item.id}${index}`} xs="12" lg="4">
                        <Card
                            // className={`cards-body mb-4 background${cardLabel}`}
                            className={`cards-body mb-4 backgroundClient`}
                            tag={Link}
                            to={`${item.id}`}
                        >
                            <CardBody className="cursorPointer">
                                <Row>
                                    <Col>
                                        <div className="cardTitle">{item.name}</div>
                                        <div className="cardSubtitle">
                                            Cases - {item.childrenCount}
                                            {/*{childrenType && gchildrenType ? (*/}
                                            {/*  <>*/}
                                            {/*    {_.startCase(gchildrenType.label)}s (*/}
                                            {/*    {item.childrenCount})*/}
                                            {/*  </>*/}
                                            {/*) : null}*/}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col xs="5">
                    <span className="dateRangeDashboard">{`${getUIFriendlyDate(
                        item.startOn
                    )} to ${getUIFriendlyDate(item.endOn)}`}</span>
                                        </Col>
                                        <Col xs="7">
                                            <Badge className={classnames(
                                                {
                                                    "bg-success": item.campaignStatusId === constants.CAMPAIGN_STATUS_IDS.COMPLETE,
                                                    "bg-info": item.campaignStatusId === constants.CAMPAIGN_STATUS_IDS.SAVING_INITIATIVES,
                                                    "bg-warning": item.campaignStatusId === constants.CAMPAIGN_STATUS_IDS.ON_HOLD,

                                                }, "float-right campaignStatusBadge")}>
                                                {item.campaignStatusName}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </CardFooter>
                        </Card>
                    </Col>
                );
            })
        ) : (
            <Row>
              <PlaceholderMessage 
                message={`${clientName} does not have any active Campaigns`} 
                header={"No Campaigns found"}
            />
            </Row>
        );
    }

    return (
        <PageContainer>
            {loading
                ? <Loader center={'m-auto'}/>
          : (<Col className={'m-0 p-0'}>
              <Row className={'mx-0 my-3 d-flex justify-content-start align-items-center'}>
                  <LongBreadCrumb context={client?.name} page={"Campaign"} loading={loading} trailing={
                          <StyledButton
                              to={`/diagnostic-admin/${client?.id}/${client?.name}`}
                              icon={AiOutlineAppstoreAdd}
                              children={"New Diagnostic"}
                              color="primary"
                          />
                   }/>
              </Row>
              <Row className={'m-0 p-0'}>
                  {client  ? cardDeck(
                          campaigns,
                          loading,
                          client?.name
                      ) : null}
              </Row>
          </Col>)}
        </PageContainer>
    );
}