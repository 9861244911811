

export default function PlaceholderMessage({header, message}) {

  return (
    <div style={{
      height: "47vh",
      width: "100vw",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column-reverse",
    }}>
      <h3>{header}</h3>
      <div>{message}</div>
    </div>
  );
}