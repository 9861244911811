import axios from "axios";
import _ from "lodash";
import { storage, UserContext, constants } from "./";
import { useMsal } from "@azure/msal-react";
import { graphConfig, msalConfig } from "../authConfig";
import { msalInstance } from "../index";
import { protectedResources } from "../authConfig";
import async from "async";
const API = "/api";
// const API = "https://dashboard-uat.wellnecity.io/api";
import ReactTooltip from "react-tooltip";
import { useContext } from "react";

function userToken() {
  return storage.getItem("token");
}

function setUserToken(token, username) {
  storage.setItem("token", token);
  storage.setItem("currentUser", username);
}

const getUserToken = async (scope) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const account = msalInstance.getAllAccounts()[0];
  const request = {
    scopes: protectedResources.graphMe.scopes,
    account: activeAccount || account,
  };
  const authResult = await msalInstance.acquireTokenSilent(request);
  // console.log(authResult);
  return authResult.accessToken;
};

const getAPIToken = async () => {
  const account = msalInstance.getAllAccounts()[0];
  return msalInstance
    .acquireTokenSilent({
      scopes: protectedResources.wcityApi.scopes,
      account: account,
    })
    .then((response) => {
      return response.accessToken;
    });
};

function commonHeaders() {
  return getAPIToken().then((token) => {
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
  });
}

const headers = () =>
  Object.assign(commonHeaders(), { "Content-Type": "application/json" });

const multipart_headers = () =>
  Object.assign(commonHeaders(), { "Content-Type": "multipart/form-data" });

const html_content_type_headers = () =>
  Object.assign(
    {},
    {
      // "Accept": "application/octet-stream",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/octet-stream",
    }
  );

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
}

axios.interceptors.response.use(
  function (response) {
    const clientVersion = storage.getItem("app-version");
    const serverVersion = response.headers["wcity-version"];
    if (
      (clientVersion === null || clientVersion !== serverVersion) &&
      clientVersion !== undefined &&
      serverVersion !== undefined
    ) {
      storage.setItem("app-version", serverVersion);
      console.warn("This page is about to reload with an updated version...");
      setTimeout(function () {
        window.location.reload();
      }, 300);
    }
    if (response?.data?.user) {
      storage.setItem(
        constants.localStorageKeys.currentUser,
        JSON.stringify(response.data.user)
      );
    }
    return response;
  },
  function (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      logData({
        eventType: constants.LOG_EVENT_TYPES.ACCESS_ERROR,
        severity: constants.LOG_SEVERITIES.WARNING,
        message: `User encountered an access error.`,
        clientName: null,
        patientId: null,
        pHIFlag: false,
        resourceList: null,
      });
    } else if (error.response && error.response.status === 500) {
      logData({
        eventType: constants.LOG_EVENT_TYPES.CRITICAL_ERROR,
        severity: constants.LOG_SEVERITIES.ERROR,
        message: `User encountered a critical error.`,
        clientName: null,
        patientId: null,
        pHIFlag: false,
        resourceList: null,
      });
    }
    if (
      error.response &&
      error.response.status === 401 &&
      _.includes(error.response.url, window.location)
    ) {
      console.warn("Unauthorized - clearing current user");
      window.location.href = "/forbidden";
      // storage.removeItem("loggedOnAt");
      // storage.removeItem("currentUser");
      //       storage.clear();
      // storage.removeItem("token");
      // setTimeout(function () {
      //   window.location.href = "/";
      // }, 500);
    }
    if (error.response && error.response.status === 403) {
      let currentUser = storage.getItem("currentUser");
      if (currentUser) {
        // window.location.href = "/forbidden";
      }
      console.error("You are not authorized to view this data.");
    }
    return error;
  }
);

const securePostFormDataApi = (url, formData) => {
  return getAPIToken()
    .then((token) => {
      const headers = multipart_headers();
      headers["Authorization"] = `Bearer ${token}`;
      return axios({
        method: "post",
        url: `${API}/${url}`,
        data: formData,
        timeout: 600000,
        headers: headers,
      });
    })
    .catch((err) => console.error(err));
};

function callGraph() {
  return getAPIToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      return axios({
        method: "GET",
        url: graphConfig.graphMeEndpoint,
        timeout: 600000,
        headers: headers,
      });
    })
    .catch((err) => console.error(err));
}

const logData = (data) => {
  securePostApi("UserAdmin/LogUIEvent", data)
    .then((response) => {
      if (response && response.data) {
      }
    })
    .catch(api.catchHandler);
};

const securePostApi = (url, data, cancelToken = null) => {
  return getAPIToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      return axios({
        method: "post",
        url: `${API}/${url}`,
        data: data,
        timeout: 600000,
        headers: headers,
        cancelToken: cancelToken,
      });
    })
    .catch((err) => console.error(err));
};

const secureFetchApi = (url, params = {}, cancelToken = null) => {
  return getAPIToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      return axios.get(`${API}/${url}${queryString(params)}`, {
        headers: headers,
        cancelToken: cancelToken,
      });
    })
    .catch((err) => console.error(err));
};

const secureDeleteApi = (url, cancelToken = null) => {
  return getAPIToken()
    .then((token) => {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      return axios.delete(`${API}/${url}}`, {
        headers: headers,
        cancelToken: cancelToken,
      });
    })
    .catch((err) => console.error(err));
};

function secure_post_form_data(url, formData) {
  return getAPIToken().then((token) => {
    return axios.post(`${API}/${url}`, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  });
}

const logMsal = (url, data) => {
  return axios({
    method: "post",
    url: `${API}/${url}`,
    data: data,
    timeout: 600000,
  });
};

const post = (url, data) => {
  return axios({
    method: "post",
    url: `${API}/${url}`,
    data: data,
    timeout: 600000,
    headers: commonHeaders(),
  });
};

const apiFetch = (url, params = {}) => {
  return axios.get(`${API}/${url}${queryString(params)}`, {
    headers: headers(),
  });
};

const getTimezones = () =>
  apiFetch("Reference/TimezoneList").then((r) => ({
    timezones: _.map(r.data, (x) => ({ value: x, label: x })),
  }));

// const getStates = () => apiFetch('Public/StateList').then(r => ({
//   states: _.map(r.data, x => ({...x, value: x.id, label: x.name}))
// }));

const getProducts = () =>
  api.securePost("SavingsLever/list", {}).then((response) => ({
    products: response.data.message,
  }));

const getClients = () =>
  api.securePost("Client/GetList", { activeOnly: true }).then((response) => ({
    clients: response.data.message,
  }));

const buildRecentLabFormPayload = (json, files, fileEventStatus) => {
  let payload = new FormData();
  // only works for non-arrays, non-objects
  for (var k in json) {
    if (json[k]) {
      payload.append(k, json[k]);
    }
  }
  if (files) {
    for (let i = 0; i < files.length; i++) {
      payload.append(`files`, files[i]);
    }
  }
  if (fileEventStatus) {
    for (let i = 0; i < fileEventStatus.length; i++) {
      payload.append(`fileEventStatus`, fileEventStatus[i]);
    }
  }
  if (json.uploadedFileLinks) {
    payload.append(
      "serializedUploadedFileLinks",
      JSON.stringify(json.uploadedFileLinks)
    );
  }
  return payload;
};

const loadValueSets = (typedValue, callback, url, showCode = false) => {
  const payload = {
    url: url,
    searchString: typedValue,
    count: 100,
  };
  api
    .securePost("Participant/GetValueSetCodes", payload)
    .then((response) => {
      if (response && response.data && response.data.success) {
        let list = _.map(response.data.message, (d) => {
          return {
            ...d,
            value: d.code,
            label: showCode ? `${d.code} ${d.display}` : d.display,
            description: d.text,
          };
        });
        callback(list);
      }
    })
    .catch(() => {
      console.error(
        `An error occurred while retrieving Value Set at url: ${url}`
      );
      callback([]);
    });
};

const loadInternalValueSets = (
  typedValue,
  callback,
  url,
  clientId,
  valueTypeId,
  showCode = false,
  sortField = null
) => {
  const payload = {
    maxResults: 250,
    display: typedValue,
    code: showCode ? typedValue : null,
    displayAndCode: showCode,
    activeOnly: true,
    SortField: sortField,
    valueSetTypeId: valueTypeId,
    clientId: clientId,
  };
  api
    .securePost(url, payload)
    .then((response) => {
      if (response && response.data && response.data.success) {
        let list = _.map(response.data.message, (d) => {
          return {
            ...d,
            value: d.id,
            label: showCode ? `${d.code} ${d.display}` : d.display,
            description: d.description,
          };
        });
        callback(list);
      }
    })
    .catch(() => {
      console.error("An error occurred while retrieving Value Set");
      callback([]);
    });
};

const getPractitionerList = (
  typedValue,
  callback,
  currentUser,
  clientId,
  showCode = false
) => {
  let payload = {
    clientId: clientId,
  };
  if (!typedValue) {
    //payload.stringIdentifier = currentUser.email;
  } else if (isNaN(typedValue)) {
    payload.stringIdentifier = typedValue;
  } else {
    payload.intIdentifier = Number.parseInt(typedValue);
  }
  api
    .securePost("Participant/GetPractitioners", payload)
    .then((response) => {
      if (response && response.data && response.data.success) {
        let list = _.map(response.data.message, (d) => {
          return {
            ...d,
            value: d.id,
            label: showCode
              ? `${d.firstName} ${d.lastName} - ${d.email}`
              : `${d.firstName} ${d.lastName}`,
          };
        });
        callback(list);
      }
    })
    .catch(() => {
      console.error("An error occurred while retrieving practitioner list.");
      callback([]);
    });
};

const getClientGroupList = (typedValue, callback) => {
  api
    .secureFetch("Client/ListClientGroups")
    .then((response) => {
      if (response && response.data && response.data.success) {
        let list = _.map(response.data.message, (d) => {
          return {
            ...d,
            value: d.id,
            label: d.name,
          };
        });
        callback(list);
      }
    })
    .catch(() => {
      console.error("An error occurred while retrieving practitioner list.");
      callback([]);
    });
};

const getBigQueryDatasets = (typedValue, callback) => {
  api
    .secureFetch("Client/ListDatasets")
    .then((response) => {
      if (response && response.data && response.data.success) {
        let list = _.map(response.data.message, (d) => {
          return {
            ...d,
            value: d.reference.datasetId,
            label: d.reference.datasetId,
          };
        });
        callback(list);
      }
    })
    .catch(() => {
      console.error("An error occurred while retrieving practitioner list.");
      callback([]);
    });
};

const deleteResource = (callback, obj, resourceType, clientId) => {
  if (!obj) return;
  let payload = {
    id: obj?.id,
    resourceType: resourceType,
    clientId: clientId,
  };

  api
    .securePost("Participant/DeleteResource", payload)
    .then((response) => {
      if (response && response.data && response.data.success) {
        callback("Resource successfully deleted");
      } else {
        callback("Resource could not be deleted");
      }
    })
    .catch(() => {
      console.error("An error occurred deleting the resource");
      callback("Resource could not be deleted");
    });
};

const api = {
  // downloadDocument(url) {
  //   const bol_1 = img1;
  //   const bol_2 = img2;
  //   const bol_3 = img3;
  //   const goodPallet = img4;
  //   const leaningPallet = img5;
  //   const pallets = img6;

  //   const doc_url = eval(url);
  //   try {
  //     var link = document.createElement("a");
  //     link.setAttribute("href", doc_url);
  //     link.setAttribute("download", doc_url);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch {
  //     Alert.error("There was an error downloading the document");
  //   }
  // },
  // downloadCSV(url) {
  //   const quantity_of_shipments = csv1;
  //   const quality_assurance_and_compliance = csv2;

  //   const doc_url = eval(url);
  //   try {
  //     var link = document.createElement("a");
  //     link.setAttribute("href", doc_url);
  //     link.setAttribute("download", doc_url);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch {
  //     Alert.error("There was an error downloading the document");
  //   }
  // },
  // downloadPDF(url) {
  //   try {
  //     var link = document.createElement("a");
  //     // link.setAttribute("href", url);
  //     // link.setAttribute("download", url);
  //     // just for demo ______________
  //     link.setAttribute("href", pdf1);
  //     link.setAttribute("download", pdf1);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch {
  //     Alert.error("There was an error downloading the document");
  //   }
  // },
  get_html_content(url) {
    try {
      if (url) {
        return axios.get(`${url}`, { headers: html_content_type_headers() });
      }
      return Promise.resolve(null);
    } catch {
      return Promise.resolve(null);
    }
  },
  fetch_raw(url, params = {}) {
    return axios.get(`${url}${queryString(params)}`, { headers: headers() });
  },

  fetch: apiFetch,
  secureFetch: secureFetchApi,
  setUserToken: setUserToken,
  securePost: securePostApi,
  secureDelete: secureDeleteApi,
  securePostFormData: securePostFormDataApi,
  getClients: getClients,
  loadValueSets: loadValueSets,
  loadInternalValueSets: loadInternalValueSets,
  getProducts: getProducts,
  getAccessToken: getAPIToken,
  postFormData: secure_post_form_data,
  buildRecentLabFormPayload: buildRecentLabFormPayload,
  getPractitionerList: getPractitionerList,
  getClientGroupList: getClientGroupList,
  getBigQueryDatasets,
  getUserToken,
  callGraph,
  logMsal,
  logData: logData,

  upload(verb, url, data) {
    switch (verb.toLowerCase()) {
      case "post":
        return axios.post(`${API}/${url}`, data, { headers: headers() });
      case "put":
        return axios.put(`${API}/${url}`, data, { headers: headers() });
      case "patch":
        return axios.patch(`${API}/${url}`, data, { headers: headers() });
      default:
    }
  },

  post: post,

  put(url, data) {
    return axios.put(`${API}/${url}`, data, { headers: headers() });
  },

  patch(url, data) {
    return axios.patch(`${API}/${url}`, data, { headers: headers() });
  },

  delete(url) {
    return axios.delete(`${API}/${url}`, { headers: headers() });
  },

  post_form_data(url, formData) {
    return axios.post(`${API}/${url}`, formData, {
      headers: multipart_headers(),
    });
  },

  put_form_data(url, formData) {
    return axios.put(`${API}/${url}`, formData, {
      headers: multipart_headers(),
    });
  },

  deleteResource,

  postGetBlob(url, data) {
    return axios.post(`${API}${url}`, data, {
      headers: headers(),
      withCredentials: true,
      responseType: "blob",
    });
  },

  postFormDataGetBlob(url, formData) {
    return axios.post(`${API}${url}`, formData, {
      // headers: {
      //   // "Content-Type": "multipart/form-data",
      //   Authorization: `Bearer ${userToken()}`
      // },
      headers: multipart_headers(),
      withCredentials: true,
      responseType: "blob",
    });
  },

  // post_form_data_get_blob(url, formData) {
  //   return axios.post(`${API}/${url}`, formData, {
  //     headers: multipart_headers(),
  //     responseType: "blob",
  //   });
  // },

  getTimezones: getTimezones,
  userToken() {
    return userToken();
  },
  catchHandler: (e) => {
    console.error(e);
  },
};

export default api;