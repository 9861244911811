import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { helpers, dateHelpers } from "../utils";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import { FormGroup, FormControl, FormLabel } from "@mui/material";
import cx from "classnames";
import { BiCalendarEvent } from "react-icons/bi";
import { FormFeedback } from "reactstrap";
import { StyledFormLabel } from "./index";

export default function StyledSingleDatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    if (props.selected) {
      let parsedDate =
        props.selected.isValid && props.selected.isValid()
          ? props.selected.toDate()
          : moment(props.selected).toDate();
      if (parsedDate) {
        setSelectedDate(parsedDate);
      }
    }
  }, [props.selected]);

  useEffect(() => {
    if (props.minDate) {
      let parsedDate =
        props.minDate.isValid && props.minDate.isValid()
          ? props.minDate.toDate()
          : moment(props.minDate).toDate();
      if (parsedDate) {
        setMinDate(parsedDate);
      }
    }
  }, [props.minDate]);

  useEffect(() => {
    if (props.maxDate) {
      let parsedDate =
        props.maxDate.isValid && props.maxDate.isValid()
          ? props.maxDate.toDate()
          : moment(props.maxDate).toDate();
      if (parsedDate) {
        setMaxDate(parsedDate);
      }
    }
  }, [props.maxDate]);

  function changeDateSelected(dateChange) {
    if (dateHelpers.verifyDate(dateChange)) {
      const dateMoment = dateChange ? moment(dateChange).toDate() : null;
      setSelectedDate(dateMoment);
      props.onChangeCallback(dateMoment, props.fieldName);
    } else {
      toast.warning("Please enter a valid date");
    }
  }
  const CustomInput = React.forwardRef((inputProps, ref) => {
    return (
      <>
        <DebounceInput
          onClick={inputProps.onClick}
          ref={ref}
          className="form-control datePickerInput"
          {...inputProps}
          debounceTimeout={500}
        />
        <BiCalendarEvent
          onClick={inputProps.onClick}
          className="float-right calendarDatePicker"
          size="1.5em"
        />
      </>
    );
  });

  let pickerInput = (
    <div className="datePickerInput mb-0">
      <DatePicker
        ariaInvalid={props.errorMessage ? "true" : "false"}
        className={cx(
          props.errorMessage ? "border-danger" : "",
          "form-control w-100 datePickerInput mb-0"
        )}
        selected={selectedDate || ""}
        onChange={changeDateSelected}
        onKeyDown={(event) =>
          helpers.onDatePickerKeyDown(event, changeDateSelected)
        }
        minDate={minDate || ""}
        maxDate={maxDate || ""}
        customInput={<CustomInput />}
        showTimeSelect={props.showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={1}
        dateFormat={
          props.showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"
        }
      />
    </div>
  );
  if (!props.label) {
    return pickerInput;
  }
  return (
    <FormControl component="fieldset">
      {/*{props.label ? (*/}
      {/*  <FormLabel component="legend" className="text-left float-left">*/}
      {/*    {props.label}*/}
      {/*    {props.required && helpers.requiredStar()}*/}
      {/*  </FormLabel>*/}
      {/*) : null}*/}
      <StyledFormLabel {...props} />
      <FormGroup aria-label="position" row>
        {pickerInput}
        <FormFeedback invalid={props.errorMessage}>
          {props.errorMessage}
        </FormFeedback>
      </FormGroup>
    </FormControl>
  );
}