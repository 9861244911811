import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { StyledInput, StyledSection, StyledFormLabel } from ".";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { FormControl } from "@mui/material";
import _ from "lodash";
import { MdClose, MdOutlineAddCircleOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { dateHelpers } from "../utils";
import { BiCheck } from "react-icons/bi";
import { useAutosizeTextArea } from "../hooks";
import ReactQuill, { Quill } from "react-quill";

export default function PatientChartNotes({
  obj,
  setObj,
  editingNoteIndices,
  setEditingNoteIndicies,
  onChange,
}) {
  const [noteToEdit, setNoteToEdit] = useState(null);

  function handleEditNoteSave(note, index) {
    let notes = obj.notes.slice();
    // return;
    notes[index] = note;
    onChange("notes", notes);
  }

  return (
    <StyledSection title="Notes">
      <Row className="mb-4">
        <Col xs="12">
          <StyledInput
            id="note"
            name="note"
            label="New Note"
            type="textarea"
            value={obj?.note ? obj.note.text : ""}
            maxLength="10000"
            onChange={(e) =>
              onChange("note", {
                text: e.target.value,
                time: dateHelpers.formatDateForServer(new Date()),
              })
            }
          />
          <div
            onClick={() => {
              if (!obj.note || !obj.note.text) {
                toast.warning("Enter a note before adding");
              } else {
                let notesTemp = obj?.notes?.slice();
                notesTemp.push(obj?.note);
                let recentObjTemp = { ...obj, notes: notesTemp, note: "" };
                setObj(recentObjTemp);
              }
            }}
            className="clickable-icon"
            style={{ float: "right" }}
          >
            <MdOutlineAddCircleOutline className="mr-1" />
            <small>Add Note</small>
          </div>
        </Col>
      </Row>
      {obj?.notes && obj?.notes.length > 0 && (
        <Row className="mb-4">
          <Col xs="12">
            <FormControl component="fieldset">
              <StyledFormLabel label={"Notes"} />
              {_.map(obj.notes, (note, i) => {
                return (
                  <NoteField
                    id={`note-${i}-${note?.time || new Date()}`}
                    note={note}
                    index={i}
                    editingNoteIndices={editingNoteIndices}
                    setEditingNoteIndicies={setEditingNoteIndicies}
                    onSaveNote={handleEditNoteSave}
                  />
                  // <Fragment key={`note-${i}-${note?.time || new Date()}`}>
                  //   <span>
                  //     <FiEdit
                  //       className="clickable-icon mr-2"
                  //       onClick={() => {
                  //         let editingNoteIndicesTemp =
                  //           editingNoteIndices.slice();
                  //         if (editingNoteIndicesTemp.includes(i)) {
                  //           let index = editingNoteIndicesTemp.indexOf(i);
                  //           editingNoteIndicesTemp.splice(index, 1);
                  //         } else {
                  //           editingNoteIndicesTemp.push(i);
                  //         }
                  //         setEditingNoteIndicies(editingNoteIndicesTemp);
                  //       }}
                  //     />
                  //     <FiMinusCircle
                  //       className="clickable-icon red"
                  //       onClick={() => {
                  //         // remove index from note editing indices
                  //         let editingNoteIndicesTemp =
                  //           editingNoteIndices.slice();
                  //         let index = editingNoteIndicesTemp.indexOf(i);
                  //         editingNoteIndicesTemp.splice(index, 1);
                  //         // remove note from actual notes
                  //         let notes = obj?.notes.filter(
                  //           (_, index) => index != i
                  //         );
                  //         setEditingNoteIndicies(editingNoteIndicesTemp);
                  //         onChange("notes", notes);
                  //       }}
                  //     />
                  //   </span>
                  //   {editingNoteIndices.includes(i) ? (
                  //     <StyledInput
                  //       id="notes"
                  //       name="notes"
                  //       type="textarea"
                  //       value={obj?.notes[i]?.text}
                  //       maxLength="10000"
                  //       onChange={(e) => {
                  //         let notesTemp = obj?.notes;
                  //         notesTemp[i] = { text: e.target.value };
                  //         onChange("notes", notesTemp);
                  //       }}
                  //     />
                  //   ) : (
                  //     <div className="mb-1">{note?.text}</div>
                  //   )}
                  //   <hr />
                  // </Fragment>
                );
              })}
            </FormControl>
          </Col>
        </Row>
      )}
    </StyledSection>
  );
}

const NoteField = (props) => {
  const {
    id,
    note,
    onSaveNote,
    editingNoteIndices,
    setEditingNoteIndicies,
    index,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);
  const inputRef = useRef(null);
  const newLineRegex = RegExp(/[^\n]/g);
  // useAutosizeTextArea(inputRef.current, noteToEdit);

  function handleSaveNote() {
    if (!isEditing) return;
    // let editNote = _.cloneDeep(noteToEdit);
    setIsEditing(false);
    handleRemoveIndex();
    onSaveNote(noteToEdit, index);
  }

  function handleChangeNote(value) {
    let editNote = _.cloneDeep(noteToEdit);
    editNote.text = value;
    setNoteToEdit(editNote);
  }

  function handleCancel() {
    if (isEditing) {
      setIsEditing(false);
      setNoteToEdit(null);
      handleRemoveIndex();
    }
  }

  function handleAddIndex() {
    if (editingNoteIndices.includes(index)) return;
    let editingNoteIndicesTemp = editingNoteIndices.slice();
    editingNoteIndicesTemp.push(index);
    setEditingNoteIndicies(editingNoteIndicesTemp);
  }

  function handleRemoveIndex() {
    if (!editingNoteIndices.includes(index)) return;
    let editingNoteIndicesTemp = editingNoteIndices.slice();
    let tempIndex = editingNoteIndicesTemp.indexOf(index);
    editingNoteIndicesTemp.splice(tempIndex, 1);
    setEditingNoteIndicies(editingNoteIndicesTemp);
  }

  const toolbarOptions = ["bold", "italic", "underline", "strike"];

  return (
    <Fragment key={id}>
      <span>
        {isEditing ? (
          <span>
            <BiCheck
              className="clickable-icon mr-2 green"
              title={"Save"}
              onClick={handleSaveNote}
            />
            <MdClose
              className="clickable-icon mr-2 red"
              title={"Cancel"}
              onClick={handleCancel}
            />
          </span>
        ) : (
          <FiEdit
            className="clickable-icon mr-2"
            onClick={() => {
              setNoteToEdit(note);
              handleAddIndex();
              setIsEditing(true);
            }}
          />
        )}
        {/*<FiEdit*/}
        {/*  className="clickable-icon mr-2"*/}
        {/*  onClick={() => {*/}
        {/*     let editingNoteIndicesTemp =*/}
        {/*         editingNoteIndices.slice();*/}
        {/*     if (editingNoteIndicesTemp.includes(i)) {*/}
        {/*         let index = editingNoteIndicesTemp.indexOf(i);*/}
        {/*         editingNoteIndicesTemp.splice(index, 1);*/}
        {/*     } else {*/}
        {/*         editingNoteIndicesTemp.push(i);*/}
        {/*     }*/}
        {/*     setEditingNoteIndicies(editingNoteIndicesTemp);*/}
        {/*// }}*/}
        {/*// />*/}
        <FiMinusCircle
          className="clickable-icon red"
          onClick={() => {
            // remove index from note editing indices
            // let editingNoteIndicesTemp =
            //     editingNoteIndices.slice();
            // let index = editingNoteIndicesTemp.indexOf(i);
            // editingNoteIndicesTemp.splice(index, 1);
            // // remove note from actual notes
            // let notes = obj?.notes.filter(
            //     (_, index) => index != i
            // );
            // setEditingNoteIndicies(editingNoteIndicesTemp);
            // onChange("notes", notes);
          }}
        />
      </span>
      {isEditing && noteToEdit ? (
        // <div className="editor">
        <ReactQuill
          theme="snow"
          modules={{
            toolbar: toolbarOptions,
          }}
          value={noteToEdit?.text ?? ""}
          onChange={handleChangeNote}
        />
      ) : null}
      {!isEditing && note ? (
        <div>
          <ReactQuill theme="bubble" value={note?.text} readOnly={true} />
        </div>
      ) : null}
      {/*// </div>*/}
      {/*// <textarea*/}
      {/*//   ref={inputRef}*/}
      {/*//   id="notes"*/}
      {/*//   name="notes"*/}
      {/*//   className={"form-control"}*/}
      {/*//   type="textarea"*/}
      {/*//   value={noteToEdit.text}*/}
      {/*//   maxLength="10000"*/}
      {/*//   rows={rowCount}*/}
      {/*//   onChange={({ target }) => {*/}
      {/*//     handleChangeNote(target.value);*/}
      {/*//     // let notesTemp = obj?.notes;*/}
      {/*//     // notesTemp[i] = { text: e.target.value };*/}
      {/*//     // onChange("notes", notesTemp);*/}
      {/*//   }}*/}
      {/*// />*/}
      {/*// <div className="mb-1">*/}
      {/*//   <p>{note?.text}</p>*/}
      {/*// </div>*/}
      {/*{editingNoteIndices.includes(i) ? (*/}
      {/*  <StyledInput*/}
      {/*    id="notes"*/}
      {/*    name="notes"*/}
      {/*    type="textarea"*/}
      {/*    value={obj?.notes[i]?.text}*/}
      {/*    maxLength="10000"*/}
      {/*    onChange={(e) => {*/}
      {/*      let notesTemp = obj?.notes;*/}
      {/*      notesTemp[i] = { text: e.target.value };*/}
      {/*      onChange("notes", notesTemp);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <div className="mb-1">{note?.text}</div>*/}
      {/*)}*/}
      <hr />
    </Fragment>
  );
};