import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { api } from "../utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function LongBreadCrumb(props) {
  let { clientId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!props.client) {
      setLoading(props.loading);
    }
  }, [props.loading, client]);

  useEffect(() => {
    if (props.client) {
      setLoading(true);
      getClient()
        .then((data) => {
          if (!data) return;
          setClient(data["client"]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.client]);

  function getClient() {
    return api
      .secureFetch(`Client/SimpleClient/${clientId}`)
      .then((response) => {
        if (response && response.data?.success) {
          return { client: response.data.message };
        } else {
          toast.error(
            response.data.message
              ? response.data.message
              : "There was an error retrieving the Client."
          );
        }
      })
      .catch(api.catchHandler);
  }

  return (
    <Row
      id={"breadcrumb"}
      className={"mx-0 d-flex justify-content-between w-100 breadcrumb"}
    >
      {loading ? (
        <Col className={"m-0 p-0 d-flex align-items-stretch h-100"}>
          <Skeleton
            containerClassName={"bread-crumb-skeleton"}
            height={"100%"}
            width={"100%"}
            count={1}
          />
        </Col>
      ) : (
        <>
          <Col className={"m-0 p-0 d-flex justify-content-start"}>
            {!props.single ? (
              <>
                <Col
                  lg={"auto"}
                  className={"m-0 p-0 h-100 d-flex align-items-center"}
                >
                  {props.client && client ? (
                    <span className={"bread-crumb-client"}>
                      {client?.name ?? ""}
                    </span>
                  ) : (
                    <span className={"bread-crumb-client"}>
                      {props.context}
                    </span>
                  )}
                </Col>
                <div className={"m-0 p-0 h-100"}>
                  <div className={"bread-crumb-separator"} />
                </div>
                <Col lg={"auto"} className={"m-0 p-0 bread-crumb-subtext"}>
                  {props.page}
                </Col>
              </>
            ) : null}
            {/*: <Skeleton containerClassName={'bread-crumb-skeleton'} height={'100%'} width={'100%'} count={1}/>}*/}
          </Col>
          <Col className={"m-0 p-0 d-flex justify-content-end"}>
            {props.trailing ? props.trailing : props.children}
          </Col>
        </>
      )}
    </Row>
  );
}

const TrailingAction = ({ children }) => {
  console.debug(children);
  if (children?.length > 1) {
    return children;
  }

  return null;
};