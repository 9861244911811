import React, { Fragment, useState, useEffect } from "react";
import { StyledInput, StyledFormLabel} from ".";
import {
  FormGroup,
  FormControl,
} from "@mui/material";

export default function FilterText(props) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(props.value || "")
  }, [props.value])

  function onChange(value) {
    let resolvedValue = value;
    if (props.forceInt) {
      resolvedValue = parseInt(value);
      if (isNaN(resolvedValue)) {
        resolvedValue = null;
      }
    }
    const filteredDisplay = resolvedValue
      ? props.useEqualsDisplayName
        ? `${props.displayName}: ${resolvedValue}`
        : `${props.displayName} ${props.descriptionPrefix ? props.descriptionPrefix : "starts with"} ${resolvedValue}`
      : null;
    setValue(resolvedValue);
    props.onChangeCallback({
      filterName: props.filterName,
      value: resolvedValue,
      filteredDisplay: filteredDisplay
    });
  }

  return (
    <Fragment>
      {props.noLabel ? null
     : <StyledFormLabel {...props} />}
      <StyledInput
        onKeyDown={props.onKeyDown}
        className="form-control text-black"
        minLength={props.minLength || 2}
        value={value}
        placeholder={props.placeholder}
        debounceTimeout={props.debounceTimeout ?? 500}
        onChange={event => onChange(event.target.value)}
        disabled={props.disabled}
      />
    </Fragment>
  );
}
