import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useMatch,
  useParams,
} from "react-router-dom";
import { Person, MgtPerson, Login, PersonViewType } from "@microsoft/mgt-react";
import { FaChevronRight } from "react-icons/fa";
import { slideInLeft } from "react-animations";

// STYLE, LOGO & IMG
import logo2 from "../assets/images/well_purple.png";
import { Col, Row } from "reactstrap";
import Button from "@mui/material/Button";
import classNames from "classnames";
import _ from "lodash";
import { Menu, MenuButton, MenuDivider, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { UserContext, constants, helpers, storage, api } from "../utils";
import { IoLogOut } from "react-icons/io5";
import axios from "axios";
import { graphConfig, loginRequest } from "../authConfig";
import { callMsGraph, callMsGraphPhoto } from "../graph";
import userContext from "../utils/userContext";
import { ProfileAvatar } from "./index";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { MdAddBusiness, MdOutlineLocalFireDepartment } from "react-icons/md";

const { ROUTES } = constants;
let currentUser = storage.getItem("currentUser");
currentUser = currentUser ? JSON.parse(currentUser) : null;

const Header = () => {
  let { type, clientId, campaignId, caseId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  campaignId = campaignId ? parseInt(campaignId, 10) : null;
  caseId = campaignId ? parseInt(caseId, 10) : null;
  const [dropdown, setDropdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState(mainTabs.clients);
  const { currentUser } = useContext(UserContext);
  const location = useLocation();
  const isReadOnly = _.some(
    currentUser.views,
    (v) => v.typeOfView === constants.ACCESS_VIEWS.PATIENT_CHART_READONLY.value
  );
  const hasCommonLinks = _.some(
    currentUser.views,
    (v) => v.typeOfView === constants.ACCESS_VIEWS.BASIC.value
  );
  const hasCampaignManagement = _.some(
    currentUser.views,
    (v) => v.typeOfView === constants.ACCESS_VIEWS.CAMPAIGNS.value
  );
  const hasClientView = _.some(
    currentUser.views,
    (v) =>
      v.typeOfView === constants.ACCESS_VIEWS.PATIENT_CHART.value ||
      v.typeOfView === constants.ACCESS_VIEWS.PATIENT_CHART_READONLY.value
  );
  const hasAdmin = currentUser.isAdmin;

  useEffect(() => {}, []);

  const getSubLinks = () => {
    if (selectedTab == mainTabs.clients && clientId !== null) {
      return hasCampaignManagement
        ? subNavigation.clients.main
        : subNavigation.clientsNonAdmin.main;
    }
    if (_.includes(location.pathname, "products")) {
      return subNavigation.products;
    }
    if (_.includes(location.pathname, "admin")) {
      return subNavigation.admin;
    }
    return [];
  };

  return (
    <>
      <Col id={"main-header"} lg={12} className={"p-0 vw-100 main-header"}>
        <Row className="d-flex justify-content-between mx-0 header-bg">
          <Row
            className={"d-flex align-items-center mx-0 justify-content-start"}
            lg={8}
          >
            <NavTab linkTo={"/"} onClick={() => {}} classNames={"no-hover"}>
              <img className="header-logo" src={logo2} />
            </NavTab>
            {hasClientView ? (
              <NavTab
                active={
                  location.pathname === "/" ||
                  _.includes(location.pathname, "/clients")
                }
                name={"Clients"}
                linkTo={"/"}
                onClick={() => setSelectedTab(mainTabs.clients)}
              >
                Clients
              </NavTab>
            ) : null}
            {hasCampaignManagement ? (
              <NavTab
                active={_.includes(location.pathname, "/products")}
                name={"Products"}
                linkTo={ROUTES.Product.Management}
                onClick={() => setSelectedTab(mainTabs.products)}
              >
                Products
              </NavTab>
            ) : null}
            {hasCommonLinks ? (
              <NavTab
                active={_.includes(location.pathname, "/tool-box")}
                name={"Toolbox"}
                linkTo={ROUTES.Toolbox}
                onClick={() => setSelectedTab(mainTabs.toolbox)}
              >
                Toolbox
              </NavTab>
            ) : null}
            {hasAdmin ? (
              <NavTab
                active={_.includes(location.pathname, "/admin")}
                name={"Administration"}
                linkTo={ROUTES.Admin.ClientAdmin}
                onClick={() => setSelectedTab(mainTabs.admin)}
              >
                Administration
              </NavTab>
            ) : null}
          </Row>
          <Row className={"m-0"}>
            <Col></Col>
            <HeaderProfile />
          </Row>
        </Row>
        <hr className={"m-0"} />
        <SubHeader subLinks={getSubLinks()} selectedTab={selectedTab} />
      </Col>
    </>
  );
};

const SubHeader = (props) => {
  const [links, setLinks] = useState([]);
  const location = useLocation();
  const { clientId, campaignId, typeId } = useParams();

  useEffect(() => {
    setLinks(props.subLinks);
  }, [props.subLinks]);

  //Trying to replace params that should be replaced anyway... :'(
  const replacePathParams = (path, params, prefix = ":") => {
    let newPath = path;
    Object.entries(params).forEach(([key, value]) => {
      if (value === null) return;
      newPath = newPath.replace(prefix + key, value);
    });
    return newPath;
  };

  function buildLinks() {
    if (links.length > 0) {
      return _.map(links, (sub, i) => {
        return (
          <SubNav
            key={i}
            name={sub.name}
            linkTo={replacePathParams(sub.link, {
              clientId: clientId,
              campaignId: campaignId,
              typeId: typeId,
            })}
            pathName={location.pathname}
            active={_.includes(location.pathname, sub.link)}
          />
        );
      });
    }
    return <SubNav name={"hidden"} linkTo={"_"} />;
  }

  return (
    <Row
      className={classNames("p-0", "ps-2", "w-100", "mx-0", {
        "header-visibility": !links.length,
        "sub-header": links.length > 0,
      })}
    >
      <Row lg={"auto"} className={"sub-header-decoration"}>
        <Col lg={"auto"} className={"d-flex align-items-center me-2"}>
          <FaChevronRight className={"icon-color mx-2"} />
        </Col>
      </Row>
      <Col className={"d-flex justify-content-start sub-header-link-row"}>
        {buildLinks()}
      </Col>
    </Row>
  );
};

const SubNav = (props) => {
  // const [isActive, setIsActive] = useState(props.active ?? false);
  const location = useLocation();
  const params = useParams();

  const isActive = location.pathname === props.linkTo;
  return (
    <Col lg={"auto"} className={"px-0 d-flex align-items-stretch"}>
      <Link
        className={classNames("base-sub-nav", {
          "sub-nav-inactive": !isActive,
          "sub-nav-active": isActive,
        })}
        to={props.linkTo}
      >
        <Row lg={"auto"} className={"m-0 p-0"}>
          {props.name}
        </Row>
        {isActive ? (
          <Row lg={"auto"} className={"m-0 p-0 active-sub-item"}></Row>
        ) : null}
      </Link>
    </Col>
  );
};

const NavTab = (props) => {
  // const [active, setActive] = useState(props.active);
  const location = useLocation();
  // useEffect(() => {
  //   setActive(
  //     _.includes(location.pathname, "/user-admin") ? false : props.active
  //   );
  // }, [props.active]);

  const isActive = _.includes(location.pathname, "/user-admin")
    ? false
    : props.active;
  return (
    <Col className={"h-100 d-flex align-items-stretch p-0"}>
      <Link
        className={classNames(
          props.classNames,
          { "main-nav-tab-inactive": !isActive },
          { "main-nav-tab-active": isActive }
        )}
        tag={Link}
        onClick={props.onClick}
        to={props.linkTo}
      >
        <span className={"text-black mx-2"}>{props.children}</span>
      </Link>
    </Col>
  );
};

const subNavigation = {
  clients: {
    main: [
      { name: "Plan", link: ROUTES.Client.PlanDetails },
      { name: "Savings", link: ROUTES.Client.SavingsNavigator },
      {
        name: "Campaigns",
        link: ROUTES.Client.Campaigns,
        viewTypes: [constants.ACCESS_VIEWS.CAMPAIGNS],
      },
      {
        name: "Members",
        link: ROUTES.Client.MemberManagement,
        viewTypes: [
          constants.ACCESS_VIEWS.PATIENT_CHART,
          constants.ACCESS_VIEWS.PATIENT_CHART_READONLY,
        ],
      },
      {
        name: "Participant Merge",
        link: ROUTES.Client.PatientMerge,
        viewTypes: [
          constants.ACCESS_VIEWS.PATIENT_CHART,
          constants.ACCESS_VIEWS.PATIENT_CHART_READONLY,
        ],
      },
      { name: "Management", link: ROUTES.Client.ClientAdmin },
    ],
    client: [
      { name: "Savings", link: "/" },
      { name: "Financials", link: "/" },
      { name: "Members", link: "/" },
      { name: "Management", link: "/" },
    ],
  },
  clientsNonAdmin: {
    main: [
      {
        name: "Members",
        link: ROUTES.Client.MemberManagement,
        viewTypes: [
          constants.ACCESS_VIEWS.PATIENT_CHART,
          constants.ACCESS_VIEWS.PATIENT_CHART_READONLY,
        ],
      },
    ],
    client: [{ name: "Members", link: "/" }],
  },
  products: [
    { name: "Management", link: ROUTES.Product.Management },
    { name: "Configuration", link: ROUTES.Product.Configuration },
    { name: "Reporting", link: ROUTES.Product.Reporting },
  ],
  toolbox: {},
  admin: [
    { name: "Clients", link: ROUTES.Admin.ClientAdmin },
    {
      name: "Client Groups",
      link: ROUTES.Admin.ClientGroupAdmin,
    },
    { name: "Fhir", link: ROUTES.Admin.FhirAdmin },
    { name: "User", link: ROUTES.Admin.UserAdmin },
  ],
};

const mainTabs = {
  clients: "Clients",
  products: "Products",
  toolbox: "Toolbox",
  admin: "Administration",
};

const UserInfo = () => {
  // const msal = useMsal();

  // useEffect(() => {
  //
  // }, []);

  return <div></div>;
};

const HeaderProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(UserContext);

  let microsoft = "microsoft";
  let redirect = "redirect";

  function clearCache() {
    api
      .secureFetch("fhiradmin/clearCache")
      .then((res) => {
        if (!res) return;
      })
      .catch(api.catchHandler);
  }

  return (
    // <>

    <Menu
      transition
      menuButton={
        <MenuButton className={"avatar"}>
          {/*{isAuthenticated && <Person personQuery="me" view={PersonViewType.oneline} />}*/}
          <ProfileAvatar />
        </MenuButton>
      }
    >
      {currentUser && currentUser.isAdmin && (
        <>
          {/*<MenuItem onClick={() => navigate(ROUTES.Admin.UserAdmin)}>*/}
          {/*  <Row>*/}
          {/*    <Col lg={"auto"}>*/}
          {/*      <BsFillPersonLinesFill />*/}
          {/*    </Col>*/}
          {/*    <Col>User Admin</Col>*/}
          {/*  </Row>*/}
          {/*</MenuItem>*/}
          {/*<MenuItem onClick={() => navigate(ROUTES.Admin.ClientAdmin)}>*/}
          {/*  <Row>*/}
          {/*    <Col lg={"auto"}>*/}
          {/*      <MdAddBusiness />*/}
          {/*    </Col>*/}
          {/*    <Col>Client Admin</Col>*/}
          {/*  </Row>*/}
          {/*</MenuItem>*/}
          {/*<MenuItem onClick={() => navigate(ROUTES.Admin.FhirAdmin)}>*/}
          {/*  <Row>*/}
          {/*    <Col lg={"auto"}>*/}
          {/*      <MdOutlineLocalFireDepartment />*/}
          {/*    </Col>*/}
          {/*    <Col>FHIR Admin</Col>*/}
          {/*  </Row>*/}
          {/*</MenuItem>*/}
          {process.env.NODE_ENV === "development" ? (
            <MenuItem onClick={() => clearCache()}>
              <Row>
                <Col lg={"auto"}>
                  <MdAddBusiness />
                </Col>
                <Col>Clear Cache</Col>
              </Row>
            </MenuItem>
          ) : null}
          <MenuDivider />
        </>
      )}
      {/*<MenuItem onClick={() => {}}>*/}
      {/*  {" "}*/}
      {/*  /!* Was calling handleLogout(microsoft) here *!/*/}
      {/*  <Row>*/}
      {/*    <Col lg={"auto"}>*/}
      {/*      <IoIosSettings />*/}
      {/*    </Col>*/}
      {/*    <Col>Settings</Col>*/}
      {/*  </Row>*/}
      {/*</MenuItem>*/}
      <MenuItem onClick={logout}>
        <Row>
          <Col lg={"auto"}>
            <IoLogOut />
          </Col>
          <Col>Log Out</Col>
        </Row>
      </MenuItem>
    </Menu>
  );
};

export default Header;