import {
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export default function StyledToggle(props) {
  return (
    <FormControl component="fieldset">
      {props.header ? (
        <FormLabel component="legend">{props.header}</FormLabel>
      ) : null}
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={props.value}
          control={
            <Switch
              onClick={props.onClick}
              disabled={props.disabled}
              checked={props.value}
            />
          }
          label={props.label}
          labelPlacement={props.labelPlacement || "top"}
        />
      </FormGroup>
    </FormControl>
  );
}