import { useMsal } from "@azure/msal-react";
import { constants, storage, UserContext } from "../utils";
import React, { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";

const ProfileAvatar = () => {
  const { instance, accounts } = useMsal();
  const image =
    storage.getItem(constants.localStorageKeys.profilePhoto) ?? null;
  const [account, setActiveAccount] = useState(null);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (!instance.getActiveAccount()) return;
    setActiveAccount(instance.getActiveAccount());
  }, []);

  if (!account) {
    return null;
  }

  return (
    <div
      className={
        "w-100 h-100 d-flex justify-content-evenly align-items-center mr-lg-4 mr-sm-1"
      }
    >
      <div className={"mr-lg-2 mr-sm-1"}>
        <Avatar
          name={account?.name ?? ""}
          size={"24"}
          round
          src={`data:image/png;base64,${image}`}
        />
        {/*)}*/}
      </div>
      <div
        className={"font-weight-bold d-lg-inline d-sm-none"}
        style={{ fontSize: "0.9rem" }}
      >
        {account?.name ?? ""}
      </div>
    </div>
  );
};

export default ProfileAvatar;