import React, { useContext } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./";
import SignOutButton from "./SignOutButton";
import { Grid } from "@mui/material/";
import { UserContext } from "../utils";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */
const AuthButton = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { currentUser } = useContext(UserContext);
  // console.debug(isAuthenticated);

  return (
    <>
      <Grid>
        {props.children}
        <div className={"w-100 d-flex justify-content-center"}>
          {isAuthenticated && currentUser ? (
            <SignOutButton />
          ) : (
            <SignInButton />
          )}
        </div>
      </Grid>
    </>
  );
};

export default AuthButton;