import { FormGroup, FormControl } from "@mui/material";
import { useState } from "react";
import cx from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { StyledFormLabel, ValueSetToolTip } from "./";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import AsyncSelect from "react-select/async";

export default function NuStyledAsyncSelect(props) {
  const [inputValue, setInputValue] = useState(null);

  return (
    <FormControl component="fieldset">
      <StyledFormLabel {...props} />
      <FormGroup aria-label="position" row>
        <span
          className={props.description ? "selectWithToolTipSpacing" : "w-100"}
        >
          <AsyncSelect
            styles={{
              option: (provided, state) => ({
                ...provided,
                paddingBottom: 20,
                textAlign: "left",
              }),
            }}
            isDisabled={props.isDisabled}
            defaultOptions
            isClearable={props.isClearable}
            isMulti={props.isMulti}
            placeholder={props.placeHolder}
            loadOptions={props.loadOptions}
            onInputChange={setInputValue}
            inputValue={inputValue || ""}
            onChange={props.onChange}
            value={props.value}
            classNamePrefix="wcityNuSelect"
            // className={props.description ? "selectWithToolTipSpacing" : "w-100"}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </span>
        {props.description ? (
          <ValueSetToolTip
            key={`${props.value}`}
            idName={props.idName}
            description={props.description}
          />
        ) : null}
      </FormGroup>
    </FormControl>
  );
}