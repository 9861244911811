import { useEffect, useState, Fragment } from "react";
import AsyncSelect from "react-select/async";
import { api, filterHelpers, helpers, constants, dateHelpers } from "../utils";
import {
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  CardBody,
  Collapse,
} from "reactstrap";
import {
  WidgetCard,
  StyledModal,
  StyledSelect,
  StyledSingleDatePicker,
  StyledInput,
  StyledSection,
  Loader,
  StyledAsyncSelect,
  FhirChoiceVariable,
  StyledTD,
} from ".";
import _ from "lodash";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const {
  MEDICAL,
  PHARMACY,
  COPAY,
  DEDUCTIBLE,
  COINSURANCE,
  BILLED_AMOUNT,
  INELIGIBLE_AMOUNT,
  ALLOWED_AMOUNT,
  PAID_AMOUNT,
  UNIT_COUNT,
  SALES_TAX,
  AWP,
  INGREDIENT_COST,
  DISPENCING_FEE,
  USUAL_AND_CUSTOMARY,
  TOTAL_MEMBER_PAID,
  TOTAL_PAYOR_PAID,
  CLAIMS_MEMBER_FINANCIAL_TYPES,
} = constants;

function RenderClaims({
  claims,
  isEditing,
  editModalOpenCallback,
  viewModalOpenCallback,
  currentClaimCallback,
  isDeletingCallback,
  recentLabValueCallback,
  type,
}) {
  const typeFilter =
    type === MEDICAL
      ? constants.MEDICAL_CLAIM_TYPES
      : constants.PHARMACY_CLAIM_TYPES;
  claims = _.chain(claims)
    .filter((claim) =>
      _.some(typeFilter, (t) =>
        t === claim?.type?.label ? claim.type.label.toLowerCase() : ""
      )
    )
    .orderBy(
      ["fillDate", "serviceEndDate", "createdDate"],
      ["desc", "desc", "desc"]
    )
    .value();
  return (
    <Row>
      <Col>
        {claims && claims.length ? (
          <Table className="surrounding-borders-hidden" bordered>
            <thead>
              <tr>
                {type === MEDICAL ? (
                  <Fragment>
                    {isEditing && <th />}
                    <th>Primary Diagnosis</th>
                    <th>Procedure</th>
                    <th>Service Date</th>
                  </Fragment>
                ) : (
                  <Fragment>
                    {isEditing && <th />}
                    <th>Medication Name</th>
                    <th>Prescribing Physician</th>
                    <th>Fill Date</th>
                  </Fragment>
                )}
              </tr>
            </thead>
            <tbody>
              {_.map(claims, (c, index) => {
                const firstDiagnosis = _.head(c.diagnosisList)?.diagnosis;
                return type === MEDICAL ? (
                  <tr
                    key={`medicalClaim-${index}-${c.id}`}
                    onClick={() => {
                      if (!isEditing) {
                        viewModalOpenCallback(true);
                        currentClaimCallback(c);
                      }
                    }}
                    className={classNames({
                      cursorPointer: !isEditing,
                      clickableRow: !isEditing,
                    })}
                  >
                    {isEditing && (
                      <StyledTD textCenter>
                        <>
                          <FiEdit
                            className="clickable-icon mr-2"
                            onClick={() => {
                              editModalOpenCallback(true);
                              currentClaimCallback(c);
                            }}
                          />
                          <FiMinusCircle
                            className="clickable-icon red"
                            onClick={() => {
                              isDeletingCallback(true);
                              currentClaimCallback(c);
                              viewModalOpenCallback(true);
                            }}
                          />
                        </>
                      </StyledTD>
                    )}
                    <StyledTD>
                      {_.head(c.diagnosisList)?.diagnosis?.code || "N/A"}
                    </StyledTD>
                    <StyledTD>
                      {/*{_.map(c.procedureList, (p) => (*/}
                      {/*  <div key={p.procedure.code}>{p.procedure.code}</div>*/}
                      {/*))}*/}
                    </StyledTD>
                    <StyledTD textRight>
                      {dateHelpers.toMDYDateString(c.serviceStartDate)}
                      {dateHelpers.toMDYDateString(c.serviceStartDate) !==
                        null &&
                        c.serviceEndDate !== null && <span> - </span>}
                      <span>
                        {dateHelpers.toMDYDateString(c.serviceEndDate)}
                      </span>
                    </StyledTD>
                  </tr>
                ) : (
                  <tr
                    key={`pharmacyClaim${index}`}
                    onClick={() => {
                      if (!isEditing) {
                        viewModalOpenCallback(true);
                        currentClaimCallback(c);
                      }
                    }}
                    className={classNames({
                      cursorPointer: !isEditing,
                      clickableRow: !isEditing,
                    })}
                  >
                    {isEditing && (
                      <StyledTD textCenter>
                        <>
                          <FiEdit
                            className="clickable-icon mr-2"
                            onClick={() => {
                              editModalOpenCallback(true);
                              currentClaimCallback(c);
                            }}
                          />
                          <FiMinusCircle
                            className="clickable-icon red"
                            onClick={() => {
                              isDeletingCallback(true);
                              currentClaimCallback(c);
                              viewModalOpenCallback(true);
                            }}
                          />
                        </>
                      </StyledTD>
                    )}
                    <StyledTD>{c.drugName}</StyledTD>
                    <StyledTD>{c.prescriberName}</StyledTD>
                    <StyledTD textRight>
                      {dateHelpers.toMDYDateString(c.fillDate)}
                    </StyledTD>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : null}
      </Col>
    </Row>
  );
}

function CollapsableSection({
  cardBodyClass,
  stickySection,
  collapseSection,
  isScrollable,
  onOpen,
  onClose,
}) {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <CardBody className={cardBodyClass || "card-widget"}>
      <Row>
        <Col xs="11">{stickySection}</Col>
      </Row>
      <Collapse isOpen={collapse}>
        <div className={classNames({ scrollhost: isScrollable })}>
          <Row>
            <Col xs="11">{collapseSection}</Col>
          </Row>
        </div>
      </Collapse>
      {collapseSection && (
        <Row>
          <Col xs="6">
            {collapse ? (
              <div
                onClick={() => {
                  if (onClose) onClose();
                  toggle();
                }}
                className="clickable-icon colorPurple"
              >
                show less
              </div>
            ) : (
              <div
                onClick={() => {
                  if (onOpen) onOpen();
                  toggle();
                }}
                className="clickable-icon colorPurple"
              >
                show more
              </div>
            )}
          </Col>
        </Row>
      )}
    </CardBody>
  );
}

function ViewModalLine({ title, value }) {
  return (
    <div className="mb-1">
      <b>{title}:&nbsp;</b>
      {value}
    </div>
  );
}

function RenderSummaryFinancialData({ financialData, memberPaidRow }) {
  const payorPaidRow = _.find(financialData, (x) =>
    _.includes(x.name.toLowerCase(), TOTAL_PAYOR_PAID)
  );
  const total =
    (memberPaidRow && memberPaidRow.amount ? memberPaidRow.amount : 0.0) +
    (payorPaidRow && payorPaidRow.amount ? payorPaidRow.amount : 0.0);
  return (
    <tr className="summary-row">
      <StyledTD>{_.startCase(PAID_AMOUNT)}</StyledTD>
      <StyledTD>
        {payorPaidRow
          ? helpers.formatUSDCurrency(payorPaidRow.amount || 0)
          : helpers.formatUSDCurrency(0)}
      </StyledTD>
      <StyledTD>
        {memberPaidRow
          ? helpers.formatUSDCurrency(memberPaidRow.amount || 0)
          : helpers.formatUSDCurrency(0)}
      </StyledTD>
      <StyledTD>{helpers.formatUSDCurrency(total)}</StyledTD>
    </tr>
  );
}

function RenderFinancialData({ employer, member, total, financialData }) {
  /*
  CLAIMS_MEMBER_FINANCIAL_TYPES: [
    "Coinsurance",
    "Copay",
    "Deductible",
    "Total Patient Paid",
  ],
*/
  return (
    <tr key={`financials ${financialData.name}`}>
      <StyledTD>{financialData.name}</StyledTD>
      <StyledTD>
        {employer
          ? helpers.formatUSDCurrency(financialData.amount || 0)
          : helpers.formatUSDCurrency(0)}
      </StyledTD>
      <StyledTD>
        {member
          ? helpers.formatUSDCurrency(financialData.amount || 0)
          : helpers.formatUSDCurrency(0)}
      </StyledTD>
      <StyledTD>
        {total
          ? helpers.formatUSDCurrency(financialData.amount || 0)
          : helpers.formatUSDCurrency(0)}
      </StyledTD>
    </tr>
  );
}

const EMPTY_CLAIM = {
  type: {},
  revenueCode: {},
  drgCode: {},
  serviceStartDate: "",
  serviceEndDate: "",
};

export default function PatientChartClaims({
  clientId,
  participantId,
  subjectOptions,
}) {
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [loading, setLoading] = useState(false);
  const [claims, setClaims] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [viewPharmacyModalOpen, setViewPharmacyModalOpen] = useState(false);
  const [viewMedicalModalOpen, setViewMedicalModalOpen] = useState(false);
  const [editPharmacyModalOpen, setEditPharmacyModalOpen] = useState(false);
  const [editMedicalModalOpen, setEditMedicalModalOpen] = useState(false);
  const [showFullDiagnosisList, setShowFullDiagnosisList] = useState(false);
  const [showFullFinancialList, setShowFullFinancialList] = useState(false);
  const [currentClaim, setCurrentClaim] = useState(
    Object.assign({}, EMPTY_CLAIM)
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [icd10CodesList, setIcd10CodesList] = useState([]);

  function reset() {
    setEditPharmacyModalOpen(false);
    setEditMedicalModalOpen(false);
    setViewPharmacyModalOpen(false);
    setViewMedicalModalOpen(false);
    setCurrentClaim(Object.assign({}, EMPTY_CLAIM));
    setIsEditing(false);
    setIsDeleting(false);
  }

  useEffect(refreshData, []);

  function refreshData() {
    if (loading) return;
    setLoading(true);
    const apiCalls = [getClaimInfo(), getICD10List()];
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.pagedClaimsInfo) {
          setClaims(
            _.chain(aggResults.pagedClaimsInfo.items)
              .map((c) => ({
                ...c,
                type: helpers.mapToValueLabelDescription(c.type),
                status: _.find(
                  constants.FHIR_CLAIM_STATUS,
                  (s) => s.value === c.status
                ),
                patientGender: _.find(
                  constants.CLAIM_GENDER_TYPES,
                  (g) => g.value === c.patientGender
                ),
                diagnosisList: _.map(c.diagnosisList, (d) => ({
                  ...d,
                  diagnosis: helpers.mapToValueLabelDescription(d.diagnosis),
                })),
              }))
              .map((c) => processDiagnosisList(c))
              .value()
          );
        }
        if (aggResults.ICD10CodesList) {
          setIcd10CodesList(aggResults.ICD10CodesList);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function processDiagnosisList(claim) {
    if (!claim.diagnosisList.length) return claim;
    let primaryAndSecondary = [];
    let others = [];

    //get first 2 diagnosis in list
    primaryAndSecondary = _.chain(claim.diagnosisList)
      .sort((d) => d.seq)
      .take(2)
      .value();
    //  put all remaining diagnosis in this list
    others = _.reject(claim.diagnosisList, (d) => {
      return _.some(primaryAndSecondary, (p) => p === d);
    });
    claim.primaryAndSecondary = primaryAndSecondary;
    claim.others = others;
    return claim;
  }

  function getClaimInfo(link) {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      // link: link,
      // maxResults: constants.DEFAULT_PAGE_SIZE,
    };
    return api
      .securePost("Participant/ClaimsInfo", payload)
      .then((response) => {
        if (response && response.data) {
          return { pagedClaimsInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function getICD10List() {
    const payload = {
      maxResults: 500000,
      // display: typedValue,
      // code: showCode ? typedValue : null,
      displayAndCode: false,
      activeOnly: true,
    };
    return api
      .securePost(
        constants.INTERNAL_VALUE_SET_URLS.DIAGNOSIS_ICD10_CODES,
        payload
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          let list = _.map(response.data.message, (d) => {
            d.code = _.replace(d.code, ".", "");
            return {
              ...d,
              value: d.id,
              label: d.display,
              description: d.description,
            };
          });
          return { ICD10CodesList: list };
        }
      })
      .catch(api.catchHandler);
  }

  function onChangeClaim(field, value) {
    let tempClaim = Object.assign({}, currentClaim);
    tempClaim[field] = value;
    setCurrentClaim(tempClaim);
  }

  function onChangeDiagnosis(field, value, diagnosis) {
    let tempClaim = Object.assign({}, currentClaim);
    let tempDiagnosisList = tempClaim.diagnosisList;
    let index = _.findIndex(
      tempClaim.diagnosisList,
      (d) => d.diagnosis === diagnosis
    );
    tempDiagnosisList[index].diagnosis = value;
    tempClaim.diagnosisList = tempDiagnosisList;
    setCurrentClaim(tempClaim);
  }

  function resolveDiagnosisPositionName(index, title) {
    if (isNaN(index)) return title;
    switch (index) {
      case 0:
        return `Primary ${title}`;
      case 1:
        return `Secondary ${title}`;
      case 2:
        return `Third ${title}`;
      case 3:
        return `Fourth ${title}`;
      case 4:
        return `Fifth ${title}`;
      case 5:
        return `Sixth ${title}`;
      case 6:
        return `Seventh ${title}`;
      case 7:
        return `Eigth ${title}`;
      case 8:
        return `Ninth ${title}`;
      case 9:
        return `Tenth ${title}`;
      default:
        return title;
    }
  }

  function resolveMemberPaidRow(list) {
    const total = _.chain(list)
      .filter(
        (x) =>
          _.includes(x.name.toLowerCase(), COPAY) ||
          _.includes(x.name.toLowerCase(), DEDUCTIBLE) ||
          _.includes(x.name.toLowerCase(), COINSURANCE)
      )
      .sumBy((x) => x.amount)
      .value();
    return { name: _.startCase(TOTAL_MEMBER_PAID), amount: total };
  }

  return (
    <>
      <WidgetCard
        title="Claims"
        startOpen={claims && claims.length > 0}
        isScrollable
      >
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col>
              {claims && claims.length > 0 && (
                <Fragment>
                  <WidgetCard
                    title="Medical Claims"
                    isScrollable
                    startOpen
                    // canCreateNew={!isReadonly}
                    // canEdit={!isReadonly}
                    onCreateNew={() => {
                      setIsEditing(false);
                      setEditMedicalModalOpen(true);
                    }}
                    onEdit={() => setIsEditing(!isEditing)}
                  >
                    <RenderClaims
                      type={MEDICAL}
                      claims={claims}
                      isEditing={isEditing}
                      editModalOpenCallback={setEditMedicalModalOpen}
                      viewModalOpenCallback={setViewMedicalModalOpen}
                      currentClaimCallback={setCurrentClaim}
                      isDeletingCallback={setIsDeleting}
                      recentLabValueCallback={() => {}}
                    />
                  </WidgetCard>
                  <WidgetCard
                    title="Pharmacy Claims"
                    isScrollable
                    startOpen
                    // canCreateNew={!isReadonly}
                    // canEdit={!isReadonly}
                    onCreateNew={() => {
                      setIsEditing(false);
                      setEditPharmacyModalOpen(true);
                    }}
                    onEdit={() => setIsEditing(!isEditing)}
                  >
                    <RenderClaims
                      type={PHARMACY}
                      claims={claims}
                      isEditing={isEditing}
                      editModalOpenCallback={setEditPharmacyModalOpen}
                      viewModalOpenCallback={setViewPharmacyModalOpen}
                      currentClaimCallback={setCurrentClaim}
                      isDeletingCallback={setIsDeleting}
                      recentLabValueCallback={() => {}}
                    />
                  </WidgetCard>
                </Fragment>
              )}
            </Col>
          </Row>
        )}
      </WidgetCard>

      {/* Pharmacy Claim View Modal */}
      <StyledModal
        show={viewPharmacyModalOpen}
        onHide={reset}
        size="xl"
        title={"Pharmacy Claim"}
        disabled={loading}
        savePrompt="CLOSE"
        onSave={reset}
      >
        <StyledSection title="Zone 1">
          <Row>
            <Col>
              <WidgetCard
                title="Claim Identity"
                startOpen={currentClaim}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col xs="6">
                        <ViewModalLine
                          title="Wellnecity Claim Id"
                          value={currentClaim?.wellnecityClaimId}
                        />
                      </Col>
                      <Col xs="6">
                        <ViewModalLine
                          title="External Claim Id"
                          value={currentClaim?.externalClaimId}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Claim Type"
                            value={currentClaim?.type?.label}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Claim Status"
                            value={currentClaim?.status?.label}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Insurer"
                            value={currentClaim.insurer}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Payor"
                            value={currentClaim.payor}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Plan Name"
                            value={currentClaim.planName}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Patient Identity"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col xs="6">
                        <ViewModalLine
                          title="Wellnecity Id"
                          value={currentClaim?.patientInternalId}
                        />
                      </Col>
                      <Col xs="6">
                        <ViewModalLine
                          title="Patient Id"
                          value={currentClaim?.patientExternalId}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Patient First Name"
                            value={currentClaim?.patientFirstName}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Patient Last Name"
                            value={currentClaim?.patientLastName}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Patient Gender"
                            value={currentClaim?.patientGender?.label}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Patient DOB"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.patientDOB
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Patient State"
                            value={currentClaim?.patientState}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Patient Zip Code"
                            value={helpers.handleZipCode(
                              currentClaim?.patientZip
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Provider Identity"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col xs="6">
                        <ViewModalLine
                          title="Prescriber Name"
                          value={currentClaim?.prescriberName}
                        />
                      </Col>
                      <Col xs="6">
                        <ViewModalLine
                          title="Pharmacy Name"
                          value={currentClaim?.pharmacyName}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Prescriber NPI"
                            value={currentClaim?.prescriberNPI}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Pharmacy NPI"
                            value={currentClaim?.pharmacyNPI}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Prescription Number"
                            value={currentClaim?.prescriptionNumber}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Subscriber Identity"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col>
                        <ViewModalLine
                          title="Subscriber Id"
                          value={currentClaim?.subscriberId}
                        />
                      </Col>
                      <Col>
                        <ViewModalLine
                          title="Wellnecity Id"
                          value={currentClaim?.subscriberWellnecityId}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Subscriber Name"
                            value={currentClaim?.subscriberName}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Zone 2">
          <Row className="mb-4">
            <Col>
              <WidgetCard
                title="Dates"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col xs="6">
                        <ViewModalLine
                          title="Fill Date"
                          value={dateHelpers.toMDYDateString(
                            currentClaim?.fillDate
                          )}
                        />
                      </Col>
                      <Col xs="6">
                        <ViewModalLine
                          title="Prescription Date"
                          value={dateHelpers.toMDYDateString(
                            currentClaim?.prescriptionDate
                          )}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Created Date"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.createdDate
                            )}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Paid Date"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.paidDate
                            )}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Financials"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th className={"dianosis-header"}></th>
                      <th className={"dianosis-header"}>Employer Paid </th>
                      <th className={"dianosis-header"}>Member Paid</th>
                      <th className={"dianosis-header"}>Total Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showFullFinancialList ? (
                      <Fragment>
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), COPAY)
                            ) || { name: _.startCase(COPAY), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), DEDUCTIBLE)
                            ) || { name: _.startCase(DEDUCTIBLE), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), COINSURANCE)
                            ) || { name: _.startCase(COINSURANCE), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), SALES_TAX)
                            ) || { name: _.startCase(SALES_TAX), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          employer
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), TOTAL_PAYOR_PAID)
                            ) || {
                              name: _.startCase(TOTAL_PAYOR_PAID),
                              amount: 0,
                            }
                          }
                        />
                        {/*                        <RenderFinancialData 
                          member total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), TOTAL_MEMBER_PAID)
                            ) || { name: _.startCase(TOTAL_MEMBER_PAID), amount: 0 }
                          }
                        />
*/}
                        <RenderFinancialData
                          member
                          total
                          financialData={resolveMemberPaidRow(
                            currentClaim.financials
                          )}
                        />
                      </Fragment>
                    ) : null}
                    <RenderSummaryFinancialData
                      financialData={currentClaim.financials}
                      memberPaidRow={resolveMemberPaidRow(
                        currentClaim.financials
                      )}
                    />
                    {currentClaim.financials &&
                    currentClaim.financials.length ? (
                      <tr
                        className="clickable-icon colorPurple"
                        onClick={() =>
                          setShowFullFinancialList(!showFullFinancialList)
                        }
                      >
                        <td colSpan="4" className="text-left">
                          {showFullFinancialList ? "Show less" : "Show more"}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </WidgetCard>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <WidgetCard
                title="Pricing Data"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Ingredient Cost"
                            value={
                              _.find(currentClaim.financials, (f) =>
                                _.includes(
                                  f.name.toLowerCase(),
                                  INGREDIENT_COST
                                )
                              )?.amount || ""
                            }
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="AWP"
                            value={
                              _.find(currentClaim.financials, (f) =>
                                _.includes(f.name.toLowerCase(), AWP)
                              )?.amount || ""
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Usual and Customary"
                            value={
                              _.find(currentClaim.financials, (f) =>
                                _.includes(
                                  f.name.toLowerCase(),
                                  USUAL_AND_CUSTOMARY
                                )
                              )?.amount || ""
                            }
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Dispensing Fee"
                            value={
                              _.find(currentClaim.financials, (f) =>
                                _.includes(f.name.toLowerCase(), DISPENCING_FEE)
                              )?.amount || ""
                            }
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                  // collapseSection={
                  //   <Fragment>
                  //   </Fragment>
                  // }
                />
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Zone 3">
          <Row className="mb-4">
            <Col>
              <WidgetCard
                title="Drug Details"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Drug Name"
                            value={currentClaim.drugName}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="NDC Code"
                            value={currentClaim.ndcCode}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Generic Name"
                            value={currentClaim.genericName}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Drug Dosage"
                            value={currentClaim.drugDosage}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Brand Indicator"
                            value={currentClaim.brandIndicator}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Specialty Indicator"
                            value={currentClaim.specialtyIndicator}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Formulary Indicator"
                            value={currentClaim.formularyIndicator}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Compound Indicator"
                            value={currentClaim.compoundIndicator}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Refills Authorized"
                            value={currentClaim.numberOfRepeatsAllowed}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Supply Days"
                            value={currentClaim.expectedSupplyDuration}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Refill Number"
                            value={currentClaim.fillNumber}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Quantity Dispensed"
                            value={currentClaim.quantityDispensed}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6"></Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
      </StyledModal>

      {/* Medical Claim View Modal */}
      <StyledModal
        show={viewMedicalModalOpen}
        onHide={reset}
        size="xl"
        title={"Medical Claim"}
        disabled={loading}
        savePrompt="CLOSE"
        onSave={reset}
      >
        <StyledSection title="Zone 1">
          <Row>
            <Col>
              <WidgetCard
                title="Claim Identity"
                startOpen={currentClaim}
                isScrollable
                hideCollapse
              >
                <Fragment>
                  <CollapsableSection
                    // isScrollable
                    stickySection={
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Wellnecity Claim Id"
                            value={currentClaim?.wellnecityClaimId}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="External Claim Id"
                            value={currentClaim?.externalClaimId}
                          />
                        </Col>
                      </Row>
                    }
                    collapseSection={
                      <Fragment>
                        <Row>
                          <Col>
                            <ViewModalLine
                              title="Claim Type"
                              value={currentClaim?.type?.label}
                            />
                          </Col>
                          <Col>
                            <ViewModalLine
                              title="Claim Status"
                              value={currentClaim?.stringStatus}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ViewModalLine
                              title="Revenue Code"
                              value={currentClaim?.revenueCode?.code}
                            />
                          </Col>
                          <Col>
                            <ViewModalLine
                              title="DRG Code"
                              value={currentClaim?.drgCode?.code}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ViewModalLine
                              title="Insurer"
                              value={currentClaim.insurer}
                            />
                          </Col>
                          <Col>
                            <ViewModalLine
                              title="Payor"
                              value={currentClaim.payor}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ViewModalLine
                              title="Plan Name"
                              value={currentClaim.planName}
                            />
                          </Col>
                          <Col>
                            <ViewModalLine
                              title="Place Of Service"
                              value={currentClaim.placeOfService}
                            />
                          </Col>
                        </Row>
                        {/* <Row>
                        <Col>
                          <ViewModalLine
                            title="Office"
                            value={currentClaim.office}
                          />
                        </Col>
                        <Col>
                        </Col>
                      </Row> */}
                        <Row>
                          <Col></Col>
                        </Row>
                      </Fragment>
                    }
                  />
                </Fragment>
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Patient Identity"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col xs="6">
                        <ViewModalLine
                          title="Wellnecity Id"
                          value={currentClaim?.patientInternalId}
                        />
                      </Col>
                      <Col xs="6">
                        <ViewModalLine
                          title="Patient Id"
                          value={currentClaim?.patientExternalId}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Patient First Name"
                            value={currentClaim?.patientFirstName}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Patient Last Name"
                            value={currentClaim?.patientLastName}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Patient Gender"
                            value={currentClaim?.patientGender?.label}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Patient DOB"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.patientDOB
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="State"
                            value={currentClaim?.patientState}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Zip Code"
                            value={helpers.handleZipCode(
                              currentClaim?.patientZip
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Provider Identity"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col>
                        <ViewModalLine
                          title="Rendering Provider"
                          value={currentClaim?.renderingProviderName}
                        />
                      </Col>
                      <Col>
                        <ViewModalLine
                          title="Billing Provider"
                          value={currentClaim?.billingProvider}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Rendering Provider ID"
                            value={currentClaim?.renderingId}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Billing Provider Id"
                            value={currentClaim?.billingProviderId}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Rendering Provider State"
                            value={currentClaim?.renderingState}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Billing Provider State"
                            value={currentClaim?.billingProviderState}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Rendering Provider Zip"
                            value={helpers.handleZipCode(
                              currentClaim?.renderingZip
                            )}
                          />
                        </Col>
                        <Col>
                          <ViewModalLine
                            title="Billing Provider Zip"
                            value={helpers.handleZipCode(
                              currentClaim?.billingProviderZip
                            )}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Subscriber Identity"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col>
                        <ViewModalLine
                          title="Subscriber Id"
                          value={currentClaim?.subscriberId}
                        />
                      </Col>
                      <Col>
                        <ViewModalLine
                          title="Wellnecity Id"
                          value={currentClaim?.subscriberWellnecityId}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col>
                          <ViewModalLine
                            title="Subscriber Name"
                            value={currentClaim?.subscriberName}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Zone 2">
          <Row>
            <Col>
              <WidgetCard
                title="Dates"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <CollapsableSection
                  // isScrollable
                  stickySection={
                    <Row>
                      <Col xs="6">
                        <ViewModalLine
                          title="Service Start Date"
                          value={dateHelpers.toMDYDateString(
                            currentClaim.serviceStartDate
                          )}
                        />
                      </Col>
                      <Col xs="6">
                        <ViewModalLine
                          title="Service End Date"
                          value={dateHelpers.toMDYDateString(
                            currentClaim.serviceEndDate
                          )}
                        />
                      </Col>
                    </Row>
                  }
                  collapseSection={
                    <Fragment>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Created Date"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.createdDate
                            )}
                          />
                        </Col>
                        <Col xs="6">
                          <ViewModalLine
                            title="Admit Date"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.admitDate
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <ViewModalLine
                            title="Paid Date"
                            value={dateHelpers.toMDYDateString(
                              currentClaim.paidDate
                            )}
                          />
                        </Col>
                        <Col xs="6"></Col>
                      </Row>
                    </Fragment>
                  }
                />
              </WidgetCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <WidgetCard
                title="Financials"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th className={"dianosis-header"}></th>
                      <th className={"dianosis-header"}>Employer Paid </th>
                      <th className={"dianosis-header"}>Member Paid</th>
                      <th className={"dianosis-header"}>Total Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <RenderFinancialData
                      total
                      financialData={
                        _.find(currentClaim.financials, (f) =>
                          _.includes(f.name.toLowerCase(), BILLED_AMOUNT)
                        ) || { name: _.startCase(BILLED_AMOUNT), amount: 0 }
                      }
                    />
                    <RenderFinancialData
                      total
                      financialData={
                        _.find(currentClaim.financials, (f) =>
                          _.includes(f.name.toLowerCase(), INELIGIBLE_AMOUNT)
                        ) || { name: _.startCase(INELIGIBLE_AMOUNT), amount: 0 }
                      }
                    />
                    <RenderFinancialData
                      total
                      financialData={
                        _.find(currentClaim.financials, (f) =>
                          _.includes(f.name.toLowerCase(), ALLOWED_AMOUNT)
                        ) || { name: _.startCase(ALLOWED_AMOUNT), amount: 0 }
                      }
                    />
                    {showFullFinancialList ? (
                      <Fragment>
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), COPAY)
                            ) || { name: _.startCase(COPAY), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), DEDUCTIBLE)
                            ) || { name: _.startCase(DEDUCTIBLE), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), COINSURANCE)
                            ) || { name: _.startCase(COINSURANCE), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), SALES_TAX)
                            ) || { name: _.startCase(SALES_TAX), amount: 0 }
                          }
                        />
                        <RenderFinancialData
                          employer
                          total
                          financialData={
                            _.find(currentClaim.financials, (f) =>
                              _.includes(f.name.toLowerCase(), TOTAL_PAYOR_PAID)
                            ) || {
                              name: _.startCase(TOTAL_PAYOR_PAID),
                              amount: 0,
                            }
                          }
                        />
                        <RenderFinancialData
                          member
                          total
                          financialData={resolveMemberPaidRow(
                            currentClaim.financials
                          )}
                        />
                      </Fragment>
                    ) : null}
                    <RenderSummaryFinancialData
                      financialData={currentClaim.financials}
                      memberPaidRow={resolveMemberPaidRow(
                        currentClaim.financials
                      )}
                    />
                    {currentClaim.financials &&
                    currentClaim.financials.length ? (
                      <tr
                        className="clickable-icon colorPurple"
                        onClick={() =>
                          setShowFullFinancialList(!showFullFinancialList)
                        }
                      >
                        <td colSpan="4" className="text-left">
                          {showFullFinancialList ? "Show less" : "Show more"}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Zone 3">
          <Row className="mb-4">
            <Col>
              <WidgetCard
                title="Diagnosis"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th className={"dianosis-header"}></th>
                      <th className={"dianosis-header"}>Code Value</th>
                      <th className={"dianosis-header"}>Code Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(currentClaim.diagnosisList, (d, index) => {
                      let primaryCodeDisplay = _.find(
                        icd10CodesList,
                        (icd) => icd.code === d.diagnosis?.code
                      );
                      return index === 0 ||
                        index === 1 ||
                        showFullDiagnosisList ? (
                        <tr key={`dg-${index}`}>
                          <StyledTD>
                            {resolveDiagnosisPositionName(index, "Diagnosis")}
                          </StyledTD>
                          <StyledTD>
                            {primaryCodeDisplay?.code || d.diagnosis?.code}
                          </StyledTD>
                          <StyledTD>{primaryCodeDisplay?.display}</StyledTD>
                        </tr>
                      ) : null;
                    })}
                    {showFullDiagnosisList ? ( // need more information on differentiating thses values in Explanation of benefits
                      <Fragment>
                        <tr>
                          <StyledTD>{"Admitting Diagnosis"}</StyledTD>
                          <StyledTD>
                            {currentClaim.diagnosisList &&
                            currentClaim.diagnosisList.length &&
                            currentClaim.diagnosisList[0]
                              ? (
                                  _.find(
                                    icd10CodesList,
                                    (icd) =>
                                      icd.code ===
                                      currentClaim.diagnosisList[0].diagnosis
                                        ?.code
                                  ) || currentClaim.diagnosisList[0].diagnosis
                                )?.code
                              : null}
                          </StyledTD>
                          <StyledTD>
                            {currentClaim.diagnosisList &&
                            currentClaim.diagnosisList.length &&
                            currentClaim.diagnosisList[0]
                              ? (
                                  _.find(
                                    icd10CodesList,
                                    (icd) =>
                                      icd.code ===
                                      currentClaim.diagnosisList[0].diagnosis
                                        ?.code
                                  ) || currentClaim.diagnosisList[0].diagnosis
                                )?.display
                              : null}
                          </StyledTD>
                        </tr>
                        <tr>
                          <StyledTD>{"Discharge Diagnosis"}</StyledTD>
                          <StyledTD>
                            {currentClaim.diagnosisList &&
                            currentClaim.diagnosisList.length &&
                            currentClaim.diagnosisList[0]
                              ? (
                                  _.find(
                                    icd10CodesList,
                                    (icd) =>
                                      icd.code ===
                                      currentClaim.diagnosisList[0].diagnosis
                                        ?.code
                                  ) || currentClaim.diagnosisList[0].diagnosis
                                )?.code
                              : null}
                          </StyledTD>
                          <StyledTD>
                            {currentClaim.diagnosisList &&
                            currentClaim.diagnosisList.length &&
                            currentClaim.diagnosisList[0]
                              ? (
                                  _.find(
                                    icd10CodesList,
                                    (icd) =>
                                      icd.code ===
                                      currentClaim.diagnosisList[0].diagnosis
                                        ?.code
                                  ) || currentClaim.diagnosisList[0].diagnosis
                                )?.display
                              : null}
                          </StyledTD>
                        </tr>
                      </Fragment>
                    ) : null}
                  </tbody>
                </Table>
                {currentClaim.diagnosisList &&
                currentClaim.diagnosisList.length > 2 ? (
                  <div
                    className="clickable-icon colorPurple"
                    onClick={() =>
                      setShowFullDiagnosisList(!showFullDiagnosisList)
                    }
                  >
                    {showFullDiagnosisList ? "Show less" : "Show more"}
                  </div>
                ) : null}
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Zone 4">
          <Row className="mb-4">
            <Col>
              <WidgetCard
                title="Procedures"
                startOpen={claims}
                isScrollable
                hideCollapse
              >
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th className={"dianosis-header"}>Line Number</th>
                      <th className={"dianosis-header"}>DRG</th>
                      <th className={"dianosis-header"}>Procedure Code</th>
                      <th className={"dianosis-header"}>Procedure Name</th>
                      <th className={"dianosis-header"}>Primary Modifier</th>
                      <th className={"dianosis-header"}>Secondary Modifier</th>
                      <th className={"dianosis-header"}>Service Date</th>
                      <th className={"dianosis-header"}>Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentClaim && currentClaim.procedureList
                      ? _.map(currentClaim.procedureList, (p, index) => (
                          <tr key={`cl-${index}`}>
                            <StyledTD>{p.seq}</StyledTD>
                            <StyledTD></StyledTD>

                            {/*<StyledTD>{currentClaim.drgCode?.code}</StyledTD>*/}
                            <StyledTD>{p?.procedure?.code ?? ""}</StyledTD>
                            <StyledTD>{null}</StyledTD>
                            <StyledTD>{p.primaryModifier ?? ""}</StyledTD>
                            <StyledTD>{p.secondaryModifier ?? ""}</StyledTD>
                            {/*<StyledTD>*/}
                            {/*  {p.modifiers && p.modifiers.length && p.modifiers[0]*/}
                            {/*    ? p.modifiers[0].code*/}
                            {/*    : constants.NOT_PROVIDED}*/}
                            {/*</StyledTD>*/}
                            {/*<StyledTD>*/}
                            {/*  {p.modifiers && p.modifiers.length && p.modifiers[1]*/}
                            {/*    ? p.modifiers[1].code*/}
                            {/*    : constants.NOT_PROVIDED}*/}
                            {/*</StyledTD>*/}
                            <StyledTD>
                              {helpers.getUIFriendlyDate(p?.date)}
                            </StyledTD>
                            <StyledTD>
                              {helpers.formatUSDCurrency(p.amount || 0)}
                            </StyledTD>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </WidgetCard>
            </Col>
          </Row>
        </StyledSection>
      </StyledModal>
    </>
  );
}