import { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Badge,
} from "reactstrap";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

export default function WidgetCard(props) {
  const [collapse, setCollapse] = useState(
    _.isBoolean(props.startOpen) ? props.startOpen : false
  );
  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <Card className="mb-2 mt-0 mx-0">
      <CardHeader className="expand-md bg-white">
        <Row>
          <Col className="text-left">
            <div className="float-left d-flex justify-content-evenly align-items-center">
              <b>{props.title}</b>
              {props.totalCount ? (
                <Badge className="mx-2 px-2 py-1" pill id="total-count-badge">
                  {props.totalCount}
                </Badge>
              ) : null}
            </div>
            {props.subtitle}
          </Col>
          <Col className="text-right">
            {props.canCreateNew ? (
              <MdOutlineAddCircleOutline
                data-tip
                data-for={`createNew${props.title}Tip`}
                onClick={() => {
                  props.onCreateNew();
                }}
                className="clickable-icon mr-3"
              />
            ) : null}
            {props.canEdit ? (
              <FiEdit onClick={props.onEdit} className="clickable-icon mr-3" />
            ) : null}
            {props.hideCollapse ? null : collapse ? (
              <IoIosArrowUp
                onClick={() => {
                  if (props.onClose) props.onClose();
                  toggle();
                }}
                className="clickable-icon"
              />
            ) : (
              <IoIosArrowDown
                onClick={() => {
                  if (props.onOpen) props.onOpen();
                  toggle();
                }}
                className="clickable-icon"
              />
            )}
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={props.hideCollapse || collapse}>
        <div className={classNames({ scrollhost: props.isScrollable })}>
          <CardBody className={props.cardBodyClass || "card-widget"}>
            {props.children}
          </CardBody>
        </div>
      </Collapse>

      {props.createNewOptions && (
        <ReactTooltip
          className="tooltip"
          border
          clickable
          id={`createNew${props.title}Tip`}
          delayHide="250"
          place="bottom"
          type="light"
          effect="solid"
        >
          <>
            {_.map(props.createNewOptions, (x) => {
              return (
                <div
                  className="tooltip-option"
                  onClick={() => props.onCreateNew(x)}
                >
                  {x?.label}
                </div>
              );
            })}
          </>
        </ReactTooltip>
      )}
    </Card>
  );
}