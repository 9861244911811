import { useEffect, useState, Fragment } from "react";
import { helpers, api } from "../utils";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import _ from "lodash";
import {
  Col,
  Row,
  ButtonGroup,
  Alert,
} from "reactstrap";
import { StyledButton, ProductTree, StyledInput, StyledSelect } from "./";
import { MdOutlineAccountTree } from 'react-icons/md';

const emptyProduct = {
  id: 0,
  name: "",
  parentSavingsLeverId: null,
  formulaFileKey: null,
};

const VIEWS = {
  EDIT: "Edit",
  HIERARCHY: "Hierarchy",
};

export default function ProductAdmin(props) {
  const [availableProducts, setAvailableProducts] = useState(null);
  const [productHierarchy, setProductHierarchy] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [viewMode, setViewMode] = useState(VIEWS.EDIT);

  useEffect(() => {
    let apiCalls = [api.getProducts()];
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.products) {
          let leverlist = _.map(aggResults.products, (p) => ({
            value: p.id,
            label: p.name,
          }));
          leverlist.unshift({ label: "No parent", value: null });
          setAvailableProducts(leverlist);
        }
      })
      .catch((error) => {
        helpers.catchHandler(error);
      });
  }, []);

  useEffect(() => {
    if (!loading) return;
    GetHierarchy();
  }, [loading]);

  const GetHierarchy = () => {
    setLoading(true);
    api
      .secureFetch("SavingsLever/SLHierarchy", {})
      .then((response) => {
        if (response.data && response.data.success) {
          setProductHierarchy(response.data.message);
        }
      })
      .catch((error) => {
        helpers.catchHandler(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let nodeList = null;
    if (productHierarchy) {
      nodeList = helpers.getCheckboxTreeNodes(productHierarchy, checked);
    }
    if (!_.isEqual(nodeList, nodes)) {
      setNodes(nodeList);
    }
  }, [productHierarchy, checked]);

  function onChangeProduct(field, value) {
    let tempProduct = Object.assign({}, selectedProduct);
    tempProduct[field] = value;
    setSelectedProduct(tempProduct);
  }

  function isInvalid() {
    let errors = [];
    if (!selectedProduct.name) {
      errors.push("Please enter a name for the product.");
    }
    let errorMessage = _.join(errors, " ");
    if (errorMessage.length > 0) {
      setAlertMessage({
        flavor: "danger",
        text: errorMessage,
      });
      return true;
    } else {
      return false;
    }
  }

  function saveProduct() {
    if (isInvalid()) return;
    // TO DO --> shape the payload
    // let payload = Object.assign({}, selectedProduct);
    api
      .securePost(`SavingsLever/SaveSavingsLever`, selectedProduct)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setAlertMessage({
            flavor: "success",
            text: "Product saved.",
          });
          setTimeout(() => {
            setLoading(true);
            setAlertMessage(null)
            setSelectedProduct(null)
          }, 2000);
        } else {
          setAlertMessage({
            flavor: "danger",
            text: "Error saving product",
          });
        }
      });
  }

  function selectProduct(product) {
    setSelectedProduct(null);
    setChecked([]);
    setViewMode(VIEWS.EDIT);
    let productKey = product.key || product.value;
    setSelectedProduct(product);
    setChecked([productKey]);
    }

  useEffect(() => {
    if (checked && checked.length > 0) {
      onCheckboxTreeClick(checked[0]);
    }
  }, [checked]);

  function onCheckboxTreeClick(checkedKey) {
    let parentProduct = helpers.findFirstParent(nodes, checkedKey);
    if (parentProduct) {
      setSelectedProduct(parentProduct);
    } else {
      if (!selectedProduct) {
        console.warning(
          "may be missing scenario to find node here",
          helpers.findFirstParent(nodes, checkedKey)
        );
      }
    }
  }

  // function onProductCheck(checkedList) {
  //   setViewMode(VIEWS.EDIT)
  //   let checkedKey = checkedList[checkedList.length - 1];
  //   if (selectedProduct && selectProduct.key === checkedKey) {
  //     closeOutEditing();
  //   } else {
  //     let node = helpers.findNodeByKey(nodes, checkedKey);
  //     if (node) {
  //       setSelectedProduct(node.currentNode.parent);
  //       setChecked([checkedKey]);
  //     }
  //   }
  // }

  const StyledNode = styled.button`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid #009879;
    cursor: default !important;
  `;
  function renderChildren(parent) {
    return _.map(parent.children, (c) => (
      <TreeNode
        label={
          <StyledNode
            // onClick={() => {
            //   selectProduct(c.parent);
            // }}

          >
            {c.parent.name}
          </StyledNode>
        }
      >
        {c.children.length > 0 ? renderChildren(c) : null}
      </TreeNode>
    ));
  }
  function createNewProduct(parent = null) {
    setViewMode(VIEWS.EDIT);
    let newProduct = Object.assign({}, emptyProduct);
    if (parent) {
      newProduct.parentSavingsLeverId = parent.id;
    }
    setSelectedProduct(newProduct);
  }

  function closeOutEditing() {
    setSelectedProduct(null);
    setChecked([]);
  }

  function toggleViewMode() {
    if (viewMode === VIEWS.EDIT) {
      setViewMode(VIEWS.HIERARCHY);
    } else {
      setViewMode(VIEWS.EDIT);
    }
  }

  return availableProducts ? (
    <>
      <div className="child-container__site">
        <Row className="w-100">
          <Col xs="6">
            <h3>Products</h3>
          </Col>
          <Col xs="6">
            <ButtonGroup className="float-right">
              <StyledButton
                color={viewMode === VIEWS.EDIT ? "primary" : ""}
                onClick={toggleViewMode}
                showEditIcon
              >
                Edit Mode
              </StyledButton>
              <StyledButton
                color={viewMode === VIEWS.HIERARCHY ? "primary" : ""}
                onClick={toggleViewMode}
                icon={MdOutlineAccountTree}
              >
                Hierarchy
              </StyledButton>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="w-100">
          <Col xs="5">
            {viewMode === VIEWS.EDIT ? (
              <ProductTree
                nodes={nodes}
                checked={checked}
                expanded={expanded}
                setChecked={() => {}}
                setExpanded={(expanded) => setExpanded(expanded)}
                onlyLeafCheckboxes={false}
                noCascade={true}
                // onCheck={(checkedItems) => {
                //   // since it always sends back list of checkboxes, only ever want 1
                //   checkedItems = _.reject(checkedItems, n => _.some(checked, x => x === n));
                //   onProductCheck(checkedItems)
                //   setChecked(checkedItems);
                // }}
                onClick={(item) => {
                  setViewMode(VIEWS.EDIT);
                  if (
                    checked &&
                    checked.length > 0 &&
                    checked[0] === item.value
                  ) {
                    setChecked([]);
                    setSelectedProduct(null);
                  } else {
                    setChecked([item.value]);
                  }
                }}
                hideCheckboxes={true}
              />
            ) : (
              <>
                {productHierarchy ? (
                  <Tree
                    lineWidth={"2px"}
                    lineColor={"#58539d"}
                    // lineColor={'rgba(204, 213, 226, 1)'}
                    lineBorderRadius={"10px"}
                    label={<StyledNode>Savings Libraries</StyledNode>}
                  >
                    {renderChildren(productHierarchy)}
                  </Tree>
                ) : null}
              </>
            )}
          </Col>
          <Col xs="7">
            <Row>
              <Col>
                {viewMode === VIEWS.EDIT && selectedProduct ? (
                  <>
                    <Row>
                      <Col>
                        <h3>
                          Edit{" "}
                          {selectedProduct.name
                            ? selectedProduct.name
                            : "New Product"}
                        </h3>
                        {alertMessage ? (
                          <Alert color={alertMessage.flavor}>
                            {alertMessage.text}
                          </Alert>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <StyledInput
                          type="text"
                          maxLength="50"
                          id="productName"
                          value={selectedProduct.name || ""}
                          onChange={(e) =>
                            onChangeProduct("name", e.target.value)
                          }
                          name="productName"
                          label="Name"
                        />
                      </Col>
                      <Col>
                        <StyledSelect
                          id="parentSavingsLeverId"
                          isSingleSelect={true}
                          filterName="user_lever"
                          label="Parent Product"
                          options={availableProducts}
                          onChange={(e) =>
                            onChangeProduct("parentSavingsLeverId", e.value)
                          }
                          value={_.find(
                            availableProducts,
                            (l) =>
                              l.value === selectedProduct.parentSavingsLeverId
                          )}
                          isDisabled={true}
                          defaultValue={{ label: "No parent", value: null }}
                          // clear={clear}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" className="mt-2">
                        {selectedProduct && selectedProduct.id ? (
                          <StyledButton
                            onClick={() => createNewProduct(selectedProduct)}
                            color="warning"
                            showPlusIcon
                          >
                            New Child Product
                          </StyledButton>
                        ) : null}
                      </Col>
                      <Col xs="6" className="mt-2">
                        <ButtonGroup className="float-right">
                          <StyledButton
                            color="primary"
                            className="text-light"
                            onClick={saveProduct}
                            showSaveIcon
                          >
                            {selectedProduct.parentSavingsLeverId
                              ? "Save Child"
                              : "Save Parent"}
                          </StyledButton>
                          <StyledButton onClick={closeOutEditing}>
                            Cancel
                          </StyledButton>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {viewMode === VIEWS.EDIT && (
                      <StyledButton
                        color="warning"
                        className="float-right"
                        onClick={createNewProduct}
                        showPlusIcon
                      >
                        New Parent Product
                      </StyledButton>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  ) : null;
}
