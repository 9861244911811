export default function StyledTD(props) {
  let incomingProps = Object.assign({}, props);
  let textAlignment = ' text-left'
  let className = (props.className || "");
  if (props.textRight) {
    textAlignment = ' text-right';
    delete incomingProps.textRight;
  }
  if (props.textCenter) {
    textAlignment = ' text-center';
    delete incomingProps.textCenter;
  }
  incomingProps.className = className + textAlignment;
  return (
    <td {...incomingProps}>{props.children}</td>
  )
}