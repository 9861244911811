import React, { useState, useEffect, useRef } from "react";
import { api, constants, helpers, filterHelpers } from "../utils";
import { Form, Alert, Table, Row, Col } from "react-bootstrap";
import {
  Card,
  CardTitle,
  CardBody,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import {
  StyledButton,
  Upload,
  FilterText,
  StyledModal,
  StyledInput,
  StyledFormLabel,
  StyledTD,
  PageContainer,
  LongBreadCrumb,
  StyledPager,
  NavigationTab,
  StyledAsyncSelect,
  StyledSelect,
  FilterSelect,
} from "./";
import _ from "lodash";
import { toast } from "react-toastify";
import StyledFilterToggle from "./StyledFilterToggle";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { DASHBOARD_TYPES, GreenCheck, RedX, ROUTES } = constants;

const emptyNewClient = {
  id: 0,
  name: "",
};

const NavigationTabs = {
  clients: 0,
  clientGroups: 1,
};
const NavNames = {
  clients: "clients",
  clientGroups: "client-groups",
};

export default function ClientAdmin() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const selectedTab = searchParams.get("tab");
  // if (!selectedTab) {
  //   setSearchParams({ tab: NavNames.clients, page: 1 });
  // }

  const [saving, setSaving] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginatedList, setPaginatedList] = useState(null);
  const [selectedClientGroup, setSelectedClientGroup] = useState(null);
  //Clients
  const [maxPageSize, setMaxPageSize] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useState([
    { filterName: "activeOnly", value: true },
  ]);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [bigQueryDatasets, setBigQueryDatasets] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);

  useEffect(() => {}, [searchParams]);

  useEffect(fetchDropdownOptions, []);

  useEffect(() => {
    refreshClientList();
  }, [pageNumber, filters]);

  function onFilterChange(changedFilter) {
    const filterList = filterHelpers.getFilters(filters, changedFilter);
    setPageNumber(1);
    setFilters(filterList);
  }

  function fetchDropdownOptions() {
    let apiCalls = [];
    apiCalls.push(getDatasets());
    apiCalls.push(getClientGroups());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.datasets && aggResults.datasets.length) {
          let mappedDataset = aggResults.datasets.map((dataset, idx) => {
            return {
              ...dataset,
              label: dataset.reference.datasetId,
              value: dataset.reference.datasetId,
            };
          });
          setBigQueryDatasets(mappedDataset);
        }
        if (aggResults.clientGroups && aggResults.clientGroups.length) {
          let mappedGroups = aggResults.clientGroups.map((clientGroup, idx) => {
            return {
              label: clientGroup.name,
              value: clientGroup.id,
            };
          });
          setClientGroups(mappedGroups);
        }
      })
      .catch(api.catchHandler);
  }

  function getDatasets() {
    return api
      .secureFetch("Client/ListDatasets")
      .then((response) => {
        if (!response || !response.data || !response.data.success) return;
        return {
          datasets: response.data.message,
        };
      })
      .catch(api.catchHandler);
  }

  function getClientGroups() {
    return api
      .secureFetch("Client/ListClientGroups")
      .then((response) => {
        if (!response || !response.data || !response.data.success) return;
        return {
          clientGroups: response.data.message,
        };
      })
      .catch(api.catchHandler);
  }

  function saveClient(e) {
    e.preventDefault();
    setSaving(true);
    let payload = Object.assign({}, selectedClient);
    payload.logoContent = selectedClient.logo;
    api
      .postFormData("Client/SaveClient", payload)
      .then((response) => {
        if (response.data && response.data.success) {
          toast.success("Client saved successfully");
        }
      })
      .catch((error) => {
        helpers.catchHandler(error);
      })
      .finally(() => {
        refreshClientList();
        setSaving(false);
        setSelectedClient(null);
      });
  }

  function onChangeClient(field, value) {
    let tempClient = Object.assign({}, selectedClient);
    tempClient[field] = value;
    setSelectedClient(tempClient);
  }

  function createNewClient() {
    let newItem = Object.assign({}, emptyNewClient);
    setSelectedClient(newItem);
  }

  function createClientRefreshPayload() {
    let payload = {
      sortField: sortField,
      sortDirection: sortDirection,
      maxResults: constants.DEFAULT_PAGE_SIZE,
      pageNumber: pageNumber,
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    return payload;
  }

  function refreshClientList() {
    if (loading) return;
    setLoading(true);
    api
      .securePost("Client/PaginatedList", createClientRefreshPayload())
      .then((response) => {
        if (response && response.data && response.data.success) {
          setPaginatedList(response.data.message);
        } else {
          console.error("Error getting back list of clients");
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function toggleClient(clientId) {
    api
      .secureFetch(`Client/ToggleClient/${clientId}`)
      .then((response) => {
        if (!response) return;
        if (response.data.success) {
          toast.success("Successfully toggled the client. Refreshing..");
          setTimeout(() => refreshClientList(), 500);
        }
      })
      .catch((e) => console.error(e));
  }

  function onUpload(files) {
    onChangeClient("logo", files[0]);
  }

  return (
    <PageContainer>
      <Col
        className={"m-0 p-0 h-100 w-100 flex-column justify-content-between"}
      >
        <LongBreadCrumb
          context={"Client"}
          page={"Administration"}
          trailing={
            <StyledButton
              color="primary"
              className="float-right"
              onClick={createNewClient}
              showPlusIcon
            >
              New Client
            </StyledButton>
          }
        />
        <Row className={"m-0 p-0 w-100 h-100 position-relative"}>
          <div
            className={
              "position-relative overflow-hidden w-100 h-100 px-4 pt-2 pb-0 rounded bg-white d-flex flex-column space-between"
            }
          >
            <Col sm="12" className={"h-100 w-100 "}>
              <div
                sm="3"
                style={{ height: "10%" }}
                className="w-100 mb-3 d-flex"
              >
                <Col className={"w-25"}>
                  <FilterText
                    placeHolder={"Name"}
                    filterName="name"
                    label="Name"
                    onChangeCallback={onFilterChange}
                    value={filterHelpers.getValue("name", filters)}
                  />
                </Col>

                <Col className={"w-25"} style={{ zIndex: 1000 }}>
                  <FilterSelect
                    isDisabled={loading}
                    filterName="ClientGroupId"
                    displayName="Client Group"
                    options={clientGroups}
                    onChangeCallback={onFilterChange}
                    isSingleSelect={true}
                    value={_.filter(
                      clientGroups,
                      (x) =>
                        x.value ===
                        filterHelpers.getValue("ClientGroupId", filters)
                    )}
                  />
                </Col>
                <Col className={"w-25"}>
                  <StyledFilterToggle
                    displayName={"Active"}
                    filterName="activeOnly"
                    onChangeCallback={onFilterChange}
                    value={filterHelpers.getValue("activeOnly", filters)}
                  />
                </Col>
              </div>
              <div
                style={{ height: "80%" }}
                className={"m-0 p-0 w-100 overflow-auto"}
              >
                <Table bordered className={"w-100"}>
                  <thead>
                    <tr>
                      <th
                        width={"8%"}
                        className={"diagnostic-table-header header-align-left"}
                      >
                        Logo
                      </th>
                      <th
                        className={"diagnostic-table-header header-align-left"}
                      >
                        Name
                      </th>
                      <th
                        width={"20%"}
                        className={"diagnostic-table-header header-align-left"}
                      >
                        Client Group
                      </th>
                      <th
                        width={"5%"}
                        className={"diagnostic-table-header header-align-left"}
                      >
                        FHIR
                      </th>
                      <th
                        width={"8%"}
                        className={"diagnostic-table-header header-align-left"}
                      >
                        Big Query
                      </th>
                      <th
                        width={"2%"}
                        className={"diagnostic-table-header header-align-left"}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      _.times(maxPageSize, (idx) => (
                        <tr key={`user-skeleton-row-${idx}`}>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              width={250}
                              className={"w-100"}
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              width={250}
                              className={"w-100"}
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td width={30}>
                            <Skeleton
                              width={50}
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                        </tr>
                      ))
                    ) : paginatedList?.list &&
                      paginatedList?.list.length > 0 ? (
                      _.map(paginatedList.list, (client) => {
                        return (
                          <tr key={client.id}>
                            <StyledTD className={"py-1 align-middle"}>
                              <img
                                className={"client__img"}
                                width={55}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "/missing-logo.png";
                                }}
                                src={`/api/Client/Logo/${client.id}`}
                              />
                              {/*<div*/}
                              {/*  style={helpers.clientLogoStyle(client.id, 50)}*/}
                              {/*/>*/}
                            </StyledTD>
                            <StyledTD className={cx("py-1 align-middle")}>
                              {client.deactivatedAt ? (
                                <del>{client.name}</del>
                              ) : (
                                <span>{client.name}</span>
                              )}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              {client.clientGroupName ? (
                                client.clientGroupName
                              ) : (
                                <small className={"text-muted"}>None</small>
                              )}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              {client.fhirBaseUrl ? <GreenCheck /> : <RedX />}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              {client.bigQueryDataset ? (
                                <GreenCheck />
                              ) : (
                                <RedX />
                              )}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              <EditMenu
                                onEdit={() => setSelectedClient(client)}
                                itemId={client.id}
                                onToggleStatus={toggleClient}
                                showRevive={client?.deactivatedAt}
                              />
                              {/*<ButtonGroup className="float-right btn-group-sm">*/}
                              {/*  <StyledButton*/}
                              {/*    color="primary"*/}
                              {/*    onClick={() => {*/}
                              {/*      selectClient(client);*/}
                              {/*    }}*/}
                              {/*    showEditIcon*/}
                              {/*  >*/}
                              {/*    Edit*/}
                              {/*  </StyledButton>*/}
                              {/*  <StyledButton*/}
                              {/*    tag={Link}*/}
                              {/*    to={`/clients/${client.id}/campaigns`}*/}
                              {/*    color="warning"*/}
                              {/*    icon={MdOutlineDashboard}*/}
                              {/*  >*/}
                              {/*    Campaigns*/}
                              {/*  </StyledButton>*/}
                              {/*</ButtonGroup>*/}
                            </StyledTD>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className={"text-center"}>
                          No results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div sm="3" style={{ height: "10%" }} className={"m-0 p-0 w-100"}>
                <StyledPager
                  loading={loading}
                  showPager
                  callBack={setPageNumber}
                  totalPages={paginatedList?.totalPages ?? 0}
                  pageNumber={pageNumber}
                />
              </div>
            </Col>
          </div>
        </Row>
        <StyledModal
          show={selectedClient}
          onHide={() => setSelectedClient(null)}
          size="xl"
          showCancel
          title={selectedClient?.id > 0 ? "Edit Client" : "New Client"}
          onSave={(e) => saveClient(e)}
          disabled={saving}
          savePrompt={"Save Changes"}
        >
          <Form autoComplete="new-password">
            <Row className="mb-4">
              <Col xs="12">
                <StyledInput
                  type="text"
                  name="name"
                  id="name"
                  maxLength="30"
                  onChange={(event) =>
                    onChangeClient("name", event.target.value)
                  }
                  value={selectedClient ? selectedClient.name : ""}
                  className="form-control"
                  debounceTimeout={300}
                  label="Name"
                  required
                />
                <StyledInput
                  type="text"
                  name="fhirBaseUrl"
                  id="fhirBaseUrl"
                  maxLength="200"
                  onChange={(event) =>
                    onChangeClient("fhirBaseUrl", event.target.value)
                  }
                  value={selectedClient ? selectedClient.fhirBaseUrl : ""}
                  className="form-control"
                  // debounceTimeout={300}
                  label="FHIR Base Url"
                />
              </Col>
            </Row>
            <Row className={"mb-4"}>
              <Col xs="4">
                <StyledSelect
                  label={"Big Query Dataset"}
                  value={
                    bigQueryDatasets?.length && selectedClient?.bigQueryDataset
                      ? _.find(
                          bigQueryDatasets,
                          (dataset) =>
                            console.debug(dataset) ||
                            dataset.reference.datasetId ===
                              selectedClient.bigQueryDataset
                        )
                      : null
                  }
                  placeholder={"Select a Dataset"}
                  onChange={({ value }) =>
                    onChangeClient("bigQueryDataset", value)
                  }
                  options={bigQueryDatasets}
                />
              </Col>
              <Col xs="4">
                <StyledSelect
                  label={"Client Group"}
                  isClearable
                  value={
                    clientGroups?.length && selectedClient?.clientGroupId
                      ? _.find(
                          clientGroups,
                          (group) =>
                            group.value === selectedClient.clientGroupId
                        )
                      : null
                  }
                  placeholder={"Select a Client Group"}
                  onChange={(e) => {
                    onChangeClient("clientGroupId", e?.value);
                    // onChangeClient("clientGroupId", value)
                  }}
                  options={clientGroups}
                />
              </Col>
            </Row>
            <StyledFormLabel label={"Logo"} />
            <Card className="mt-2">
              <CardBody>
                <CardTitle className="text-center">
                  Drop the logo file or click on the icon below to choose it
                </CardTitle>
                <Upload onUpload={onUpload} maxFiles={1} />
              </CardBody>
            </Card>
          </Form>
        </StyledModal>
      </Col>
    </PageContainer>
  );
}

const EditMenu = (props) => {
  const { showRevive, itemId, onToggleStatus, onEdit } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <ButtonDropdown
      direction="left"
      isOpen={menuOpen}
      toggle={() => setMenuOpen(!menuOpen)}
    >
      <DropdownToggle id={"table-edit"}>
        <FontAwesomeIcon icon={"ellipsis-v"} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onEdit}>Edit</DropdownItem>
        {showRevive ? (
          <DropdownItem
            className={"text-success"}
            onClick={() => onToggleStatus(itemId)}
          >
            Revive
          </DropdownItem>
        ) : (
          <DropdownItem
            className={"text-danger"}
            onClick={() => onToggleStatus(itemId)}
          >
            Deactivate
          </DropdownItem>
        )}
        <DropdownItem divider />
        <DropdownItem tag={Link} to={`/clients/${itemId}/campaigns`}>
          Campaigns
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};