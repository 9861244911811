export default function UnderConstruction({message}) {
  return (
    <div style={{
      height: "70vh",
      width: "100vw",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column-reverse",
    }}>
      <h3>Under Construction</h3>
      <div>{message}</div>
    </div>
  );
}