import React, { useEffect, useState } from "react";
import { LongBreadCrumb, PageContainer, UnderConstruction } from "./";
import { Badge, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { helpers } from "../utils";
import _ from "lodash";

const dummyCampaigns = [
  { id: 1, name: "Dev 2021" },
  { id: 2, name: "Dev 2022" },
  { id: 3, name: "Dev 2023" },
];

const SkeletonCard = (props) => {
  return (
    <Col key={`skele${props.index}`} xs="12" lg="4" className={"m-0 p-0"}>
      <Card className={`cards-body mb-4 backgroundClients`}>
        <CardBody>
          <Row
            lg={"auto"}
            className={"m-0 p-0 h-100 w-100 d-flex justify-content-start"}
          >
            <Col xs="3" className={"m-0 px-1 py-0 d-flex align-items-center"}>
              <Skeleton
                height={"100"}
                width={"100"}
                containerClassName={"skeleton-def w-75 h-75"}
                circle
                count={1}
              />
            </Col>
            <Col className={"m-0 p-0  d-flex align-items-center"}>
              <Skeleton
                width={"100"}
                height={"100"}
                containerClassName={"skeleton-def w-75 h-25"}
                count={1}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

const CampaignCard = (props) => {
  return (
    <Col key={`dash-campaign-${props.item.id}${props.index}`} xs="12" lg="4">
      <Card
        // className={`cards-body mb-4 background${cardLabel}`}
        className={`cards-body mb-4 backgroundClient`}
        tag={Link}
        to={`#/`}
      >
        <CardBody className="cursorPointer">
          <Row>
            <Col>
              <div className="cardTitle">{props.item.name}</div>
              <div className="cardSubtitle">
                {/*Cases - {item.childrenCount}*/}
                {/*{childrenType && gchildrenType ? (*/}
                {/*  <>*/}
                {/*    {_.startCase(gchildrenType.label)}s (*/}
                {/*    {item.childrenCount})*/}
                {/*  </>*/}
                {/*) : null}*/}
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row>
            {/*<Col xs="5">*/}
            {/*  <span className="dateRangeDashboard">{`${getUIFriendlyDate(*/}
            {/*    item.startOn*/}
            {/*  )} to ${getUIFriendlyDate(item.endOn)}`}</span>*/}
            {/*</Col>*/}
            <Col xs="7">
              <Badge className="float-right campaignStatusBadge">
                {/*{props.item.campaignStatusName}*/}
              </Badge>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default function ProductManagement(props) {
  const [campaigns, setCampaigns] = useState(dummyCampaigns);

  //@$$tro - Product/Management should be list of campaigns (new API call and tile rendering of campaign level)
  return (
    <>
      <PageContainer>
        <Col className={"m-0 p-0"}>
          <LongBreadCrumb context={"Products"} page={"Management"} />
          <Row className={"m-0 p-0 py-4 w-100 h-100"}>
            {/*<UnderConstruction></UnderConstruction>*/}
            {campaigns &&
              campaigns.length &&
              _.map(campaigns, (c, idx) => (
                <CampaignCard key={c.id} item={c} index={idx} />
              ))}
          </Row>
        </Col>
      </PageContainer>
    </>
  );
}