import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Table, Form, ButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import _ from "lodash";
import {
  FilterSwitch,
  FilterText,
  LongBreadCrumb,
  PageContainer,
  Pager,
  StyledButton,
  StyledPager,
} from "./";
import { filterHelpers, api, constants } from "../utils";
import { Link } from "react-router-dom";
import { StyledTD, Loader } from "./";
import {
  ButtonDropdown,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import StyledFilterToggle from "./StyledFilterToggle";

const { ROUTES } = constants;

export default function UsersAdmin() {
  const [filters, setFilters] = useState([
    { filterName: "activeOnly", value: true },
  ]);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [paginatedUsers, setPaginatedUsers] = useState(null);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageSize, setMaxPageSize] = useState(15);

  useEffect(() => {
    refreshData();
  }, [filters, pageNumber]);

  function createPayload() {
    let payload = {
      sortField: sortField,
      sortDirection: sortDirection,
      pageNumber: pageNumber,
      maxResults: maxPageSize,
      //name: filters["name"].value
    };
    _.each(filters, (filter) => (payload[filter.filterName] = filter.value));
    return payload;
  }

  function refreshData() {
    if (loading) return;
    setLoading(true);
    api
      .securePost("userAdmin/list", createPayload())
      .then((response) => {
        if (!response || !response.data) return;
        setPaginatedUsers(response.data.message);
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function filterChange(changedFilter) {
    console.debug(changedFilter);
    const filterList = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterList);
  }

  function onToggleUser(userId) {
    api
      .securePost(`userAdmin/ToggleUser/${userId}`, {})
      .then((r) => {
        if (r.data.success) {
          refreshData();
        } else {
          setAlertMessage(r.data.message);
        }
      })
      .catch((error) => console.error(error));
  }

  // if (loading) {
  //   return (
  //     <Loader />
  //   );
  // }

  return (
    <PageContainer>
      <Col
        className={"m-0 p-0 h-100 w-100 flex-column justify-content-between"}
      >
        <LongBreadCrumb context={"Users"} page={"Management"} />
        {alertMessage ? <Alert color="danger">{alertMessage}</Alert> : null}
        <Row className={"m-0 p-0 w-100 position-relative"}>
          <div
            className={
              "position-relative overflow-hidden w-100 bodyHeight px-4 pt-2 pb-0 rounded bg-white d-flex flex-column space-between"
            }
          >
            <Col sm="12" className={"h-100 w-100"}>
              <div
                sm="3"
                style={{ height: "10%" }}
                className="w-100 mb-3 d-flex"
              >
                <Col className={"w-25"}>
                  <FilterText
                    placeHolder={"Name"}
                    filterName="name"
                    label="Name"
                    onChangeCallback={filterChange}
                    value={filterHelpers.getValue("name", filters)}
                  />
                </Col>
                <Col className={"w-25"}>
                  <FilterText
                    placeHolder={"Name"}
                    filterName="email"
                    label="Email"
                    onChangeCallback={filterChange}
                    value={filterHelpers.getValue("email", filters)}
                  />
                </Col>
                <Col className={"w-25"}>
                  <StyledFilterToggle
                    displayName={"Active"}
                    filterName="activeOnly"
                    onChangeCallback={filterChange}
                    value={filterHelpers.getValue("activeOnly", filters)}
                  />
                </Col>
              </div>
              <div
                style={{ height: "80%" }}
                className={"m-0 p-0 w-100 overflow-auto"}
              >
                <Table bordered className={"w-100"}>
                  <thead className={"sticky-top"}>
                    <tr>
                      {/* <th></th> */}
                      <th
                        className={
                          "diagnostic-table-header font-weight-bold header-align-left"
                        }
                      >
                        First Name
                      </th>
                      <th
                        className={
                          "diagnostic-table-header font-weight-bold header-align-left"
                        }
                      >
                        Last Name
                      </th>
                      <th
                        className={
                          "diagnostic-table-header font-weight-bold header-align-left"
                        }
                      >
                        Username
                      </th>
                      <th
                        className={
                          "diagnostic-table-header font-weight-bold header-align-left"
                        }
                      >
                        Phone
                      </th>
                      <th
                        className={
                          "diagnostic-table-header font-weight-bold header-align-left"
                        }
                      >
                        Status
                      </th>
                      <th
                        className={"diagnostic-table-header font-weight-bold"}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      _.times(maxPageSize, (idx) => (
                        <tr key={`user-skeleton-row-${idx}`}>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              width={250}
                              className={"w-100"}
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td>
                            <Skeleton
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                          <td width={30}>
                            <Skeleton
                              width={50}
                              containerClassName={"skeleton-def w-100"}
                            />
                          </td>
                        </tr>
                      ))
                    ) : paginatedUsers &&
                      paginatedUsers.list &&
                      paginatedUsers.list.length > 0 ? (
                      _.map(paginatedUsers.list, (user) => {
                        return (
                          <tr key={`user-row-${user.id}`}>
                            {/* TODO is this check supposed to do something? */}
                            <StyledTD className={"py-1 align-middle"}>
                              {user.firstName}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              {user.lastName}
                            </StyledTD>
                            <StyledTD className={"py-0 align-middle"}>
                              {user.username}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"} textRight>
                              {user.phone}
                            </StyledTD>
                            <StyledTD className={"py-1 align-middle"}>
                              {user.deactivatedAt ? (
                                <span className={"text-danger"}>
                                  Deactivated
                                </span>
                              ) : (
                                <span className={"text-success"}>Active</span>
                              )}
                            </StyledTD>
                            <StyledTD
                              width={30}
                              textCenter
                              className={"py-1 align-middle"}
                            >
                              <EditMenu
                                onToggleStatus={onToggleUser}
                                itemId={user.id}
                              />
                            </StyledTD>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className={"text-center"}>
                          No results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div sm="3" style={{ height: "10%" }} className={"m-0 p-0 w-100"}>
                <StyledPager
                  loading={loading}
                  showPager
                  callBack={setPageNumber}
                  totalPages={paginatedUsers?.totalPages ?? 0}
                  pageNumber={pageNumber}
                />
              </div>
            </Col>
          </div>
        </Row>
      </Col>
    </PageContainer>
  );
}

const EditMenu = (props) => {
  const { showRevive, itemId, onToggleStatus } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  function deactivatePractitionerResource() {
    api
      .secureFetch(`Participant/DeactivatePractitionerResource/${itemId}`)
      .then((res) => {
        if (!res) return;
      })
      .catch(api.catchHandler);
  }

  return (
    <ButtonDropdown
      direction="left"
      isOpen={menuOpen}
      toggle={() => setMenuOpen(!menuOpen)}
    >
      <DropdownToggle id={"table-edit"}>
        <FontAwesomeIcon icon={"ellipsis-v"} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem tag={Link} to={`${ROUTES.Admin.UserAdmin}/${itemId}`}>
          Edit
        </DropdownItem>
        {process.env.NODE_ENV === constants.DEV_ENV ? (
          <DropdownItem
            className={"text-success"}
            onClick={() => deactivatePractitionerResource()}
          >
            Deactivate Pract
          </DropdownItem>
        ) : null}
        {showRevive ? (
          <DropdownItem
            className={"text-success"}
            onClick={() => onToggleStatus(itemId)}
          >
            Revive
          </DropdownItem>
        ) : (
          <DropdownItem
            className={"text-danger"}
            onClick={() => onToggleStatus(itemId)}
          >
            Deactivate
          </DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};