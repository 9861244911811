import _ from "lodash";
import { FiDelete, FiTarget } from "react-icons/fi";
import {
  BsExclamationCircle,
  BsClipboardCheck,
  BsChatLeftText,
} from "react-icons/bs";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { MdOutlineAddCircleOutline, MdPersonalVideo } from "react-icons/md";
import { GiMedicines } from "react-icons/gi";
import { TbMedicineSyrup } from "react-icons/tb";
import { FaSyringe, FaSprayCan } from "react-icons/fa";
import { BiCheck, BiX } from "react-icons/bi";

const THEMES = {
  darkTheme: "darkTheme",
  lightTheme: "lightTheme",
  default: "lightTheme",
};

const windowKeys = {};

const localStorageKeys = {
  appVersion: "app-version",
  currentUser: "currentUser",
  lastActivity: "lastActivity",
  profilePhoto: "profile-photo",
  patientSavedSearch: "savedSearch",
};

const ONE_MINUTE = 60000;
const TWO_MINUTES = 120000;
const THIRTY_MINUTES = 1800000;
const TWO_HOURS = 7200000;
const TEN_SECONDS = 10000;
const FIVE_MINUTES = 300000;
const TEN_MINUTES = 600000;
const TWENTY_MINUTES = 1200000;

const BREAKPOINTS = {
  mobile: 320,
  mobileLandscape: 480,
  smallDesktop: 768,
  tablet: 856,
  tabletLandscape: 992,
  desktop: 1080,
  desktopLarge: 1500,
  desktopWide: 1920,
};

const PAGE_NAMES = {
  DASHBOARD: "Dashboard",
};

const SORT_DIRECTION = {
  ASC: "Ascending",
  DESC: "Descending",
};

const REFERENCE_DATA_URL_LIST = [
  {
    maxNameLength: 50,
    elementName: "SomeElement",
    pageTitle: "Some Elements",
    toggleURL: "Reference/ToggleSomeElementActive",
    saveURL: "Reference/SaveSomeElement",
    listURL: "Reference/SomeList",
    reactPath: "/some-elements",
  },
];

const ACCESS_VIEWS = {
  BASIC: {
    value: 1,
    label: "Common Links",
  },
  CAMPAIGNS: {
    value: 2,
    label: "Campaign Management",
  },
  PATIENT_CHART: {
    value: 3,
    label: "Patient Chart",
  },
  PATIENT_CHART_READONLY: {
    value: 4,
    label: "Patient Chart Readonly",
  },
};

const ACCESS_VIEW_OPTIONS = _.toArray(ACCESS_VIEWS);

const ROLE_NAMES = {
  SYSADMIN: "System Administrator",
};

const ROLE_IDS = {
  SYSADMIN: 1,
};

const ROLE_DICTIONARY = {
  1: ROLE_NAMES.SYSADMIN,
};

const ALL_ROLES = [
  {
    value: ROLE_IDS.SYSADMIN,
    label: ROLE_NAMES.SYSADMIN,
    groupName: "Administration",
  },
];

const UPDATE_FREQUENCY_NAMES = {
  ONGOING: "Ongoing",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  QUARTERLY: "Quarterly",
};

const UPDATE_FREQUENCY_IDS = {
  ONGOING: 1,
  DAILY: 2,
  WEEKLY: 3,
  MONTHLY: 4,
  QUARTERLY: 5,
};

const UPDATE_FREQUENCY_LIST = [
  {
    value: UPDATE_FREQUENCY_IDS.ONGOING,
    label: UPDATE_FREQUENCY_NAMES.ONGOING,
  },
  {
    value: UPDATE_FREQUENCY_IDS.DAILY,
    label: UPDATE_FREQUENCY_NAMES.DAILY,
  },
  {
    value: UPDATE_FREQUENCY_IDS.WEEKLY,
    label: UPDATE_FREQUENCY_NAMES.WEEKLY,
  },
  {
    value: UPDATE_FREQUENCY_IDS.MONTHLY,
    label: UPDATE_FREQUENCY_NAMES.MONTHLY,
  },
  {
    value: UPDATE_FREQUENCY_IDS.QUARTERLY,
    label: UPDATE_FREQUENCY_NAMES.QUARTERLY,
  },
];

const EMPTY_USER = {
  id: -1,
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  username: "",
  roles: [],
  editing: true,
  password: "",
};

const DASHBOARD_TYPES = {
  DASHBOARD: { value: 0, label: "dashboard" },
  CLIENT: { value: 1, label: "client" },
  CAMPAIGN: { value: 2, label: "campaign" },
  CASE: { value: 3, label: "case" },
  CASE_ITEMS: { value: 4, label: "case_item" },
};

const PATIENT_CHART_DASHBOARD_TYPES = {
  DASHBOARD: { value: 0, label: "dashboard" },
  CLIENT: { value: 1, label: "client" },
  PARTICIPANT: { value: 2, label: "participant" },
};

const PATIENT_CHART_NOTIFICATION_TYPES = {
  FLAG: "Flag",
  ACTIVITY: "Activity",
  GOAL: "Goal",
  TASK: "Task",
};

const PATIENT_CHART_NOTIFICATION_OPTIONS = [
  {
    value: 0,
    label: PATIENT_CHART_NOTIFICATION_TYPES.FLAG,
    selected: true,
    icon: <BsExclamationCircle />,
  },
  {
    value: 2,
    label: PATIENT_CHART_NOTIFICATION_TYPES.GOAL,
    selected: true,
    icon: <FiTarget />,
  },
  {
    value: 3,
    label: PATIENT_CHART_NOTIFICATION_TYPES.TASK,
    selected: true,
    icon: <BsClipboardCheck />,
  },
];

const FHIR_CHOICE_VARIABLE_TYPES = {
  DATE_TIME: "DateTime",
  AGE: "Age",
  PERIOD: "Period",
  RANGE: "Range",
  STRING: "String",
  QUANTITY: "Quantity",
  CODEABLE_CONCEPT: "Codeable Concept",
  BOOLEAN: "Boolean",
  INTEGER: "Integer",
  RATIO: "Ratio",
  SAMPLED_DATA: "Sampled Data",
  TIME: "Time",
};

const FHIR_CHOICE_VARIABLE_TYPE_OPTIONS = {
  DATE_TIME: {
    value: 1,
    label: FHIR_CHOICE_VARIABLE_TYPES.DATE_TIME,
    key: "dateTime",
  },
  AGE: { value: 2, label: FHIR_CHOICE_VARIABLE_TYPES.AGE, key: "age" },
  PERIOD: { value: 3, label: FHIR_CHOICE_VARIABLE_TYPES.PERIOD, key: "period" },
  RANGE: { value: 4, label: FHIR_CHOICE_VARIABLE_TYPES.RANGE, key: "range" },
  STRING: { value: 5, label: FHIR_CHOICE_VARIABLE_TYPES.STRING, key: "string" },
  QUANTITY: {
    value: 6,
    label: FHIR_CHOICE_VARIABLE_TYPES.QUANTITY,
    key: "quantity",
  },
  CODEABLE_CONCEPT: {
    value: 7,
    label: FHIR_CHOICE_VARIABLE_TYPES.CODEABLE_CONCEPT,
  },
  BOOLEAN: {
    value: 8,
    label: FHIR_CHOICE_VARIABLE_TYPES.BOOLEAN,
    key: "boolean",
  },
  INTEGER: {
    value: 9,
    label: FHIR_CHOICE_VARIABLE_TYPES.INTEGER,
    key: "integer",
  },
  RATIO: { value: 10, label: FHIR_CHOICE_VARIABLE_TYPES.RATIO, key: "ratio" },
  SAMPLED_DATA: { value: 11, label: FHIR_CHOICE_VARIABLE_TYPES.SAMPLED_DATA },
  TIME: { value: 12, label: FHIR_CHOICE_VARIABLE_TYPES.TIME },
};

const FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST = [
  { value: 1, label: FHIR_CHOICE_VARIABLE_TYPES.DATE_TIME },
  { value: 2, label: FHIR_CHOICE_VARIABLE_TYPES.AGE },
  { value: 3, label: FHIR_CHOICE_VARIABLE_TYPES.PERIOD },
  { value: 4, label: FHIR_CHOICE_VARIABLE_TYPES.RANGE },
  { value: 5, label: FHIR_CHOICE_VARIABLE_TYPES.STRING },
  { value: 6, label: FHIR_CHOICE_VARIABLE_TYPES.QUANTITY },
  { value: 7, label: FHIR_CHOICE_VARIABLE_TYPES.CODEABLE_CONCEPT },
  { value: 8, label: FHIR_CHOICE_VARIABLE_TYPES.BOOLEAN },
  { value: 9, label: FHIR_CHOICE_VARIABLE_TYPES.INTEGER },
  { value: 10, label: FHIR_CHOICE_VARIABLE_TYPES.RATIO },
  { value: 11, label: FHIR_CHOICE_VARIABLE_TYPES.SAMPLED_DATA },
  { value: 12, label: FHIR_CHOICE_VARIABLE_TYPES.TIME },
];

// const ICD10_API_URL = "https://clinicaltables.nlm.nih.gov/fhir/R4/ValueSet/icd10cm/$expand?_format=json";
const ICD10_API_URL = "/Participant/GetDiagnosisIcd10Codes";

const FHIR_GENDER_TYPES = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: "Other" },
  { value: 4, label: "Unknown" },
];

const CLAIM_GENDER_TYPES = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Other" },
  { value: 3, label: "Unknown" },
];

/* Codes within fhir - enum within nuget package */
const FHIR_OBSERVATION_STATUSES = [
  { value: 0, label: "Registered" },
  { value: 1, label: "Preliminary" },
  { value: 2, label: "Final" },
  { value: 3, label: "Amended" },
  { value: 4, label: "Corrected" },
  { value: 5, label: "Cancelled" },
  { value: 6, label: "Entered in Error" },
  { value: 7, label: "Unknown" },
];

// From fhir, may need to change if well makes modifiable
const FHIR_OBSERVATION_CATEGORIES = {
  SOCIAL_HISTORY: "Social History",
  VITAL_SIGNS: "Vital Signs",
  IMAGING: "Imaging",
  LABORATORY: "Laboratory",
  PROCEDURE: "Procedure",
  SURVEY: "Survey",
  EXAM: "Exam",
  THERAPY: "Therapy",
  ACTIVITY: "Activity",
};

const OBSERVATION_VITAL_SIGNS_TEMP_OPTIONS = [
  { value: "Fahrenheit", label: "Fahrenheit" },
  { value: "Celsius", label: "Celsius" },
];
const OBSERVATION_VITAL_SIGNS_BLOOD_OPTIONS = [
  { value: "mmHg", label: "mmHg" },
];
const OBSERVATION_VITAL_SIGNS_HEIGHT_OPTIONS = [
  { value: "Inches", label: "Inches" },
  { value: "Centimeters", label: "Centimeters" },
];
const OBSERVATION_VITAL_SIGNS_WEIGHT_OPTIONS = [
  { value: "lbs", label: "lbs" },
  { value: "kg", label: "kg" },
];

const MARITAL_STATUSES = [
  { value: 1, label: "Single" },
  { value: 2, label: "Married" },
  { value: 3, label: "Widowed" },
  { value: 4, label: "Divorced" },
  { value: 5, label: "Separated" },
  { value: 6, label: "Registered Partnership" },
];

const VALUE_SET_TYPE_IDS = {
  BODY_SITES: 1,
  CARE_PLAN_CATEGORIES: 2,
  CLAIM_TYPE: 3,
  COMMUNICATION_CATEGORIES: 4,
  COMMUNICATION_MEDIUM: 5,
  COMMUNICATION_STATUS_REASON: 6,
  COMMUNICATION_TOPIC: 7,
  CONDITION_CATEGORIES: 8,
  CONDITION_CLINICALS: 9,
  CONDITION_SEVERITIES: 10,
  CONDITION_STAGES: 11,
  CONDITION_VERIFICATION_STATUSES: 12,
  DIAGNOSIS_ICD10_CODES: 13,
  FLAG_CATEGORIES: 14,
  GOAL_ACHIEVEMENTS: 15,
  GOAL_CATEGORIES: 16,
  GOAL_PRIORITIES: 17,
  MEDICATION_USAGE_CATEGORIES: 18,
  OBSERVATION_CATEGORIES: 19,
  OBSERVATION_CODES: 20,
  OBSERVATION_INTERPRETATIONS: 21,
  RELATIONSHIP_TYPE: 22,
  SERVICE_REQUEST_CATEGORY: 23,
  TASK_TYPE: 24,
};

const VALUE_SET_TYPES = [
  { id: VALUE_SET_TYPE_IDS.BODY_SITES, label: "Body Sites" },
  { id: VALUE_SET_TYPE_IDS.CARE_PLAN_CATEGORIES, label: "Care Plan Category" },
  { id: VALUE_SET_TYPE_IDS.CLAIM_TYPE, label: "Claim Type" },
  {
    id: VALUE_SET_TYPE_IDS.COMMUNICATION_CATEGORIES,
    label: "Communication Category",
  },
  {
    id: VALUE_SET_TYPE_IDS.COMMUNICATION_MEDIUM,
    label: "Communication Medium",
  },
  {
    id: VALUE_SET_TYPE_IDS.COMMUNICATION_STATUS_REASON,
    label: "Communication Status Reason",
  },
  { id: VALUE_SET_TYPE_IDS.COMMUNICATION_TOPIC, label: "Communication Topic" },
  { id: VALUE_SET_TYPE_IDS.CONDITION_CATEGORIES, label: "Condition Category" },
  { id: VALUE_SET_TYPE_IDS.CONDITION_CLINICALS, label: "Condition Clinical" },
  {
    id: VALUE_SET_TYPE_IDS.CONDITION_SEVERITIES,
    label: "Condition Severities",
  },
  { id: VALUE_SET_TYPE_IDS.CONDITION_STAGES, label: "Condition Stages" },
  {
    id: VALUE_SET_TYPE_IDS.CONDITION_VERIFICATION_STATUSES,
    label: "Condition Verification Status",
  },
  {
    id: VALUE_SET_TYPE_IDS.DIAGNOSIS_ICD10_CODES,
    label: "Diagnosis ICD10 Codes",
  },
  { id: VALUE_SET_TYPE_IDS.FLAG_CATEGORIES, label: "Flag Categories" },
  { id: VALUE_SET_TYPE_IDS.GOAL_ACHIEVEMENTS, label: "Goal Achievements" },
  { id: VALUE_SET_TYPE_IDS.GOAL_CATEGORIES, label: "Goal Categories" },
  { id: VALUE_SET_TYPE_IDS.GOAL_PRIORITIES, label: "Goal Priorities" },
  {
    id: VALUE_SET_TYPE_IDS.MEDICATION_USAGE_CATEGORIES,
    label: "Medication Usage Category",
  },
  {
    id: VALUE_SET_TYPE_IDS.OBSERVATION_CATEGORIES,
    label: "Observation Category",
  },
  { id: VALUE_SET_TYPE_IDS.OBSERVATION_CODES, label: "Observation Code" },
  {
    id: VALUE_SET_TYPE_IDS.OBSERVATION_INTERPRETATIONS,
    label: "Observation Interpretation",
  },
  { id: VALUE_SET_TYPE_IDS.RELATIONSHIP_TYPE, label: "Relationship Type" },
  {
    id: VALUE_SET_TYPE_IDS.SERVICE_REQUEST_CATEGORY,
    label: "Service Request Category",
  },
  { id: VALUE_SET_TYPE_IDS.TASK_TYPE, label: "Task Type" },
];

const MEDICAL_CLAIM_TYPES = ["professional", "institutional"];

const PHARMACY_CLAIM_TYPES = ["pharmacy"]; //waiting on type names

const DENTAL_COVERAGE_TYPES = ["dental", "dntl"];

const VISION_COVERAGE_TYPES = ["vispol"];

const COVERAGE_REJECT_LIST = ["EmploymentCoverage", "Name not provided", ""];

const FHIR_FLAG_STATUSES = [
  { value: 0, label: "Active" },
  { value: 1, label: "Inactive" },
  { value: 2, label: "Entered in Error" },
];

const FHIR_GOAL_LIFECYCLE_STATUSES = [
  { value: 0, label: "Proposed" },
  { value: 1, label: "Planned" },
  { value: 2, label: "Accepted" },
  { value: 3, label: "Active" },
  { value: 4, label: "On Hold" },
  { value: 5, label: "Completed" },
  { value: 6, label: "Cancelled" },
  { value: 7, label: "Entered in Error" },
  { value: 8, label: "Rejected" },
];

const FHIR_TASK_STATUSES = [
  { value: 0, label: "Draft" },
  { value: 1, label: "Requested" },
  { value: 2, label: "Received" },
  { value: 3, label: "Accepted" },
  { value: 4, label: "Rejected" },
  { value: 5, label: "Ready" },
  { value: 6, label: "Cancelled" },
  { value: 7, label: "In Progress" },
  { value: 8, label: "On Hold" },
  { value: 9, label: "Failed" },
  { value: 10, label: "Completed" },
  { value: 11, label: "Entered in Error" },
];

const FHIR_TASK_INTENTS = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Proposal" },
  { value: 2, label: "Plan" },
  { value: 3, label: "Order" },
  { value: 4, label: "Original Order" },
  { value: 5, label: "Reflex Order" },
  { value: 6, label: "Filler Order" },
  { value: 7, label: "Instance Order" },
  { value: 8, label: "Option" },
];

const FHIR_CARE_PLAN_INTENTS = [
  { value: 0, label: "Proposal" },
  { value: 1, label: "Plan" },
  { value: 2, label: "Order" },
  { value: 3, label: "Option" },
  // { value: 4, label: "Directive" },
];

const FHIR_REQUEST_PRIORITIES = [
  { value: 0, label: "Routine" },
  { value: 1, label: "Urgent" },
  { value: 2, label: "Asap" },
  { value: 3, label: "Stat" },
];

const FHIR_EVENT_STATUS = [
  { value: 0, label: "Preparation" },
  { value: 1, label: "In Progress" },
  { value: 2, label: "Not Done" },
  { value: 3, label: "On Hold" },
  { value: 4, label: "Stopped" },
  { value: 5, label: "Completed" },
  { value: 6, label: "Entered in Error" },
  { value: 7, label: "Unknown" },
];

const FHIR_REQUEST_STATUS = [
  { value: 0, label: "Draft" },
  { value: 1, label: "Active" },
  { value: 2, label: "On Hold" },
  { value: 3, label: "Revoked" },
  { value: 4, label: "Completed" },
  { value: 5, label: "Entered in Error" },
  { value: 6, label: "Unknown" },
];

const FHIR_CLAIM_STATUS = [
  { value: 0, label: "Active" },
  { value: 1, label: "Cancelled" },
  { value: 2, label: "Draft" },
  { value: 3, label: "Entered in Error" },
];

const FHIR_CLAIM_OUTCOME = [
  { value: 0, label: "Queued" },
  { value: 1, label: "Complete" },
  { value: 2, label: "Error" },
  { value: 3, label: "Partial" },
];

const FHIR_REQUEST_INTENTS = [
  { value: 0, label: "Proposal" },
  { value: 1, label: "Plan" },
  { value: 2, label: "Directive" },
  { value: 3, label: "Order" },
  { value: 4, label: "Original Order" },
  { value: 5, label: "Reflex Order" },
  { value: 6, label: "Filler Order" },
  { value: 7, label: "Instance Order" },
  { value: 8, label: "Option" },
];

const FHIR_PUBLICATION_STATUSES = [
  { value: 0, label: "Draft" },
  { value: 1, label: "Active" },
  { value: 2, label: "Retired" },
  { value: 3, label: "Unknown" },
];

const FHIR_MEDICATION_STATEMENT_STATUS_CODES = [
  { value: 0, label: "Active" },
  { value: 1, label: "Completed" },
  { value: 2, label: "Entered in Error" },
  { value: 3, label: "Intended" },
  { value: 4, label: "Stopped" },
  { value: 5, label: "On Hold" },
  { value: 6, label: "Unknown" },
  { value: 7, label: "Not Taken" },
];

const FHIR_UNITS_OF_TIME = [
  { value: 0, label: "Seconds" },
  { value: 1, label: "Minutes" },
  { value: 2, label: "Hours" },
  { value: 3, label: "Days" },
  { value: 4, label: "Weeks" },
  { value: 5, label: "Months" },
  { value: 6, label: "Years" },
];

const FHIR_EVENT_TIMING = [
  { value: 0, label: "Morning" },
  { value: 1, label: "Early Morning" },
  { value: 2, label: "Late Morning" },
  { value: 3, label: "Noon" },
  { value: 4, label: "Afternoon" },
  { value: 5, label: "Early Afternoon" },
  { value: 6, label: "Late Afternoon" },
  { value: 7, label: "Evening" },
  { value: 8, label: "Early Evening" },
  { value: 9, label: "Late Evening" },
  { value: 10, label: "Night" },
  { value: 11, label: "After Sleep" },
  { value: 12, label: "(HS) Prior to extended sleep" },
  { value: 13, label: "Upon waking up" },
  { value: 14, label: "(C) Meal" },
  { value: 15, label: "(CM) Breakfast" },
  { value: 16, label: "(CD) Lunch" },
  { value: 17, label: "(CV) Dinner" },
  { value: 18, label: "(AC) Before meal" },
  { value: 19, label: "(ACM) Before breakfast" },
  { value: 20, label: "(ACD) Before lunch" },
  { value: 21, label: "(ACV) Before dinner" },
  { value: 22, label: "(PC) After meal" },
  { value: 23, label: "(PCM) After breakfast" },
  { value: 24, label: "(PCD) After lunch" },
  { value: 25, label: "(PCV) After dinner" },
];
/* End Codes within fhir - enum within nuget package */

/* Mediums is set list - non-maintainble - in order to allow for icons */
const FHIR_CONTACT_LOG_MEDIUMS = [
  { value: 0, label: "Phone", icon: <AiOutlinePhone size={20} /> },
  { value: 1, label: "Email", icon: <AiOutlineMail size={20} /> },
  // { value: 2, label: "Mail", icon: <BsMailbox size={20}/>},
  { value: 3, label: "Text", icon: <BsChatLeftText size={20} /> },
  // { value: 4, label: "Video Conferencing", icon: <MdPersonalVideo size={20}/>},
  // { value: 5, label: "Face to Face", icon: <IoChatbubbleOutline size={20}/> },
];

const MEDICATION_TYPES = [
  {
    value: 0,
    icon: <GiMedicines color={"#41ADA3"} size={20} />,
    label: "Tablet/Capsule",
  },
  {
    value: 1,
    icon: <TbMedicineSyrup color={"#41ADA3"} size={20} />,
    label: "Syrup",
  },
  {
    value: 2,
    icon: <FaSyringe color={"#41ADA3"} size={20} />,
    label: "Injection",
  },
  {
    value: 3,
    icon: <FaSprayCan color={"#41ADA3"} size={20} />,
    label: "Inhaler",
  },
];

const patientChartSavedSearch = {
  ClientId: null,
  Identifiers: null,
  Name: null,
  Gender: null,
  State: null,
  City: null,
};

const FHIR_RESOURCE_TYPES = [
  { value: 0, label: "Account" },
  { value: 1, label: "Activity Definition" },
  { value: 2, label: "Adverse Event" },
  { value: 3, label: "Allergy Intolerance" },
  { value: 4, label: "Appointment" },
  { value: 5, label: "Appointment Response" },
  { value: 6, label: "Audit Event" },
  { value: 7, label: "Basic" },
  { value: 8, label: "Binary" },
  { value: 9, label: "Biologically Derived Product" },
  { value: 10, label: "Body Structure" },
  { value: 11, label: "Bundle" },
  { value: 12, label: "Capability Statement" },
  { value: 13, label: "Care Plan" },
  { value: 14, label: "Care Team" },
  { value: 15, label: "Catalog Entry" },
  { value: 16, label: "Charge Item" },
  { value: 17, label: "Charge Item Definition" },
  { value: 18, label: "Claim" },
  { value: 19, label: "Claim Response" },
  { value: 20, label: "Clinical Impression" },
  { value: 21, label: "Code System" },
  { value: 22, label: "Communication" },
  { value: 23, label: "Communication Request" },
  { value: 24, label: "Compartment Definition" },
  { value: 25, label: "Composition" },
  { value: 26, label: "Concept Map" },
  { value: 27, label: "Condition" },
  { value: 28, label: "Consent" },
  { value: 29, label: "Contract" },
  { value: 30, label: "Coverage" },
  { value: 31, label: "Coverage Eligibility Request" },
  { value: 32, label: "Coverage Eligibility Response" },
  { value: 33, label: "Detected Issue" },
  { value: 34, label: "Device" },
  { value: 35, label: "Device Definition" },
  { value: 36, label: "Device Metric" },
  { value: 37, label: "Device Request" },
  { value: 38, label: "Device Use Statement" },
  { value: 39, label: "Diagnostic Report" },
  { value: 40, label: "Document Manifest" },
  { value: 41, label: "Document Reference" },
  { value: 42, label: "Domain Resource" },
  { value: 43, label: "Effect Evidence Synthesis" },
  { value: 44, label: "Encounter" },
  { value: 45, label: "Endpoint" },
  { value: 46, label: "Enrollment Request" },
  { value: 47, label: "Enrollment Response" },
  { value: 48, label: "Episode Of Care" },
  { value: 49, label: "Event Definition" },
  { value: 50, label: "Evidence" },
  { value: 51, label: "Evidence Variable" },
  { value: 52, label: "Example Scenario" },
  { value: 53, label: "Explanation Of Benefit" },
  { value: 54, label: "Family Member History" },
  { value: 55, label: "Flag" },
  { value: 56, label: "Goal" },
  { value: 57, label: "Graph Definition" },
  { value: 58, label: "Group" },
  { value: 59, label: "Guidance Response" },
  { value: 60, label: "Healthcare Service" },
  { value: 61, label: "Imaging Study" },
  { value: 62, label: "Immunization" },
  { value: 63, label: "Immunization Evaluation" },
  { value: 64, label: "Immunization Recommendation" },
  { value: 65, label: "Implementation Guide" },
  { value: 66, label: "Insurance Plan" },
  { value: 67, label: "Invoice" },
  { value: 68, label: "Library" },
  { value: 69, label: "Linkage" },
  { value: 70, label: "List" },
  { value: 71, label: "Location" },
  { value: 72, label: "Measure" },
  { value: 73, label: "Measure Report" },
  { value: 74, label: "Media" },
  { value: 75, label: "Medication" },
  { value: 76, label: "Medication Administration" },
  { value: 77, label: "Medication Dispense" },
  { value: 78, label: "Medication Knowledge" },
  { value: 79, label: "Medication Request" },
  { value: 80, label: "Medication Statement" },
  { value: 81, label: "Medicinal Product" },
  { value: 82, label: "Medicinal Product Authorization" },
  { value: 83, label: "Medicinal Product Contraindication" },
  { value: 84, label: "Medicinal Product Indication" },
  { value: 85, label: "Medicinal Product Ingredient" },
  { value: 86, label: "Medicinal Product Interaction" },
  { value: 87, label: "Medicinal Product Manufactured" },
  { value: 88, label: "Medicinal Product Packaged" },
  { value: 89, label: "Medicinal Product Pharmaceutical" },
  { value: 90, label: "Medicinal Product Undesirable Effect" },
  { value: 91, label: "Message Definition" },
  { value: 92, label: "Message Header" },
  { value: 93, label: "Molecular Sequence" },
  { value: 94, label: "Naming System" },
  { value: 95, label: "Nutrition Order" },
  { value: 96, label: "Observation" },
  { value: 97, label: "Observation Definition" },
  { value: 98, label: "Operation Definition" },
  { value: 99, label: "Operation Outcome" },
  { value: 100, label: "Organization" },
  { value: 101, label: "Organization Affiliation" },
  { value: 102, label: "Parameters" },
  { value: 103, label: "Patient" },
  { value: 104, label: "Payment Notice" },
  { value: 105, label: "Payment Reconciliation" },
  { value: 106, label: "Person" },
  { value: 107, label: "Plan Definition" },
  { value: 108, label: "Practitioner" },
  { value: 109, label: "Practitioner Role" },
  { value: 110, label: "Procedure" },
  { value: 111, label: "Provenance" },
  { value: 112, label: "Questionnaire" },
  { value: 113, label: "Questionnaire Response" },
  { value: 114, label: "Related Person" },
  { value: 115, label: "Request Group" },
  { value: 116, label: "Research Definition" },
  { value: 117, label: "Research Element Definition" },
  { value: 118, label: "Research Study" },
  { value: 119, label: "Research Subject" },
  { value: 120, label: "Resource" },
  { value: 121, label: "Risk Assessment" },
  { value: 122, label: "Risk Evidence Synthesis" },
  { value: 123, label: "Schedule" },
  { value: 124, label: "Search Parameter" },
  { value: 125, label: "Service Request" },
  { value: 126, label: "Slot" },
  { value: 127, label: "Specimen" },
  { value: 128, label: "Specimen Definition" },
  { value: 129, label: "Structure Definition" },
  { value: 130, label: "Structure Map" },
  { value: 131, label: "Subscription" },
  { value: 132, label: "Substance" },
  { value: 133, label: "Substance Nucleic Acid" },
  { value: 134, label: "Substance Polymer" },
  { value: 135, label: "Substance Protein" },
  { value: 136, label: "Substance Reference Information" },
  { value: 137, label: "Substance Source Material" },
  { value: 138, label: "Substance Specification" },
  { value: 139, label: "Supply Delivery" },
  { value: 140, label: "Supply Request" },
  { value: 141, label: "Task" },
  { value: 142, label: "Terminology Capabilities" },
  { value: 143, label: "Test Report" },
  { value: 144, label: "Test Script" },
  { value: 145, label: "Value Set" },
  { value: 146, label: "Verification Result" },
  { value: 147, label: "Vision Prescription" },
];

const FHIR_RESOURCES = {
  SEARCH_PARAMETER: "SearchParameter",
};

const SEARCH_PARAMETER_TYPES = [
  { value: 0, label: "Number" },
  { value: 1, label: "Date" },
  { value: 2, label: "String" },
  { value: 3, label: "Token" },
  { value: 4, label: "Reference" },
  { value: 5, label: "Composite" },
  { value: 6, label: "Quantity" },
  { value: 7, label: "Uri" },
  { value: 8, label: "Special" },
];

const INTERNAL_VALUE_SET_URLS = {
  CONDITION_CLINICALS: "Reference/ConditionClinicalValueSet",
  BODY_SITES: "Reference/BodySiteValueSet",
  CONDITION_CATEGORIES: "Reference/ConditionCategoryValueSet",
  CONDITION_STAGES: "Reference/ConditionStageValueSet",
  CONDITION_VERIFICATION_STATUSES:
    "Reference/ConditionVerificationStatusValueSet",
  CONDITION_SEVERITIES: "Reference/ConditionSeverityValueSet",
  OBSERVATION_CATEGORIES: "Reference/ObservationCategoryValueSet",
  OBSERVATION_INTERPRETATIONS: "Reference/ObservationInterpretationValueSet",
  OBSERVATION_CODES: "Reference/ObservationCodeValueSet",
  FLAG_CATEGORIES: "Reference/FlagCategoryValueSet",
  GOAL_CATEGORIES: "Reference/GoalCategoryValueSet",
  GOAL_PRIORITIES: "Reference/GoalPriorityValueSet",
  GOAL_ACHIEVEMENTS: "Reference/GoalAchievementValueSet",
  COMMUNICATION_CATEGORIES: "Reference/CommunicationCategoryValueSet",
  CARE_PLAN_CATEGORIES: "Reference/CarePlanCategoryValueSet",
  MEDICATION_USAGE_CATEGORIES: "Reference/MedicationUsageCategoryValueSet",
  DIAGNOSIS_ICD10_CODES: "Reference/DiagnosisIcd10CodeValueSet",
  TASK_TYPE: "Reference/TaskTypeValueSet",
  COMMUNICATION_STATUS_REASON: "Reference/CommunicationStatusReasonValueSet",
  COMMUNICATION_TOPIC: "Reference/CommunicationTopicValueSet",
  COMMUNICATION_MEDIUM: "Reference/CommunicationMediumValueSet",
  SERVICE_REQUEST_CATEGORY: "Reference/ServiceRequestCategoryValueSet",
  CLAIM_TYPE: "Reference/ClaimTypeValueSet",
  RELATIONSHIP_TYPE: "Reference/RelationshipTypeValueSet",
};

const REFERENCE_VALUE_SET_URL_LIST = [
  {
    name: "Condition Clinicals",
    isMaintainable: false,
    saveUrl: "Reference/SaveConditionClinical",
    listUrl: "Reference/ConditionClinicalList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CONDITION_CLINICALS,
    deleteUrl: "Reference/ToggleConditionClinical",
    deleteAllUrl: "Reference/SoftDeleteAllConditionClinical",
    populateUrl: "Reference/PopulateConditionClinicals",
    reactPath: "condition-clinical",
  },
  {
    name: "Body Sites",
    isMaintainable: true,
    saveUrl: "Reference/SaveBodySite",
    listUrl: "Reference/BodySiteList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.BODY_SITES,
    deleteUrl: "Reference/ToggleBodySite",
    deleteAllUrl: "Reference/SoftDeleteAllBodySite",
    populateUrl: "Reference/PopulateBodySites",
    reactPath: "body-sites",
  },
  {
    name: "Condition Categories",
    isMaintainable: false,
    saveUrl: "Reference/SaveConditionCategory",
    listUrl: "Reference/ConditionCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CONDITION_CATEGORIES,
    deleteUrl: "Reference/ToggleConditionCategory",
    // deleteAllUrl: "Reference/SoftDeleteAllConditionCategory",
    // populateUrl: "Reference/PopulateConditionCategories",
    reactPath: "condition-categories",
  },
  {
    name: "Condition Stages",
    isMaintainable: true,
    saveUrl: "Reference/SaveConditionStage",
    listUrl: "Reference/ConditionStageList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CONDITION_STAGES,
    deleteUrl: "Reference/ToggleConditionStage",
    deleteAllUrl: "Reference/SoftDeleteAllConditionStage",
    populateUrl: "Reference/PopulateConditionStages",
    reactPath: "condition-stages",
  },
  {
    name: "Condition Verification Statuses",
    isMaintainable: false,
    saveUrl: "Reference/SaveConditionVerificationStatus",
    listUrl: "Reference/ConditionVerificationStatusList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CONDITION_VERIFICATION_STATUSES,
    deleteUrl: "Reference/ToggleConditionVerificationStatus",
    deleteAllUrl: "Reference/SoftDeleteAllConditionVerificationStatus",
    populateUrl: "Reference/PopulateConditionVerificationStatuses",
    reactPath: "condition-verification-statuses",
  },
  {
    name: "Condition Severities",
    isMaintainable: true,
    saveUrl: "Reference/SaveConditionSeverity",
    listUrl: "Reference/ConditionSeverityList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CONDITION_SEVERITIES,
    deleteUrl: "Reference/ToggleConditionSeverity",
    deleteAllUrl: "Reference/SoftDeleteAllConditionSeverity",
    populateUrl: "Reference/PopulateConditionSeverities",
    reactPath: "condition-severities",
  },
  {
    name: "Observation Categories",
    isMaintainable: true,
    saveUrl: "Reference/SaveObservationCategory",
    listUrl: "Reference/ObservationCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.OBSERVATION_CATEGORIES,
    deleteUrl: "Reference/ToggleObservationCategory",
    deleteAllUrl: "Reference/SoftDeleteAllObservationCategory",
    populateUrl: "Reference/PopulateObservationCategories",
    reactPath: "condition-observation-categories",
  },
  {
    name: "Observation Interpretations",
    isMaintainable: true,
    saveUrl: "Reference/SaveObservationInterpretation",
    listUrl: "Reference/ObservationInterpretationList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.OBSERVATION_INTERPRETATIONS,
    deleteUrl: "Reference/ToggleObservationInterpretation",
    deleteAllUrl: "Reference/SoftDeleteAllObservationInterpretation",
    populateUrl: "Reference/PopulateObservationInterpretations",
    reactPath: "condition-observation-interpretations",
  },
  {
    name: "Observation Codes",
    isMaintainable: true,
    saveUrl: "Reference/SaveObservationCode",
    listUrl: "Reference/ObservationCodeList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.OBSERVATION_CODES,
    deleteUrl: "Reference/ToggleObservationCode",
    deleteAllUrl: "Reference/SoftDeleteAllObservationCode",
    populateUrl: "Reference/PopulateObservationCodes",
    reactPath: "observation-codes",
  },
  {
    name: "Flag Categories",
    isMaintainable: true,
    saveUrl: "Reference/SaveFlagCategory",
    listUrl: "Reference/FlagCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.FLAG_CATEGORIES,
    deleteUrl: "Reference/ToggleFlagCategory",
    deleteAllUrl: "Reference/SoftDeleteAllFlagCategory",
    populateUrl: "Reference/PopulateFlagCategories",
    reactPath: "flag-categories",
  },
  {
    name: "Goal Categories",
    isMaintainable: true,
    saveUrl: "Reference/SaveGoalCategory",
    listUrl: "Reference/GoalCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.GOAL_CATEGORIES,
    deleteUrl: "Reference/ToggleGoalCategory",
    deleteAllUrl: "Reference/SoftDeleteAllGoalCategory",
    populateUrl: "Reference/PopulateGoalCategories",
    reactPath: "goal-categories",
  },
  {
    name: "Goal Priorities",
    isMaintainable: true,
    saveUrl: "Reference/SaveGoalPriority",
    listUrl: "Reference/GoalPriorityList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.GOAL_PRIORITIES,
    deleteUrl: "Reference/ToggleGoalPriority",
    deleteAllUrl: "Reference/SoftDeleteAllGoalPriority",
    populateUrl: "Reference/PopulateGoalPriorities",
    reactPath: "goal-priorities",
  },
  {
    name: "Goal Achievements",
    isMaintainable: true,
    saveUrl: "Reference/SaveGoalAchievement",
    listUrl: "Reference/GoalAchievementList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.GOAL_ACHIEVEMENTS,
    deleteUrl: "Reference/ToggleGoalAchievement",
    deleteAllUrl: "Reference/SoftDeleteAllGoalAchievement",
    populateUrl: "Reference/PopulateGoalAchievements",
    reactPath: "goal-achievements",
  },
  {
    name: "Communication Categories",
    isMaintainable: true,
    saveUrl: "Reference/SaveCommunicationCategory",
    listUrl: "Reference/CommunicationCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.COMMUNICATION_CATEGORIES,
    deleteUrl: "Reference/ToggleCommunicationCategory",
    deleteAllUrl: "Reference/SoftDeleteAllCommunicationCategory",
    populateUrl: "Reference/PopulateCommunicationCategories",
    reactPath: "communication-categories",
  },
  {
    name: "Care Plan Categories",
    isMaintainable: true,
    saveUrl: "Reference/SaveCarePlanCategory",
    listUrl: "Reference/CarePlanCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CARE_PLAN_CATEGORIES,
    deleteUrl: "Reference/ToggleCarePlanCategory",
    deleteAllUrl: "Reference/SoftDeleteAllCarePlanCategory",
    populateUrl: "Reference/PopulateCarePlanCategories",
    reactPath: "care-plan-categories",
  },
  {
    name: "Medication Usage Categories",
    isMaintainable: true,
    saveUrl: "Reference/SaveMedicationUsageCategory",
    listUrl: "Reference/MedicationUsageCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.MEDICATION_USAGE_CATEGORIES,
    deleteUrl: "Reference/ToggleMedicationUsageCategory",
    deleteAllUrl: "Reference/SoftDeleteAllMedicationUsageCategory",
    populateUrl: "Reference/PopulateMedicationUsageCategories",
    reactPath: "medication-usage-categories",
  },
  {
    name: "Task Types",
    isMaintainable: true,
    saveUrl: "Reference/SaveTaskType",
    listUrl: "Reference/TaskTypeList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.TASK_TYPE,
    deleteUrl: "Reference/ToggleTaskType",
    deleteAllUrl: "Reference/SoftDeleteAllTaskType",
    // populateUrl: "Reference/PopulateConditionClinicals",
    reactPath: "task-type",
  },
  {
    name: "Communication Status Reason",
    isMaintainable: true,
    saveUrl: "Reference/SaveCommunicationStatusReason",
    listUrl: "Reference/CommunicationStatusReasonList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.COMMUNICATION_STATUS_REASON,
    deleteUrl: "Reference/ToggleCommunicationStatusReason",
    deleteAllUrl: "Reference/SoftDeleteAllCommunicationStatusReason",
    // populateUrl: "Reference/PopulateConditionClinicals",
    reactPath: "communication-status-reason",
  },
  {
    name: "Communication Topic",
    isMaintainable: true,
    saveUrl: "Reference/SaveCommunicationTopic",
    listUrl: "Reference/CommunicationTopicList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.COMMUNICATION_TOPIC,
    deleteUrl: "Reference/ToggleCommunicationTopic",
    deleteAllUrl: "Reference/SoftDeleteAllCommunicationTopic",
    // populateUrl: "Reference/PopulateConditionClinicals",
    reactPath: "communication-topic",
  },
  {
    name: "Communication Medium",
    isMaintainable: true,
    saveUrl: "Reference/SaveCommunicationMedium",
    listUrl: "Reference/CommunicationMediumList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.COMMUNICATION_MEDIUM,
    deleteUrl: "Reference/ToggleCommunicationMedium",
    deleteAllUrl: "Reference/SoftDeleteAllCommunicationMedium",
    // populateUrl: "Reference/PopulateConditionClinicals",
    reactPath: "communication-medium",
  },
  {
    name: "Referral Category", //Service Request Category
    isMaintainable: true,
    saveUrl: "Reference/SaveServiceRequestCategory",
    listUrl: "Reference/ServiceRequestCategoryList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.SERVICE_REQUEST_CATEGORY,
    deleteUrl: "Reference/ToggleServiceRequestCategory",
    deleteAllUrl: "Reference/SoftDeleteAllServiceRequestCategory",
    // populateUrl: "Reference/PopulateConditionClinicals",
    reactPath: "referral-category",
  },
  {
    name: "Claim Type", //Service Request Category
    isMaintainable: true,
    saveUrl: "Reference/SaveClaimType",
    listUrl: "Reference/ClaimTypeList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.CLAIM_TYPE,
    deleteUrl: "Reference/ToggleClaimType",
    deleteAllUrl: "Reference/SoftDeleteAllClaimType",
    populateUrl: "Reference/PopulateClaimType",
    reactPath: "claim-type",
  },
  {
    name: "Relationship Type", //Service Request Category
    isMaintainable: true,
    saveUrl: "Reference/SaveRelationshipType",
    listUrl: "Reference/RelationshipTypeList",
    valueSetUrl: INTERNAL_VALUE_SET_URLS.RELATIONSHIP_TYPE,
    deleteUrl: "Reference/ToggleRelationshipType",
    deleteAllUrl: "Reference/SoftDeleteAllRelationshipType",
    populateUrl: "Reference/PopulateRelationshipType",
    reactPath: "relationship",
  },
];

const BOOLEAN_OPTIONS = [
  { label: "True", value: true },
  { label: "False", value: false },
];

const MERGE_URL_PARAMS = {
  manualRecord: "manualRecord",
  importedRecord: "importedRecord",
};

const ROUTES = {
  Client: {
    Campaigns: "/clients/:clientId/campaigns",
    SingleCampaign: "/clients/:clientId/campaigns/:campaignId",
    MemberManagement: "/clients/:clientId/patient-chart",
    SavingsNavigator: "/clients/:clientId/savings-navigator",
    PlanDetails: "/clients/:clientId/plan-navigator",
    ClientAdmin: "/clients/:clientId/management",
    PatientChart: "/clients/:clientId/patient-chart/:participantId",
    NewPatient: "/clients/:clientId/patient-chart/new",
    PatientMerge: "/clients/:clientId/participants/merge",
    PatientChartDiagnosis:
      "/clients/:clientId/patient-chart/:participantId/diagnosis",
  },
  Product: {
    Configuration: "/products/configuration",
    Management: "/products/management",
    Reporting: "/products/reporting",
  },
  Toolbox: "/tool-box",
  Admin: {
    ClientAdmin: "/admin/client",
    ClientGroupAdmin: "/admin/client-group",
    UserAdmin: "/admin/user",
    FhirAdmin: "/admin/fhir",
  },
  FhirAdmin: {
    SearchParameterAdmin: "/admin/search-parameters",
    ValueSetAdmin: "/admin/value-set",
    ICD10MappingAdmin: "/admin/icd10-mapping",
    DiagnosisIcd10CodesAdmin: "/admin/diagnosis-icd10",
  },
};

const LOG_EVENT_TYPES = {
  LOGIN: 1,
  LOGIN_ATTEMPT: 2,
  LOGOUT: 3,
  TIMEOUT: 4,
  CHANGE_PASSWORD: 5,
  USER_CREATED: 6,
  USER_LOCKED: 7,
  USER_UNLOCKED: 8,
  OPEN_CLIENT: 9, //Occurs when a user changes between clients in the PMI
  PATIENT_SEARCH: 10, //Occurs when a user searches for a patient record in the PMI
  PHI_TOGGLE: 11, //Occurs when a user changes the PHI visibility toggle value in the PMI
  VIEW_PATIENT: 12, //Occurs when the user selects a patient after a search and views his details
  CREATE_DATA: 13, //Occurs when the user creates a patient-related FHIR resource in the PMI
  UPDATE_DATA: 14, //Occurs when the user updates an existing patient-related FHIR resource in the PMI
  ACCESS_ERROR: 15, //This occurs when a 400-level HTTP error is generated while using the PMI
  CRITICAL_ERROR: 16, //This occurs when a 500-level HTTP error is generated while using the PMI
};

const LOG_SEVERITIES = {
  DEFAULT: 0,
  DEBUG: 100,
  INFO: 200,
  NOTICE: 300,
  WARNING: 400,
  ERROR: 500,
  CRITICAL: 600,
  ALERT: 700,
  EMERGENCY: 800,
};
const SORT_DIR = {
  ASC: "asc",
  DESC: "desc",
};

const ICONS = {
  add: <MdOutlineAddCircleOutline />,
  delete: <FiDelete />,
};

const USER_ERROR_MESSAGES = {
  noResponse:
    "We did not receive a response from the server which could indicate a network issue. Please wait 30 minutes and try again.",
};

const DEFAULT_PAGE_SIZE = 25;

const constants = {
  THEMES,
  PAGE_NAMES,
  ROUTES,
  ICONS,
  USER_ERROR_MESSAGES,
  SORT_DIR,
  VALUE_SET_TYPES,
  VALUE_SET_TYPE_IDS,
  patientChartSavedSearch,
  MERGE_URL_PARAMS,
  GreenCheck: () => <BiCheck className={"text-success"} />,
  RedX: () => <BiX className={"text-danger"} />,
  ZIP_CODE_REGEX: /^[0-9]{5}$/,
  COUNTRY_LIST: ["United State of America", "Other"],
  DEV_ENV: "development",
  COUNTRY_OPTION_LIST: [
    { value: -1, label: "Other", postalRegex: "" },
    {
      value: 1,
      label: "United States of America",
      postalRegex: "d{5}([ -]d{4})?",
    },
  ],
  US_STATE_LIST: [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Island",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
  US_STATE_ABBR_LIST: [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ],
  US_STATE_ABBR_OPTIONS: [
    { value: 1, label: "AL" },
    { value: 2, label: "AK" },
    { value: 3, label: "AZ" },
    { value: 4, label: "AR" },
    { value: 5, label: "CA" },
    { value: 6, label: "CO" },
    { value: 7, label: "CT" },
    { value: 8, label: "DE" },
    { value: 9, label: "DC" },
    { value: 10, label: "FL" },
    { value: 11, label: "GA" },
    { value: 12, label: "HI" },
    { value: 13, label: "ID" },
    { value: 14, label: "IL" },
    { value: 15, label: "IN" },
    { value: 16, label: "IA" },
    { value: 17, label: "KS" },
    { value: 18, label: "KY" },
    { value: 19, label: "LA" },
    { value: 20, label: "ME" },
    { value: 21, label: "MD" },
    { value: 22, label: "MA" },
    { value: 23, label: "MI" },
    { value: 24, label: "MN" },
    { value: 25, label: "MS" },
    { value: 26, label: "MO" },
    { value: 27, label: "MT" },
    { value: 28, label: "NE" },
    { value: 29, label: "NV" },
    { value: 30, label: "NH" },
    { value: 31, label: "NJ" },
    { value: 32, label: "NM" },
    { value: 33, label: "NY" },
    { value: 34, label: "NC" },
    { value: 35, label: "ND" },
    { value: 36, label: "OH" },
    { value: 37, label: "OK" },
    { value: 38, label: "OR" },
    { value: 39, label: "PA" },
    { value: 40, label: "RI" },
    { value: 41, label: "SC" },
    { value: 42, label: "SD" },
    { value: 43, label: "TN" },
    { value: 44, label: "TX" },
    { value: 45, label: "UT" },
    { value: 46, label: "VT" },
    { value: 47, label: "VA" },
    { value: 48, label: "WA" },
    { value: 49, label: "WV" },
    { value: 50, label: "WI" },
    { value: 51, label: "WY" },
  ],
  PASSWORD_COMPLEXITY_DESCRIPTION:
    "Passwords must be at least 8 characters long and contain at least 1 uppercase character, at least 1 lowercase character and at least 1 number.",
  XLSX_MIME: "application/vnd.openxmlformats-officedocument.spreadsheetml",
  CSV_MIME: "text/csv",
  TEN_SECONDS_MS: 10000,
  FIVE_MINUTES_MS: 300000,
  TEN_MINUTES_MS: 600000,
  TWENTY_MINUTES_MS: 1200000,
  TWENTY_THREE_HOURS_MS: 82800000,
  SORT_DIRECTION,
  ROLE_NAMES,
  ROLE_IDS,
  DEFAULT_TIMEZONE: "Eastern Standard Time",
  ROLE_DICTIONARY,
  UPDATE_FREQUENCY_IDS,
  UPDATE_FREQUENCY_NAMES,
  UPDATE_FREQUENCY_LIST,
  BREAKPOINTS,
  ALL_ROLES,
  REFERENCE_DATA_URL_LIST,
  EMPTY_USER,
  COLOR_OPTIONS: [
    { value: 0, label: "Gray", hexCode: "#A7B0B7" },
    { value: 1, label: "Purple", hexCode: "#303082" },
    { value: 2, label: "Orange", hexCode: "#DF7953" },
    { value: 3, label: "Light Purple", hexCode: "#6E6EA8" },
    { value: 4, label: "Green", hexCode: "#799E51" },
    { value: 5, label: "Blue", hexCode: "#023f72" },
    { value: 6, label: "Light Blue", hexCode: "#7C9EB2" },
  ],
  DASHBOARD_TYPES,
  PATIENT_CHART_DASHBOARD_TYPES,
  CAMPAIGN_STATUS_IDS: {
    SAVING_INITIATIVES: 1,
    APPROVED_PREPARATION: 2,
    ACTIVE: 3,
    COMPLETE: 4,
    CANCELLED: 5,
    ON_HOLD: 6,
  },
  CAMPAIGN_STATUSES: [
    { value: 1, label: "Savings Initiative" },
    { value: 2, label: "Approved / Preparation" },
    { value: 3, label: "Active" },
    { value: 4, label: "Complete" },
    { value: 5, label: "Cancelled" },
    { value: 6, label: "On Hold" },
  ],
  ACCESS_VIEWS,
  ACCESS_VIEW_OPTIONS,
  FHIR_GENDER_TYPES,
  CLAIM_GENDER_TYPES,
  FHIR_CHOICE_VARIABLE_TYPES,
  FHIR_CHOICE_VARIABLE_TYPE_OPTIONS,
  FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
  FHIR_OBSERVATION_STATUSES,
  BOOLEAN_OPTIONS,
  PATIENT_CHART_NOTIFICATION_TYPES,
  PATIENT_CHART_NOTIFICATION_OPTIONS,
  FHIR_FLAG_STATUSES,
  FHIR_GOAL_LIFECYCLE_STATUSES,
  FHIR_TASK_STATUSES,
  FHIR_TASK_INTENTS,
  FHIR_REQUEST_PRIORITIES,
  FHIR_EVENT_STATUS,
  FHIR_CONTACT_LOG_MEDIUMS,
  FHIR_REQUEST_INTENTS,
  FHIR_REQUEST_STATUS,
  FHIR_CLAIM_STATUS,
  FHIR_CLAIM_OUTCOME,
  FHIR_RESOURCE_TYPES,
  SEARCH_PARAMETER_TYPES,
  FHIR_PUBLICATION_STATUSES,
  REFERENCE_VALUE_SET_URL_LIST,
  FHIR_RESOURCES,
  DEFAULT_PAGE_SIZE,
  INTERNAL_VALUE_SET_URLS,
  FHIR_CARE_PLAN_INTENTS,
  ICD10_API_URL,
  FHIR_MEDICATION_STATEMENT_STATUS_CODES,
  MEDICATION_TYPES,
  FHIR_UNITS_OF_TIME,
  FHIR_EVENT_TIMING,
  FHIR_OBSERVATION_CATEGORIES,
  MEDICAL_CLAIM_TYPES,
  PHARMACY_CLAIM_TYPES,
  DENTAL_COVERAGE_TYPES,
  VISION_COVERAGE_TYPES,
  COVERAGE_REJECT_LIST,
  MARITAL_STATUSES,
  localStorageKeys,
  ONE_MINUTE,
  TWO_MINUTES,
  THIRTY_MINUTES,
  TEN_SECONDS,
  FIVE_MINUTES,
  TEN_MINUTES,
  TWENTY_MINUTES,
  OBSERVATION_VITAL_SIGNS_TEMP_OPTIONS,
  OBSERVATION_VITAL_SIGNS_BLOOD_OPTIONS,
  OBSERVATION_VITAL_SIGNS_HEIGHT_OPTIONS,
  OBSERVATION_VITAL_SIGNS_WEIGHT_OPTIONS,
  LOG_EVENT_TYPES,
  LOG_SEVERITIES,
  NULL_AS_STRING: "null",
  MEDICAL: "medical",
  PHARMACY: "pharmacy",
  COPAY: "copay",
  DEDUCTIBLE: "deductible",
  COINSURANCE: "coinsurance",
  BILLED_AMOUNT: "billed amount",
  INELIGIBLE_AMOUNT: "ineligible",
  ALLOWED_AMOUNT: "allowed amount",
  PAID_AMOUNT: "paid amount",
  UNIT_COUNT: "unit count",
  SALES_TAX: "sales tax",
  AWP: "average wholesale price",
  INGREDIENT_COST: "ingredient cost paid",
  DISPENCING_FEE: "dispensing fee submitted",
  USUAL_AND_CUSTOMARY: "usual and customary submitted",
  TOTAL_MEMBER_PAID: "total patient paid",
  TOTAL_PAYOR_PAID: "total payor paid",
  CLAIMS_MEMBER_FINANCIAL_TYPES: [
    "Coinsurance",
    "Copay",
    "Deductible",
    "Total Patient Paid",
  ],
  DISPLAY: "DISPLAY",
  NOT_PROVIDED: "not-provided",
};

export default constants;