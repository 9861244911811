import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import _ from 'lodash';
import StyledSelect from './StyledSelect';

export default function FilterSelect(props) {
  //const [value, setValue] = useState();
  //const [selectedValue, setSelectedValue] = useState(null);

  function onChange(selections) {
      let values = [];
      let labels = [];
      let filteredDisplay = '';
      if(selections)
      {
          values = selections.length
              ? _.map(selections, x => x.value)
              : [selections.value];
          labels = selections.length
              ? _.map(selections, x => x.label)
              : [selections.label];
          let valueDisplay = labels.join(', ');
          filteredDisplay =
              values && valueDisplay
                  ? `${props.displayName}: ${valueDisplay}`
                  : '';
        //   setValue(values);
          if(props.isSingleSelect)
          {
              const newValue = values && values.length ? values[0] : null;
              const label = labels && labels.length ? labels[0] : null;
              props.onChangeCallback({
                  filterName: props.filterName,
                  value: newValue,
                  label: label,
                  filteredDisplay: filteredDisplay
              });
          }
          else{
              props.onChangeCallback({
                  filterName: props.filterName,
                  values: values,
                  labels: labels,
                  filteredDisplay: filteredDisplay
              });
          }
      } else {
          //If cross is clicked set values to empty
          //setValue({label: '', value: ''});
          props.onChangeCallback({
              filterName: props.filterName,
              value: null,
              filteredDisplay: ''
          });
      }
  }

  if(!props.options) return (<span/>);


//   let selectedValue = null;
//   if(value && value.length)
//       selectedValue =
//           _.filter(props.options, x => _.some(value, x.value));
  const isMulti = !props.isSingleSelect;
  return (
      <span>
          {/* <div className="filter-caption">{props.displayName}</div> */}
          <StyledSelect
              isMulti={isMulti}
              name={props.displayName}
              label={props.displayName}
              closeMenuOnSelect={!isMulti}
              placeholder={props.placeholder}
              isClearable
              options={props.options}
              value={props.value}
              isDisabled={props.isDisabled}
              onChange={onChange}
              defaultValue={props.defaultValue}
              components={makeAnimated()}
              className={'react-container'}
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              onCloseResetsInput={false}
              classNamePrefix="react-select"
          />
          {/* <Select
              closeMenuOnSelect={!isMulti}
              isMulti={isMulti}
              isClearable
              isDisabled={props.isDisabled}
              components={makeAnimated()}
              options={props.options}
              className={'react-container'}
              onChange={onChange}
              value={props.value}
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              onCloseResetsInput={false}
              classNamePrefix="react-select"
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
          /> */}
      </span>
  );
}
