import React, { useState, useEffect } from "react";
import {
  Loader,
  LongBreadCrumb,
  PageContainer,
  StyledButton,
  StyledModal,
  StyledInput,
  Pager,
  WidgetCard,
  StyledTD,
  Checkbox,
  FilterText,
  StyledSelect,
  StyledAsyncSelect,
} from "./";
import { Row, Col, Table } from "reactstrap";
import { api, helpers, constants, filterHelpers } from "../utils";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import { FiEdit, FiMinusCircle } from "react-icons/fi";

const EMPTY_ITEM = {
  id: "",
  code: "",
  display: "",
  system: "",
  parent: null,
};

function DiagnosisIcd10CodesAdmin() {
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("code");
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedList, setPaginatedList] = useState(null);
  const [currentItem, setCurrentItem] = useState(Object.assign({}, EMPTY_ITEM));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loadModalOpen, setLoadModalOpen] = useState(false);
  const [clearDataModalOpen, setClearDataModalOpen] = useState(false);
  const [includeNonMaintainable, setIncludeNonMaintainable] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(refreshData, [sortField, sortDirection, pageNumber, filters]);

  useEffect(() => {
    console.error("current: ", currentItem);
  }, [currentItem]);

  function refreshData() {
    setLoading(true);
    let apiCalls = [];
    apiCalls.push(getPaginatedIcd10CodeList());

    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.paginatedInfo) {
          aggResults.paginatedInfo.list = _.map(
            aggResults.paginatedInfo.list,
            (code) => {
              return {
                ...code,
                parent: code.parent
                  ? {
                      ...code?.parent,
                      label: `${code?.parent?.code} ${code?.parent?.display}`,
                      value: code?.parent?.id,
                    }
                  : null,
              };
            }
          );

          setPaginatedList(aggResults.paginatedInfo);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function getPaginatedIcd10CodeList() {
    let payload = {
      sortField: sortField,
      sortDirection: sortDirection,
      pageNumber: pageNumber,
      maxResults: constants.DEFAULT_PAGE_SIZE,
      activeOnly: true,
    };

    _.each(filters, (filter) => {
      if (filter.values && filter.values.length > 0) {
        payload[filter.filterName] = filter.values[0];
      } else {
        payload[filter.filterName] = filter.label || filter.value;
      }
    });

    return api
      .securePost("Reference/DiagnosisIcd10CodeList", payload)
      .then((response) => {
        // setPaginatedList(response.data);
        return { paginatedInfo: response.data };
      })
      .catch(helpers.catchHandler)
      .finally(() => {
        setLoading(false);
      });
  }

  function filterChange(changedFilter) {
    const filterList = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterList);
  }

  function reset() {
    setCurrentItem(Object.assign({}, EMPTY_ITEM));
    setEditModalOpen(false);
  }

  function saveItem() {
    if (!validated()) return;

    api
      .securePost("Reference/SaveDiagnosisIcd10Code", currentItem)
      .then((response) => {
        if (response.data && response.data.success) {
          toast.success("Saved successfully");
          reset();
          refreshData();
        } else {
          toast.error("Could not be saved");
        }
      })
      .catch(helpers.catchHandler);
  }

  function deleteItem(item) {
    api
      .securePost(`Reference/ToggleDiagnosisIcd10Code/${item.id}`)
      .then((response) => {
        if (response.data && response.data.success) {
          reset();
          refreshData();
          toast.success("Successfully deleted");
        } else {
          toast.error("Could not be deleted");
        }
      })
      .catch(helpers.catchHandler);
  }

  function onChangeItem(field, value) {
    let tempItem = Object.assign({}, currentItem);
    tempItem[field] = value;
    setCurrentItem(tempItem);
  }

  function validated() {
    if (!currentItem) {
      toast.error("Unable to find Current Item. Please try again");
      return false;
    }
    if (!currentItem.display || !currentItem.display.trim()) {
      toast.warning("Display is required");
      return false;
    }
    if (!currentItem.code || !currentItem.code.trim()) {
      toast.warning("Code is required");
      return false;
    }
    if (!currentItem.system || !currentItem.system.trim()) {
      toast.warning("System is required");
      return false;
    }
    return true;
  }

  return (
    <>
      <PageContainer>
        {loading ? (
          <Loader center={"m-auto"} />
        ) : (
          <Col className={"m-0 p-0"}>
            <Row
              className={
                "mx-0 my-3 d-flex justify-content-start align-items-center"
              }
            >
              <Col>
                <LongBreadCrumb
                  context={"Diagnosis ICD10 Code"}
                  page={"Management"}
                  loading={loading}
                />
              </Col>
            </Row>
            <WidgetCard
              title={"Diagnosis ICD10 Codes"}
              onOpen={() => {}}
              startOpen={true}
              canCreateNew={true}
              onCreateNew={() => {
                setCurrentItem(null);
                setEditModalOpen(true);
              }}
            >
              <Row className="mb-2">
                <Col>
                  {/* {paginatedList &&
                  paginatedList.list &&
                  paginatedList.list.length ? ( */}
                  <>
                    <Row
                      className={
                        "mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center"
                      }
                    >
                      <Col xs="4">
                        <FilterText
                          disabled={loading}
                          filterName="Code"
                          label="Code"
                          onChangeCallback={filterChange}
                          debounceTimeout={750}
                          value={filterHelpers.getValue("Code", filters)}
                        />
                      </Col>
                      <Col xs="4">
                        <FilterText
                          disabled={loading}
                          filterName="Display"
                          label="Display"
                          onChangeCallback={filterChange}
                          debounceTimeout={750}
                          value={filterHelpers.getValue("Display", filters)}
                        />
                      </Col>
                      <Col xs="4">
                        <FilterText
                          disabled={loading}
                          filterName="System"
                          label="System"
                          onChangeCallback={filterChange}
                          debounceTimeout={750}
                          value={filterHelpers.getValue("System", filters)}
                        />
                      </Col>
                    </Row>
                    <Table size="sm" responsive striped>
                      <thead>
                        <tr>
                          <th />
                          <th className="header-default">Code</th>
                          <th className="header-default">Parent</th>
                          <th className="header-default">Display</th>
                          <th className="header-default">System</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={9}>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          _.map(paginatedList?.list, (x, i) => {
                            return (
                              <tr
                                key={`DiagnosisICD!)Codes${i}`}
                                onClick={() => {}}
                                className="fontSmall"
                              >
                                <StyledTD>
                                  <div style={{ display: "flex" }}>
                                    <FiEdit
                                      className="clickable-icon mr-1"
                                      onClick={() => {
                                        setEditModalOpen(true);
                                        setCurrentItem(x);
                                      }}
                                    />
                                    <FiMinusCircle
                                      className="clickable-icon red mr-2"
                                      onClick={() => {
                                        setCurrentItem(x);
                                        deleteItem(x);
                                      }}
                                    />
                                  </div>
                                </StyledTD>
                                <StyledTD>{x.code}</StyledTD>
                                <StyledTD>
                                  {x.parent?.code ? x.parent.code : "-"}
                                </StyledTD>
                                <StyledTD>{x.display}</StyledTD>
                                <StyledTD>{x.system}</StyledTD>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                      <Pager
                        pageNumber={
                          paginatedList?.pageNumber
                            ? paginatedList.pageNumber
                            : 0
                        }
                        totalPages={
                          paginatedList?.totalPages
                            ? paginatedList.totalPages
                            : 0
                        }
                        callBack={(newPageNumber) =>
                          setPageNumber(newPageNumber)
                        }
                      />
                    </Table>
                  </>
                  {/* ) : null} */}
                </Col>
              </Row>
            </WidgetCard>
          </Col>
        )}
        <StyledModal
          title={`Edit Diganosis ICD10 Code`}
          showCancel
          onSave={saveItem}
          show={editModalOpen}
          onHide={() => setEditModalOpen(false)}
          savePrompt="Save"
          size="xl"
        >
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="code"
                name="code"
                label="Code"
                value={currentItem?.code}
                onChange={(e) => onChangeItem("code", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="display"
                name="display"
                label="Display"
                value={currentItem?.display}
                onChange={(e) => onChangeItem("display", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="system"
                name="system"
                label="System"
                value={currentItem?.system}
                onChange={(e) => onChangeItem("system", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledAsyncSelect
                isClearable
                label="Parent"
                placeholder="ICD-10 Code Lookup"
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.DIAGNOSIS_ICD10_CODES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.DIAGNOSIS_ICD10_CODES,
                    true
                  )
                }
                onChange={(e) => onChangeItem("parent", e)}
                value={currentItem?.parent}
              />
            </Col>
          </Row>
        </StyledModal>
      </PageContainer>
    </>
  );
}

export default DiagnosisIcd10CodesAdmin;