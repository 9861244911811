import React, {useState, useEffect} from 'react';
import {
    PageContainer,
    LongBreadCrumb,
    StyledButton,
    Loader,
    StyledTD,
    StyledInput,
    StyledSelect,
    StyledSingleDatePicker, StyledModal, StyledStepper
} from './';
import {Card, CardBody, Col, Form, Row, Table} from "reactstrap";
import {BsCircleFill, BsPlusLg} from "react-icons/bs";
import {TbEqual, TbLayoutSidebarLeftCollapse} from "react-icons/tb";
import _ from "lodash";
import {api, dateHelpers} from "../utils";
import {useParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {RiEdit2Line} from "react-icons/ri";
import {GoCommentDiscussion} from "react-icons/go";
import {FaCompressArrowsAlt, FaExpandArrowsAlt} from "react-icons/fa";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";

const campaignDummyStats = {
    7: {
        inProgressGoal: "$13,278.99",
        aheadOfGoal: "$1,033.87",
        realizedSavings: "$14,312.86",
    },
};

const caseDummyComments = {
    8: [
        {
            id: uuidv4(),
            timestamp: "09/23/2018 - 10:15am",
            createdByName: "Doe, John",
            comment: (
                <>
                    <span className="bg-purple30 p-1 roundedCorners">@Tom</span> be sure
                    to get this done soon
                </>
            ),
        },
        {
            id: uuidv4(),
            timestamp: "09/01/2018 - 04:33pm",
            createdByName: "Browning, Thomas",
            comment: (
                <>
                    <span className="bg-purple30 p-1 roundedCorners">@John</span> I'm
                    having a problem getting information back from UNC High Point. Not
                    sure what the issue is, but I'm working on it.
                </>
            ),
        },
        {
            id: uuidv4(),
            timestamp: "08/29/2018 - 10:15am",
            createdByName: "Doe, John",
            comment: (
                <>
                    <span className="bg-purple30 p-1 roundedCorners">@Tom</span> what's up
                    with this case
                </>
            ),
        },
    ],
};

const caseStepsDummyData = {
    8: [
        {
            label: `Initialized`,
            value: 1,
        },
        {
            label: `Submitted for Validation`,
            value: 2,
        },
        {
            label: `Eligibility Determined`,
            value: 3,
        },
        {
            label: `Eligibility - Confirmed`,
            value: 4,
        },
        {
            label: `Eligibility - Eligible`,
            value: 5,
        },
        {
            label: `Eligibility - Removed`,
            value: 6,
        },
        {
            label: `Completed`,
            value: 7,
        },
    ],
    9: [
        {
            label: `Initialized`,
            value: 1,
        },
        {
            label: `Submitted for Validation`,
            value: 2,
        },
        {
            label: `Refunds Pending`,
            value: 3,
        },
        {
            label: `Not Duplicated`,
            value: 4,
        },
        {
            label: `Completed`,
            value: 5,
        },
    ],
};

const caseItemDummyData = {
    8: [
        {
            id: uuidv4(),
            statusName: "Refund Pending",
            memberNumber: "W773758724",
            claimDate: "03/24/2018",
            claimNumber: "UNC-21897-H89",
            claimAmount: "$359.17",
            vendorName: "UNC High Point Hospital",
            physicianName: "Jordan, Michael",
        },
        {
            id: uuidv4(),
            statusName: "Complete",
            memberNumber: "W125623788",
            claimDate: "03/09/2018",
            claimNumber: "UNC-45232-H89",
            claimAmount: "$489.07",
            vendorName: "UNC High Point Hospital",
            physicianName: "Williams, Roy",
        },
        {
            id: uuidv4(),
            statusName: "Not Duplicate",
            memberNumber: "W773758724",
            claimDate: "03/24/2018",
            claimNumber: "UNC-22321-H89",
            claimAmount: "$269.88",
            vendorName: "UNC High Point Hospital",
            physicianName: "Carter, Vince",
        },
        {
            id: uuidv4(),
            statusName: "Refund Pending",
            memberNumber: "W125623788",
            claimDate: "04/09/2018",
            claimNumber: "UNC-78549-H89",
            claimAmount: "$278.99",
            vendorName: "UNC High Point Hospital",
            physicianName: "Hamm, Mia",
        },
        {
            id: uuidv4(),
            statusName: "Refund Pending",
            memberNumber: "W773758724",
            claimDate: "06/24/2018",
            claimNumber: "UNC-98744-H89",
            claimAmount: "$995.47",
            vendorName: "UNC High Point Hospital",
            physicianName: "Brown, Larry",
        },
        {
            id: uuidv4(),
            statusName: "Complete",
            memberNumber: "W125623788",
            claimDate: "04/24/2018",
            claimNumber: "UNC-87452-H89",
            claimAmount: "$135.17",
            vendorName: "UNC High Point Hospital",
            physicianName: "Jordan, Michael",
        },
        {
            id: uuidv4(),
            statusName: "Complete",
            memberNumber: "W773758724",
            claimDate: "06/24/2018",
            claimNumber: "UNC-98654-H89",
            claimAmount: "$769.87",
            vendorName: "UNC High Point Hospital",
            physicianName: "Jeong, Ken",
        },
        {
            id: uuidv4(),
            statusName: "Complete",
            memberNumber: "W125623788",
            claimDate: "05/02/2018",
            claimNumber: "UNC-98744-H89",
            claimAmount: "$256.35",
            vendorName: "UNC High Point Hospital",
            physicianName: "Harvey, Matt",
        },
        {
            id: uuidv4(),
            statusName: "Not Duplicate",
            memberNumber: "W773758724",
            claimDate: "03/24/2018",
            claimNumber: "UNC-20887-H89",
            claimAmount: "$110.98",
            vendorName: "UNC High Point Hospital",
            physicianName: "Jeong, Ken",
        },
    ],
    9: [
        {
            id: uuidv4(),
            statusName: "Complete",
            memberNumber: "W773758724",
            claimDate: "06/24/2018",
            claimNumber: "UNC-98654-H89",
            claimAmount: "$769.87",
            vendorName: "UNC High Point Hospital",
            physicianName: "Jeong, Ken",
        },
        {
            id: uuidv4(),
            statusName: "Complete",
            memberNumber: "W125623788",
            claimDate: "05/02/2018",
            claimNumber: "UNC-98744-H89",
            claimAmount: "$256.35",
            vendorName: "UNC High Point Hospital",
            physicianName: "Harvey, Matt",
        },
        {
            id: uuidv4(),
            statusName: "Not Duplicate",
            memberNumber: "W773758724",
            claimDate: "03/24/2018",
            claimNumber: "UNC-20887-H89",
            claimAmount: "$110.98",
            vendorName: "UNC High Point Hospital",
            physicianName: "Jeong, Ken",
        },
    ],
    26: [],
    27: [],
};

const emptyNewCase = {
    id: 0,
    name: "",
    caseManager: null,
    startOn: dateHelpers.firstDateOfYear(),
    endOn: dateHelpers.lastDateOfYear(),
};


function getUIFriendlyDate(date) {
    return dateHelpers.toMDYDateString(date, dateHelpers.YMDHMS);
}

function miniStat(title, value) {
    return (
        <span className="float-left">
      <span className="dateHeaders">{title}</span>
      <span className="dateHeadersData">{value}</span>
    </span>
    );
}

function CollapsibleCard(props) {
    const [showCard, setShowCard] = useState(
        _.isBoolean(props.startOpen) ? props.startOpen : true
    );

    let collapseButtonColumnWidth = props.collapseButtonColumnWidth || 1;
    let actionColumnWidth = props.actionColumnWidth || 1;
    let firstColumnWidth = props.firstColumnWidth || 11;
    if (props.titleAction) {
        firstColumnWidth = firstColumnWidth - actionColumnWidth;
    }
    return (
        <Card
            className="backgroundWhite fontSize85 mb-2"
            ke={props.key || uuidv4()}
        >
            <CardBody className="p-2">
                <Row>
                    <Col
                        xs={firstColumnWidth}
                        onClick={() => setShowCard(!showCard)}
                        className="text-left"
                    >
                        {props.title}
                    </Col>
                    {showCard && props.titleAction ? (
                        <Col xs={actionColumnWidth}>{props.titleAction}</Col>
                    ) : null}
                    <Col
                        xs={collapseButtonColumnWidth}
                        onClick={() => setShowCard(!showCard)}
                    >
                        {showCard ? (
                            <IoMdArrowDropdown className="float-right" />
                        ) : (
                            <IoMdArrowDropup className="float-right" />
                        )}
                    </Col>
                </Row>
                {showCard && props.content}
            </CardBody>
        </Card>
    );
}

export default function CaseManager(props) {
    let { type, clientId, campaignId, caseId } = useParams();
    clientId = clientId ? parseInt(clientId, 10) : null;
    campaignId = campaignId ? parseInt(campaignId, 10) : null;
    const [loading, setLoading] = useState(false);
    const [cases, setCases] = useState(null);
    const [client, setClient] = useState(null);
    const [campaign, setCampaign] = useState(null);
    const [showNewCaseModal, setShowNewCaseModal] = useState(false);
    const [newCase, setNewCase] = useState(null);
    const [currentCases, setCurrentCases] = useState([]);
    const [currentCase, setCurrentCase] = useState(null);
    const [activeOnly, setActiveOnly] = useState(true);
    const [showFullScreen, setShowFullScreen] = useState(false);


    function openNewCaseModal() {
        const newItem = Object.assign({}, emptyNewCase);
        newItem.campaign = campaign;
        setNewCase(newItem);
        setShowNewCaseModal(true);
    }

    let showCaseInfo = currentCase && currentCase.id;

    function getAllCaseManagers() {
        // TODO return list with only case manager roles
        // and access to the given client - add clientId to payload
        return api
            .securePost(`UserAdmin/GetUsers`, { activeOnly: activeOnly })
            .then((response) => {
                if (response && response.data) {
                    return { caseManagers: response.data.message };
                }
            })
            .catch(api.catchHandler);
    }

    function getClient() {
        return api
            .secureFetch(`Client/Client/${clientId}`)
            .then((response) => {
                if (response && response.data) {
                    return { client: response.data };
                }
            })
            .catch(api.catchHandler);
    }

    function toggleCurrentCase(c) {
        if (currentCase && currentCase.id === c.id) {
            setCurrentCase(null);
            return;
        }
        setCurrentCase(c);
    }

    function getCampaign() {
        return api
            .secureFetch(`Campaign/GetCampaign/${campaignId}`)
            .then((response) => {
                if (response && response.data) {
                    return { campaign: response.data.message };
                }
            })
            .catch(api.catchHandler);
    }

    function getCase() {
        return api
            .secureFetch(`Campaign/GetCampaignCase/${caseId}`)
            .then((response) => {
                if (response && response.data) {
                    return { case: response.data.message };
                }
            })
            .catch(api.catchHandler);
    }

    function getCases() {
        return api
            .securePost(`Campaign/PaginatedCaseList`, {
                CampaignId: campaignId,
                maxResults: 100,
                activeOnly: activeOnly,
            })
            .then((response) => {
                if (response && response.data && response.data.list) {
                    return { cases: response.data.list };
                }
            })
            .catch(api.catchHandler);
    }

    function onChangeNewCase(field, value) {
        let changes = Object.assign({}, newCase);
        changes[field] = value;
        setNewCase(changes);
    }

    function refreshData() {
        if (!loading) {
            setLoading(true);
            let apiCalls = [];
            if (clientId) {
                apiCalls.push(getClient());
            }
            if (campaignId) {
                apiCalls.push(getCampaign());
                apiCalls.push(getCases());
            }
            Promise.all(apiCalls)
                .then((arrayResults) => {
                    let aggResults = {};
                    _.each(arrayResults, (x) => Object.assign(aggResults, x));
                    if (aggResults.client) {
                        setClient(aggResults.client);
                    }
                    if (aggResults.campaign) {
                        setCampaign(aggResults.campaign);
                    }
                    if (aggResults.cases) {
                        setCurrentCases(aggResults.cases);
                    }
                })
                .catch(api.catchHandler)
                .finally(() => setLoading(false));
        }
    }

    useEffect(() => {
        refreshData();
    }, []);

    const caseArea = (
        <Col>
            <Row className="caseCard">
                <Col xs="4">
                    <CollapsibleCard
                        title={<span className="dateHeadersData">Case Detail</span>}
                        titleAction={
                            <RiEdit2Line
                                color="primary"
                                className="float-right"
                                onClick={() => {}}
                            />
                        }
                        content={
                            <>
                                <Row className="mb-4 pl-2">
                                    <Col xs="4" className="px-1">
                                        {miniStat("Status: ", currentCase?.caseStatusName)}
                                    </Col>
                                    <Col xs="8" className="px-1">
                                        {miniStat("Current Step: ", "Disposition Claims")}
                                    </Col>
                                    <Col xs="4" className="px-1">
                                        {miniStat("Priority: ", "Medium")}
                                    </Col>
                                    <Col xs="8" className="px-1">
                                        {miniStat("Savings Manager: ", "Doe, John")}
                                    </Col>
                                    <Col xs="4" className="px-1">
                                        {miniStat(
                                            "Start Date: ",
                                            getUIFriendlyDate(currentCase?.startOn)
                                        )}
                                    </Col>
                                    <Col xs="8" className="px-1">
                                        {miniStat("Case Manager: ", currentCase?.caseManagerName)}
                                    </Col>
                                    <Col xs="4" className="px-1">
                                        {miniStat(
                                            "End Date: ",
                                            getUIFriendlyDate(currentCase?.endOn)
                                        )}
                                    </Col>
                                    <Col xs="8" className="px-1">
                                        {miniStat("Days: ", 90)}
                                    </Col>
                                </Row>
                            </>
                        }
                    />
                    <CollapsibleCard
                        startOpen={true}
                        title={<span className="dateHeadersData">Comments</span>}
                        titleAction={
                            <GoCommentDiscussion
                                color="primary"
                                className="float-right"
                                onClick={() => {}}
                            />
                        }
                        content={
                            <Row className="mb-4 pl-2">
                                <Col xs="12" className="px-1">
                                    {_.map(caseDummyComments[currentCase?.id], (cmt, idx) => {
                                        return (
                                            <Row
                                                key={`caseComment${cmt.id}${idx}`}
                                                className="my-2 mx-1"
                                            >
                                                <Col>
                          <span className="dateHeaders float-left">
                            {cmt.timestamp}{" "}
                          </span>
                                                    <span className="float-right text-primary mr-3">
                            {cmt.createdByName}
                          </span>
                                                    <br />
                                                    <span className="float-left ml-5">
                            {cmt?.comment}
                          </span>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>
                        }
                    />
                </Col>
                <Col xs="8">
                    <CollapsibleCard
                        startOpen={true}
                        title={
                            <>
                                <Row className="w-100">
                                    <Col xs="12" className="pr-0">
                                        <StyledStepper
                                            steps={caseStepsDummyData[currentCase?.id]}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }
                        titleAction={null}
                        content={
                            <>
                                <Row className="mb-4">
                                    <Col xs="12" lg="6"></Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        {caseItemDummyData[currentCase?.id] &&
                                        caseItemDummyData[currentCase?.id].length ? (
                                            <Table bordered size="sm" responsive striped>
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Member #</th>
                                                    <th>Claim Date</th>
                                                    <th>Claim #</th>
                                                    <th>Claim Amount</th>
                                                    <th>Vendor</th>
                                                    <th>Phsyician Name</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {_.map(
                                                    caseItemDummyData[currentCase?.id],
                                                    (c, index) => {
                                                        return (
                                                            <tr
                                                                key={`campaignCaseRow${c.id}${index}`}
                                                                onClick={() => navigate(getChildLink(c))}
                                                                className="cursorPointer fontSize85 clickableRow"
                                                            >
                                                                <StyledTD>{c.statusName}</StyledTD>
                                                                <StyledTD>{c.memberNumber}</StyledTD>
                                                                <StyledTD textRight>
                                                                    {getUIFriendlyDate(c.claimDate)}
                                                                </StyledTD>
                                                                <StyledTD>{c.claimNumber}</StyledTD>
                                                                <StyledTD textRight>{c.claimAmount}</StyledTD>
                                                                <StyledTD>{c.vendorName}</StyledTD>
                                                                <StyledTD>{c.physicianName}</StyledTD>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <>
                                                {loading ? <Loader /> : "No case items for this case."}
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        }
                    />
                </Col>
            </Row>
            <div className="closeRightPanel">
                <TbLayoutSidebarLeftCollapse
                    size="2em"
                    className="float-right mb-2"
                    onClick={() => setCurrentCase(null)}
                    title="Close Case Details"
                />
                <br />
                {showFullScreen ? (
                    <FaCompressArrowsAlt
                        size="1.65em"
                        onClick={() => setShowFullScreen(!showFullScreen)}
                        title="Collapse Full Screen"
                    />
                ) : (
                    <FaExpandArrowsAlt
                        size="1.65em"
                        onClick={() => setShowFullScreen(!showFullScreen)}
                        title="Expand Full Screen"
                    />
                )}
            </div>
        </Col>
    );

    return(
        <PageContainer>
            <Col>
                <LongBreadCrumb context={client?.name} page={'Campaign ' + campaign?.name} loading={loading} trailing={
                <StyledButton
                    color="primary"
                    className="float-right"
                    onClick={() => openNewCaseModal(true)}
                    showPlusIcon
                >
                    New Case
                </StyledButton>}/>
                <Row lg={10} className={'m-0 p-0 h-100 w-100'}>
                    <Card className={'w-100 h-100'}>
                        <CardBody>
                            <Row>
                                <Col xs="4">
                                    <h5 className="dateHeadersData mb-4">
                                        {campaign?.savingsLever?.name}
                                    </h5>
                                </Col>
                                <Col xs="8">

                                </Col>
                            </Row>
                            <Card className="backgroundWhite">
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col xs="2">
                                            <span className="dateHeaders">Start Date</span>
                                            <br />
                                            <span className="dateHeadersData">
                                  {getUIFriendlyDate(campaign?.startOn)}
                                </span>
                                        </Col>
                                        <Col xs="1" className="text-right pl-0">
                                            <span className="dateHeaders">End Date</span>
                                            <br />
                                            <span className="dateHeadersData">
                                  {getUIFriendlyDate(campaign?.endOn)}
                                </span>
                                        </Col>
                                        <Col xs="6" className="pl-5">
                                            <Row>
                                                <Col xs="1" className="pr-0">
                                                    <BsCircleFill className="colorRed" />
                                                </Col>
                                                <Col className="pl-0">
                                    <span className="greenMoney">
                                      {
                                          campaignDummyStats[campaign?.id]
                                              ?.inProgressGoal
                                      }
                                    </span>
                                                    <br />
                                                    In progress
                                                    <br /> Goal
                                                </Col>
                                                <Col xs="1" className="px-0">
                                                    <BsPlusLg />
                                                </Col>
                                                <Col xs="1" className="pr-0">
                                                    <BsCircleFill className="colorPurple" />
                                                </Col>
                                                <Col className="pl-0">
                                    <span className="greenMoney">
                                      {
                                          campaignDummyStats[campaign?.id]
                                              ?.aheadOfGoal
                                      }
                                    </span>
                                                    <br />
                                                    Ahead of
                                                    <br /> Goal
                                                </Col>
                                                <Col xs="1" className="px-0">
                                                    <TbEqual size="1.5em" />
                                                </Col>
                                                <Col>
                                    <span className="greenMoney">
                                      {
                                          campaignDummyStats[campaign?.id]
                                              ?.realizedSavings
                                      }
                                    </span>
                                                    <br />
                                                    Realized
                                                    <br /> Savings
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="w-100 mr-0">
                                        <Col xs="12" className="pr-0">
                                            {loading ? <Loader /> :
                                                currentCases && currentCases.length ? (
                                                    <Row className="w-100 mr-0 p-0">
                                                        <Col
                                                            className={

                                                                showCaseInfo ? "maxWidth245" : ""
                                                            }
                                                        >
                                                            <Table
                                                                bordered
                                                                size="sm"
                                                                responsive
                                                                striped
                                                            >
                                                                <thead>
                                                                <tr>
                                                                    <th
                                                                        className={'diagnostic-table-header'}
                                                                        style={
                                                                            showCaseInfo
                                                                                ? {
                                                                                    maxWidth:
                                                                                        "200px !important",
                                                                                }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        Case Name
                                                                    </th>
                                                                    {showCaseInfo ? null : (
                                                                        <>
                                                                            <th className={'diagnostic-table-header'}>Status</th>
                                                                            <th className={'diagnostic-table-header'}>Start On</th>
                                                                            <th className={'diagnostic-table-header'}>End On</th>
                                                                            <th className={'diagnostic-table-header'}>Case Manager</th>
                                                                            <th className={'diagnostic-table-header'}># of Items</th>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {_.map(currentCases, (c, index) => {
                                                                    return (
                                                                        <tr
                                                                            key={`campaignCaseRow${c.id}${index}`}
                                                                            onClick={() =>
                                                                                toggleCurrentCase(c)
                                                                            }
                                                                            className="cursorPointer clickableRow"
                                                                        >
                                                                            <StyledTD>{c.name}</StyledTD>
                                                                            {showCaseInfo ? null : (
                                                                                <>
                                                                                    <StyledTD>
                                                                                        {c.caseStatusName}
                                                                                    </StyledTD>
                                                                                    <StyledTD textRight>
                                                                                        {getUIFriendlyDate(
                                                                                            c.startOn
                                                                                        )}
                                                                                    </StyledTD>
                                                                                    <StyledTD textRight>
                                                                                        {getUIFriendlyDate(
                                                                                            c.endOn
                                                                                        )}
                                                                                    </StyledTD>
                                                                                    <StyledTD>
                                                                                        {c.caseManagerName}
                                                                                    </StyledTD>
                                                                                    {/* <StyledTD>{c.caseItemCount}</StyledTD> */}
                                                                                    <StyledTD textRight>
                                                                                        {
                                                                                            caseItemDummyData[c.id]
                                                                                                ?.length
                                                                                        }
                                                                                    </StyledTD>
                                                                                </>
                                                                            )}
                                                                        </tr>
                                                                    );
                                                                })}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                        {showCaseInfo ? caseArea : null}
                                                    </Row>
                                                ) : (
                                                    "No cases exist for this campaign."
                                                )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </Row>
            </Col>
            <StyledModal
                show={showNewCaseModal}
                onHide={() => setShowNewCaseModal(false)}
                size="xl"
                title={"Create Case"}
                onSave={() => {}}
                disabled={loading}
                showCancel
                savePrompt={"Save"}
            >
                <Form>
                    <Row className="px-4 w-100 mb-4">
                        <Col xs="12">
                            <StyledInput
                                label={"Name"}
                                required
                                debounceTimeout={300}
                                type="text"
                                name="name"
                                id="name"
                                maxLength="200"
                                value={newCase?.name || ""}
                                onChange={(e) => onChangeNewCase("name", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="px-4 w-100 mb-4">
                        <Col xs="12">
                            <StyledSelect
                                label={"Case Manager"}
                                required
                                // options={caseManagerOptions}
                                // TODO --> get case managers
                                options={[
                                    { value: 1, label: "Leia Organa" },
                                    { value: 2, label: "Obi Wan Kenobi" },
                                ]}
                                name="caseManager"
                                id="caseManager"
                                value={newCase?.caseManager || ""}
                                onChange={(selection) =>
                                    onChangeNewCase("caseManager", selection)
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="px-4 w-100">
                        <Col xs="3">
                            <StyledSingleDatePicker
                                label={"Start On"}
                                required
                                debounceTimeout={300}
                                type="text"
                                name="startOn"
                                id="startOn"
                                maxLength="200"
                                value={newCase?.startOn || ""}
                                onChangeCallback={(date) => onChangeNewCase("startOn", date)}
                            />
                        </Col>
                        <Col xs="3">
                            <StyledSingleDatePicker
                                label={"End On"}
                                required
                                debounceTimeout={300}
                                type="text"
                                name="endOn"
                                id="endOn"
                                maxLength="200"
                                value={newCase?.endOn || ""}
                                onChangeCallback={(date) => onChangeNewCase("endOn", date)}
                            />
                        </Col>
                    </Row>
                </Form>
            </StyledModal>
        </PageContainer>
    )
}