import React, { useState, useEffect } from "react";
import { constants } from "../utils";
import { Row, Col } from "react-bootstrap";
import { Card, CardBody, CardDeck } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { PageContainer, LongBreadCrumb } from "./";
import _ from "lodash";
import { AiFillMedicineBox, AiOutlineSearch } from "react-icons/ai";
import { BsStack } from "react-icons/bs";
import { FaNotesMedical } from "react-icons/fa";

const SkeletonCard = (props) => {
  return (
    <Col key={`skele${props.index}`} xs="12" lg="4" className={"m-0 p-0"}>
      <Card className={`cards-body mb-4 backgroundClients`}>
        <CardBody>
          <Row
            lg={"auto"}
            className={"m-0 p-0 h-100 w-100 d-flex justify-content-start"}
          >
            <Col xs="3" className={"m-0 px-1 py-0 d-flex align-items-center"}>
              <Skeleton
                height={"100"}
                width={"100"}
                containerClassName={"skeleton-def w-75 h-75"}
                circle
                count={1}
              />
            </Col>
            <Col className={"m-0 p-0  d-flex align-items-center"}>
              <Skeleton
                width={"100"}
                height={"100"}
                containerClassName={"skeleton-def w-75 h-25"}
                count={1}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

const AdminCard = ({ title, to, icon }) => {
  return (
    <Col key={`fhirAdmin${title}`} xs="12" lg="4" className={"m-0 p-0"}>
      <Card className={`cards-body mb-4 backgroundClients`} tag={Link} to={to}>
        <CardBody className="cursorPointer">
          <Row className={"m-0 p-0 d-flex align-items-center h-100"}>
            <Col xs="4" className="text-dark">
              {icon}
            </Col>
            <Col>
              <div className="cardTitle">{title}</div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default function FhirAdmin() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  function buildAdminDeck() {
    if (!loading) {
      return (
        <>
          <AdminCard
            title="Search Parameters"
            icon={<AiOutlineSearch size={50} />}
            to={`${constants.ROUTES.Admin.FhirAdmin}${constants.ROUTES.FhirAdmin.SearchParameterAdmin}`}
          />
          <AdminCard
            title="Value Sets"
            icon={<BsStack size={50} />}
            to={`${constants.ROUTES.Admin.FhirAdmin}${constants.ROUTES.FhirAdmin.ValueSetAdmin}`}
          />
          <AdminCard
            title="ICD10 Mappings"
            icon={<AiFillMedicineBox size={50} />}
            to={`${constants.ROUTES.Admin.FhirAdmin}${constants.ROUTES.FhirAdmin.ICD10MappingAdmin}`}
          />
          <AdminCard
            title="ICD10 Codes"
            icon={<FaNotesMedical size={50} />}
            to={`${constants.ROUTES.Admin.FhirAdmin}${constants.ROUTES.FhirAdmin.DiagnosisIcd10CodesAdmin}`}
          />
        </>
      );
    }
    return _.times(3, (num) => <SkeletonCard index={num} />);
  }

  return (
    <PageContainer>
      <Col className={"m-0 p-0"}>
        <Row
          className={
            "mx-0 my-3 d-flex justify-content-start align-items-center"
          }
        >
          <LongBreadCrumb context={"FHIR"} page={"Management"} />
        </Row>
        <Row className={"m-0 px-0 py-2 d-flex justify-content-evenly"}>
          <CardDeck className={"w-100"}>{buildAdminDeck()}</CardDeck>
        </Row>
      </Col>
    </PageContainer>
  );
}