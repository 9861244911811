import React, { useState, useEffect, useContext, Fragment } from "react";
import { api, constants, dateHelpers, helpers, UserContext } from "../utils";
import { Row, Col, Table } from "reactstrap";
import {
  StyledAsyncSelect,
  StyledModal,
  StyledSingleDatePicker,
  StyledSelect,
  StyledInput,
  StyledSection,
  WidgetCard,
  Loader,
  SelectionRow,
  Checkbox,
  PatientChartNotes,
  PatientChartGoalModals,
} from ".";
import { toast } from "react-toastify";
import { FiEdit, FiMinusCircle, FiArchive, FiTarget } from "react-icons/fi";
import classNames from "classnames";
import _ from "lodash";
import { BsExclamationCircle, BsClipboardCheck } from "react-icons/bs";

const EMPTY_FLAG = {
  id: "",
  code: null,
  category: null,
  subjectReference: "",
  status: null,
  author: "",
  period: null,
};

const EMPTY_GOAL = {
  id: "",
  subjectReference: "",
  category: null,
  description: null,
  priority: null,
  lifecycleStatus: null,
  startDate: null,
  targetMeasure: null,
  detailType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
  detail: null,
  notes: [],
  achievementStatus: null,
  outcome: null,
};

const EMPTY_TASK = {
  id: "",
  description: "",
  code: null,
  creationDate: null,
  status: null,
  intent: null,
  priority: null,
  deadline: null,
  performerReference: "",
  completedOn: null,
  owner: null,
  notes: [],
};

const NotificationPill = ({
  icon,
  notification,
  display,
  isEditing,
  onClick,
  onArchive,
  onDelete,
  onEdit,
}) => {
  return (
    <div
      className={classNames("notification-pill", {
        "notification-pill-archived": notification?.archived,
      })}
    >
      <div
        onClick={onClick}
        className={"notification-pill-message cursorPointer clickableRow"}
      >
        <div className="mr-3">{icon}</div>
        <div>{display}</div>
      </div>
      {isEditing && (
        <div className="px-3 notification-pill-menu">
          <FiEdit onClick={onEdit} className="clickable-icon" />
          {!notification.archived ? (
            <FiArchive onClick={onArchive} className="clickable-icon" />
          ) : null}
          <FiMinusCircle onClick={onDelete} className="clickable-icon red" />
        </div>
      )}
    </div>
  );
};

export default function PatientChartNotifications({
  clientId,
  participantId,
  subjectOptions,
  conditionOptions,
  observationOptions,
  serviceRequestOptions,
  refreshReferenceData,
  refreshAll,
}) {
  const userCtx = useContext(UserContext);
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [elementsShownList, setElementsShownList] = useState(
    constants.PATIENT_CHART_NOTIFICATION_OPTIONS
  );
  const [flagEditModalOpen, setFlagEditModalOpen] = useState(false);
  const [flagViewModalOpen, setFlagViewModalOpen] = useState(false);
  const [goalEditModalOpen, setGoalEditModalOpen] = useState(false);
  const [goalViewModalOpen, setGoalViewModalOpen] = useState(false);
  const [taskEditModalOpen, setTaskEditModalOpen] = useState(false);
  const [taskViewModalOpen, setTaskViewModalOpen] = useState(false);
  const [flags, setFlags] = useState([]);
  const [currentFlag, setCurrentFlag] = useState(Object.assign({}, EMPTY_FLAG));
  const [goals, setGoals] = useState([]);
  const [currentGoal, setCurrentGoal] = useState(Object.assign({}, EMPTY_GOAL));
  const [tasks, setTasks] = useState([]);
  const [currentTask, setCurrentTask] = useState(Object.assign({}, EMPTY_TASK));
  const [editingTaskNoteIndices, setEditingTaskNoteIndicies] = useState([]);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    refreshData();
  }, [showArchived, refreshAll]);

  function refreshData(flags = true, goals = true, tasks = true) {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      if (flags) {
        apiCalls.push(getFlagInfo());
      }
      if (goals) {
        apiCalls.push(getGoalInfo());
      }
      if (tasks) {
        apiCalls.push(getTaskInfo());
      }
      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));

          if (aggResults.flagInfo) {
            aggResults.flagInfo = _.map(aggResults.flagInfo, (flag) => {
              return {
                ...flag,
                status: _.find(
                  constants.FHIR_FLAG_STATUSES,
                  (x) => x.value === flag.status
                ),
                category: flag.category
                  ? {
                      ...flag.category,
                      value: flag.category?.code,
                      label: flag.category?.display,
                      description: flag.category?.text,
                    }
                  : null,
                subjectReference:
                  _.find(subjectOptions, (x) =>
                    _.includes(x.label, flag.subjectReference)
                  ) || subjectOptions?.length === 1
                    ? subjectOptions[0]
                    : null,
              };
            });
            setFlags(aggResults.flagInfo);
          }
          if (aggResults.goalInfo) {
            const opts = conditionOptions.concat(
              observationOptions,
              serviceRequestOptions,
              conditionOptions
            );
            aggResults.goalInfo = _.map(aggResults.goalInfo, (goal) => {
              return {
                ...goal,
                subjectReference:
                  _.find(subjectOptions, (x) =>
                    _.includes(x.label, goal.subjectReference)
                  ) || subjectOptions?.length === 1
                    ? subjectOptions[0]
                    : null,
                priority: goal.priority
                  ? {
                      ...goal.priority,
                      value: goal.priority?.code,
                      label: goal.priority?.display,
                      description: goal.priority?.text,
                    }
                  : null,
                category: goal.category
                  ? {
                      ...goal.category,
                      value: goal.category?.code,
                      label: goal.category?.display,
                      description: goal.category?.text,
                    }
                  : null,
                achievementStatus: goal.achievementStatus
                  ? {
                      ...goal.achievementStatus,
                      value: goal.achievementStatus?.code,
                      label: goal.achievementStatus?.display,
                      description: goal.achievementStatus?.text,
                    }
                  : null,
                lifecycleStatus: _.find(
                  constants.FHIR_GOAL_LIFECYCLE_STATUSES,
                  (x) => x.value === goal.lifecycleStatus
                ),
                detailType: goal.detailType
                  ? _.find(
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
                      (x) => x.value === goal.detailType
                    )
                  : constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
                addressesReference: _.find(opts, (x) =>
                  x.value.endsWith(goal?.addressesReference)
                ),
              };
            });
            setGoals(aggResults.goalInfo);
          }
          if (aggResults.taskInfo) {
            aggResults.taskInfo = _.map(aggResults.taskInfo, (task) => {
              return {
                ...task,
                code: task.code
                  ? {
                      ...task.code,
                      value: task.code.code,
                      label: task.code.display,
                      description: task.code.text,
                    }
                  : null,
                status: _.find(
                  constants.FHIR_TASK_STATUSES,
                  (x) => x.value === task.status
                ),
                intent: _.find(
                  constants.FHIR_TASK_INTENTS,
                  (x) => x.value === task.intent
                ),
                priority: _.find(
                  constants.FHIR_REQUEST_PRIORITIES,
                  (x) => x.value === task.priority
                ),
                performerReference: _.find(subjectOptions, (x) =>
                  _.includes(x.label, task.performerReference)
                ),
              };
            });
            setTasks(aggResults.taskInfo);
          }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function getFlagInfo() {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      includeArchived: showArchived,
    };
    return api
      .securePost("Participant/FlagInfo", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { flagInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function getGoalInfo() {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      includeArchived: showArchived,
    };
    return api
      .securePost("Participant/GoalInfo", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { goalInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function getTaskInfo() {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      includeArchived: showArchived,
    };
    return api
      .securePost("Participant/TaskInfo", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { taskInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function flagValidated() {
    if (!currentFlag) {
      toast.error("No Flag found. Please try again");
      return false;
    }
    if (!currentFlag.code || currentFlag.code.length <= 0) {
      toast.warning("Message is required");
      return false;
    }
    if (!currentFlag.status) {
      toast.warning("Status is required");
      return false;
    }
    if (!currentFlag.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    return true;
  }

  function taskValidated() {
    if (!currentTask) {
      toast.error("No Task found. Please try again");
      return false;
    }
    if (!currentTask.description || currentTask.description.length <= 0) {
      toast.warning("Description is required");
      return false;
    }
    if (!currentTask.status) {
      toast.warning("Status is required");
      return false;
    }
    if (!currentTask.intent) {
      toast.warning("Intent is required");
      return false;
    }
    if (!currentTask.priority) {
      toast.warning("Priority is required");
      return false;
    }
    if (!currentTask.performerReference) {
      toast.warning("Assignee is required");
      return false;
    }
    return true;
  }

  function reset() {
    setIsEditing(false);
    setIsDeleting(false);
    setIsArchiving(false);
    setFlagEditModalOpen(false);
    setFlagViewModalOpen(false);
    setTaskEditModalOpen(false);
    setTaskViewModalOpen(false);
    setGoalEditModalOpen(false);
    setGoalViewModalOpen(false);
    setCurrentFlag(JSON.parse(JSON.stringify(EMPTY_FLAG)));
    setCurrentTask(JSON.parse(JSON.stringify(EMPTY_TASK)));
    setCurrentGoal(JSON.parse(JSON.stringify(EMPTY_GOAL)));
    setEditingTaskNoteIndicies([]);
  }

  function updateFlag(payloadChange, action) {
    setLoading(true);
    const payload = {
      ...currentFlag,
      clientId: clientId,
      subjectReference:
        currentFlag?.subjectReference?.value || _.head(subjectOptions).value,
      status: currentFlag?.status?.value,
      ...payloadChange,
    };
    api
      .securePost("Participant/UpdateFlag", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "Flag",
            "Flag",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData(true, false, false);
          toast.success(`Flag successfully ${action}`);
        } else {
          toast.error(`Flag could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Flag could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveFlag() {
    if (!flagValidated()) return;
    updateFlag({}, "saved");
  }

  function deleteFlag() {
    updateFlag(
      {
        status: _.find(constants.FHIR_FLAG_STATUSES, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function archiveFlag() {
    updateFlag(
      {
        status: _.find(constants.FHIR_FLAG_STATUSES, { label: "Inactive" })
          ?.value,
      },
      "archived"
    );
  }

  function updateTask(payloadChange, action) {
    setLoading(true);
    const payload = {
      ...currentTask,
      clientId: clientId,
      performerReference:
        currentTask?.performerReference?.value || _.head(subjectOptions).value,
      status: currentTask?.status?.value,
      intent: currentTask?.intent?.value,
      priority: currentTask?.priority?.value,
      ...payloadChange,
    };
    api
      .securePost("Participant/UpdateTask", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "Task",
            "Task",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData(false, false, true);
          refreshReferenceData();
          toast.success(`Task successfully ${action}`);
        } else {
          toast.error(`Task could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Task could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveTask() {
    if (!taskValidated()) return;
    if (currentTask.note) {
      let currentTaskTemp = Object.assign({}, currentTask);
      currentTaskTemp.notes.push(currentTaskTemp.note);
      currentTaskTemp.note = { text: "" };
      setCurrentTask(currentTaskTemp);
    }
    updateTask({}, "saved");
  }

  function deleteTask() {
    updateTask(
      {
        status: _.find(constants.FHIR_TASK_STATUSES, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function archiveTask() {
    updateTask(
      {
        status: _.find(constants.FHIR_TASK_STATUSES, { label: "Completed" })
          ?.value,
      },
      "archived"
    );
  }

  function onChangeFlag(field, value) {
    let tempFlag = Object.assign({}, currentFlag);
    tempFlag[field] = value;
    setCurrentFlag(tempFlag);
  }

  function onChangeTask(field, value) {
    let tempTask = Object.assign({}, currentTask);
    tempTask[field] = value;
    setCurrentTask(tempTask);
  }

  return (
    <>
      <WidgetCard
        title="Notifications"
        startOpen
        isScrollable
        canCreateNew={!isReadonly}
        canEdit={!isReadonly}
        onEdit={() => setIsEditing(!isEditing)}
        createNewOptions={constants.PATIENT_CHART_NOTIFICATION_OPTIONS}
        onCreateNew={(selection) => {
          setIsEditing(false);
          switch (selection?.label) {
            case constants.PATIENT_CHART_NOTIFICATION_TYPES.FLAG:
              if (
                !currentFlag.subjectReference &&
                subjectOptions?.length === 1
              ) {
                let tempFlag = {
                  ...currentFlag,
                  subjectReference: subjectOptions[0],
                };
                setCurrentFlag(tempFlag);
              }
              setFlagEditModalOpen(true);
              break;
            case constants.PATIENT_CHART_NOTIFICATION_TYPES.ACTIVITY:
              setActivityEditModalOpen(true);
              break;
            case constants.PATIENT_CHART_NOTIFICATION_TYPES.GOAL:
              if (
                !currentGoal.subjectReference &&
                subjectOptions?.length === 1
              ) {
                let tempGoal = {
                  ...currentGoal,
                  subjectReference: subjectOptions[0],
                };
                setCurrentGoal(tempGoal);
              }
              setGoalEditModalOpen(true);
              break;
            case constants.PATIENT_CHART_NOTIFICATION_TYPES.TASK:
              setTaskEditModalOpen(true);
              break;
          }
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <div>
              <SelectionRow
                list={constants.PATIENT_CHART_NOTIFICATION_OPTIONS}
                setList={setElementsShownList}
              />
              <div className="flex mt-2" style={{ justifyContent: "flex-end" }}>
                <Checkbox
                  onChange={() => setShowArchived(!showArchived)}
                  selected={showArchived}
                  label="show archived"
                />
              </div>
            </div>

            <div className="mt-4">
              {_.find(
                elementsShownList,
                (x) =>
                  x.label === constants.PATIENT_CHART_NOTIFICATION_TYPES.FLAG
              )?.selected &&
                _.map(flags, (x, idx) => (
                  <NotificationPill
                    key={`flag-pill-${idx}`}
                    icon={<BsExclamationCircle size={30} />}
                    notification={x}
                    display={x?.code}
                    isEditing={isEditing}
                    onClick={() => {
                      setFlagViewModalOpen(true);
                      setCurrentFlag(x);
                    }}
                    onEdit={() => {
                      setFlagEditModalOpen(true);
                      setCurrentFlag(x);
                    }}
                    onArchive={() => {
                      setIsArchiving(true);
                      setCurrentFlag(x);
                      setFlagViewModalOpen(true);
                    }}
                    onDelete={() => {
                      setIsDeleting(true);
                      setCurrentFlag(x);
                      setFlagViewModalOpen(true);
                    }}
                  />
                ))}
            </div>
            <div>
              {_.find(
                elementsShownList,
                (x) =>
                  x.label === constants.PATIENT_CHART_NOTIFICATION_TYPES.GOAL
              )?.selected &&
                _.map(goals, (x, idx) => (
                  <NotificationPill
                    key={`goal-pill-${idx}`}
                    icon={<FiTarget size={30} />}
                    notification={x}
                    display={x?.description}
                    isEditing={isEditing}
                    onClick={() => {
                      setGoalViewModalOpen(true);
                      setCurrentGoal(x);
                    }}
                    onArchive={() => {
                      setIsArchiving(true);
                      setGoalViewModalOpen(true);
                      setCurrentGoal(x);
                    }}
                    onEdit={() => {
                      setGoalEditModalOpen(true);
                      setCurrentGoal(x);
                    }}
                    onDelete={() => {
                      setIsDeleting(true);
                      setCurrentGoal(x);
                      setGoalViewModalOpen(true);
                    }}
                  />
                ))}
            </div>
            <div>
              {_.find(
                elementsShownList,
                (x) =>
                  x.label === constants.PATIENT_CHART_NOTIFICATION_TYPES.TASK
              )?.selected &&
                _.map(tasks, (x, idx) => (
                  <NotificationPill
                    icon={<BsClipboardCheck size={30} />}
                    key={`task-pill-${idx}`}
                    notification={x}
                    display={x?.description}
                    isEditing={isEditing}
                    onClick={() => {
                      setTaskViewModalOpen(true);
                      setCurrentTask(x);
                    }}
                    onArchive={() => {
                      setIsArchiving(true);
                      setTaskViewModalOpen(true);
                      setCurrentTask(x);
                    }}
                    onEdit={() => {
                      setTaskEditModalOpen(true);
                      setCurrentTask(x);
                    }}
                    onDelete={() => {
                      setIsDeleting(true);
                      setCurrentTask(x);
                      setTaskViewModalOpen(true);
                    }}
                  />
                ))}
            </div>
          </>
        )}
      </WidgetCard>

      {/* Flag View Only Modal */}
      <StyledModal
        show={flagViewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        onHide={reset}
        size="xl"
        title={
          isDeleting
            ? `Delete Flag?`
            : isArchiving
            ? `Archive Flag?`
            : `Flag Display`
        }
        onSave={isDeleting ? deleteFlag : isArchiving ? archiveFlag : reset}
        savePrompt={
          isDeleting ? "Delete Flag" : isArchiving ? "Archive Flag" : "Close"
        }
        disabled={loading}
      >
        <StyledSection title="Info">
          {currentFlag?.archived ? (
            <div className="mb-3 red center-text">
              ARCHIVED ({currentFlag?.status?.label})
            </div>
          ) : null}
          <div className="mb-1">
            <b>Message:&nbsp;</b>
            {currentFlag?.code}
          </div>
          <div className="mb-1">
            <b>Category:&nbsp;</b>
            {currentFlag?.category?.label}
          </div>
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentFlag?.subjectReference?.label}
          </div>
          <div className="mb-1">
            <b>Status:&nbsp;</b>
            {currentFlag?.status?.label}
          </div>
          <div className="mb-1">
            <b>Period Start:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentFlag?.period?.start,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Period End:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentFlag?.period?.end,
              dateHelpers.YMDHMS
            )}
          </div>
        </StyledSection>
      </StyledModal>

      {/* Flag Edit Modal */}
      <StyledModal
        show={flagEditModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={
          isEditing
            ? `Update Flag ${
                currentFlag?.archived
                  ? `ARCHIVED (${currentFlag?.status?.label})`
                  : ""
              }`
            : "Create Flag"
        }
        onSave={saveFlag}
        savePrompt={"Save Flag"}
        showRequiredFieldsMessage={true}
        disabled={loading}
      >
        <StyledSection title="Info">
          <Row className="mb-4">
            <Col xs="6">
              <StyledInput
                id="code"
                name="code"
                label="Message"
                maxLength="350"
                value={currentFlag?.code}
                onChange={(e) => onChangeFlag("code", e.target.value)}
                required
              />
            </Col>
            <Col>
              <StyledAsyncSelect
                label="Category"
                isClearable
                required
                placeholder="Category Lookup"
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.FLAG_CATEGORIES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.FLAG_CATEGORIES,
                    false
                  )
                }
                onChange={(e) => onChangeFlag("category", e)}
                value={currentFlag?.category}
                idName="flagCategory"
                description={currentFlag?.category?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="status"
                label="Status"
                required
                onChange={(e) => onChangeFlag("status", e)}
                value={currentFlag?.status}
                options={constants.FHIR_FLAG_STATUSES}
                isClearable
              />
            </Col>
            <Col>
              <StyledSelect
                label="Subject"
                required
                isClearable
                options={subjectOptions}
                name="subject"
                id="subject"
                value={currentFlag?.subjectReference || ""}
                onChange={(selection) =>
                  onChangeFlag("subjectReference", selection)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSingleDatePicker
                label="Period Start"
                name="Period Start"
                id="PeriodStart"
                selected={currentFlag?.period?.start}
                onChangeCallback={(e) =>
                  onChangeFlag("period", { ...currentFlag?.period, start: e })
                }
                maxDate={currentFlag?.period?.end}
              />
            </Col>
            <Col>
              <StyledSingleDatePicker
                label="Period End"
                name="Period End"
                id="PeriodEnd"
                selected={currentFlag?.period?.end}
                onChangeCallback={(e) =>
                  onChangeFlag("period", { ...currentFlag?.period, end: e })
                }
                minDate={currentFlag?.period?.start}
              />
            </Col>
          </Row>
        </StyledSection>
      </StyledModal>

      {/* Goal Modals - separated b/c Care Plan can create a goal */}
      <PatientChartGoalModals
        conditionOptions={conditionOptions}
        observationOptions={observationOptions}
        subjectOptions={subjectOptions}
        serviceRequestOptions={serviceRequestOptions}
        goalEditModalOpen={goalEditModalOpen}
        goalViewModalOpen={goalViewModalOpen}
        reset={reset}
        isEditing={isEditing}
        isDeleting={isDeleting}
        isArchiving={isArchiving}
        loading={loading}
        setLoading={setLoading}
        currentGoal={currentGoal}
        setCurrentGoal={setCurrentGoal}
        refreshData={() => refreshData(false, true, false)}
        refreshReferenceData={refreshReferenceData}
        clientId={clientId}
      />

      {/* Task View Only Modal */}
      <StyledModal
        show={taskViewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        onHide={reset}
        size="xl"
        title={
          isDeleting
            ? `Delete Task?`
            : isArchiving
            ? `Archive Task`
            : `Task Display`
        }
        onSave={
          isDeleting
            ? deleteTask
            : isArchiving
            ? archiveTask
            : () => {
                setTaskViewModalOpen(false);
                setCurrentTask(JSON.parse(JSON.stringify(EMPTY_TASK)));
              }
        }
        savePrompt={
          isDeleting ? "Delete Task" : isArchiving ? "Archive Task" : "Close"
        }
        disabled={loading}
      >
        <StyledSection title="Info">
          {currentTask?.archived ? (
            <div className="mb-3 red center-text">
              ARCHIVED ({currentTask?.status?.label})
            </div>
          ) : null}
          <div className="mb-1">
            <b>Description:&nbsp;</b>
            {currentTask?.description}
          </div>
          <div className="mb-1">
            <b>Type:&nbsp;</b>
            {currentTask?.code?.label}
          </div>
          <div className="mb-1">
            <b>Creation Date:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentTask?.creationDate,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Last Modified:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentTask?.lastModified,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Status:&nbsp;</b>
            {currentTask?.status?.label}
          </div>
          <div className="mb-1">
            <b>Intent:&nbsp;</b>
            {currentTask?.intent?.label}
          </div>
          <div className="mb-1">
            <b>Priority:&nbsp;</b>
            {currentTask?.priority?.label}
          </div>
          <div className="mb-1">
            <b>Deadline:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentTask?.deadline?.end,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Completed On:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentTask?.completedOn,
              dateHelpers.YMDHMS
            )}
          </div>
        </StyledSection>
        <StyledSection title="About">
          <div className="mb-1">
            <b>Assignee:&nbsp;</b>
            {currentTask?.performerReference?.label}
          </div>
          <div className="mb-1">
            <b>Owner:&nbsp;</b>
            {currentTask?.owner?.firstName} {currentTask?.owner?.lastName},{" "}
            {currentTask?.owner?.username}
          </div>
        </StyledSection>
        <StyledSection title="Notes">
          {_.map(currentTask?.notes, (note, i) => (
            <Fragment key={`n-${i}`}>
              <div key={`note${i}`} className="mb-1">
                {note?.text}
              </div>
              <hr />
            </Fragment>
          ))}
        </StyledSection>
      </StyledModal>

      {/* Task Edit Modal */}
      <StyledModal
        show={taskEditModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={
          isEditing
            ? `Update Task ${
                currentTask?.archived
                  ? `ARCHIVED (${currentTask?.status?.label})`
                  : ""
              }`
            : "Create Task"
        }
        onSave={saveTask}
        savePrompt="Save Task"
        showRequiredFieldsMessage
        disabled={loading}
      >
        <StyledSection title="Info">
          <Row className="mb-4">
            <Col>
              <StyledInput
                id="description"
                name="Description"
                label="Description"
                required
                maxLength="2000"
                value={currentTask?.description}
                onChange={(e) => onChangeTask("description", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledAsyncSelect
                id="code"
                name="code"
                isClearable
                label="Type"
                onChange={(e) => onChangeTask("code", e)}
                value={currentTask?.code}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.TASK_TYPE,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.TASK_TYPE,
                    false
                  )
                }
                idName="taskType"
                description={currentTask?.code?.description}
              />
            </Col>
            <Col>
              <StyledSingleDatePicker
                label="Creation Date"
                name="Creation Date"
                id="creationDate"
                selected={currentTask?.creationDate}
                onChangeCallback={(e) => onChangeTask("creationDate", e)}
                maxDate={new Date()}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="status"
                label="Status"
                required
                onChange={(e) => onChangeTask("status", e)}
                value={currentTask?.status}
                options={constants.FHIR_TASK_STATUSES}
                isClearable
              />
            </Col>
            <Col>
              <StyledSelect
                id="intent"
                label="Intent"
                required
                onChange={(e) => onChangeTask("intent", e)}
                value={currentTask?.intent}
                options={constants.FHIR_TASK_INTENTS}
                isClearable
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="priority"
                label="Priority"
                onChange={(e) => onChangeTask("priority", e)}
                value={currentTask?.priority}
                options={constants.FHIR_REQUEST_PRIORITIES}
                isClearable
                required
              />
            </Col>
            <Col>
              <StyledSingleDatePicker
                label="Deadline"
                name="Deadline"
                id="deadline"
                selected={currentTask?.deadline?.end}
                onChangeCallback={(e) =>
                  onChangeTask("deadline", { ...currentTask?.deadline, end: e })
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSingleDatePicker
                label="Completed On"
                name="completedOn"
                id="completedOn"
                selected={currentTask?.completedOn}
                maxDate={new Date()}
                onChangeCallback={(e) => onChangeTask("completedOn", e)}
              />
            </Col>
          </Row>
        </StyledSection>

        <StyledSection title="About">
          <Row className="mb-4">
            <Col>
              <StyledSelect
                label="Assignee"
                required
                isClearable
                options={subjectOptions}
                name="performer"
                id="performer"
                value={currentTask?.performerReference || ""}
                onChange={(selection) =>
                  onChangeTask("performerReference", selection)
                }
              />
            </Col>
          </Row>
        </StyledSection>
        <PatientChartNotes
          obj={currentTask}
          setObj={setCurrentTask}
          editingNoteIndices={editingTaskNoteIndices}
          setEditingNoteIndicies={setEditingTaskNoteIndicies}
          onChange={onChangeTask}
        />
      </StyledModal>
    </>
  );
}