import { useEffect, useState, useContext, Fragment } from "react";
import { api, helpers, constants, dateHelpers, UserContext } from "../utils";
import { Row, Col } from "reactstrap";
import {
  WidgetCard,
  StyledModal,
  StyledSelect,
  StyledSingleDatePicker,
  StyledInput,
  StyledSection,
  Loader,
  StyledAsyncSelect,
  FhirChoiceVariable,
  PatientChartNotes,
} from ".";
import _ from "lodash";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { FaPrescription } from "react-icons/fa";

const EMPTY_MEDICATION = {
  id: "",
  clientId: 0,
  status: null,
  statusReason: null,
  category: null,
  subjectReference: null,
  medication: null,
  reasonReference: null,
  reasonCode: "",
  dateAsserted: null,
  informationSourceReference: null,
  effective: null,
  effectiveType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD,
  dose: null,
  doseType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY,
  dosageText: null,
  route: "",
  asNeeded: null,
  frequency: null,
  period: null,
  periodUnit: null,
  when: [],
  notes: [],
};

export default function PatientChartMedications({
  clientId,
  participantId,
  subjectOptions,
  conditionOptions,
  observationOptions,
}) {
  const userCtx = useContext(UserContext);
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [loading, setLoading] = useState(false);
  const [medications, setMedications] = useState([]);
  const [currentMedication, setCurrentMedication] = useState(
    JSON.parse(JSON.stringify(EMPTY_MEDICATION))
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingNoteIndices, setEditingNoteIndices] = useState([]);

  useEffect(refreshData, []);

  function refreshData() {
    if (loading) return;
    setLoading(true);
    let apiCalls = [];
    apiCalls.push(getMedicationInfo());
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.medicationInfo) {
          const opts = conditionOptions.concat(observationOptions);
          aggResults.medicationInfo = _.map(
            aggResults.medicationInfo,
            (medication) => {
              const referenceList = _.map(
                medication.reasonReference,
                (rr, i) => {
                  return (
                    _.find(
                      opts,
                      (r) =>
                        r.value === "Observation/" + rr ||
                        r.value === "Condition/" + rr
                    ) || { label: "", value: "" }
                  );
                }
              );
              return {
                ...medication,
                effectiveAge: medication.effective?.age,
                effectiveDateTime: medication.effective?.dateTime,
                effectivePeriod: medication.effective?.period
                  ? {
                      start: medication.effective.period.start,
                      end: medication.effective.period.end,
                    }
                  : null,
                effectiveRange: medication.effective?.range
                  ? {
                      high: medication.effective.range.high,
                      low: medication.effective.range.low,
                    }
                  : null,
                effectiveString: medication.effective?.string,
                // effectiveType: medication.effective?.type,
                status: _.find(
                  constants.FHIR_MEDICATION_STATEMENT_STATUS_CODES,
                  (x) => x.value === medication.status
                ),
                category: {
                  ...medication.category,
                  value: medication.category?.code,
                  label: medication.category?.display,
                  description: medication.category?.text,
                },
                subjectReference:
                  _.find(subjectOptions, (x) =>
                    _.includes(x.label, medication.subjectReference)
                  ) || subjectOptions?.length === 1
                    ? subjectOptions[0]
                    : null,
                reasonReference: referenceList,
                informationSourceReference:
                  _.find(subjectOptions, (x) =>
                    _.includes(x.label, medication.informationSourceReference)
                  ) || subjectOptions?.length === 1
                    ? subjectOptions[0]
                    : null,
                effectiveType: medication.effectiveType
                  ? _.find(
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
                      (x) => x.value === medication.effectiveType
                    )
                  : constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD,
                doseType: medication.doseType
                  ? _.find(
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
                      (x) => x.value === medication.doseType
                    )
                  : constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY,
                asNeeded:
                  medication.asNeeded != null &&
                  medication.asNeeded != undefined
                    ? _.find(
                        constants.BOOLEAN_OPTIONS,
                        (x) => x.value === medication.asNeeded
                      )
                    : null,
                type: _.find(
                  constants.MEDICATION_TYPES,
                  (x) => x.value === medication.type
                ),
                periodUnit: _.find(
                  constants.FHIR_UNITS_OF_TIME,
                  (x) => x.value === medication.periodUnit
                ),
                when: _.map(medication?.when, (x) =>
                  _.find(constants.FHIR_EVENT_TIMING, (y) => y.value == x)
                ),
              };
            }
          );
          setMedications(aggResults.medicationInfo);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function getMedicationInfo() {
    const payload = {
      clientId: clientId,
      status: currentMedication?.status?.value,
      clientIdentifierId: participantId,
    };
    return api
      .securePost("Participant/MedicationInfo", payload)
      .then((response) => {
        if (response && response.data) {
          return { medicationInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function validated() {
    if (!currentMedication) {
      toast.error("No Medication found. Please try again");
      return false;
    }
    if (!currentMedication.medication) {
      toast.warning("Medication Name is required");
      return false;
    }
    if (!currentMedication.status) {
      toast.warning("Status is required");
      return false;
    }
    if (!currentMedication.category) {
      toast.warning("Category is required");
      return false;
    }
    if (!currentMedication.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    if (
      currentMedication.doseQuantity &&
      currentMedication.doseType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY.value &&
      !currentMedication.doseQuantity.units &&
      currentMedication.doseQuantity.value !== null &&
      !isNaN(currentMedication.doseQuantity.value)
    ) {
      toast.warning("Units are required with Dose Quantity");
      return false;
    }
    if (
      currentMedication.doseThype?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentMedication.doseRange.units &&
      currentMedication.doseRange.low !== null &&
      !isNaN(currentMedication.doseRange.low)
    ) {
      toast.warning("Units are required with Dose Range");
      return false;
    }
    if (
      currentMedication.doseType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentMedication.doseRange.units &&
      currentMedication.doseRange.high !== null &&
      !isNaN(currentMedication.doseRange.high)
    ) {
      toast.warning("Units are required with Dose Range");
      return false;
    }
    if (
      currentMedication.frequency != null &&
      !isNaN(currentMedication.frequency) &&
      (currentMedication.frequency < 1 ||
        !Number.isInteger(currentMedication.frequency))
    ) {
      toast.warning("Frequency must be a positive integer");
      return false;
    }
    if (
      currentMedication.period != null &&
      !isNaN(currentMedication.period) &&
      currentMedication.period < 0
    ) {
      toast.warning("Period must be a positive number");
      return false;
    }
    if (
      currentMedication.period != null &&
      !isNaN(currentMedication.period) &&
      currentMedication.periodUnit == null
    ) {
      toast.warning("Period Units are required if Period is provided");
      return false;
    }
    return true;
  }

  function reset() {
    setEditModalOpen(false);
    setViewModalOpen(false);
    setIsEditing(false);
    setIsDeleting(false);
    setEditingNoteIndices([]);
    setCurrentMedication(JSON.parse(JSON.stringify(EMPTY_MEDICATION)));
  }

  function updateMedication(payloadChange, action) {
    setLoading(true);

    let payload = {
      ...currentMedication,
      clientId: clientId,
      subjectReference:
        currentMedication?.subjectReference?.value ||
        _.head(subjectOptions).value,
      status: currentMedication?.status.value,
      reasonReference: _.map(currentMedication.reasonReference, (x) => x.value),
      informationSourceReference:
        currentMedication?.informationSourceReference?.value,
      type: currentMedication?.type?.value,
      asNeeded: currentMedication?.asNeeded?.value,
      periodUnit: currentMedication?.periodUnit?.value,
      when: _.map(currentMedication?.when, (x) => x.value),
      ...payloadChange,
    };

    helpers.addChoiceVariable(
      payload,
      "effective",
      currentMedication.effectiveType,
      currentMedication.effective
    );
    helpers.addChoiceVariable(
      payload,
      "dose",
      currentMedication.doseType,
      currentMedication.dose
    );
    api
      .securePost("Participant/UpdateMedication", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "MedicationStatement",
            "Medication",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData();
          toast.success(`Medication successfully ${action}`);
        } else {
          toast.error(`Medication could not be ${action}`);
        }
      })
      .catch(() => toast.error(`Medication could not be ${action}`))
      .finally(() => setLoading(false));
  }

  function saveMedication() {
    if (currentMedication.note) {
      let currentMedicationTemp = Object.assign({}, currentMedication);
      currentMedicationTemp.notes.push(currentMedicationTemp.note);
      currentMedicationTemp.note = { text: "" };
      setCurrentMedication(currentMedicationTemp);
    }
    if (!validated()) return;
    updateMedication({}, "saved");
  }

  function deleteMedication() {
    updateMedication(
      {
        status: _.find(constants.FHIR_MEDICATION_STATEMENT_STATUS_CODES, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function onChangeMedication(field, value) {
    let tempMedication = Object.assign({}, currentMedication);
    tempMedication[field] = value;
    setCurrentMedication(tempMedication);
  }

  function getChioceVariableValue(field) {
    if (!field) return "";
    if (
      field.type === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD.value
    ) {
      return `${dateHelpers.toMDYDateString(
        field.period?.start,
        dateHelpers.YMDHMS
      )}-${dateHelpers.toMDYDateString(field.period?.end, dateHelpers.YMDHMS)}`;
    } else if (
      field.type === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME.value
    ) {
      return `${dateHelpers.toMDYDateString(
        field.dateTime,
        dateHelpers.YMDHMS
      )}`;
    } else if (
      field.type === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value
    ) {
      return `${field.range?.low || ""}-${field.range?.high || ""}`;
    }
    return "";
  }

  function getMedicationEffective(medication) {
    if (!medication || !medication.effective) return "";
    return getChioceVariableValue(medication.effective);
  }

  /* choice variable display */
  //  function getMedicationEffective(medication) {
  //   if(medication && medication.effective) {
  //      if(medication.effectiveType?.value === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD.value) {
  //        if(medication.effective.start && medication.effective.end) {
  //          return `${dateHelpers.toMDYDateString(medication.effective.start, dateHelpers.YMDHMS)}-${dateHelpers.toMDYDateString(medication.effective.end, dateHelpers.YMDHMS)}`;
  //        } else if(medication.effective.start) {
  //         return `${dateHelpers.toMDYDateString(medication.effective.start, dateHelpers.YMDHMS)}- Current`;
  //        } else {
  //         return `N/A-${dateHelpers.toMDYDateString(medication.effective.end, dateHelpers.YMDHMS)}`;
  //        }
  //     } else if(medication.effectiveType?.value === constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME.value) {
  //       return `${dateHelpers.toMDYDateString(medication.effective, dateHelpers.YMDHMS)} - Current`;
  //     } else {
  //       return medication.effective;
  //     }
  //   }
  // }

  /* choice variable dosage dose */
  function getMedicationDose(medication) {
    if (medication && medication.dose) {
      if (
        medication.doseType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value
      ) {
        return `${medication.dose?.low ?? ""}-${medication.dose?.high ?? ""} ${
          medication.dose?.units
        }`;
      } else if (
        medication.doseType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY.value
      ) {
        return `${medication.dose?.quantity?.value} ${medication.dose?.quantity?.units}`;
      } else {
        return medication.dose;
      }
    }
  }

  return (
    <>
      <WidgetCard
        title="Medications"
        startOpen
        canCreateNew={!isReadonly}
        canEdit={!isReadonly}
        isScrollable
        onCreateNew={() => {
          if (
            !currentMedication.subjectReference &&
            subjectOptions?.length === 1
          ) {
            let tempMedication = {
              ...currentMedication,
              subjectReference: subjectOptions[0],
              informationSourceReference: subjectOptions[0],
            };
            setCurrentMedication(tempMedication);
          }
          setIsEditing(false);
          setEditModalOpen(true);
        }}
        onEdit={() => setIsEditing(!isEditing)}
      >
        {loading ? (
          <Loader />
        ) : (
          <Row className="p-3 text-left">
            <Col>
              {_.chain(medications)
                .map((medication, idx) => (
                  <div
                    key={`medications${idx}`}
                    className={classNames("mb-3", {
                      cursorPointer: !isEditing,
                      clickableRow: !isEditing,
                    })}
                    onClick={() => {
                      if (!isEditing) {
                        setCurrentMedication(medication);
                        setViewModalOpen(true);
                      }
                    }}
                  >
                    <div>
                      {isEditing ? (
                        <>
                          <>
                            <FiEdit
                              className="clickable-icon mr-1"
                              onClick={() => {
                                setEditModalOpen(true);
                                setCurrentMedication(medication);
                              }}
                            />
                            <FiMinusCircle
                              className="clickable-icon red mr-2"
                              onClick={() => {
                                setCurrentMedication(medication);
                                setIsDeleting(true);
                                setViewModalOpen(true);
                              }}
                            />
                          </>
                        </>
                      ) : null}

                      <span className="mr-2">
                        {medication.type?.icon ?? (
                          <FaPrescription size={20} color={"#41ADA3"} />
                        )}
                      </span>
                      {medication.medication}
                      {medication.effective ? (
                        <div
                          className="small text-muted"
                          style={{ float: "right" }}
                        >
                          {getMedicationEffective(medication)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
                .value()}
            </Col>
          </Row>
        )}
      </WidgetCard>

      {/* Medication View Only Modal */}
      <StyledModal
        show={viewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        onHide={reset}
        size="xl"
        title={isDeleting ? "Delete Medication?" : "Medication Display"}
        onSave={isDeleting ? deleteMedication : reset}
        savePrompt={isDeleting ? "Delete Medication" : "Close"}
        disabled={loading}
      >
        <StyledSection title="Info">
          <div className="mb-1">
            <b>Status:&nbsp;</b>
            {currentMedication?.status?.label}
          </div>
          <div className="mb-1">
            <b>Status Reason:&nbsp;</b>
            {currentMedication?.statusReason}
          </div>
          <div className="mb-1">
            <b>Category:&nbsp;</b>
            {currentMedication?.category?.label}
          </div>
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentMedication?.subjectReference?.label}
          </div>
        </StyledSection>
        <StyledSection title="About">
          <div className="mb-1">
            <b>Medication Name:&nbsp;</b>
            {currentMedication?.medication}
          </div>
          <div className="mb-1">
            <b>Reason Reference(s):&nbsp;</b>
            <ul className="ml-5">
              {currentMedication?.reasonReference
                ? _.map(currentMedication.reasonReference, (rr, i) => (
                    <li key={`reasonReference${i}`}>{rr.label}</li>
                  ))
                : null}
            </ul>
          </div>
          <div className="mb-1">
            <b>Reason:&nbsp;</b>
            {currentMedication?.reasonCode}
          </div>
          <div className="mb-1">
            <b>Date Asserted:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentMedication?.dateAsserted,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Information Source:&nbsp;</b>
            {currentMedication?.informationSourceReference?.label}
          </div>
          <div className="mb-1">
            <b>Effective:</b> {getMedicationEffective(currentMedication)}
          </div>
        </StyledSection>
        <StyledSection title="Dosage">
          <div className="mb-1">
            <b>Dose:&nbsp;</b>
            {getMedicationDose(currentMedication)}
          </div>
          <div className="mb-1">
            <b>Route of entry into body:&nbsp;</b>
            {currentMedication?.route}
          </div>
          <div className="mb-1">
            <b>Frequency:&nbsp;</b>
            {currentMedication?.frequency}
          </div>
          <div className="mb-1">
            <b>Period:&nbsp;</b>
            {currentMedication?.period}
          </div>
          <div className="mb-1">
            <b>Period Unit:&nbsp;</b>
            {currentMedication?.periodUnit?.label}
          </div>
          <div className="mb-1">
            <b>When:&nbsp;</b>
            <ul className="ml-5">
              {currentMedication?.when &&
                _.map(currentMedication.when, (x, i) => (
                  <li key={`reasonReference${i}`}>{x?.label}</li>
                ))}
            </ul>
          </div>
          <div className="mb-1">
            <b>Take as needed:&nbsp;</b>
            {helpers.convertBoolToYesOrNo(currentMedication?.asNeeded?.value)}
          </div>
          <div className="mb-1">
            <b>Dosage Instructions:&nbsp;</b>
            {currentMedication?.dosageText}
          </div>
        </StyledSection>
        <StyledSection title="Notes">
          {_.map(currentMedication?.notes, (note, i) => {
            return (
              <Fragment key={`note${i}`}>
                <div className="mb-1">{note.text}</div>
                <hr />
              </Fragment>
            );
          })}
        </StyledSection>
      </StyledModal>

      {/* Medication Edit Modal */}
      <StyledModal
        show={editModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={isEditing ? "Update Medication" : "Create Medication"}
        onSave={saveMedication}
        savePrompt={"Save Medication"}
        showRequiredFieldsMessage={true}
        disabled={loading}
      >
        <StyledSection title="Info">
          <Row className="mb-4">
            <Col>
              <StyledInput
                id="medication"
                name="medication"
                label="Medication Name"
                maxLength="350"
                value={currentMedication?.medication}
                onChange={(e) =>
                  onChangeMedication("medication", e.target.value)
                }
                required
              />
            </Col>
            <Col xs="6">
              <StyledSelect
                label="Type"
                options={constants.MEDICATION_TYPES}
                name="type"
                id="type"
                isClearable
                value={currentMedication?.type || ""}
                onChange={(selection) => onChangeMedication("type", selection)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <StyledSelect
                id="status"
                label="Status"
                required
                onChange={(e) => onChangeMedication("status", e)}
                value={currentMedication?.status}
                options={constants.FHIR_MEDICATION_STATEMENT_STATUS_CODES}
                isClearable
              />
            </Col>
            <Col xs="6">
              <StyledInput
                id="statusReason"
                name="statusReason"
                label="Status Reason"
                maxLength="200"
                value={currentMedication?.statusReason}
                onChange={(e) =>
                  onChangeMedication("statusReason", e.target.value)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledAsyncSelect
                label="Category"
                isClearable
                required
                placeholder="Category Lookup"
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS
                      .MEDICATION_USAGE_CATEGORIES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.MEDICATION_USAGE_CATEGORIES,
                    false
                  )
                }
                onChange={(e) => onChangeMedication("category", e)}
                value={currentMedication?.category}
                idName="medicationCategory"
                description={currentMedication?.category?.description}
              />
            </Col>
            <Col>
              <StyledSelect
                label="Subject"
                required
                isClearable
                options={subjectOptions}
                name="subject"
                id="subject"
                value={currentMedication?.subjectReference || ""}
                onChange={(selection) =>
                  onChangeMedication("subjectReference", selection)
                }
              />
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="About">
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="reasonReference"
                isMulti
                label="Reason Reference(s)"
                onChange={(e) => onChangeMedication("reasonReference", e)}
                value={currentMedication?.reasonReference}
                options={conditionOptions.concat(observationOptions)}
                isClearable
              />
            </Col>
            <Col>
              <StyledInput
                id="reasonCode"
                name="reasonCode"
                label="Reason"
                maxLength="300"
                value={currentMedication?.reasonCode}
                onChange={(e) =>
                  onChangeMedication("reasonCode", e.target.value)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSingleDatePicker
                label="Date Asserted"
                name="dateAsserted"
                id="dateAsserted"
                selected={currentMedication?.dateAsserted}
                onChangeCallback={(e) => onChangeMedication("dateAsserted", e)}
                maxDate={new Date()}
              />
            </Col>
            <Col>
              <StyledSelect
                label="Information Source"
                isClearable
                options={subjectOptions}
                name="informationSourceReference"
                id="informationSourceReference"
                value={currentMedication?.informationSourceReference || ""}
                onChange={(selection) =>
                  onChangeMedication("informationSourceReference", selection)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="12">
              <FhirChoiceVariable
                id="effectiveType"
                name="effective"
                label="Choose Effective Type"
                obj={currentMedication}
                valueType={currentMedication?.effectiveType}
                onChangeType={(e) => onChangeMedication("effectiveType", e)}
                value={currentMedication?.effective}
                onChangeCallback={(val, type) =>
                  onChangeMedication(`effective${type}`, val)
                }
                options={[
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
                ]}
              />
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="Dosage">
          <Row className="mb-4">
            <Col xs="12">
              <FhirChoiceVariable
                id="dose"
                name="dose"
                label="Choose Dose Type"
                obj={currentMedication}
                valueType={currentMedication?.doseType}
                onChangeType={(e) => onChangeMedication("doseType", e)}
                value={currentMedication?.dose}
                onChangeCallback={(val, type) =>
                  onChangeMedication(`dose${type}`, val)
                }
                options={[
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.QUANTITY,
                  constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE,
                ]}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledInput
                id="route"
                name="route"
                label="Route of entry into body"
                maxLength="300"
                value={currentMedication?.route}
                onChange={(e) => onChangeMedication("route", e.target.value)}
              />
            </Col>
            <Col>
              <StyledSelect
                label="Take as needed?"
                isClearable
                options={constants.BOOLEAN_OPTIONS}
                name="asNeeded"
                id="asNeeded"
                value={currentMedication?.asNeeded || ""}
                onChange={(selection) =>
                  onChangeMedication("asNeeded", selection)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="4">
              <StyledInput
                id="frequency"
                type="number"
                name="frequency"
                label="Frequency (# times per period)"
                value={currentMedication?.frequency}
                onChange={(e) =>
                  onChangeMedication("frequency", e.target.valueAsNumber)
                }
              />
            </Col>
            <div className="flex flex-end text-muted">times every</div>
            <Col xs="4">
              <StyledInput
                id="period"
                type="number"
                name="Period"
                label="Period (# of period units)"
                value={currentMedication?.period}
                onChange={(e) =>
                  onChangeMedication("period", e.target.valueAsNumber)
                }
              />
            </Col>
            <Col>
              <StyledSelect
                label="Period units"
                isClearable
                options={constants.FHIR_UNITS_OF_TIME}
                name="periodUnit"
                id="periodUnit"
                value={currentMedication?.periodUnit || ""}
                onChange={(selection) =>
                  onChangeMedication("periodUnit", selection)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                label="When"
                isMulti
                isClearable
                options={constants.FHIR_EVENT_TIMING}
                name="when"
                id="when"
                value={currentMedication?.when || ""}
                onChange={(selection) => onChangeMedication("when", selection)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <StyledInput
                id="dosageText"
                name="dosageText"
                label="Dosage Instructions"
                maxLength="1000"
                value={currentMedication?.dosageText}
                onChange={(e) =>
                  onChangeMedication("dosageText", e.target.value)
                }
                type="textarea"
              />
            </Col>
          </Row>
        </StyledSection>
        <PatientChartNotes
          obj={currentMedication}
          setObj={setCurrentMedication}
          editingNoteIndices={editingNoteIndices}
          setEditingNoteIndicies={setEditingNoteIndices}
          onChange={onChangeMedication}
        />
      </StyledModal>
    </>
  );
}