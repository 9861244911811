import cx from "classnames";
import React from "react";
import { AiOutlineMergeCells } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";

export default function ActionButtonRow(props) {
  const { onMerge, onReject, idx, disabled, mergeDisabled, rejectDisabled } =
    props;

  const mergeId = idx + "-merge";
  const mergeTooltipId = idx + "-merge";
  const rejectId = idx + "-reject";
  const rejectTooltipId = rejectId + "-tooltip";

  function handleMergeClick() {
    if (disabled) return;
    onMerge();
  }

  const disableButton = disabled ? "disabled" : "";
  return (
    <div className={"action-row-container"}>
      {/*<Tooltip title>*/}
      <button
        className={cx("action-button", { hover: !mergeDisabled })}
        id={mergeId}
        title={
          mergeDisabled ? "No records found to merge with" : "Merge record"
        }
        disabled={mergeDisabled}
        onClick={handleMergeClick}
      >
        {props.mergeIcon ? (
          props.mergeIcon
        ) : (
          <AiOutlineMergeCells className={"merge-button"} />
        )}
      </button>
      <button
        className={cx("action-button", { hover: !rejectDisabled })}
        id={rejectId}
        title={props?.disabledTooltip ?? "Reject Record"}
        onClick={onReject}
        aria-disabled={disabled}
      >
        <TiDeleteOutline className={"reject-button"} />
      </button>
    </div>
  );
}