import { useEffect, useState, useContext } from "react";
import { api, constants, dateHelpers, helpers, UserContext } from "../utils";
import { Row, Col } from "reactstrap";
import {
  WidgetCard,
  StyledModal,
  StyledSelect,
  StyledSingleDatePicker,
  StyledInput,
  StyledSection,
  Loader,
  StyledAsyncSelect,
  LinkPager,
  PatientChartGoalModals,
  PatientChartNotes,
} from ".";
import _ from "lodash";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { MdOutlineAddCircleOutline } from "react-icons/md";

const EMPTY_CARE_PLAN = {
  id: "",
  clientId: 0,
  status: null,
  intent: null,
  category: [],
  subjectReference: "",
  created: null,
  addressReferences: [],
  goalReferences: [],
  activityReferences: [],
  notes: [],
  title: "",
  description: "",
  period: null,
};

const EMPTY_GOAL = {
  id: "",
  subjectReference: "",
  category: null,
  description: null,
  priority: null,
  lifecycleStatus: null,
  startDate: null,
  targetMeasure: null,
  detailType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
  detail: null,
  notes: [],
  achievementStatus: null,
  outcome: null,
};

export default function PatientChartCarePlan({
  clientId,
  participantId,
  subjectOptions,
  refreshReferenceData,
  primaryConditionOptions,
  goalOptions,
  taskOptions,
  serviceRequestOptions,
  observationOptions,
  conditionOptions,
  setRefreshAll,
  refreshAll,
}) {
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [carePlans, setCarePlans] = useState(null);
  const [currentCarePlan, setCurrentCarePlan] = useState(
    JSON.parse(JSON.stringify(EMPTY_CARE_PLAN))
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingNoteIndices, setEditingNoteIndicies] = useState([]);
  const [previousLinks, setPreviousLinks] = useState([]);
  const [nextLink, setNextLink] = useState(null);
  const [currentLink, setCurrentLink] = useState(null);
  const [currentGoal, setCurrentGoal] = useState(Object.assign({}, EMPTY_GOAL));
  const [goalEditModalOpen, setGoalEditModalOpen] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData(link) {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      apiCalls.push(getCarePlanInfo(link));

      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));

          if (aggResults.carePlanInfo) {
            aggResults.carePlanInfo.items = _.map(
              aggResults.carePlanInfo.items,
              (carePlan) => {
                return {
                  ...carePlan,
                  status: _.find(
                    constants.FHIR_REQUEST_STATUS,
                    (x) => x.value === carePlan.status
                  ),
                  intent: _.find(
                    constants.FHIR_CARE_PLAN_INTENTS,
                    (x) => x.value === carePlan.intent
                  ),
                  category: _.map(carePlan.category, (x) => {
                    return {
                      ...x,
                      value: x.code,
                      label: x.display,
                      description: x.text,
                    };
                  }),
                  subjectReference:
                    _.find(subjectOptions, (x) =>
                      _.includes(x.label, carePlan.subjectReference)
                    ) || subjectOptions?.length === 1
                      ? subjectOptions[0]
                      : null,
                  goalReferences: _.map(carePlan.goalReferences, (y) =>
                    _.find(goalOptions, (x) => x.value === y)
                  ),
                  addressReferences: _.map(carePlan.addressReferences, (y) =>
                    _.find(primaryConditionOptions, (x) => x.value === y)
                  ),
                  activityReferences: _.map(carePlan.activityReferences, (y) =>
                    _.find(
                      taskOptions.concat(serviceRequestOptions),
                      (x) => x.value === y
                    )
                  ),
                };
              }
            );
            setCarePlans(aggResults.carePlanInfo.items);
            setCurrentLink(aggResults.carePlanInfo.currentLink);
            setNextLink(aggResults.carePlanInfo.nextLink);
          }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function getCarePlanInfo(link) {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      link: link,
      maxResults: constants.DEFAULT_PAGE_SIZE,
    };
    return api
      .securePost("Participant/CarePlanInfo", payload)
      .then((response) => {
        if (response && response.data) {
          return { carePlanInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function validated() {
    if (!currentCarePlan) {
      toast.error("No Care Plan found. Please try again");
      return false;
    }
    if (!currentCarePlan.title) {
      toast.warning("Title is required");
      return false;
    }
    if (!currentCarePlan.status) {
      toast.warning("Status is required");
      return false;
    }
    if (!currentCarePlan.description) {
      toast.warning("Description is required");
      return false;
    }
    if (!currentCarePlan.intent) {
      toast.warning("Intent is required");
      return false;
    }
    if (!currentCarePlan.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    return true;
  }

  function reset() {
    setEditModalOpen(false);
    setViewModalOpen(false);
    setIsDeleting(false);
    setIsEditing(false);
    setCurrentCarePlan(JSON.parse(JSON.stringify(EMPTY_CARE_PLAN)));
    setEditingNoteIndicies([]);
  }

  function updateCarePlan(payloadChange, action) {
    setLoading(true);

    let payload = {
      ...currentCarePlan,
      clientId: clientId,
      status: currentCarePlan?.status?.value,
      intent: currentCarePlan?.status?.value,
      subjectReference:
        currentCarePlan?.subjectReference?.value ||
        _.head(subjectOptions).value,
      goalReferences: _.map(currentCarePlan?.goalReferences, (x) => x.value),
      addressReferences: _.map(
        currentCarePlan?.addressReferences,
        (x) => x.value
      ),
      activityReferences: _.map(
        currentCarePlan?.activityReferences,
        (x) => x.value
      ),
      ...payloadChange,
    };

    api
      .securePost("Participant/UpdateCarePlan", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          // console.log("resource response id ", response.data.message.id);
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "CarePlan",
            "Care Plan",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData();
          refreshReferenceData();
          toast.success(`Care Plan successfully ${action}`);
        } else {
          toast.error(`Care Plan could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Care Plan could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveCarePlan() {
    if (!validated()) return;

    if (currentCarePlan.note) {
      let currentCarePlanTemp = JSON.parse(JSON.stringify(EMPTY_CARE_PLAN));
      currentCarePlanTemp.notes.push(currentCarePlanTemp.note);
      currentCarePlanTemp.note = { text: "" };
      setCurrentCarePlan(currentCarePlanTemp);
    }
    updateCarePlan({}, "saved");
  }

  function deleteCarePlan() {
    updateCarePlan(
      {
        status: _.find(constants.FHIR_REQUEST_STATUS, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function onChangeCarePlan(field, value) {
    let tempCarePlan = Object.assign({}, currentCarePlan);
    tempCarePlan[field] = value;
    setCurrentCarePlan(tempCarePlan);
  }

  return (
    <>
      <WidgetCard
        title="Care Plan"
        isScrollable
        startOpen
        canCreateNew={!isReadonly}
        canEdit={!isReadonly}
        onCreateNew={() => {
          if (
            !currentCarePlan.subjectReference &&
            subjectOptions?.length === 1
          ) {
            let tempCarePlan = {
              ...currentCarePlan,
              subjectReference: subjectOptions[0],
            };
            setCurrentCarePlan(tempCarePlan);
          }
          setIsEditing(false);
          setEditModalOpen(true);
        }}
        onEdit={() => setIsEditing(!isEditing)}
      >
        {loading ? (
          <Loader />
        ) : (
          <Row className="p-3 ml-2 text-left">
            <Col>
              <div>
                {_.chain(carePlans)
                  .map((carePlan, idx) => (
                    <div
                      key={`carePlans${idx}`}
                      className={classNames("mb-4", {
                        cursorPointer: !isEditing,
                        clickableRow: !isEditing,
                      })}
                      onClick={() => {
                        if (!isEditing) {
                          setCurrentCarePlan(carePlan);
                          setViewModalOpen(true);
                        }
                      }}
                    >
                      {isEditing ? (
                        <>
                          <>
                            <FiEdit
                              className="clickable-icon mr-1"
                              onClick={() => {
                                setEditModalOpen(true);
                                setCurrentCarePlan(carePlan);
                              }}
                            />
                            <FiMinusCircle
                              className="clickable-icon red mr-2"
                              onClick={() => {
                                setCurrentCarePlan(carePlan);
                                setIsDeleting(true);
                                setViewModalOpen(true);
                              }}
                            />
                          </>
                        </>
                      ) : null}
                      <span>
                        <b>{carePlan.title}:&nbsp;</b>
                        {carePlan.description}
                      </span>
                      <div className="ml-5">
                        {carePlan?.goalReferences &&
                          _.map(carePlan.goalReferences, (x, i) => {
                            return (
                              <li key={`carePlanGoalReference${i}`}>
                                {x?.label}
                              </li>
                            );
                          })}
                      </div>
                    </div>
                  ))
                  .value()}
              </div>
              {nextLink || previousLinks ? (
                <LinkPager
                  currentLink={currentLink}
                  setCurrentLink={setCurrentLink}
                  nextLink={nextLink}
                  previousLinks={previousLinks}
                  setPreviousLinks={setPreviousLinks}
                  refreshData={(nextLink) => refreshData(nextLink)}
                />
              ) : null}
            </Col>
          </Row>
        )}
      </WidgetCard>

      {/* Care Plan View Only Modal */}
      <StyledModal
        show={viewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        scrollable
        onHide={reset}
        size="xl"
        title={isDeleting ? `Delete Care Plan?` : `Care Plan Display`}
        onSave={isDeleting ? deleteCarePlan : reset}
        savePrompt={isDeleting ? "Delete Care Plan" : "Close"}
        disabled={loading}
      >
        <StyledSection title="Info">
          <div className="mb-1">
            <b>Title:&nbsp;</b>
            {currentCarePlan?.title}
          </div>
          <div className="mb-1">
            <b>Description:&nbsp;</b>
            {currentCarePlan?.description}
          </div>
          <div className="mb-1">
            <b>Status:&nbsp;</b>
            {currentCarePlan?.status?.label}
          </div>
          <div className="mb-1">
            <b>Intent:&nbsp;</b>
            {currentCarePlan?.intent?.label}
          </div>
          <div className="mb-1">
            <b>Period Start:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentCarePlan?.period?.start,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Period End:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentCarePlan?.period?.end,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Created Date:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentCarePlan?.created,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentCarePlan?.subjectReference?.label}
          </div>
          <div>
            <b>Categories:&nbsp;</b>
            <ul className="ml-5">
              {currentCarePlan?.category &&
                _.map(currentCarePlan.category, (x, i) => {
                  return <li key={`carePlanCategory${i}`}>{x.label}</li>;
                })}
            </ul>
          </div>
        </StyledSection>
        <StyledSection title="About">
          <div className="mb-1">
            <b>Author:&nbsp;</b>
            {currentCarePlan?.authorReference?.label}
          </div>
          <div className="mb-1">
            <b>Contributor:&nbsp;</b>
            {currentCarePlan?.contributorReference?.label}
          </div>
          <div className="mb-1">
            <b>Care Team:&nbsp;</b>
            {currentCarePlan?.careTeamReference?.label}
          </div>
          <div>
            <b>Addresses:&nbsp;</b>
            <ul className="ml-5">
              {currentCarePlan?.addressReferences &&
                _.map(currentCarePlan.addressReferences, (x, i) => {
                  return (
                    <li key={`carePlanAddressReference${i}`}>{x?.label}</li>
                  );
                })}
            </ul>
          </div>
          <div>
            <b>Goals:&nbsp;</b>
            <ul className="ml-5">
              {currentCarePlan?.goalReferences &&
                _.map(currentCarePlan.goalReferences, (x, i) => {
                  return <li key={`carePlanGoalReference${i}`}>{x?.label}</li>;
                })}
            </ul>
          </div>
          <div>
            <b>Activities:&nbsp;</b>
            <ul className="ml-5">
              {currentCarePlan?.activityReferences &&
                _.map(currentCarePlan.activityReferences, (x, i) => {
                  return (
                    <li key={`carePlanActivityReference${i}`}>{x?.label}</li>
                  );
                })}
            </ul>
          </div>
        </StyledSection>
        <StyledSection title="Notes">
          {_.map(currentCarePlan?.notes, (note, i) => {
            return (
              <>
                <div key={`note${i}`} className="mb-1">
                  {note.text}
                </div>
                <hr />
              </>
            );
          })}
        </StyledSection>
      </StyledModal>

      {/* Care Plan Edit Modal */}
      <StyledModal
        show={editModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={isEditing ? "Update Care Plan" : "Create Care Plan"}
        onSave={saveCarePlan}
        savePrompt={"Save Care Plan"}
        showRequiredFieldsMessage={true}
        disabled={loading}
      >
        <StyledSection title="Info">
          <Row className="mb-4">
            <Col xs="6">
              <StyledInput
                id="title"
                name="title"
                label="Title"
                maxLength="100"
                value={currentCarePlan?.title}
                onChange={(e) => onChangeCarePlan("title", e.target.value)}
                required
              />
            </Col>
            <Col>
              <StyledSelect
                label="Status"
                required
                isClearable
                options={constants.FHIR_REQUEST_STATUS}
                name="status"
                id="status"
                value={currentCarePlan?.status || ""}
                onChange={(selection) => onChangeCarePlan("status", selection)}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <StyledInput
                id="description"
                name="description"
                label="Description"
                maxLength="1000"
                value={currentCarePlan?.description}
                onChange={(e) =>
                  onChangeCarePlan("description", e.target.value)
                }
                required
                type="textarea"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <StyledSelect
                label="Intent"
                required
                isClearable
                options={constants.FHIR_CARE_PLAN_INTENTS}
                name="intent"
                id="intent"
                value={currentCarePlan?.intent || ""}
                onChange={(selection) => onChangeCarePlan("intent", selection)}
              />
            </Col>
            <Col xs="6">
              <StyledAsyncSelect
                id="category"
                isMulti
                isClearable
                label="Category"
                onChange={(e) => onChangeCarePlan("category", e)}
                value={currentCarePlan?.category}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.CARE_PLAN_CATEGORIES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.CARE_PLAN_CATEGORIES,
                    false
                  )
                }
                idName="carePlanCategory"
                description={currentCarePlan?.category?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSingleDatePicker
                label="Period Start"
                name="Period Start"
                id="PeriodStart"
                selected={currentCarePlan?.period?.start}
                onChangeCallback={(e) =>
                  onChangeCarePlan("period", {
                    ...currentCarePlan?.period,
                    start: e,
                  })
                }
                maxDate={currentCarePlan?.period?.end}
              />
            </Col>
            <Col>
              <StyledSingleDatePicker
                label="Period End"
                name="Period End"
                id="PeriodEnd"
                selected={currentCarePlan?.period?.end}
                onChangeCallback={(e) =>
                  onChangeCarePlan("period", {
                    ...currentCarePlan?.period,
                    end: e,
                  })
                }
                minDate={currentCarePlan?.period?.start}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                label="Subject"
                required
                isClearable
                options={subjectOptions}
                name="subject"
                id="subject"
                value={currentCarePlan?.subjectReference || ""}
                onChange={(selection) =>
                  onChangeCarePlan("subjectReference", selection)
                }
              />
            </Col>
            <Col>
              <StyledSingleDatePicker
                label="Created Date"
                name="created"
                id="created"
                selected={currentCarePlan?.created}
                onChangeCallback={(e) => onChangeCarePlan("created", e)}
                maxDate={new Date()}
              />
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="About">
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                label="Author"
                isDisabled
                isClearable
                // options={subjectOptions}
                name="authorReference"
                id="authorReference"
                value={currentCarePlan?.authorReference || ""}
                onChange={(selection) =>
                  onChangeCarePlan("authorReference", selection)
                }
              />
            </Col>
            <Col xs="6">
              <StyledSelect
                label="Contributor"
                isDisabled
                isClearable
                // options={subjectOptions}
                name="contributorReference"
                id="contributorReference"
                value={currentCarePlan?.contributorReference || ""}
                onChange={(selection) =>
                  onChangeCarePlan("contributorReference", selection)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                label="Care Team"
                isDisabled
                isClearable
                // options={subjectOptions}
                name="careTeamReference"
                id="careTeamReference"
                value={currentCarePlan?.careTeamReference || ""}
                onChange={(selection) =>
                  onChangeCarePlan("careTeamReference", selection)
                }
              />
            </Col>
            <Col xs="6">
              <StyledSelect
                id="addressReferences"
                isMulti
                label="Addresses"
                onChange={(e) => onChangeCarePlan("addressReferences", e)}
                value={currentCarePlan?.addressReferences}
                options={primaryConditionOptions}
                isClearable
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="goalReferences"
                isMulti
                label={
                  <div
                    className="flex cursorPointer"
                    style={{ alignItems: "center" }}
                  >
                    Goals
                    <MdOutlineAddCircleOutline
                      onClick={() => setGoalEditModalOpen(true)}
                      className="ml-1"
                    />
                  </div>
                }
                onChange={(e) => onChangeCarePlan("goalReferences", e)}
                value={currentCarePlan?.goalReferences}
                options={goalOptions}
                isClearable
              />
            </Col>
            <Col xs="6">
              <StyledSelect
                id="activityReferences"
                isMulti
                label="Activities"
                onChange={(e) => onChangeCarePlan("activityReferences", e)}
                value={currentCarePlan?.activityReferences}
                options={taskOptions.concat(serviceRequestOptions)}
                isClearable
              />
            </Col>
          </Row>
        </StyledSection>
        <PatientChartNotes
          obj={currentCarePlan}
          setObj={setCurrentCarePlan}
          editingNoteIndices={editingNoteIndices}
          setEditingNoteIndicies={setEditingNoteIndicies}
          onChange={onChangeCarePlan}
        />
      </StyledModal>

      {/* Goal Edit Modal */}
      <PatientChartGoalModals
        conditionOptions={conditionOptions}
        observationOptions={observationOptions}
        subjectOptions={subjectOptions}
        serviceRequestOptions={serviceRequestOptions}
        goalEditModalOpen={goalEditModalOpen}
        reset={() => {
          setGoalEditModalOpen(false);
          setCurrentGoal(EMPTY_GOAL);
        }}
        loading={loading}
        setLoading={setLoading}
        currentGoal={currentGoal}
        setCurrentGoal={setCurrentGoal}
        refreshReferenceData={refreshReferenceData}
        clientId={clientId}
        refreshData={() => setRefreshAll(!refreshAll)}
      />
    </>
  );
}