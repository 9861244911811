import React, { useEffect, useState } from "react";
import { api, filterHelpers, helpers, constants, dateHelpers } from "../utils";
import {
  FormGroup,
  FormControl,
} from "@mui/material";
import {
  Row,
  Col,
} from "reactstrap";
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { 
  StyledFormLabel, 
  StyledSingleDatePicker 
} from "./"
import _ from 'lodash';
import StyledInput from "./StyledInput";
import StyledSelect from "./StyledSelect";

export default function FhirChoiceVariable(props) {
  const [chosenType, setChosenType] = useState(props.valueType 
    ? props.valueType
    : props.options && props.options.length
      ? props.options[0]
      : null);

  useEffect(() => {
    determineInputType();
  }, [chosenType])

  function determineInputType() {
    if (chosenType) {
      switch(chosenType.label) {
        case constants.FHIR_CHOICE_VARIABLE_TYPES.DATE_TIME:
          return(
              <StyledSingleDatePicker
                required={props.required}
                label={_.capitalize(props.name)}
                type="text"
                name={props.name}
                id="cvSingleDate"
                maxLength="200"
                selected={props.obj[`${props.name}DateTime`]}
                onChangeCallback={e => props.onChangeCallback(e, chosenType.label)}
              />
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.PERIOD:
          return(
            <Row>
              <Col xs="6">
                <StyledSingleDatePicker
                  required={props.required}
                  label={`Start ${_.capitalize(props.name)}`}
                  type="text"
                  name={props.name}
                  id="cvPeriodStart"
                  maxLength="200"
                  selected={props.obj[`${props.name}Period`]?.start}
                  onChangeCallback={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "start": e}, chosenType.label)}
                  maxDate={props.obj[`${props.name}Period`]?.end}    
                />
              </Col>
              <Col>
                <StyledSingleDatePicker
                  required={props.required}
                  label={`End ${_.capitalize(props.name)}`}
                  type="text"
                  name={props.name}
                  id="cvPeriodEnd"
                  maxLength="200"
                  selected={props.obj[`${props.name}Period`]?.end}
                  onChangeCallback={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "end": e}, chosenType.label)}
                  minDate={props.obj[`${props.name}Period`]?.start} 
                />
              </Col>
            </Row>
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.AGE:
          return(
            <StyledInput
              required={props.required}
              label={_.capitalize(props.name)}
              type="number"
              name={props.name}
              id="cvAge"
              maxLength="3"
              onChange={e => props.onChangeCallback(e.target.valueAsNumber, chosenType.label)}
              value={props.obj[`${props.name}Age`]}
            />
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.INTEGER:
          return(
            <StyledInput
              required={props.required}
              label={_.capitalize(props.name)}
              type="number"
              name={props.name}
              id="cvInteger"
              maxLength="200"
              onChange={e => props.onChangeCallback(e.target.valueAsNumber, chosenType.label)}
              value={props.obj[`${props.name}Integer`]}
            />
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.RANGE:
          return(
            <Row>
              <Col>
                <StyledInput
                  required={props.required}
                  label={`Low ${_.capitalize(props.name)}`}
                  type="number"
                  name={props.name}
                  id="cvRangeLow"
                  maxLength="200"
                  value={props.obj[`${props.name}Range`]?.low}
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "low": e.target.valueAsNumber}, chosenType.label)}
                />
              </Col>
              <Col>
                <StyledInput
                  required={props.required}
                  label={`High ${_.capitalize(props.name)}`}
                  type="number"
                  name={props.name}
                  id="cvRangeHigh"
                  maxLength="200"
                  value={props.obj[`${props.name}Range`]?.high}
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "high": e.target.valueAsNumber}, chosenType.label)}
                />
              </Col>
              <Col xs="3">
                <StyledInput
                  id="cvRangeUnits"
                  name={props.valueAsNumber}
                  label={`${_.capitalize(props.name)} Units`}
                  value={props.obj[`${props.name}Range`]?.units}
                  maxLength="10"
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "units": e.target.value}, chosenType.label)}
                />
              </Col>
            </Row>
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.STRING:
          return(
            <StyledInput
              required={props.required}
              label={_.capitalize(props.name)}
              name={props.name}
              id="cvString"
              maxLength="200"
              onChange={e => props.onChangeCallback(e.target.value, chosenType.label)}
              value={props.obj[`${props.name}String`]}

            />
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.BOOLEAN:
          return(
            <StyledSelect
              label={_.capitalize(props.name)}
              required={props.required}
              isClearable
              name={props.name}
              id="cvBoolean"
              options={constants.BOOLEAN_OPTIONS}
              onChange={selection => props.onChangeCallback(selection.value, chosenType.label)}
              //value={props.obj[`${props.name}Boolean`]}
              value={constants.BOOLEAN_OPTIONS.filter(opt => opt.value === props.obj[`${props.name}Boolean`])}
            />
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.QUANTITY:
          return(
            <Row>
              <Col>
                <StyledInput
                  required={props.required}
                  label={_.capitalize(props.name)}
                  type="number"
                  name={props.name}
                  id="cvQuantityValue"
                  maxLength="200"
                  value={props.obj[`${props.name}Quantity`]?.value}
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "value": e.target.valueAsNumber}, chosenType.label)}
                />
              </Col>
              <Col xs="3">
                <StyledInput
                  id="cvQuantityUnits"
                  name={props.name}
                  label={`${_.capitalize(props.name)} Units`}
                  value={props.obj[`${props.name}Quantity`]?.units}
                  maxLength="10"
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "units": e.target.value}, chosenType.label)}
                />
              </Col>
            </Row>
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.RATIO:
          return(
            <Row>
              <Col>
                <StyledInput
                  required={props.required}
                  label={`${_.capitalize(props.name)} Numerator`}
                  type="number"
                  name={props.name}
                  id="cvRatioNumerator"
                  maxLength="200"
                  value={props.obj[`${props.name}Ratio`]?.numerator}
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "numerator": e.target.valueAsNumber}, chosenType.label)}
                />
              </Col>
              <Col>
                <StyledInput
                  required={props.required}
                  label={`${_.capitalize(props.name)} Denominator`}
                  type="number"
                  name={props.name}
                  id="cvRatioDenominator"
                  maxLength="200"
                  value={props.obj[`${props.name}Ratio`]?.denominator}
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "denominator": e.target.valueAsNumber}, chosenType.label)}
                />
              </Col>
              <Col xs="3">
                <StyledInput
                  id="cvRatioUnits"
                  name={props.name}
                  label={`${_.capitalize(props.name)} Units`}
                  value={props.obj[`${props.name}Ratio`]?.units}
                  maxLength="10"
                  onChange={e => props.onChangeCallback({...props?.obj[`${props.name}${chosenType?.label}`], "units": e.target.value}, chosenType.label)}
                />
              </Col>
            </Row>
          );
        case constants.FHIR_CHOICE_VARIABLE_TYPES.TIME:
          return(
            <Row>
            <Col>
              <StyledInput
                required={props.required}
                label={`${_.capitalize(props.name)}`}
                type="time"
                name={props.name}
                id="cvTime"
                maxLength="200"
                value={props.obj[`${props.name}Time`]}
                onChange={e => props.onChangeCallback(e.target.value, chosenType.label)}
              />
            </Col>
            </Row>
          );
      }
    }
  }

  return (
    props.options && props.options.length ?
      <Row className="mb-4">
        {props.hideTypeSelection
          ? null
          : (<Col xs="3">
              <FormControl component="fieldset">
                <StyledFormLabel {...props} />
                  <FormGroup aria-label="position" row>
                    <Select
                      name={`Choose ${_.capitalize(props.name)} Type`}
                      id={props.id}
                      placeholder={props.placeholder || ''}
                      options={props.options}
                      value={chosenType}
                      isDisabled={props.isDisabled}
                      onChange={(e) => {props.onChangeType(e); setChosenType(e)}}
                      defaultValue={props.defaultValue}
                      classNamePrefix='wcitySelect'
                      makeAnimated={makeAnimated}
                      className="w-100"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </FormGroup>
                </FormControl>
            </Col>)
        }        
        <Col>
          {determineInputType()}
        </Col>
      </Row>
    : null
 
  );
}
