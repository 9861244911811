import React, { useState, useEffect, useContext, useRef } from "react";
import {
  PageContainer,
  LongBreadCrumb,
  StyledInput,
  StyledFormLabel,
  StyledButton,
  StyledModal,
  SpreadSheetUpload,
  StyledTD,
} from "./";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import _ from "lodash";
import { api, dateHelpers, helpers, UserContext } from "../utils";
import { Form, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FaEdit } from "react-icons/fa";
import classnames from "classnames";
import { unparse } from "papaparse";

//
// const uploadTypes = {
//
// }

const uploadTypes = {
  logo: {
    name: "Logo",
    types: { "image/*": [".jpeg", ".png"] },
  },
  spreadSheet: {
    name: "Call Log",
    types: [".xls", ".xlsm"],
  },
  // callLog: {""},
};

const defaultPictureSize = 125;
export default function ClientManagement(props) {
  let { clientId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showBadDataModal, setShowBadDataModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [logo, setLogo] = useState(null);
  const [originalFileName, setOriginalFileName] = useState("");
  const [badData, setBadData] = useState([]);
  const [logoHovered, setLogoHovered] = useState(false);
  const [showUploadWarning, setShowUploadWarning] = useState(false);
  const [toastId, setToastId] = useState(null);
  const [activeTab, setActiveTab] = useState(clientManagementTabs.general);
  const [pictureSize, setPictureSize] = useState(
    defaultPictureSize / window.devicePixelRatio
  );

  const { currentUser } = useContext(UserContext);
  function saveClient(passedClient) {
    setSaving(true);
    let payload = Object.assign({}, passedClient ?? client);
    payload.logoContent = client.logo;
    api
      .postFormData("Client/SaveClient", payload)
      .then((response) => {
        if (response.data && response.data.success) {
          toast.success("Client saved successfully");
        }
      })
      .catch((error) => {
        helpers.catchHandler(error);
      })
      .finally(() => {
        refreshData();
        setSaving(false);
      });
  }

  function refreshData() {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      apiCalls.push(getClient());
      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));
          if (aggResults.client) {
            setClient(aggResults.client);
          }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function getClient() {
    return api
      .secureFetch(`Client/Client/${clientId}`)
      .then((response) => {
        if (response && response.data) {
          return { client: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function onUpload(files) {
    const formData = new FormData();
    formData.append("logo", files[0]);
    let newClient = Object.assign({}, client);
    newClient.logo = formData;
    saveClient(newClient);
  }

  function onSpreadSheetUpload() {
    setShowUploadWarning(false);
    if (files && files.length > 0) {
      const payload = new FormData();
      _.forEach(files, (file) => {
        payload.append("file", file);
        console.debug({ file });
        const fileName = _.split(file.name, ".")[0];
        setOriginalFileName(fileName);
      });
      const id = toast.loading(
        "Processing Call Log. This could take a while. Please do not leave or refresh the page.",
        {
          bodyClassName: "px-2",
        }
      );
      setToastId(id);
      api
        .securePostFormData(`client/UploadCallLogCsv/${clientId}`, payload)
        .then((res) => {
          if (!res) return;
          if (!res.data.success) {
            toast.update(id, {
              render: res.data.message,
              type: "error",
              isLoading: false,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              closeButton: true,
            });
            return;
          }
          setFiles([]);
          if (res?.data.message.errors) {
            setShowBadDataModal(true);
            setBadData(res.data.message.errors);
          }
          toast.update(id, {
            render: `Successfully Uploaded (${
              res.data.message.uploadCount ?? 0
            }) items.`,
            type: "success",
            isLoading: false,
            closeButton: true,
          });
        })
        .catch((err) => {
          console.error(err);
          toast.error("There was an error uploading the files");
        });
    }
  }

  const purgeContactRecords = () => {
    setLoading(true);
    api
      .secureFetch("client/PurgeUploadedCommunications/" + clientId)
      .then((res) => {
        if (!res || !res.data.success) return;
        console.debug(res);
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  };

  const handleUploadNotification = () => {
    setShowUploadWarning(true);
  };

  const handleClientChange = (field, value) => {
    let newClient = Object.assign({}, client);
    newClient[field] = value;
    setClient(newClient);
  };

  useEffect(() => {
    refreshData();
    return () => {
      toast.dismiss();
    };
  }, []);

  function getCSV() {
    const csvData = unparse(badData, {
      type: "text/csv;charset=utf-8;",
      header: false,
      columns: [
        "memberId",
        "fullName",
        "socialNumber",
        "date",
        "nurse",
        "description",
        "contactTypes",
        "categories",
        "timeSpent",
        "errorMessage",
        "rowNumber",
      ],
    });
    const headerData = unparse({
      fields: [
        "Member Id",
        "Full Name",
        "SS",
        "Date",
        "Nurse",
        "Description",
        "Contact Types",
        "Categories",
        "Time Spent (In Minutes)",
        "Error Message",
        "Row Number",
      ],
      data: [],
    });
    const csvValue = headerData + csvData;

    helpers.browserExportCSVFile(
      csvValue,
      `${client.name}_ErrorLog_${dateHelpers.getLongDate()}.csv`
    );
    setTimeout(() => setShowBadDataModal(false), 500);
  }
  const logoRef = useRef(null);

  return (
    <PageContainer>
      <Col className={"m-0 p-0 h-75"}>
        <LongBreadCrumb page={"Management"} loading={loading} client />
        <Row lg={10} className={"m-0 p-0 h-100 w-100"}>
          <ClientManagementNavigation
            activeTab={activeTab}
            onSelectTab={setActiveTab}
          >
            <ClientNavTab
              isActive={activeTab === clientManagementTabs.general}
              tabIndex={clientManagementTabs.general}
              title={"General"}
              onClick={setActiveTab}
            />
            <ClientNavTab
              isActive={activeTab === clientManagementTabs.uploads}
              tabIndex={clientManagementTabs.uploads}
              title={"Uploads"}
              onClick={setActiveTab}
              canDrag
            />
          </ClientManagementNavigation>
          <Card className={"w-100 h-100"}>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={clientManagementTabs.general}>
                  <Row
                    className={
                      "m-0 h-100 p-0 d-flex align-items-center justify-content-sm-center justify-content-lg-start"
                    }
                  >
                    <Col
                      lg={2}
                      md={4}
                      sm={12}
                      className={
                        " d-flex align-items-center justify-content-lg-start justify-content-sm-center"
                      }
                    >
                      <img
                        className={"client__img"}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/missing-logo.png";
                        }}
                        src={`/api/Client/Logo/${clientId}`}
                      />
                      <input
                        ref={logoRef}
                        type={"file"}
                        id={"file"}
                        style={{ display: "none" }}
                        onChange={(e) => onUpload(e.target.files)}
                      />
                    </Col>
                    <Col
                      lg={6}
                      md={8}
                      sm={12}
                      className={
                        "d-flex justify-content-lg-start justify-content-sm-center w-100"
                      }
                    >
                      {/*<Form className={"ms-2 w-100"}>*/}
                      <ClientInfoField
                        value={client?.name ?? ""}
                        label={"Name"}
                        name="name"
                        isRequired={true}
                        onChange={handleClientChange}
                        onSave={saveClient}
                      />
                      {/*</Form>*/}
                    </Col>

                    <Row className={"m-0 p-0"}>{/*<*/}</Row>
                    <Col
                      lg={"auto"}
                      className={"m-0 p-0 h-100 d-flex justify-content-center"}
                    >
                      <div className={"bread-crumb-separator"} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={clientManagementTabs.uploads}>
                  <Col lg={12} className={"d-flex align-items-end w-100 h-100"}>
                    <Row className={"w-100"}>
                      {process.env.NODE_ENV === "development" && (
                        <div>
                          {currentUser?.isAdmin && (
                            <StyledButton
                              onClick={() => purgeContactRecords()}
                              disabled={loading}
                            >
                              Purge Old Records
                            </StyledButton>
                          )}
                        </div>
                      )}
                      <Form className={"w-100"}>
                        <StyledFormLabel label={"Call Log Upload"} />
                        <Card className={"w-100"}>
                          <CardBody>
                            {/*<Row className={"d-flex justify-content-center"}>*/}
                            <CardTitle className="text-center">
                              Drop the Csv file or click on the icon below to
                              choose it.
                            </CardTitle>
                            {/*</Row>*/}

                            {/*<Row>*/}
                            {/*  <Col lg={12}>*/}
                            <SpreadSheetUpload
                              onSetFiles={setFiles}
                              files={files}
                              onUpload={(files) => {
                                console.debug(files);
                                handleUploadNotification();
                              }}
                              maxFiles={1}
                              acceptedFileTypes={{ "text/*": [".csv"] }}
                              messageText={
                                "Drop the CSV file or click on the icon below to choose it."
                              }
                              uploadType={"spreadsheet"}
                            />
                            {/*  </Col>*/}
                            {/*</Row>*/}
                          </CardBody>
                        </Card>
                      </Form>
                    </Row>
                  </Col>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Row>
      </Col>
      <StyledModal
        show={showUploadWarning}
        title={"Upload Warning"}
        showCancel
        savePrompt={"Confirm"}
        onHide={() => {
          setFiles([]);
          setShowUploadWarning(false);
        }}
        onSave={onSpreadSheetUpload}
      >
        <div>
          <p className={"text-center"}>
            You are about to upload your historical call logs.
          </p>
          <p className={"text-center"}>
            <strong className={"text-danger"}>
              This will delete all previously uploaded call logs from a
              spreadsheet.
            </strong>
          </p>
        </div>
      </StyledModal>
      <StyledModal
        show={showBadDataModal}
        title={"Upload Errors"}
        showCancel
        savePrompt={"Download"}
        onSave={() => getCSV()}
        onHide={() => {
          setShowBadDataModal(false);
        }}
      >
        <div>
          <p className={"text-center"}>
            The upload operation completed but reported errors.
          </p>
          <Col lg={12}>
            <Row>
              {badData && badData.length ? (
                <Table border>
                  <thead>
                    <tr>
                      <th
                        className={"diagnostic-table-header header-align-left"}
                      >
                        File Name
                      </th>
                      <th
                        className={"diagnostic-table-header header-align-left"}
                      >
                        # of Rows with Errors
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <StyledTD>{originalFileName}</StyledTD>
                      <StyledTD textCenter>{badData.length}</StyledTD>
                    </tr>
                  </tbody>
                </Table>
              ) : null}
            </Row>
          </Col>
        </div>
      </StyledModal>
    </PageContainer>
  );
}

const clientManagementTabs = {
  general: 0,
  uploads: 1,
};

const ClientManagementNavigation = (props) => {
  const { activeTab, onSelectTab, children } = props;
  // const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (tabIndex) => {
    if (activeTab === tabIndex) return;
    console.debug(tabIndex);
    onSelectTab(tabIndex);
  };

  return (
    <Card className={"mx-0 my-3"} style={{ background: "#fff", width: "100%" }}>
      <Row className={"px-4"}>{children}</Row>
    </Card>
  );
};

const ClientNavTab = (props) => {
  const { isActive, onClick, title, tabIndex, canDrag } = props;
  const dropRef = useRef(null);

  const handleDrag = () => {
    setTimeout(() => {
      onClick(tabIndex);
    }, 400);
  };

  useEffect(() => {
    if (canDrag && dropRef.current) {
      dropRef.current.addEventListener("dragover", handleDrag);
    }
    return () => {
      if (dropRef.current) {
        dropRef.current.removeEventListener("dragover", handleDrag);
      }
    };
  }, [dropRef]);

  return (
    <Col
      // lg={1}
      lg={1}
      sm={12}
      className={
        "d-flex align-items-lg-stretch align-items-sm-baseline justify-content-center p-0 px-1"
      }
    >
      <button
        ref={dropRef}
        onClick={() => onClick(tabIndex)}
        style={{ height: "48px" }}
        className={classNames(
          isActive ? "client-tab-active" : "client-tab-inactive",
          "client-tab"
        )}
      >
        <span className={"text-black mx-1"}>{title}</span>
      </button>
    </Col>
  );
};

const ClientInfoField = (props) => {
  const {
    name,
    label,
    value,
    maxLength = "30",
    isRequired = false,
    onChange,
    onSave,
  } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function handleSaveField(e) {
    e.preventDefault();
    const newValue = e.target.value;
    if (newValue !== value) {
      onSave();
    }
    setIsEditMode(false);
  }

  if (isEditMode) {
    return (
      <>
        <Col lg={8} sm={12} className={"m-0 p-2 d-flex justify-content-start"}>
          <StyledInput
            type="text"
            name={name}
            style={{ fontSize: "2em" }}
            id={name}
            maxLength={maxLength}
            onChange={(event) => onChange(name, event.target.value)}
            value={value}
            className="form-control font-weight-bold h1"
            debounceTimeout={300}
            required={isRequired}
          />
        </Col>
        <Col>
          <StyledButton
            className={"mx-3"}
            color="primary"
            onClick={handleSaveField}
          >
            Save
          </StyledButton>
        </Col>
      </>
    );
  }
  return (
    <Row
      className={classnames("m-o p-0 d-flex align-items-center")}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Col className={"m-0 p-2 d-flex justify-content-start"}>
        <h3>{value}</h3>
        {isHovered ? (
          <Col
            // lg={2}
            className={"m-0 py-2 d-flex"}
          >
            <Button
              color={"dark"}
              className={
                "d-flex align-items-center justify-content-center  z-index-10 position-absolute top-0 right-0"
              }
              onClick={(e) => {
                e.preventDefault();
                setIsEditMode(true);
              }}
            >
              <FaEdit />
            </Button>
          </Col>
        ) : null}
      </Col>
      <Col lg={6}></Col>
    </Row>
  );
};