import React, { useEffect, useState } from 'react';
import {
    PageContainer,
    WidgetCard,
    LongBreadCrumb,
    StyledTD,
    Pager,
    FilterText,
    StyledModal,
    StyledInput,
    StyledSelect,
} from '.';
import { Col, Row, Table } from "reactstrap";
import { api, constants, helpers, filterHelpers } from "../utils";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { toast } from 'react-toastify';
import _ from 'lodash';


const EMPTY_AILMENT = {
    value: 0,
    label: "",
    
}

const EMPTY_CATEGORY = {
    value: 0,
    label: "",
}

const EMPTY_SOURCE = {
    value: 0,
    label: "",
}

const EMPTY_CODE = {
    id: 0,
    name: "",
    ailment: null,
    category: null,
    source: null,
    referencePeriodYearCount: null,
}

export default function ICD10MappingAdmin(props) {
    const [loading, setLoading] = useState(false);
    const [icd10CodePageNumber, setIcd10CodePageNumber] = useState(1);
    const [icd10CategoryPageNumber, setIcd10CategoryPageNumber] = useState(1);
    const [icd10AilmentPageNumber, setIcd10AilmentPageNumber] = useState(1);
    const [icd10SourcePageNumber, setIcd10SourcePageNumber] = useState(1);
    const [icd10CodePaginatedList, setIcd10CodePaginatedList] = useState(1);
    const [icd10CategoryPaginatedList, setIcd10CategoryPaginatedList] = useState(1);
    const [icd10AilmentPaginatedList, setIcd10AilmentPaginatedList] = useState(1);
    const [icd10PageSourcePaginatedList, setIcd10SourcePaginatedList] = useState(1);
    const [ailmentFilters, setAilmentFilters] = useState([]);
    const [ailmentEditModalOpen, setAilmentEditModalOpen] = useState(false);
    const [currentIcd10Ailment, setCurrentIcd10Ailment] = useState(Object.assign({}, EMPTY_AILMENT));
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [categoryEditModalOpen, setCategoryEditModalOpen] = useState(false);
    const [currentIcd10Category, setCurrentIcd10Category] = useState(Object.assign({}, EMPTY_CATEGORY));
    const [sourceFilters, setSourceFilters] = useState([]);
    const [sourceEditModalOpen, setSourceEditModalOpen] = useState(false);
    const [currentIcd10Source, setCurrentIcd10Source] = useState(Object.assign({}, EMPTY_SOURCE));
    const [codeEditModalOpen, setCodeEditModalOpen] = useState(false);
    const [currentIcd10Code, setCurrentIcd10Code] = useState(Object.assign({}, EMPTY_CODE));
    const [codeFilters, setCodeFilters] = useState([]);
    const [icd10AilmentSimpleList, setIcd10AilmentSimpleList] = useState([]);
    const [icd10CategorySimpleList, setIcd10CategorySimpleList] = useState([]);
    const [icd10SourceSimpleList, setIcd10SourceSimpleList] = useState([]);

    useEffect(() => {
        refreshData();
    }, [
        icd10AilmentPageNumber, 
        ailmentFilters, 
        icd10CategoryPageNumber, 
        categoryFilters, 
        icd10SourcePageNumber, 
        sourceFilters,
        icd10CodePageNumber,
        codeFilters,
    ])

    function refreshData() {
        if(loading) return;
        setLoading(true);
        let apiCalls = [];
        apiCalls.push(getIcd10CodeInfo());
        apiCalls.push(getIcd10CategoryInfo());
        apiCalls.push(getIcd10AilmentInfo());
        apiCalls.push(getIcd10SourceInfo());
        apiCalls.push(getIcd10AilmentSimpleInfo());
        apiCalls.push(getIcd10CategorySimpleInfo());
        apiCalls.push(getIcd10SourceSimpleInfo());

        Promise.all(apiCalls)
        .then((arrayResults) => {
            let aggResults = {};
            _.each(arrayResults, (x) => Object.assign(aggResults, x));

            if(aggResults.icd10CodeInfo) {
                _.forEach(aggResults.icd10CodeInfo.list, code => {
                    code.category = { value: code.categoryId, label: code.categoryName };
                    code.ailment = { value: code.ailmentId, label: code.ailmentName };
                    code.source = { value: code.sourceId, label: code.sourceName };
                });
                setIcd10CodePaginatedList(aggResults.icd10CodeInfo);
            }
            if(aggResults.icd10CategoryInfo) {
                aggResults.icd10CategoryInfo.list =
                    _.map(aggResults.icd10CategoryInfo.list, x => ({value: x.id, label: x.name}));
                setIcd10CategoryPaginatedList(aggResults.icd10CategoryInfo);
            }
            if(aggResults.icd10SourceInfo) {
                aggResults.icd10SourceInfo.list =
                    _.map(aggResults.icd10SourceInfo.list, x => ({value: x.id, label: x.name}));
                setIcd10SourcePaginatedList(aggResults.icd10SourceInfo);
            }
            if(aggResults.icd10AilmentInfo) {
                aggResults.icd10AilmentInfo.list =
                    _.map(aggResults.icd10AilmentInfo.list, x => ({value: x.id, label: x.name}));
                setIcd10AilmentPaginatedList(aggResults.icd10AilmentInfo);
            }
            if(aggResults.icd10AilmentSimpleInfo) {
                setIcd10AilmentSimpleList(  _.map(aggResults.icd10AilmentSimpleInfo, x => ({value: x.id, label: x.name})));
            }
            if(aggResults.icd10CategorySimpleInfo) {
                setIcd10CategorySimpleList(  _.map(aggResults.icd10CategorySimpleInfo, x => ({value: x.id, label: x.name})));
            } 
            if(aggResults.icd10SourceSimpleInfo) {
                setIcd10SourceSimpleList(  _.map(aggResults.icd10SourceSimpleInfo, x => ({value: x.id, label: x.name})));
            }

        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }

    function getIcd10CodeInfo() {
        const payload = {
            pageNumber: icd10CodePageNumber,
            maxResults: constants.DEFAULT_PAGE_SIZE,
            activeOnly: true,
        }

        _.each(codeFilters, (filter) => {
            if(filter.values && filter.values.length > 0) {
                payload[filter.filterName] = filter.values[0];
            } else {
                payload[filter.filterName] = filter.label || filter.value;
            }
        });

        return api
        .securePost("FhirAdmin/Icd10CodePaginatedList", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10CodeInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function getIcd10CategoryInfo() {
        const payload = {
            pageNumber: icd10CategoryPageNumber,
            maxResults: constants.DEFAULT_PAGE_SIZE,
            activeOnly: true,
        }

        _.each(categoryFilters, (filter) => {
            if(filter.values && filter.values.length > 0) {
                payload[filter.filterName] = filter.values[0];
            } else {
                payload[filter.filterName] = filter.label || filter.value;
            }
        });


        return api
        .securePost("FhirAdmin/Icd10CategorySimpleListPaginated", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10CategoryInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function getIcd10AilmentInfo() {
        const payload = {
            pageNumber: icd10AilmentPageNumber,
            maxResults: constants.DEFAULT_PAGE_SIZE,
            activeOnly: true,
        }

        _.each(ailmentFilters, (filter) => {
            if(filter.values && filter.values.length > 0) {
                payload[filter.filterName] = filter.values[0];
            } else {
                payload[filter.filterName] = filter.label || filter.value;
            }
          });

        return api
        .securePost("FhirAdmin/Icd10AilmentSimpleListPaginated", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10AilmentInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function getIcd10SourceInfo() {
        const payload = {
            pageNumber: icd10SourcePageNumber,
            maxResults: constants.DEFAULT_PAGE_SIZE,
            activeOnly: true,
        }
        _.each(sourceFilters, (filter) => {
            if(filter.values && filter.values.length > 0) {
                payload[filter.filterName] = filter.values[0];
            } else {
                payload[filter.filterName] = filter.label || filter.value;
            }
        });


        return api
        .securePost("FhirAdmin/Icd10SourceSimpleListPaginated", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10SourceInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function getIcd10AilmentSimpleInfo() {
        const payload = {
            activeOnly: true,
        }

        return api
        .securePost("FhirAdmin/Icd10AilmentSimpleList", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10AilmentSimpleInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function getIcd10CategorySimpleInfo() {
        const payload = {
            activeOnly: true,
        }

        return api
        .securePost("FhirAdmin/Icd10CategorySimpleList", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10CategorySimpleInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function getIcd10SourceSimpleInfo() {
        const payload = {
            activeOnly: true,
        }

        return api
        .securePost("FhirAdmin/Icd10SourceSimpleList", payload)
        .then((response) => {
            if(response && response.data && response.data.success) {
                return { icd10SourceSimpleInfo: response.data.message }
            }
        })
        .catch(helpers.catchHandler);
    }

    function ailmentValidated() {
        if(!currentIcd10Ailment) {
            toast.error("Could not find ICD10 Ailment. Please try again");
            return false;
        }
        if(!currentIcd10Ailment.label || !currentIcd10Ailment.label.trim()) {
            toast.warning("Name is required");
            return false;
        }
        return true;
    }

    function categoryValidated() {
        if(!currentIcd10Category) {
            toast.error("Could not find ICD10 Category. Please try again");
            return false;
        }
        if(!currentIcd10Category.label || !currentIcd10Category.label.trim()) {
            toast.warning("Name is required");
            return false;
        }
        return true;
    }

    function sourceValidated() {
        if(!currentIcd10Source) {
            toast.error("Could not find ICD10 Source. Please try again");
            return false;
        }
        if(!currentIcd10Source.label || !currentIcd10Source.label.trim()) {
            toast.warning("Name is required");
            return false;
        }
        return true;
    }

    function codeValidated() {
        if(!currentIcd10Code) {
            toast.error("Could not find ICD10 Code. Please try again");
            return false;
        }
        if(!currentIcd10Code.ailment) {
            toast.warning("Ailment is required");
            return false;
        }
        return true;
    }

    function saveIcd10Code() {
        if(!codeValidated()) return;

        let payload = {
         ...currentIcd10Code,
         categoryId: currentIcd10Code.category?.value,
         ailmentId: currentIcd10Code.ailment?.value,
         sourceId: currentIcd10Code.source?.value,
        }

        api
          .securePost("FhirAdmin/UpdateIcd10Code", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Code saved successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Code could not be saved");
            }
          })
          .catch(helpers.catchHandler);
    }

    function saveIcd10Ailment() {
        if(!ailmentValidated()) return;

        let payload = {
            id: currentIcd10Ailment.value,
            name: currentIcd10Ailment.label,
        }

        api
          .securePost("FhirAdmin/UpdateIcd10Ailment", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Ailment saved successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Ailment could not be saved");
            }
          })
          .catch(helpers.catchHandler);
    }

    function saveIcd10Category() {
        if(!categoryValidated()) return;

        let payload = {
            id: currentIcd10Category.value,
            name: currentIcd10Category.label,
        }

        api
          .securePost("FhirAdmin/UpdateIcd10Category", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Category saved successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Category could not be saved");
            }
          })
          .catch(helpers.catchHandler);
    }

    function saveIcd10Source() {
        if(!sourceValidated()) return;

        let payload = {
            id: currentIcd10Source.value,
            name: currentIcd10Source.label,
        }

        api
          .securePost("FhirAdmin/UpdateIcd10Source", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Source saved successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Source could not be saved");
            }
          })
          .catch(helpers.catchHandler);
    }

    
    function deleteIcd10Code(code) {

        let payload = {
          ...code,
        }

        api
          .securePost("FhirAdmin/DeleteIcd10Code", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Code deleted successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Code could not be deleted");
            }
          })
          .catch(helpers.catchHandler);
    }

    function deleteIcd10Ailment(ailment) {

        let payload = {
            id: ailment.value,
            name: ailment.label,
        }

        api
          .securePost("FhirAdmin/DeleteIcd10Ailment", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Ailment deleted successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Ailment could not be deleted");
            }
          })
          .catch(helpers.catchHandler);
    }

    function deleteIcd10Category(category) {

        let payload = {
            id: category.value,
            name: category.label,
        }

        api
          .securePost("FhirAdmin/DeleteIcd10Category", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Category deleted successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Category could not be deleted");
            }
          })
          .catch(helpers.catchHandler);
    }

    function deleteIcd10Source(source) {

        let payload = {
            id: source.value,
            name: source.label,
        }

        api
          .securePost("FhirAdmin/DeleteIcd10Source", payload)
          .then(response => {
            if (response.data && response.data.success) {
              toast.success("ICD10 Source deleted successfully");
              reset();
              refreshData();
            } else {
              toast.error("ICD10 Source could not be deleted");
            }
          })
          .catch(helpers.catchHandler);
    }

    function codeFilterChange(changedFilter) {
        const filterList = filterHelpers.getFilters(codeFilters, changedFilter);
        setCodeFilters(filterList);
    }


    function ailmentFilterChange(changedFilter) {
        const filterList = filterHelpers.getFilters(ailmentFilters, changedFilter);
        setAilmentFilters(filterList);
    }

    function categoryFilterChange(changedFilter) {
        const filterList = filterHelpers.getFilters(categoryFilters, changedFilter);
        setCategoryFilters(filterList);
    }

    function sourceFilterChange(changedFilter) {
        const filterList = filterHelpers.getFilters(sourceFilters, changedFilter);
        setSourceFilters(filterList);
    }

    function reset() {
        setCurrentIcd10Ailment(Object.assign({}, EMPTY_AILMENT));
        setAilmentEditModalOpen(false);
        setCurrentIcd10Category(Object.assign({}, EMPTY_CATEGORY));
        setCategoryEditModalOpen(false);  
        setCurrentIcd10Source(Object.assign({}, EMPTY_SOURCE));
        setSourceEditModalOpen(false);
        setCurrentIcd10Code(Object.assign({}, EMPTY_CODE));
        setCodeEditModalOpen(false);
    }

    function onChange(field, value, obj, setObj) {
        let tempObj = Object.assign({}, obj);
        tempObj[field] = value;
        setObj(tempObj);
    }
    

    return (
    <PageContainer>
        <Col className={'m-0 p-0'}>
            <Row className={'mx-0 my-3 d-flex justify-content-start align-items-center'}>
                <Col>
                    <LongBreadCrumb context={"ICD10 Mappings"} page={"Management"} loading={loading} />
                </Col>
            </Row>
            <WidgetCard
                title="ICD10 Code Mappings"
                startOpen={true}
                canCreateNew={true}
                onCreateNew={() => {
                    setCodeEditModalOpen(true);
                }}
                >

            <Row className={'mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center'}>
                    <Col xs="3">
                        <FilterText
                            disabled={loading}
                            filterName="Name"
                            label="Code"
                            onChangeCallback={codeFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Name",
                                ailmentFilters
                            )}
                        />
                    </Col>
                    <Col xs="3">
                        <FilterText
                            disabled={loading}
                            filterName="Ailment"
                            label="Chronic Condition"
                            onChangeCallback={codeFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Ailment",
                                ailmentFilters
                            )}
                        />
                    </Col>
                    <Col xs="3">
                        <FilterText
                            disabled={loading}
                            filterName="Category"
                            label="Chronic Condition Category"
                            onChangeCallback={codeFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Category",
                                ailmentFilters
                            )}
                        />
                    </Col>
                    <Col>
                        <FilterText
                            disabled={loading}
                            filterName="Source"
                            label="Source"
                            onChangeCallback={codeFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Source",
                                ailmentFilters
                            )}
                        />
                    </Col>
                </Row> 
                <Table size="sm" responsive striped>
                    <thead>
                        <tr>
                            <th style={{width: "7%"}}/>
                            <th className="header-default">Code</th>
                            <th className="header-default">Chronic Condition</th>
                            <th className="header-default">Chronic Condition Category</th>
                            <th className="header-default">Source</th>
                            <th className="header-default">Ref. Period Year Count</th>
                        </tr>
                    </thead>
                    <tbody>
                    {_.map(icd10CodePaginatedList?.list, (x,i) => {
                        return(
                            <tr
                            key={`icd10Code${i}`}
                            onClick={() => {}}
                            className="fontSmall"
                            >
                                <StyledTD>
                                <div style={{display: "flex"}}>
                                    <FiEdit 
                                        className="clickable-icon mr-1" 
                                        onClick={() => 
                                        {
                                            setCodeEditModalOpen(true); 
                                            setCurrentIcd10Code(x);
                                        }
                                        }/>
                                    <FiMinusCircle 
                                        className="clickable-icon red mr-2"
                                        onClick={() => deleteIcd10Code(x) }/>
                                </div>
                                </StyledTD>
                                <StyledTD>
                                    {x.name}
                                </StyledTD>
                                <StyledTD>
                                    {x.ailmentName}
                                </StyledTD>
                                <StyledTD>
                                    {x.categoryName}
                                </StyledTD>
                                <StyledTD>
                                    {x.sourceName}
                                </StyledTD>
                                <StyledTD>
                                    {x.referencePeriodYearCount}
                                </StyledTD>
                        </tr>
                        );
                      })
                    } 
                    </tbody>
                </Table>
                    <Row>
                        <Col>
                    <Pager
                        pageNumber={icd10CodePaginatedList?.pageNumber ? icd10CodePaginatedList.pageNumber : 0}
                        totalPages={icd10CodePaginatedList?.totalPages ? icd10CodePaginatedList.totalPages : 0}
                        callBack={(newPageNumber) => setIcd10CodePageNumber(newPageNumber)}
                    />
                    </Col>
                    </Row>
                    
            </WidgetCard>
            <WidgetCard
                title="ICD10 Chronic Conditions"
                startOpen={false}
                canCreateNew={true}
                onCreateNew={() => {
                    setAilmentEditModalOpen(true);
                }}
            >
                <Row className={'mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center'}>
                    <Col xs="4">
                        <FilterText
                            disabled={loading}
                            filterName="Name"
                            label="Name"
                            onChangeCallback={ailmentFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Name",
                                ailmentFilters
                            )}
                        />
                    </Col>
                </Row> 
                <Table size="sm" responsive striped>
                    <thead>
                        <tr>
                            <th style={{width: "7%"}}/>
                            <th className="header-default">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                    {_.map(icd10AilmentPaginatedList?.list, (x,i) => {
                        return(
                            <tr
                            key={`icd10Ailment${i}`}
                            onClick={() => {}}
                            className="fontSmall"
                            >
                                <StyledTD>
                                <div style={{display: "flex"}}>
                                    <FiEdit 
                                        className="clickable-icon mr-1" 
                                        onClick={() => 
                                        {
                                            setAilmentEditModalOpen(true); 
                                            setCurrentIcd10Ailment(x);
                                        }
                                        }/>
                                    <FiMinusCircle 
                                        className="clickable-icon red mr-2"
                                        onClick={() => deleteIcd10Ailment(x) }/>
                                </div>
                                </StyledTD>
                                <StyledTD>
                                    {x.label}
                                </StyledTD>
                        </tr>
                        );
                      })
                    } 
                    </tbody>
                </Table>
                    <Row>
                        <Col>
                    <Pager
                        pageNumber={icd10AilmentPaginatedList?.pageNumber ? icd10AilmentPaginatedList.pageNumber : 0}
                        totalPages={icd10AilmentPaginatedList?.totalPages ? icd10AilmentPaginatedList.totalPages : 0}
                        callBack={(newPageNumber) => setIcd10AilmentPageNumber(newPageNumber)}
                    />
                    </Col>
                    </Row>
            </WidgetCard>
            <WidgetCard
                title="ICD10 Chronic Condition Categories"
                startOpen={false}
                canCreateNew={true}
                onCreateNew={() => {
                    setCategoryEditModalOpen(true);
                }}
                >
             <Row className={'mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center'}>
                    <Col xs="4">
                        <FilterText
                            disabled={loading}
                            filterName="Name"
                            label="Name"
                            onChangeCallback={categoryFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Name",
                                ailmentFilters
                            )}
                        />
                    </Col>
                </Row> 
                <Table size="sm" responsive striped>
                    <thead>
                        <tr>
                            <th style={{width: "7%"}}/>
                            <th className="header-default">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                    {_.map(icd10CategoryPaginatedList?.list, (x,i) => {
                        return(
                            <tr
                            key={`icd10Category${i}`}
                            onClick={() => {}}
                            className="fontSmall"
                            >
                                <StyledTD>
                                <div style={{display: "flex"}}>
                                    <FiEdit 
                                        className="clickable-icon mr-1" 
                                        onClick={() => 
                                        {
                                            setCategoryEditModalOpen(true); 
                                            setCurrentIcd10Category(x);
                                        }
                                        }/>
                                    <FiMinusCircle 
                                        className="clickable-icon red mr-2"
                                        onClick={() => deleteIcd10Category(x) }/>
                                </div>
                                </StyledTD>
                                <StyledTD>
                                    {x.label}
                                </StyledTD>
                        </tr>
                        );
                      })
                    } 
                    </tbody>
                </Table>
                    <Row>
                        <Col>
                    <Pager
                        pageNumber={icd10CategoryPaginatedList?.pageNumber ? icd10CategoryPaginatedList.pageNumber : 0}
                        totalPages={icd10CategoryPaginatedList?.totalPages ? icd10CategoryPaginatedList.totalPages : 0}
                        callBack={(newPageNumber) => setIcd10CategoryPageNumber(newPageNumber)}
                    />
                    </Col>
                    </Row>
            </WidgetCard>
            <WidgetCard
                title="ICD10 Sources"
                startOpen={false}
                canCreateNew={true}
                onCreateNew={() => {
                    setSourceEditModalOpen(true);
                }}
                >
                          <Row className={'mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center'}>
                    <Col xs="4">
                        <FilterText
                            disabled={loading}
                            filterName="Name"
                            label="Name"
                            onChangeCallback={sourceFilterChange}
                            debounceTimeout={750}
                            value={filterHelpers.getValue(
                                "Name",
                                ailmentFilters
                            )}
                        />
                    </Col>
                </Row> 
                <Table size="sm" responsive striped>
                    <thead>
                        <tr>
                            <th style={{width: "7%"}}/>
                            <th className="header-default">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                    {_.map(icd10PageSourcePaginatedList?.list, (x,i) => {
                        return(
                            <tr
                            key={`icd10Source${i}`}
                            onClick={() => {}}
                            className="fontSmall"
                            >
                                <StyledTD>
                                <div style={{display: "flex"}}>
                                    <FiEdit 
                                        className="clickable-icon mr-1" 
                                        onClick={() => 
                                        {
                                            setSourceEditModalOpen(true); 
                                            setCurrentIcd10Source(x);
                                        }
                                        }/>
                                    <FiMinusCircle 
                                        className="clickable-icon red mr-2"
                                        onClick={() => deleteIcd10Source(x) }/>
                                </div>
                                </StyledTD>
                                <StyledTD>
                                    {x.label}
                                </StyledTD>
                        </tr>
                        );
                      })
                    } 
                    </tbody>
                </Table>
                    <Row>
                        <Col>
                    <Pager
                        pageNumber={icd10PageSourcePaginatedList?.pageNumber ? icd10PageSourcePaginatedList.pageNumber : 0}
                        totalPages={icd10PageSourcePaginatedList?.totalPages ? icd10PageSourcePaginatedList.totalPages : 0}
                        callBack={(newPageNumber) => setIcd10SourcePageNumber(newPageNumber)}
                    />
                    </Col>
                    </Row>
            </WidgetCard>
        </Col>

           {/* Code Edit Modal */}
           <StyledModal
            show={codeEditModalOpen}
            showCancel
            onHide={reset}
            size="xl"
            title={currentIcd10Code ? "Update Code" : "Create Code"}
            onSave={saveIcd10Code}
            savePrompt="Save Code"
            showRequiredFieldsMessage={true}
            disabled={loading}
        >
            <Row className="mb-4 px-4 py-3 w-100">
              <Col xs="6">
                <StyledInput
                    id="name"
                    name="name"
                    label="Code"
                    maxLength="25"
                    required
                    value={currentIcd10Code?.name}
                    onChange={e => onChange("name", e.target.value, currentIcd10Code, setCurrentIcd10Code)}
                  />
              </Col>
              <Col>
                <StyledSelect
                    id="ailment"
                    label="Ailment"
                    onChange={(e) => onChange("ailment", e, currentIcd10Code, setCurrentIcd10Code)}
                    required
                    value={currentIcd10Code?.ailment}
                    options={icd10AilmentSimpleList}
                    isClearable
                  />
              </Col>
            </Row>
            <Row className="mb-4 px-4 py-3 w-100">
              <Col xs="6">
                <StyledSelect
                    id="category"
                    label="Category"
                    onChange={(e) => onChange("category", e, currentIcd10Code, setCurrentIcd10Code)}
                    value={currentIcd10Code?.category}
                    options={icd10CategorySimpleList}
                    isClearable
                  />
              </Col>
              <Col>
                <StyledSelect
                    id="source"
                    label="Source"
                    onChange={(e) => onChange("source", e, currentIcd10Code, setCurrentIcd10Code)}
                    value={currentIcd10Code?.source}
                    options={icd10SourceSimpleList}
                    isClearable
                  />
              </Col>
            </Row>
            <Row className="mb-4 px-4 py-3 w-100">
              <Col xs="6">
                 <StyledInput
                    id="referencePeriodYearCount"
                    name="referencePeriodYearCount"
                    label="Ref. Period Year Count"
                    maxLength="10"
                    value={currentIcd10Code?.referencePeriodYearCount}
                    onChange={e => onChange("referencePeriodYearCount", e.target.value, currentIcd10Code, setCurrentIcd10Code)}
                  />
              </Col>
            </Row>
        </StyledModal>

        {/* Ailment Edit Modal */}
        <StyledModal
            show={ailmentEditModalOpen}
            showCancel
            onHide={reset}
            size="xl"
            title={currentIcd10Ailment ? "Update Ailment" : "Create Ailment"}
            onSave={saveIcd10Ailment}
            savePrompt="Save Ailment"
            showRequiredFieldsMessage={true}
            disabled={loading}
            isScrollable={false}
        >
            <Row className="mb-4 px-4 py-3 w-100">
              <Col xs="6">
                <StyledInput
                    id="label"
                    name="label"
                    label="Name"
                    maxLength="250"
                    required
                    value={currentIcd10Ailment?.label}
                    onChange={e => onChange("label", e.target.value, currentIcd10Ailment, setCurrentIcd10Ailment)}
                  />
              </Col>
    
            </Row>
        </StyledModal>

          {/* Source Edit Modal */}
          <StyledModal
            show={sourceEditModalOpen}
            showCancel
            onHide={reset}
            size="xl"
            title={currentIcd10Source ? "Update Source" : "Create Source"}
            onSave={saveIcd10Source}
            savePrompt="Save Source"
            showRequiredFieldsMessage={true}
            disabled={loading}
            isScrollable
        >
            <Row className="mb-4 px-4 py-3 w-100">
              <Col xs="6">
                <StyledInput
                    id="label"
                    name="label"
                    label="Name"
                    maxLength="250"
                    required
                    value={currentIcd10Source?.label}
                    onChange={e => onChange("label", e.target.value, currentIcd10Source, setCurrentIcd10Source)}
                  />
              </Col>
    
            </Row>
        </StyledModal>

          {/* Category Edit Modal */}
          <StyledModal
            show={categoryEditModalOpen}
            showCancel
            onHide={reset}
            size="xl"
            title={currentIcd10Ailment ? "Update Category" : "Create Category"}
            onSave={saveIcd10Category}
            savePrompt="Save Category"
            showRequiredFieldsMessage={true}
            disabled={loading}
            isScrollable
        >
            <Row className="mb-4 px-4 py-3 w-100">
              <Col xs="6">
                <StyledInput
                    id="label"
                    name="label"
                    label="Name"
                    maxLength="250"
                    required
                    value={currentIcd10Category?.label}
                    onChange={e => onChange("label", e.target.value, currentIcd10Category, setCurrentIcd10Category)}
                  />
              </Col>
    
            </Row>
        </StyledModal>
    </PageContainer>
    )
}
