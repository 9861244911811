import React, { useState, useEffect, useReducer, useContext } from "react";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authConfig";
import {
  AuthButton,
  ProfileData,
  ClientDashboard,
  MemberManagement,
  ClientManagement,
  CaseManager,
  Temp,
  ProductManagement,
  SearchParameterAdmin,
  ValueSetAdmin,
  ICD10MappingAdmin,
  DiagnosisIcd10CodesAdmin,
  Logout,
  EditUser,
  UsersAdmin,
  Dashboard,
  Toolbox,
  DiagnosticAdmin,
  ProductAdmin,
  ClientAdmin,
  FhirAdmin,
  PatientChartDashboard,
  NotAllowed,
  Loader,
  SavingsNavigator,
  PlanNavigator,
  CampaignDashboard,
  PatientChartDiagnosis,
  ProductConfiguration,
  ClientGroupAdmin,
} from "./components";
import { callMsGraph } from "./graph";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faEllipsisV);

// INTERNAL
import wellness from "./assets/images/wellnecity_logo_3.png";

import { storage, api, constants, UserContext } from "./utils";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "axios";
import { msalInstance } from "./index";
import { InteractionType } from "@azure/msal-browser";
import { NewPatient } from "./pages";
import MergeAdmin from "./pages/MergeAdmin";

const {
  ACCESS_VIEWS,
  ROUTES,
  localStorageKeys,
  THIRTY_MINUTES,
  TWO_MINUTES,
  ONE_MINUTE,
} = constants;
const { BASIC, CAMPAIGNS, PATIENT_CHART, PATIENT_CHART_READONLY } =
  ACCESS_VIEWS;

const LoginPrompt = ({ loading }) => {
  return (
    <div className="split-screen">
      <div className="left">
        <section className="copy">
          <img src={wellness} alt="logo" className="logo" />
        </section>
      </div>
      <div className="right">
        <form>
          <section className="copy d-flex justify-content-center">
            <h2>Welcome back!</h2>
            <div className="login-container"></div>
          </section>
          {loading ? <Loader /> : <AuthButton />}
        </form>
      </div>
    </div>
  );
};

function verifyAccess(module, allowedViews, isAdmin) {
  let currentUser = storage.getItem("currentUser");
  currentUser = currentUser ? JSON.parse(currentUser) : null;

  if (
    !currentUser ||
    (isAdmin && !currentUser.isAdmin) ||
    (!isAdmin &&
      !_.some(allowedViews, (v) => {
        return _.find(currentUser.views, (uv) => {
          return uv.typeOfView === v.value;
        });
      }))
  ) {
    return <NotAllowed />;
  }
  return module;
}

export default function App() {
  const isAuthenticated = useIsAuthenticated();

  const { instance } = useMsal();
  const [currentUser, setCurrentUser] = useState(() => {
    let storedUser = storage.getItem(localStorageKeys.currentUser);
    storedUser = storedUser ? JSON.parse(storedUser) : null;
    return storedUser;
  });
  const [loading, setLoading] = useState(false);
  const [showSsn, setShowSsn] = useState(false);
  const [userUpdate, setUserUpdate] = useState(false);
  const [userProfilePhoto, setUserProfilePhoto] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();
  const history = useHistory();
  const userCtx = useContext(UserContext);

  const handleRedirect = () =>
    setTimeout(() => navigate("/", { replace: true }), 300);

  const setLastActivity = () => {
    if (currentUser === null) {
      if (storage.getItem(localStorageKeys.lastActivity))
        storage.removeItem(localStorageKeys.lastActivity);
      return; // not logged in
    }
    storage.setItem(localStorageKeys.lastActivity, nowTime());
  };

  const nowTime = () => {
    return new Date().getTime();
  };
  let lastActivityCheckedAt = nowTime();

  const startTimer = () => {
    setInterval(compareTimerToLastActivity, TWO_MINUTES);
  };

  useEffect(() => {
    if (currentUser) {
      window.addEventListener("click", setLastActivity);
      startTimer();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      if (lastCheckWasRecent()) return;
      compareTimerToLastActivity();
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isAuthenticated && currentUser) {
      setAuthorized(false);
      return;
    }
    if (isAuthenticated && currentUser) {
      setAuthorized(true);
    }
  }, [isAuthenticated, currentUser]);

  function lastCheckWasRecent() {
    return nowTime() - lastActivityCheckedAt < ONE_MINUTE;
  }

  const compareTimerToLastActivity = () => {
    lastActivityCheckedAt = nowTime();
    const lastActivity = storage.getItem(localStorageKeys.lastActivity);
    if (!lastActivity) return;
    const timeoutInterval =
      process.env.NODE_ENV === "Development" ? TWO_HOURS : THIRTY_MINUTES;
    if (nowTime() - parseInt(lastActivity) > timeoutInterval) {
      api.logData({
        eventType: constants.LOG_EVENT_TYPES.TIMEOUT,
        severity: constants.LOG_SEVERITIES.INFO,
        message: "User timeout after 30 minutes",
        clientName: null,
        patientId: null,
        pHIFlag: false,
        resourceList: null,
      });
      handleLogout(); //force the user to log out silently.
    }
  };

  const handleLogout = () => {
    api.logData({
      eventType: constants.LOG_EVENT_TYPES.LOGOUT,
      severity: constants.LOG_SEVERITIES.INFO,
      message: "User logged out",
      clientName: null,
      patientId: null,
      pHIFlag: showSsn,
      resourceList: null,
    });

    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      const logoutHint = currentAccount?.idTokenClaims?.login_hint ?? null;
      window.removeEventListener("click", setLastActivity);
      const logoutRequest = {
        account: currentAccount,
        logoutHint: logoutHint,
      };
      instance
        .logoutRedirect(logoutRequest)
        .catch((err) => console.error(err))
        .finally(() => handleRedirect());
    }
    setCurrentUser(null);
    localStorage.removeItem(localStorageKeys.currentUser);
  };

  function fetchExtraUserData(email) {
    setLoading(true);
    // console.log('Starting session for ' + email);
    api
      .securePost("Auth/StartSession", {})
      .then((res) => {
        if (res && res.data.success) {
          setCurrentUser(res.data.message);
          storage.setItem(
            localStorageKeys.currentUser,
            JSON.stringify(res.data.message)
          );
        } else {
          console.error("Failed to start session: ", res.data.message);
          setCurrentUser(null);
          storage.clear();
          navigate("/logout");
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      fetchExtraUserData();
    }
  }, [isAuthenticated]);

  const getPhoto = async (accountName, token) => {
    const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";
    return axios.get(graphEndpoint, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "arraybuffer",
    });
  };

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        if (!response || !response.account) return;
        // setAuthorized(response.idTokenClaims.groups.includes)
        instance.setActiveAccount(response.account);
        fetchExtraUserData(response.account.username);
        getPhoto(response.account.username, response.accessToken)
          .then((res) => {
            if (!res || !res.data) return;
            storage.setItem(
              `profile-photo`,
              Buffer.from(res.data, "binary").toString("base64")
            );
          })
          .catch((noPhotoError) => {
            // ignore
          });
        api.logData({
          eventType: constants.LOG_EVENT_TYPES.LOGIN,
          severity: constants.LOG_SEVERITIES.INFO,
          message: `User ${response.account.name} successfully logged in with email ${response.account.username}.`,
          clientName: null,
          patientId: null,
          pHIFlag: false,
          resourceList: null,
        });
      })
      .finally(() => {
        handleRedirect();
      })
      .catch((err) => console.error(err));
  };

  function renderAuthCheck() {
    if (lastCheckWasRecent()) return true;
    compareTimerToLastActivity();
    return true;
  }

  // const removeSessionData = () => {
  //   const keys = [...storage.getKeys()];
  //   if (keys && keys.length) {
  //     const filteredKeys = _.filter(
  //       keys,
  //       (k) => k !== localStorageKeys.appVersion
  //     );
  //     for (let i = 0; i < keys.length; i++) {
  //       storage.removeItem(filteredKeys[i]);
  //     }
  //   }
  //   // check if legacy indexDb for ms-photos is there and delete it
  //   indexedDB.deleteDatabase("mgt-photos");
  //   indexedDB.deleteDatabase("mgt-users");
  // };

  let user = {
    currentUser: currentUser,
    updateCurrentUser: fetchExtraUserData,
    logout: handleLogout,
    login: handleLogin,
    showSsn: showSsn,
    setShowSsn: setShowSsn,
    userPhoto: userProfilePhoto,
  };

  const isAuthorized = isAuthenticated && currentUser && renderAuthCheck();
  // if (!isAuthenticated && currentUser) {
  //   userCtx.logout();
  //   // navigate("/");
  // }
  return (
    <UserContext.Provider value={user}>
      {authorized ? (
        <AuthenticatedTemplate>
          <Routes>
            <Route
              path="/"
              element={verifyAccess(<ClientDashboard />, [
                BASIC,
                PATIENT_CHART,
                PATIENT_CHART_READONLY,
              ])}
            />
            <Route
              path="dashboard/:type/:clientId/:campaignId/:caseId"
              element={verifyAccess(<Dashboard />, [CAMPAIGNS])}
            />
            <Route
              path="dashboard/:type/:clientId/:campaignId"
              element={verifyAccess(<Dashboard />, [CAMPAIGNS])}
            />
            <Route
              path="dashboard/:type/:clientId"
              element={verifyAccess(<Dashboard />, [CAMPAIGNS])}
            />
            <Route
              path="dashboard/:type"
              element={verifyAccess(<Dashboard />, [CAMPAIGNS])}
            />
            <Route
              path={ROUTES.Client.Campaigns}
              element={verifyAccess(<CampaignDashboard />, [CAMPAIGNS])}
            />
            <Route
              path={ROUTES.Client.SavingsNavigator}
              element={verifyAccess(<SavingsNavigator />, [CAMPAIGNS])}
            />
            <Route
              path={ROUTES.Client.PlanDetails}
              element={verifyAccess(<PlanNavigator />, [CAMPAIGNS])}
            />
            <Route
              path={ROUTES.Client.ClientAdmin}
              element={verifyAccess(<ClientManagement />, [BASIC])}
            />
            <Route
              path={ROUTES.Product.Configuration}
              element={verifyAccess(<ProductConfiguration />, [CAMPAIGNS])}
            />
            <Route
              path={ROUTES.Product.Management}
              element={verifyAccess(<ProductManagement />, [BASIC])}
            />
            {/*test*/}
            <Route
              path={ROUTES.Product.Reporting}
              element={verifyAccess(<Temp />, [BASIC])}
            />
            <Route
              path={"clients/:clientId/campaigns/:campaignId"}
              element={verifyAccess(<CaseManager />, [CAMPAIGNS])}
            />
            <Route
              path={ROUTES.Client.PatientChartDiagnosis}
              element={verifyAccess(<PatientChartDiagnosis />, [
                PATIENT_CHART,
                PATIENT_CHART_READONLY,
              ])}
            />
            <Route
              path={ROUTES.Client.PatientChart}
              element={verifyAccess(<PatientChartDashboard />, [
                PATIENT_CHART,
                PATIENT_CHART_READONLY,
              ])}
            />
            <Route
              path={ROUTES.Client.NewPatient}
              element={verifyAccess(<NewPatient />, [PATIENT_CHART])}
            />
            <Route
              path={ROUTES.Client.MemberManagement}
              element={verifyAccess(<MemberManagement />, [
                PATIENT_CHART,
                PATIENT_CHART_READONLY,
              ])}
            />
            <Route
              path={ROUTES.Client.PatientMerge}
              element={verifyAccess(<MergeAdmin />, [PATIENT_CHART], true)}
            />
            <Route
              path={ROUTES.Toolbox}
              element={verifyAccess(<Toolbox />, [BASIC])}
            />
            <Route
              path={ROUTES.Admin.ClientAdmin}
              element={verifyAccess(<ClientAdmin />, [BASIC], true)}
            />
            <Route
              path={`${ROUTES.Admin.UserAdmin}/:userId`}
              element={verifyAccess(<EditUser />, [], true)}
            />
            <Route
              path={ROUTES.Admin.UserAdmin}
              element={verifyAccess(<UsersAdmin />, [], true)}
            />
            <Route
              path={ROUTES.Admin.FhirAdmin}
              element={verifyAccess(<FhirAdmin />, [], true)}
            />
            <Route
              path="diagnostic-admin/:clientId/:clientName"
              element={verifyAccess(<DiagnosticAdmin />, [], true)}
            />
            <Route
              path="diagnosticAdmin"
              element={verifyAccess(<DiagnosticAdmin />, [], true)}
            />
            <Route
              path="productAdmin"
              element={verifyAccess(<ProductAdmin />, [], true)}
            />
            <Route
              path={ROUTES.Admin.ClientGroupAdmin}
              element={verifyAccess(<ClientGroupAdmin />, [], true)}
            />
            <Route
              path={`${ROUTES.Admin.FhirAdmin}${ROUTES.FhirAdmin.SearchParameterAdmin}`}
              element={verifyAccess(<SearchParameterAdmin />, [], true)}
            />
            <Route
              path={`${ROUTES.Admin.FhirAdmin}${ROUTES.FhirAdmin.ValueSetAdmin}`}
              element={verifyAccess(<ValueSetAdmin />, [], true)}
            />
            <Route
              path={`${ROUTES.Admin.FhirAdmin}${ROUTES.FhirAdmin.ValueSetAdmin}/:valueSet`}
              element={verifyAccess(<ValueSetAdmin />, [], true)}
            />
            <Route
              path={`${ROUTES.Admin.FhirAdmin}${ROUTES.FhirAdmin.ICD10MappingAdmin}`}
              element={verifyAccess(<ICD10MappingAdmin />, [], true)}
            />
            <Route
              path={`${ROUTES.Admin.FhirAdmin}${ROUTES.FhirAdmin.DiagnosisIcd10CodesAdmin}`}
              element={verifyAccess(<DiagnosisIcd10CodesAdmin />, [], true)}
            />
            <Route path="product/temp" element={<Temp />} />
            <Route path="/forbidden" element={<NotAllowed />} />
            {/*<Route path="logout" element={<Logout />} />*/}
          </Routes>
        </AuthenticatedTemplate>
      ) : (
        <UnauthenticatedTemplate>
          <Routes>
            <Route path={"/"} element={<LoginPrompt />} />
            <Route path={"/logout"} element={<Logout />} />
            <Route path="/forbidden" element={<NotAllowed />} />
          </Routes>
        </UnauthenticatedTemplate>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        limit={3}
      />
    </UserContext.Provider>
  );
}