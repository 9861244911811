import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  forwardRef,
} from "react";
import { LongBreadCrumb, PageContainer } from "./";
import {
  ButtonGroup,
  Card,
  Col,
  Row,
  Button,
  Table,
  CardHeader,
  CardBody,
} from "reactstrap";
import { api, dateHelpers, helpers } from "../utils";
import _ from "lodash";
import { useParams } from "react-router-dom";
import accounting from "accounting";
// import family from "../assets/svg/family.svg";
// import {ReactComponent as family} from "../assets/svg/family.svg";
import { FamilyIcon, MedicalIcon, PharmacyIcon } from "./icons";
import chroma from "chroma-js";
import { BiDotsHorizontalRounded, BiExpand } from "react-icons/bi";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const AscendingIcon = () => {
  return <AiFillCaretUp color={"#76c664"} />;
};

const DescendingIcon = () => {
  return <AiFillCaretDown color={"#ff3f3f"} />;
};

// const formatter = new Intl.NumberFormat('en', {style: 'compact'});

const DisplayCard = (props) => {
  const size = "120";

  const getIcon = () => {
    if (props.title === atAGlanceItems.population)
      return <FamilyIcon className={"on-blue"} />;
    if (props.title === atAGlanceItems.medicalClaims)
      return <MedicalIcon className={"dash-image"} />;
    if (props.title === atAGlanceItems.pharmacyClaims)
      return <PharmacyIcon className={"dash-image"} />;
  };

  return (
    <Card className={props.className}>
      <Col>
        <Row className={"p-2 d-flex justify-content-between"}>
          <span className={"dash-card-header"}>{props.title}</span>
          <BiExpand className={"card-expand"} />
        </Row>
        <Row>
          <Col lg={"4"} className={"m-0 p-0 d-flex justify-content-start"}>
            {/*{props.icon ? <img width={100} src={props.icon} className={'dash-image'}/> : null}*/}
            {getIcon()}
          </Col>
          <Col lg={"8"} className={"m-0 ps-0 py-0 pe-2"}>
            {props.data}
          </Col>
        </Row>
      </Col>
    </Card>
  );
};

// const CardBodyRef = forwardRef((props, ref) => {
//     return <CardBody ref={ref} props={props}/>;
// });

const DashboardCard = (props) => {
  return (
    <Card className={"sm-drop-shadow dash-card h-100 w-100"}>
      <CardHeader className={"dash-card-header"}>
        <Row
          lg={"auto"}
          className={
            "p-0 m-0 d-flex align-items-stretch justify-content-between"
          }
        >
          <Col
            className={
              "m-0 p-0 d-flex justify-content-start align-items-center dash-card-title"
            }
          >
            <Col lg={"auto"} className={"m-0 p-0"}>
              <span className={"dash-card-title"}>{props.title}</span>
            </Col>
            {props.subTitle ? (
              <>
                <Col lg={"auto"} className={"m-0 p-0 h-100"}>
                  <div className={"bread-crumb-separator"} />
                </Col>
                <Col lg={"auto"} className={"m-0 p-0 "}>
                  <span className={"dash-card-sub-title"}>
                    {props.subTitle}
                  </span>
                </Col>
              </>
            ) : null}
          </Col>
          <Col
            className={
              "m-0 px-2 py-0 d-flex justify-content-end align-items-center"
            }
          >
            <BiDotsHorizontalRounded color={"black"} size={"1.6rem"} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody innerRef={props.ref}>
        <Row lg={12} className={"p-3"}>
          {props.children}
        </Row>
      </CardBody>
      {/*<Col className={'m-0 px-1 py-2'}>*/}
      {/*    /!*Header*!/*/}

      {/*    /!*<Row className={'d-flex align-items-center justify-content-center'}>*!/*/}
      {/*    /!*</Row>*!/*/}
      {/*</Col>*/}
    </Card>
  );
};

const donutDummyData = [
  { key: "Medical", data: 16646000, color: "#6a9263" },
  { key: "Pharmacy", data: 1219000, color: "#e9ae51" },
  { key: "Administrative", data: 531000, color: "#6cabdb" },
  { key: "Health Management", data: 1007000, color: "#006f69" },
];

const atAGlanceItems = {
  population: "Population",
  medicalClaims: "Medical Claims",
  pharmacyClaims: "Pharmacy Claims",
};

export default function PlanNavigator(props) {
  let { type, clientId, campaignId, caseId } = useParams();
  clientId = clientId ? parseInt(clientId, 10) : null;
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);

  const [chartHeight, setChartHeight] = useState(400);
  const [chartWidth, setChartWidth] = useState(550);

  let spendingRef = useRef(null);

  function refreshData() {
    let apiCalls = [];
    apiCalls.push(getClient());
    setLoading(true);
    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));
        if (aggResults.client) setClient(aggResults.client);
      })
      .finally(() => setLoading(false))
      .catch(api.catchHandler);
  }

  function getClient() {
    return api
      .secureFetch(`Client/Client/${clientId}`)
      .then((response) => {
        if (response && response.data) {
          return { client: response.data };
        }
      })
      .catch(api.catchHandler);
  }

  useEffect(() => {
    refreshData();
  }, []);

  const getCardSize = () => {
    if (spendingRef.current === null) return;
    setChartWidth(spendingRef.current.offsetWidth);
    // console.log('width: ' + spendingRef.current.offsetWidth);
    // console.log('height: ' + spendingRef.current.offsetHeight);
    setChartHeight(spendingRef.current.offsetHeight);
  };

  useEffect(() => {
    if (spendingRef.current !== null) {
      getCardSize();
    }
    return () => {
      spendingRef = null;
    };
  }, [spendingRef]);

  useEffect(() => {
    window.addEventListener("resize", getCardSize);
    return () => {
      window.removeEventListener("resize", getCardSize);
    };
  }, []);

  function getYear() {
    let dt = new Date();
    return dt.getFullYear();
  }

  return (
    <PageContainer>
      <Col className={"m-0 p-0 h-100 flex-column justify-content-between"}>
        {/*<Row className={'m-0 p-0 d-flex align-items-stretch w-100 h-100'}>*/}

        {/*Bread Crumb Row*/}
        <LongBreadCrumb
          classes={"d-flex justify-content-between"}
          client
          page={"Plan"}
          loading={loading}
          trailing={
            <ButtonGroup>
              <Button
                color={"secondary"}
                className={"plan-toggle-buttons"}
                outline
              >
                Benefits
              </Button>
              <Button
                color={"secondary"}
                className={"plan-toggle-buttons"}
                outline
              >
                Spending
              </Button>
              <Button
                color={"secondary"}
                className={"plan-toggle-buttons"}
                outline
              >
                Savings
              </Button>
            </ButtonGroup>
          }
        />
        {/*At a glance cards*/}
        <Row className={"m-0 p-0"}>
          <Col sm={12} lg={4} className={"m-0 p-0"}>
            <DisplayCard
              title={atAGlanceItems.population}
              className={"pop-card dash-card"}
              // icon={family}
              data={
                <Table size={"sm"} borderless>
                  <tr className={"card-row"}>
                    <th></th>
                    <th className={"th-card-light"}>Actual</th>
                    <th className={"th-card-light"}>Projected</th>
                    <th className={"th-card-light"}>Change</th>
                  </tr>
                  <tbody>
                    <tr className={"card-row th-data-row"}>
                      <th scope="row" className={"th-card"}>
                        Employees
                      </th>
                      <td className={"th-card"}>1,267</td>
                      <td className={"th-card"}>1,302</td>
                      <td className={"th-card"}>
                        <DescendingIcon /> 35
                      </td>
                    </tr>
                    <tr className={"card-row th-data-row"}>
                      <th scope="row" className={"th-card"}>
                        Total Lives
                      </th>
                      <td className={"th-card"}>3,186</td>
                      <td className={"th-card"}>3,206</td>
                      <td className={"th-card"}>
                        <DescendingIcon /> 35
                      </td>
                    </tr>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12} lg={4} className={"m-0 p-0"}>
            <DisplayCard
              title={atAGlanceItems.medicalClaims}
              className={"med-card dash-card"}
              data={
                <Table size={"sm"} borderless>
                  <tr className={"card-row"}>
                    <th></th>
                    <th className={"th-card-light"}>Actual</th>
                    <th className={"th-card-light"}>Projected</th>
                    <th className={"th-card-light"}>Change</th>
                  </tr>
                  <tbody>
                    <tr className={"card-row th-data-row"}>
                      <th scope="row" className={"th-card"}>
                        Costs
                      </th>
                      <td className={"th-card"}>$2,304k</td>
                      <td className={"th-card"}>$3,905k</td>
                      <td className={"th-card"}>
                        <DescendingIcon color={"#cc3333"} /> $1,601k
                      </td>
                    </tr>
                    <tr className={"card-row th-data-row"}>
                      <th scope="row" className={"th-card"}>
                        PEPM
                      </th>
                      <td className={"th-card"}>$289</td>
                      <td className={"th-card"}>$470</td>
                      <td className={"th-card"}>
                        <AscendingIcon /> 63%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12} lg={4} className={"m-0 p-0"}>
            <DisplayCard
              title={atAGlanceItems.pharmacyClaims}
              className={"pharm-card dash-card"}
              data={
                <Table size={"sm"} borderless>
                  <tr className={"card-row"}>
                    <th></th>
                    <th className={"th-card-light"}>Actual</th>
                    <th className={"th-card-light"}>Projected</th>
                    <th className={"th-card-light"}>Change</th>
                  </tr>
                  <tbody>
                    <tr className={"card-row th-data-row"}>
                      <th scope="row" className={"th-card"}>
                        Costs
                      </th>
                      <td className={"th-card"}>$2,304k</td>
                      <td className={"th-card"}>$3,905k</td>
                      <td className={"th-card"}>
                        <DescendingIcon color={"#cc3333"} />
                        $1,601k
                      </td>
                    </tr>
                    <tr className={"card-row th-data-row"}>
                      <th scope="row" className={"th-card"}>
                        PEPM
                      </th>
                      <td className={"th-card"}>$289</td>
                      <td className={"th-card"}>$470</td>
                      <td className={"th-card text-align-left"}>
                        <AscendingIcon /> 63%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
        <Row lg={7} className={"m-0 py-4"}>
          <Col className={"m-0 p-0 w-100 h-100"}>
            <DashboardCard
              ref={spendingRef}
              title={"Spending Breakdown"}
              subTitle={`As of ${dateHelpers.toLongMonth(Date.now())}`}
            >
              <Row
                lg={12}
                className={
                  "d-flex align-items-center justify-content-center w-100"
                }
              >
                {/*<PieChart*/}
                {/*  // className={'savings-donut'}*/}
                {/*  width={580}*/}
                {/*  height={400}*/}
                {/*  center={true}*/}
                {/*  displayAllLabels={true}*/}
                {/*  // margins={[10, 10]}*/}
                {/*  data={donutDummyData}*/}
                {/*  series={*/}
                {/*    <PieArcSeries*/}
                {/*      arcWidth={0.48}*/}
                {/*      doughnut*/}
                {/*      width={380}*/}
                {/*      height={300}*/}
                {/*      animated={true}*/}
                {/*      displayAllLabels={true}*/}
                {/*      padAngle={45}*/}
                {/*      label={*/}
                {/*        <PieArcLabel*/}
                {/*          height={20}*/}
                {/*          padding={"0"}*/}
                {/*          outerRadius={10}*/}
                {/*          format={({ key, data }) => DonutLabel(key, data)}*/}
                {/*        />*/}
                {/*      }*/}
                {/*      colorScheme={_.map(donutDummyData, (d) => d.color)}*/}
                {/*    />*/}
                {/*  }*/}
                {/*/>*/}
              </Row>

              {/*</Col>*/}
              {/*    </div>*/}
              {/*<Col lg={'auto m-0 p-0'}>*/}
              {/*    <Row>*/}
              {/*        Total Annual {getYear()} Budget*/}
              {/*    </Row>*/}
              {/*    <Row>*/}
              {/*        {helpers.formatCurrency(_.sum(_.map(donutDummyData, d => d.data)))}*/}
              {/*    </Row>*/}
              {/*</Col>*/}
              {/*    <h2 style={{ margin: '0', padding: 0, color: 'black' }}>*/}
              {/*    </h2>*/}
              {/*</Row>*/}
              {/*</div>*/}
            </DashboardCard>
          </Col>
          <Col lg={4} className={"w-100 h-100 m-0 ps-2"}>
            <DashboardCard title={"Savings Plan"} />
          </Col>
        </Row>
        {/*</Row>*/}
      </Col>
    </PageContainer>
  );
}

const DonutLabel = (key, data) => {
  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3) return +(n / 1e3).toFixed(1) + "K";
  };

  return (
    <div className={"me-3"}>
      <span className={"chart-key-text"}>{key}</span>
      <span className={"chart-sub-text"}>{helpers.formatCurrency(data)}</span>
    </div>
  );
};