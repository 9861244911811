import { FormGroup, FormControl, TextField } from "@mui/material";
import cx from "classnames";
import { DebounceInput } from "react-debounce-input";
import { FormFeedback, Input, InputGroup } from "reactstrap";
import { StyledFormLabel } from "./";

export default function NuStyledInput(props) {
  const isInvalid = props.errorMessage ? true : false;
  let inputElement = props.debounceTimeout ? (
    <DebounceInput
      type={props.type ?? "text"}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      className={cx(props.className, "form-control")}
      placeholder={props.placeholder}
      inputRef={props.ref}
      name={props.name}
      id={props.id}
      maxLength={props.maxLength}
      autoComplete="new-password"
      debounceTimeout={props.debounceTimeout}
    />
  ) : (
    <Input
      type={props.type ?? "text"}
      value={props.value}
      disabled={props.disabled}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      innerRef={props.ref}
      invalid={isInvalid}
      valid={props.valid}
      className={cx(props.className, "nu__input")}
      placeholder={props.placeholder}
      name={props.name}
      id={props.id}
      autoComplete="new-password"
      maxLength={props.maxLength}
    />
  );
  return (
    <FormControl component="fieldset">
      {/*<fieldset>*/}
      <StyledFormLabel {...props} />
      {/*{props.label ? <legend>{props.label}</legend> : null}*/}
      <FormGroup aria-label="position" row>
        {inputElement}
        <FormFeedback invalid={isInvalid.toString()}>
          {props.errorMessage}
        </FormFeedback>
        {/*</fieldset>*/}
      </FormGroup>
    </FormControl>
  );
}