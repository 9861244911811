import React from "react";
import cx from "classnames";

export default function MedicalIcon(props) {
  return (
    <svg
      clip-rule="evenodd"
      className={cx(props.className, "plan-icons")}
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <g>
          <path d="m18.5 12v-7c0-1.381-1.119-2.5-2.5-2.5-.784 0-1.5 0-1.5 0-.276 0-.5.224-.5.5s.224.5.5.5h1.5c.828 0 1.5.672 1.5 1.5v7c0 .276.224.5.5.5s.5-.224.5-.5z" />
          <path d="m4.5 12v-7c0-.828.672-1.5 1.5-1.5h1.5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5c0 0-.716 0-1.5 0-1.381 0-2.5 1.119-2.5 2.5v7c0 .276.224.5.5.5s.5-.224.5-.5z" />
          <path d="m9.5 20.368v2.132c0 .276.224.5.5.5h2c.276 0 .5-.224.5-.5v-2.132c3.977-.71 7-4.189 7-8.368 0-.276-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5 0 3.036-2.464 5.5-5.5 5.5s-5.5-2.464-5.5-5.5c0-.276-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5 0 4.179 3.023 7.658 7 8.368zm1-.43c0-.252-.188-.465-.438-.496-3.535-.442-6.309-3.348-6.546-6.942h1.003c.256 3.354 3.062 6 6.481 6s6.225-2.646 6.481-6h1.003c-.237 3.594-3.011 6.5-6.546 6.942-.25.031-.438.244-.438.496v2.062h-1z" />
          <path d="m24.5 15.5c-.796 0-1.559.316-2.121.879-.563.562-.879 1.325-.879 2.121v7.5c0 .663-.263 1.299-.732 1.768s-1.105.732-1.768.732c-1.554 0-3.446 0-5 0-1.381 0-2.5-1.119-2.5-2.5v-3.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v3.5c0 1.933 1.567 3.5 3.5 3.5h5c.928 0 1.819-.369 2.475-1.025.656-.657 1.025-1.547 1.025-2.475 0-2.273 0-5.36 0-7.5 0-.53.211-1.039.586-1.414s.884-.586 1.414-.586c1.105 0 2 .895 2 2v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5c0-1.657-1.343-3-3-3z" />
          <path d="m27 19.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5zm0 1c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z" />
        </g>
      </g>
    </svg>
  );
}