import { UserContext } from "../utils";
import React, { useContext, useState } from "react";
import { AuthButton, Loader, SignOutButton } from "./index";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import _ from "lodash";
import wellness from "../assets/images/wellnecity_logo_3.png";

export default function NotAllowed(props) {
  const { errorMessage } = props;
  const { currentUser } = useContext(UserContext);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  if (currentUser)
    return (
      <div className="split-screen">
        <div className="left">
          <section className="copy">
            <img src={wellness} alt="logo" className="logo" />
          </section>
        </div>
        <div className="right">
          <div className="d-flex align-items-center justify-content-center h-75">
            <Col>
{/*              <Row className={"d-flex justify-content-center  my-4"}>
                <h2>Forbidden</h2>
              </Row>
              <Row>
                <span style={{ fontSize: "1.2rem" }}>
                  You are not authorized to view this content. Please contact
                  your administrator.
                </span>
              </Row>
*/}              {isAuthenticated ? (
                <Row
                  className={
                    "d-flex align-items-center justify-content-center my-4"
                  }
                >
                  <SignOutButton />
                </Row>
              ) : null}
            </Col>
          </div>
        </div>
      </div>
    );
}
