import React, {useState, useEffect} from 'react';
import {
   Loader, 
   LongBreadCrumb,
    PageContainer, 
    StyledButton, 
    StyledModal, 
    StyledInput, 
    StyledSelect, 
    Pager 
  } from "./";
  import { api, constants } from "../utils";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { toast } from 'react-toastify';
import { FiMinusCircle } from "react-icons/fi";

const EMPTY_SEARCH_PARAMETER = {
  id: "",
  clientId: 0,
  url: "",
  base: null,
  code: "",
  name: "", 
  type: null,
  expression: "",
  status: null,
  description: "",
}

export default function SearchParameterAdmin() {
  const [loading, setLoading] = useState(false);
  const [reconfiguring, setReconfiguring] = useState(false);
  const [activeOnly, setActiveOnly] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [currentSearchParameter, setCurrentSearchParameter] = useState(Object.assign({}, EMPTY_SEARCH_PARAMETER));
  const [isDeleting, setIsDeleting] = useState(false);
  const [paginatedClients, setPaginatedClients] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    refreshData();
  }, [pageNumber])

  function refreshData() {
    if (!loading) {
        setLoading(true);
        let apiCalls = [];
        apiCalls.push(getClients());
        Promise.all(apiCalls)
            .then((arrayResults) => {
                let aggResults = {};
                _.each(arrayResults, (x) => Object.assign(aggResults, x));
                if (aggResults.clients.list) {
                  _.forEach(aggResults.clients.list, client => {
                    client.searchParameters = 
                        _.map(client.searchParameters, searchParameter => {
                          return(
                            {
                            ...searchParameter,
                            type: _.find(constants.SEARCH_PARAMETER_TYPES, x => x.value === searchParameter.type),
                            resourceType: _.find(constants.FHIR_RESOURCE_TYPES, x => x.value === searchParameter.base),
                            status: _.find(constants.FHIR_PUBLICATION_STATUSES, x => x.value === searchParameter.status),
                            }
                          );
                        })
                  })
                    setPaginatedClients(aggResults.clients);
                }
            })
            .catch(api.catchHandler)
            .finally(() => setLoading(false));
    }
  }

  function getClients() {
    return api
        .securePost(`Client/PaginatedList`, {
            ActiveOnly: activeOnly,
            SortField: "Name",
            SortDirection: "ASC",
            IncludeSearchParameters: true,
            PageNumber: pageNumber
        })
        .then((response) => {
            if (response && response.data && response.data.success) {
                return { clients: response.data.message };
            }
        })
        .catch(api.catchHandler);
  }

  function reset() {
    setEditModalOpen(false);
    setViewModalOpen(false);
    setIsDeleting(false);
    setCurrentSearchParameter(Object.assign({}, EMPTY_SEARCH_PARAMETER));
  }

  function validated() {
    if(!currentSearchParameter) {
      toast.error("No Search Parameter found. Please try again");
      return false;
    }
    if(!currentSearchParameter.url) {
      toast.warning("Url is required");
      return false;
    }
    if(!currentSearchParameter.base) {
      toast.warning("Resource Type is required");
      return false;
    }
    if(!currentSearchParameter.type) {
      toast.warning("Type is required");
      return false;
    }
    if(!currentSearchParameter.expression) {
      toast.warning("Expression is required");
      return false;
    }
    if(!currentSearchParameter.code) {
      toast.warning("Code is required");
      return false;
    }
    if(!currentSearchParameter.name) {
      toast.warning("Name is required");
      return false;
    }
    if(!currentSearchParameter.description) {
      toast.warning("Description is required");
      return false;
    }
    return true;
  }

  function updateSearchParameter(payloadChange, action) {
    setLoading(true);

    let payload = {
      ...currentSearchParameter,
      clientId: currentClient?.id,
      type: currentSearchParameter?.type?.value,
      base: currentSearchParameter?.base?.value,
      ...payloadChange,
    }
      
    api
      .securePost("Client/CreateSearchParameter", payload)
      .then((response) => {
        if(response && response.data && response.data.success) {
          reset();
          refreshData();
          toast.success(`Search Parameter successfully ${action}`);
        } else {
          toast.error(`Search Parameter could not be ${action}`);
        }
      })
      .catch(() => {
          toast.error(`Search Parameter could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  function saveSearchParameter() {
    if(!validated()) return;
    updateSearchParameter(
      {status: _.find(constants.FHIR_PUBLICATION_STATUSES, x => x.label === "Active")?.value}, 
      "saved"
      );
  }

  function deleteSearchParam() {
    api.deleteResource(
      (message) => {
        toast.success(message);
        reset();
        refreshData();
      },
      currentSearchParameter,
      
      constants.FHIR_RESOURCES.SEARCH_PARAMETER,
      currentClient?.id,
    )
  }

  function reconfigureSearchParameters(client) {
    setReconfiguring(true);
    let payload = {
      clientId: client?.id,
      status: _.find(constants.FHIR_PUBLICATION_STATUSES, x => x.label === "Active")?.value,
      urls: _.map(client?.searchParameters, "url")
    }
      
    api
      .securePost("Client/ReconfigureSearchParameters", payload)
      .then((response) => {
        if(response && response.data && response.data.success) {
          toast.success(`Search Parameters successfully reconfigured`);
        } else {
          toast.error(`Search Parameters could not be reconfigured`);
        }
      })
      .catch(() => {
          toast.error(`Search Parameters could not be reconfigured`);
      })
      .finally(() => {
        setReconfiguring(false);
      })
  }

  function onChangeSearchParameter(field, value) {
    let tempSearchParameter = Object.assign({}, currentSearchParameter);
    tempSearchParameter[field] = value;
    setCurrentSearchParameter(tempSearchParameter);
  }

  const ClientCard = ({client, fhirBaseUrl, searchParameters}) => {
    return (
        <Card
          className={`cards-body mb-4 backgroundClient`}
          style={{height: "auto"}}
          to={""}
        >
          <CardBody>
            <Row>
              <Col>
                <div className="flex" style={{justifyContent: "space-between"}}>
                  <div>
                    <div className="cardTitle">{client.name}</div>
                    <div className="cardSubtitle small text-muted">{client.fhirBaseUrl ? client.fhirBaseUrl : `No FHIR Url available for ${client.name}`}</div>
                  </div>
                  <div>
                    <MdOutlineAddCircleOutline
                      className="clickable-icon"
                      onClick={() => {
                        setCurrentClient(client);
                        setEditModalOpen(true);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 ml-5 text-left">
                  {_.map(searchParameters, x => {
                    return(
                      <div
                        className="flex"
                      >
                        <div>
                          <FiMinusCircle
                            className="clickable-icon red mr-2"
                            onClick={() => {
                              setViewModalOpen(true);
                              setIsDeleting(true);
                              setCurrentClient(client);
                              setCurrentSearchParameter(x);
                            }}
                          />
                        </div>
                        <div
                            className="mb-2 clickableRow cursorPointer"
                            onClick={() => {
                              setViewModalOpen(true);
                              setCurrentSearchParameter(x);
                            }}
                        >
                          {x.expression} ({x.description})
                        </div>
                        
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col>
                <StyledButton
                  onClick={() => reconfigureSearchParameters(client)}
                  children={"Reconfigure"}
                  color="primary"
                  size="sm"
                  className="float-right"
                />
              </Col>
            </Row>
          </CardFooter>
        </Card>
   
    );
  };


  return (
    <>
      <PageContainer>
          {loading
          ? 
            <Loader center={'m-auto'}/>
          : 
          <>
            <Col className={'m-0 p-0'}>
              <Row className={'mx-0 my-3 d-flex justify-content-start align-items-center'}>
                  <LongBreadCrumb context={"Search Parameter"} page={"Management"} loading={loading} />
              </Row>
                {_.map(paginatedClients?.list, client => {
                  return(
                    <Row className={'m-0 p-0'}>
                      <Col xs="12">
                        <ClientCard
                          client={client}
                          fhirBaseUrl={client.fhirBaseUrl}
                          searchParameters={client.searchParameters}
                        />
                      </Col>
                    </Row>
                  );
                })}
              <Row>
                <Pager
                  pageNumber={paginatedClients?.pageNumber ? paginatedClients.pageNumber : 0}
                  totalPages={paginatedClients?.totalPages ? paginatedClients.totalPages : 0}
                  callBack={(newPageNumber) => setPageNumber(newPageNumber)}
                  />
              </Row>
          </Col>
        </>
        }
      </PageContainer>

      {/* Search Parameter View Only Modal */}
      <StyledModal
          show={viewModalOpen}
          showRequiredFieldsMessage={false}
          showCancel={isDeleting}
          scrollable
          onHide={reset}
          size="xl"
          title={isDeleting ? "Delete Search Parameter" : "Search Parameter Display"}
          onSave={isDeleting ? deleteSearchParam : reset}
          savePrompt={isDeleting ? "Delete" : "Close"}
          disabled={loading}
        >
          <div className="px-4 py-3 w-100">
            <div className="mb-1"><b>Url:&nbsp;</b>{currentSearchParameter?.url}</div>
            <div className="mb-1"><b>Resource Type:&nbsp;</b>{currentSearchParameter?.resourceType?.label}</div>
            <div className="mb-1"><b>Type:&nbsp;</b>{currentSearchParameter?.type?.label}</div>
            <div className="mb-1"><b>Expression:&nbsp;</b>{currentSearchParameter?.expression}</div>
            <div className="mb-1"><b>Code:&nbsp;</b>{currentSearchParameter?.code}</div>
            <div className="mb-1"><b>Name:&nbsp;</b>{currentSearchParameter?.name}</div>
            <div className="mb-1"><b>Description:&nbsp;</b>{currentSearchParameter?.description}</div>
          </div>
        </StyledModal>



      {/* Search Parameter Create Modal */}
      <StyledModal
        show={editModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={currentClient?.name ? `Create Search Parameter for ${currentClient?.name}` : "Create Search Parameter"}
        onSave={saveSearchParameter}
        savePrompt={"Create"}
        showRequiredFieldsMessage={true}
        disabled={loading}
        scrollable
        >
          <div className="px-4 py-3 w-100">
            <Row className="mb-4">
              <Col xs="8">
                <StyledInput
                    id="url"
                    name="url"
                    label="Url"
                    required
                    value={currentSearchParameter?.url}
                    onChange={e => onChangeSearchParameter("url", e.target.value)}
                  />
              </Col>
              <Col>
                <StyledSelect
                  label="Resource Type"
                  isClearable
                  required
                  options={constants.FHIR_RESOURCE_TYPES}
                  name="base"
                  id="base"
                  value={currentSearchParameter?.base || ""}
                  onChange={(selection) =>
                    onChangeSearchParameter("base", selection)
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="6">
                <StyledSelect
                  label="Type"
                  isClearable
                  required
                  options={constants.SEARCH_PARAMETER_TYPES}
                  name="type"
                  id="type"
                  value={currentSearchParameter?.type || ""}
                  onChange={(selection) =>
                    onChangeSearchParameter("type", selection)
                  }
                />
              </Col>
              <Col>
                <StyledInput
                    id="expresion"
                    name="expression"
                    label="Expression"
                    required
                    value={currentSearchParameter?.expression}
                    onChange={e => onChangeSearchParameter("expression", e.target.value)}
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="6">
                <StyledInput
                    id="code"
                    name="code"
                    label="Code"
                    required
                    value={currentSearchParameter?.code}
                    onChange={e => onChangeSearchParameter("code", e.target.value)}
                  />
              </Col>
              <Col>
                <StyledInput
                    id="name"
                    name="name"
                    label="Name"
                    required
                    value={currentSearchParameter?.name}
                    onChange={e => onChangeSearchParameter("name", e.target.value)}
                  />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <StyledInput
                    id="description"
                    name="description"
                    label="Description"
                    required
                    value={currentSearchParameter?.description}
                    onChange={e => onChangeSearchParameter("description", e.target.value)}
                  />
              </Col>
            </Row>
          </div>
        </StyledModal>
    </>
  );
}