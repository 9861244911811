import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import React from "react";
import Skeleton from "react-loading-skeleton";
import classnames from "classnames";
import {
  MdFirstPage,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLastPage,
} from "react-icons/md";

const PagerLink = (props) => {
  const classNames = props.className || "";
  return (
    <PaginationItem disabled={props.disabled}>
      <PaginationLink
        className={classnames(props.disabled ? "pager-disabled" : "")}
        aria-disabled={props.disabled}
        onClick={() => props.callBack()}
      >
        {props.linkIcon}
      </PaginationLink>
    </PaginationItem>
  );
};

const StyledPager = (props) => {
  if (!props.callBack) {
    console.warn(
      "Add a callBack function property on the pager that accepts a page param"
    );
  }

  const showPager = props.showPager || props.totalPages > 1;
  const previous = props.totalPages == 0 || props.pageNumber > 1;
  const next =
    props.totalPages == 0 ||
    (props.pageNumber !== props.totalPages && props.totalPages > 1);

  return (
    <div className="mt-2 float-end">
      {showPager && (
        <Pagination className={"border-secondary"}>
          {/*{previous && (*/}
          <PagerLink
            classname={"pager-main"}
            disabled={props.loading || !previous}
            linkIcon={<MdFirstPage className={"pager-icon"} />}
            linkText="First"
            callBack={() => props.callBack(1)}
            refPage={props.refPage}
          />
          {/*)}*/}
          {/*{previous && (*/}
          <PagerLink
            classname={"pager-button"}
            disabled={props.loading || !previous}
            linkIcon={<MdKeyboardArrowLeft className={"pager-icon"} />}
            linkText="Previous"
            callBack={() => props.callBack(props.pageNumber - 1)}
            refPage={props.refPage}
          />
          {/*)}*/}
          {props.loading ? (
            <span className="mx-3 pt-2">
              <Skeleton
                width={100}
                count={1}
                containerClassName={"skeleton-def"}
              />
            </span>
          ) : (
            <span className="mx-3 pt-2">
              Page {props.pageNumber} of {props.totalPages}
            </span>
          )}
          {/*{next && (*/}
          <PagerLink
            classname={"pager-main"}
            linkText="Next"
            disabled={props.loading || !next}
            linkIcon={<MdKeyboardArrowRight className={"pager-icon"} />}
            callBack={() => props.callBack(props.pageNumber + 1)}
            refPage={props.refPage}
          />
          {/*)}*/}
          {/*{next && (*/}
          <PagerLink
            classname={"pager-main"}
            linkText="Last"
            disabled={props.loading || !next}
            linkIcon={<MdLastPage className={"pager-icon"} />}
            callBack={() => props.callBack(props.totalPages)}
            refPage={props.refPage}
          />
          {/*)}*/}
        </Pagination>
      )}
    </div>
  );
};

export default StyledPager;