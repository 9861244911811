import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Link = props => (
  <PaginationItem>
    <PaginationLink onClick={props.callBack}>
      {props.linkText}
    </PaginationLink>
  </PaginationItem>
);

const LinkPager = ({previousLinks, nextLink, currentLink, refreshData, setCurrentLink, setPreviousLinks}) => {

  function onNext() {
    if(nextLink) {
        let currentLinkTemp = currentLink;
        let previousLinksTemp = previousLinks.slice();
        previousLinksTemp.push(currentLinkTemp);
        setCurrentLink(nextLink);
        setPreviousLinks(previousLinksTemp);
        refreshData(nextLink);
    }
  }

  function onPrevious() {
      let previousLinksTemp = previousLinks.slice();
      let link = previousLinksTemp.pop();
      setPreviousLinks(previousLinksTemp);
      setCurrentLink(link);
      refreshData(link)

  }

  return (
    <div className="mt-2">
      <Pagination>
        {previousLinks && previousLinks.length > 0 &&
          <Link linkText="Previous" callBack={onPrevious} />
        }
        {nextLink &&
          <Link linkText="Next" callBack={onNext} />
        }
      </Pagination>
 
    </div>
  );
};

export default LinkPager;
