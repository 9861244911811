import React, { useState, useEffect } from "react";
import {
  Loader,
  LongBreadCrumb,
  PageContainer,
  StyledButton,
  StyledModal,
  StyledInput,
  Pager,
  WidgetCard,
  StyledTD,
  Checkbox,
  FilterText,
  FilterSelect,
  StyledSelect,
} from "./";
import { Row, Col, Table } from "reactstrap";
import { api, helpers, constants, filterHelpers } from "../utils";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import { FiEdit, FiMinusCircle } from "react-icons/fi";

const EMPTY_ITEM = {
  id: "",
  code: "",
  display: "",
  system: "",
};

export default function ValueSetAdmin({ props }) {
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("display");
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const [paginatedList, setPaginatedList] = useState(null);
  const [currentItem, setCurrentItem] = useState(Object.assign({}, EMPTY_ITEM));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loadModalOpen, setLoadModalOpen] = useState(false);
  const [clearDataModalOpen, setClearDataModalOpen] = useState(false);
  const [includeNonMaintainable, setIncludeNonMaintainable] = useState(false);
  const [filters, setFilters] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);

  let { valueSet } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const metaData = _.find(
    constants.REFERENCE_VALUE_SET_URL_LIST,
    (x) => x.reactPath === valueSet
  );

  useEffect(() => {
    refreshData();
  }, [sortField, sortDirection, pageNumber, metaData, filters]);

  useEffect(getClientGroups, []);

  function getClientGroups() {
    api
      .secureFetch("Client/ListClientGroups")
      .then((response) => {
        if (!response || !response.data || !response.data.success) return;
        let mappedGroups = response.data.message.length
          ? response.data.message.map((group) => {
              return {
                ...group,
                value: group.id,
                label: group.name,
              };
            })
          : [];
        mappedGroups.unshift({ value: -1, label: "Only System" });
        setClientGroups(mappedGroups);
      })
      .catch(api.catchHandler);
  }

  function refreshData() {
    if (!metaData) return;

    setLoading(true);
    let payload = {
      sortField: sortField,
      sortDirection: sortDirection,
      pageNumber: pageNumber,
      maxResults: constants.DEFAULT_PAGE_SIZE,
      activeOnly: true,
    };

    _.each(filters, (filter) => {
      payload[filter.filterName] = filter.value;
      // console.log("filter: ", filter);
      // if (filter.value) {
      //   payload[filter.filterName] = filter.value;
      // }
      // if (filter.values && filter.values.length > 0) {
      //   payload[filter.filterName] = filter.values[0];
      // } else {
      //   payload[filter.filterName] = filter.label || filter.value;
      // }
    });

    api
      .securePost(metaData?.listUrl, payload)
      .then((response) => {
        setPaginatedList(response.data);
      })
      .catch(helpers.catchHandler)
      .finally(() => {
        setLoading(false);
      });
  }

  function onChangeItem(field, value) {
    let tempItem = Object.assign({}, currentItem);
    tempItem[field] = value;
    setCurrentItem(tempItem);
  }

  function validated() {
    if (!currentItem) {
      toast.error("Unable to find Current Item. Please try again");
      return false;
    }
    if (!currentItem.display || !currentItem.display.trim()) {
      toast.warning("Display is required");
      return false;
    }
    if (!currentItem.code || !currentItem.code.trim()) {
      toast.warning("Code is required");
      return false;
    }
    if (!currentItem.system || !currentItem.system.trim()) {
      toast.warning("System is required");
      return false;
    }
    return true;
  }

  function filterChange(changedFilter) {
    const filterList = filterHelpers.getFilters(filters, changedFilter);
    setFilters(filterList);
  }

  function reset() {
    setCurrentItem(Object.assign({}, EMPTY_ITEM));
    setEditModalOpen(false);
    setLoadModalOpen(false);
    setClearDataModalOpen(false);
  }

  function saveItem() {
    if (!validated()) return;

    api
      .securePost(metaData.saveUrl, currentItem)
      .then((response) => {
        if (response.data && response.data.success) {
          toast.success("Saved successfully");
          reset();
          refreshData();
        } else {
          toast.error("Could not be saved");
        }
      })
      .catch(helpers.catchHandler);
  }

  function populateData() {
    if (!metaData) return;

    setLoading(true);
    api
      .securePost(metaData.populateUrl)
      .then((response) => {
        if (response.data && response.data.success) {
          reset();
          refreshData();
        } else {
          toast.error("Could not populate value set");
        }
      })
      .catch(helpers.catchHandler)
      .finally(() => {
        setLoading(false);
      });
  }

  function deleteItem(item) {
    api
      .securePost(`${metaData.deleteUrl}/${item.id}`)
      .then((response) => {
        if (response.data && response.data.success) {
          reset();
          refreshData();
          toast.success("Successfully deleted");
        } else {
          toast.error("Could not be deleted");
        }
      })
      .catch(helpers.catchHandler);
  }

  function deleteAllItems() {
    setLoading(true);
    api
      .securePost(metaData.deleteAllUrl)
      .then((response) => {
        if (response.data && response.data.success) {
          reset();
          refreshData();
          toast.success("Successfully cleared value set");
        } else {
          toast.error("Could not clear value set");
        }
      })
      .catch(helpers.catchHandler)
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <PageContainer>
        {loading ? (
          <Loader center={"m-auto"} />
        ) : (
          <>
            <Col className={"m-0 p-0"}>
              <Row
                className={
                  "mx-0 my-3 d-flex justify-content-start align-items-center"
                }
              >
                <Col>
                  <LongBreadCrumb
                    context={"Value Set"}
                    page={"Management"}
                    loading={loading}
                    trailing={
                      <Checkbox
                        onChange={() =>
                          setIncludeNonMaintainable(!includeNonMaintainable)
                        }
                        selected={includeNonMaintainable}
                        label="Include Non-Maintainable"
                      />
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  {_.chain(constants.REFERENCE_VALUE_SET_URL_LIST)
                    .filter(
                      !includeNonMaintainable
                        ? (x) => x.isMaintainable === true
                        : null
                    )
                    .orderBy((x) => x.name)
                    .map((set) => {
                      return (
                        <WidgetCard
                          title={set.name}
                          onOpen={() =>
                            navigate(
                              `${constants.ROUTES.Admin.FhirAdmin}${constants.ROUTES.FhirAdmin.ValueSetAdmin}/${set.reactPath}`
                            )
                          }
                          startOpen={set?.reactPath === valueSet}
                          canCreateNew={
                            set?.isMaintainable && valueSet === set?.reactPath
                          }
                          onCreateNew={() => {
                            setCurrentItem(null);
                            setEditModalOpen(true);
                          }}
                        >
                          {set?.reactPath == valueSet ? (
                            <>
                              {/* {paginatedList &&
                              paginatedList.list &&
                              paginatedList.list.length ? ( */}
                              <>
                                <Row
                                  className={
                                    "mx-0 p-0 mt-2 mb-0 pb-4 d-flex align-items-center"
                                  }
                                >
                                  <Col xs="4">
                                    <FilterText
                                      disabled={loading}
                                      filterName="Display"
                                      label="Display"
                                      onChangeCallback={filterChange}
                                      debounceTimeout={750}
                                      value={filterHelpers.getValue(
                                        "Display",
                                        filters
                                      )}
                                    />
                                  </Col>
                                  <Col xs="2">
                                    <FilterText
                                      disabled={loading}
                                      filterName="Code"
                                      label="Code"
                                      onChangeCallback={filterChange}
                                      debounceTimeout={750}
                                      value={filterHelpers.getValue(
                                        "Code",
                                        filters
                                      )}
                                    />
                                  </Col>

                                  <Col xs="2">
                                    <FilterText
                                      disabled={loading}
                                      filterName="System"
                                      label="System"
                                      onChangeCallback={filterChange}
                                      debounceTimeout={750}
                                      value={filterHelpers.getValue(
                                        "System",
                                        filters
                                      )}
                                    />
                                  </Col>
                                  <Col xs="4">
                                    <FilterSelect
                                      isDisabled={loading}
                                      filterName="adminClientGroupId"
                                      displayName="Client Group"
                                      options={clientGroups}
                                      onChangeCallback={filterChange}
                                      isSingleSelect={true}
                                      value={_.filter(
                                        clientGroups,
                                        (x) =>
                                          x.value ===
                                          filterHelpers.getValue(
                                            "adminClientGroupId",
                                            filters
                                          )
                                      )}
                                    />
                                  </Col>
                                </Row>
                                <Table size="sm" responsive striped>
                                  <thead>
                                    <tr>
                                      {set?.isMaintainable ? <th /> : null}
                                      <th className="header-default">
                                        Display
                                      </th>
                                      <th className="header-default">Code</th>
                                      <th className="header-default">System</th>
                                      <th className="header-default">
                                        Description
                                      </th>
                                      <th className="header-default">Group</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <tr>
                                        <td colSpan={9}>
                                          <Loader />
                                        </td>
                                      </tr>
                                    ) : (
                                      _.map(paginatedList?.list, (x, i) => {
                                        return (
                                          <tr
                                            key={`${metaData?.name}${i}`}
                                            onClick={() => {}}
                                            className="fontSmall"
                                          >
                                            {set?.isMaintainable && (
                                              <StyledTD>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <FiEdit
                                                    className="clickable-icon mr-1"
                                                    onClick={() => {
                                                      setEditModalOpen(true);
                                                      setCurrentItem(x);
                                                    }}
                                                  />
                                                  <FiMinusCircle
                                                    className="clickable-icon red mr-2"
                                                    onClick={() => {
                                                      setCurrentItem(x);
                                                      deleteItem(x);
                                                    }}
                                                  />
                                                </div>
                                              </StyledTD>
                                            )}
                                            <StyledTD>{x.display}</StyledTD>
                                            <StyledTD>{x.code}</StyledTD>
                                            <StyledTD>{x.system}</StyledTD>
                                            <StyledTD>{x.description}</StyledTD>
                                            <StyledTD>
                                              {x.clientGroupName ?? "System"}
                                            </StyledTD>
                                          </tr>
                                        );
                                      })
                                    )}
                                  </tbody>
                                  <Pager
                                    pageNumber={
                                      paginatedList?.pageNumber
                                        ? paginatedList.pageNumber
                                        : 0
                                    }
                                    totalPages={
                                      paginatedList?.totalPages
                                        ? paginatedList.totalPages
                                        : 0
                                    }
                                    callBack={(newPageNumber) =>
                                      setPageNumber(newPageNumber)
                                    }
                                  />
                                </Table>
                              </>
                              {/* ) : null} */}

                              {set?.populateUrl ? (
                                <Row>
                                  <Col>
                                    <StyledButton
                                      onClick={() => setLoadModalOpen(true)}
                                      children="Load Data"
                                      color="primary"
                                      size="sm"
                                      className="float-right"
                                    />
                                    <StyledButton
                                      onClick={() =>
                                        setClearDataModalOpen(true)
                                      }
                                      children="Clear Data"
                                      color="primary"
                                      size="sm"
                                      className="float-right mr-2"
                                    />
                                    {/* {set.isMaintainable &&
                                <StyledButton
                                  onClick={() => setLoadModalOpen(true)}
                                  children="Upload Data"
                                  color="primary"
                                  size="sm"
                                  className="float-right mr-2"
                                />
                              } */}
                                  </Col>
                                </Row>
                              ) : null}
                            </>
                          ) : null}
                        </WidgetCard>
                      );
                    })
                    .value()}
                </Col>
              </Row>
            </Col>
          </>
        )}

        {/* Value Set Edit Modal */}
        <StyledModal
          title={`Edit ${metaData?.name}`}
          showCancel
          onSave={saveItem}
          show={editModalOpen}
          onHide={() => setEditModalOpen(false)}
          savePrompt="Save"
          size="xl"
        >
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="display"
                name="display"
                label="Display"
                value={currentItem?.display}
                onChange={(e) => onChangeItem("display", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="code"
                name="code"
                label="Code"
                value={currentItem?.code}
                onChange={(e) => onChangeItem("code", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="system"
                name="system"
                label="System"
                value={currentItem?.system}
                onChange={(e) => onChangeItem("system", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledInput
                id="description"
                name="description"
                label="Description"
                value={currentItem?.description}
                onChange={(e) => onChangeItem("description", e.target.value)}
              />
            </Col>
          </Row>
          <Row className="px-4 py-3 w-100">
            <Col>
              <StyledSelect
                id="clientGroup"
                label="Client Group"
                value={
                  clientGroups?.length && currentItem?.clientGroupId
                    ? _.find(
                        clientGroups,
                        (group) => group.value === currentItem.clientGroupId
                      )
                    : null
                }
                placeholder={"Select a Client Group"}
                isClearable
                onChange={(e) => onChangeItem("clientGroupId", e?.value)}
                options={_.reject(clientGroups, { value: -1 })}
              />
            </Col>
          </Row>
        </StyledModal>

        {/* Value Set Load Data Modal */}
        <StyledModal
          title={`Load data for ${metaData?.name}?`}
          showCancel
          onSave={populateData}
          show={loadModalOpen}
          onHide={() => setLoadModalOpen(false)}
          savePrompt="Load Data"
          size="xl"
        >
          <Row className="px-4 py-3 w-100">
            {loading ? (
              <Loader center={"m-auto"} />
            ) : (
              <Col>Warning: populating Value Set will reset data</Col>
            )}
          </Row>
        </StyledModal>

        {/* Value Set Clear Data Modal */}
        <StyledModal
          title={`Clear data for ${metaData?.name}?`}
          showCancel
          onSave={deleteAllItems}
          show={clearDataModalOpen}
          onHide={() => setClearDataModalOpen(false)}
          savePrompt="Clear Data"
          size="xl"
        >
          <Row className="px-4 py-3 w-100">
            {loading ? (
              <Loader center={"m-auto"} />
            ) : (
              <Col>
                Warning: clearing data will remove everything from this value
                set
              </Col>
            )}
          </Row>
        </StyledModal>
      </PageContainer>
    </>
  );
}