import { Link,  } from "react-router-dom";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import _ from "lodash";

export default function Breadcrumbs(props) {
  if (!props || props.loading || !props.list || props.list.length < 1) {
    return null;
  }
  
  let breadcrumbColumn = 12;
  if (props.middleArea) {
    breadcrumbColumn = breadcrumbColumn - 6;
  }
  if (props.rightArea) {
    breadcrumbColumn = breadcrumbColumn - (props.rightAreaSize || 2);
  }
  return (
    <Row className="my-0 ms-0 me-2 p-0 pt-2align-items-center w-100">
      <Col xs={breadcrumbColumn}>
        <h4>
          <Breadcrumb>
            {_.map(props.list, (item, index) => {
              if (item.link) {
                return (
                  <Breadcrumb.Item
                    as={Link}
                    to={item.link}
                    linkAs="span"
                    key={`breadcrumbLink${index}`}
                  >
                    {item.label}
                  </Breadcrumb.Item>
                );
              }
              return (
                <Breadcrumb.Item key={`breadcrumbLink${index}`} active >
                  {item.label}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </h4>
        {props.subtitle && <span className="pl-3 pageSubtitle float-left">{props.subtitle}</span>}
      </Col>
      {props.middleArea && <Col xs="6">{props.middleArea}</Col>}
      {props.rightArea && <Col xs={props.rightAreaSize || 2} className="px-0">{props.rightArea}</Col>}
    </Row>
  );
}
