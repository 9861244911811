import * as React from "react";
import "../assets/toolbox.scss";
import snaplogic from "../assets/images/snaplogic.png";
import outlook from "../assets/images/outlook.svg";
import jira from "../assets/images/jira2.png";
import looker from "../assets/images/looker.png";
import lucidchart from "../assets/images/lucidchart.png";
import confluence from "../assets/images/confluence.png";
import { LongBreadCrumb, PageContainer } from "./index";
import { CardDeck, Card, CardBody, Row, Col } from "reactstrap";

const tools = [
  {
    id: 1,
    name: "Snaplogic",
    image: snaplogic,
    text: "Snaplogic",
    url: "https://elastic.snaplogic.com/api/1/rest/admin/sso/login?org_path=Wellnecity-ETL-Prod",
  },
  {
    id: 2,
    name: "Outlook",
    image: outlook,
    text: "Outlook",
    url: "",
  },
  {
    id: 3,
    name: "Jira",
    image: jira,
    text: "Jira",
    url: "https://wellnecity.atlassian.net/jira/",
  },
  {
    id: 4,
    name: "Looker",
    image: looker,
    text: "Looker",
    url: "",
  },
  {
    id: 5,
    name: "Confluence",
    image: confluence,
    text: "Confluence",
    url: "",
  },
  {
    id: 6,
    name: "Lucidchart",
    image: lucidchart,
    text: "Lucidchart",
    url: "",
  },
];

const ToolCard = ({ tool }) => {
  return (
    <Col xs="12" lg="2" className={"mb-sm-2"}>
      <Card className={"border rounded-1 tool-card shadow-3-soft h-100 w-100"}>
        <Col className={"p-0 m-0 h-100"}>
          <Row className={"m-3 p-0"}>
            <div
              className={"w-100 d-flex justify-content-center align-items-end"}
            >
              <img src={tool.image} className={"toolboxImg"} />
            </div>
          </Row>
          <Row
            className={
              "px-2 w-100 h-100 my-2 d-flex m-0 p-0 mt-1 align-items-baseline"
            }
          >
            <div className={"w-100"}>
              <button className="tool-btn w-100 h-100 py-2 flex-grow-1">
                {tool.name}
              </button>
            </div>
          </Row>
        </Col>
      </Card>
    </Col>
  );
};

export default function Toolbox() {
  return (
    <PageContainer>
      <Col>
        <LongBreadCrumb context={"Toolbox"} single />
        <div className="mt-5">
          {/*<div className="d-flex justify-content-around">*/}
          <Row className={"m-0 px-0 py-2 d-flex justify-content-evenly"}>
            <CardDeck className={"w-100"}>
              {tools.map((tool) => (
                <ToolCard key={tool.id} tool={tool} />
              ))}
            </CardDeck>
          </Row>
          {/*// {*/}
          {/*  return (*/}
          {/*    <div className="dash-wrapper_tools">*/}
          {/*      <div className="cards-body_tools">*/}
          {/*        <div>*/}
          {/*          <img src={tool.image} className="toolboxImg" />*/}
          {/*        </div>*/}
          {/*        <div className="card-container_tools">*/}
          {/*          <div className="card-name_tool">{tool.name}</div>*/}
          {/*          <a*/}
          {/*            href={tool.url}*/}
          {/*            target="_blank"*/}
          {/*            rel="noopener noreferrer"*/}
          {/*          >*/}
          {/*            <button className="tool-btn">Open</button>*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  );*/}
          {/*})}*/}
          {/*</div>*/}
        </div>
      </Col>
    </PageContainer>
  );
}
