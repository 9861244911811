import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "reactstrap";
import { api } from "../utils";
import _ from "lodash";
import { dateHelpers, constants, storage } from "./";
import Papa from "papaparse";
import Regex from "uuid/dist/esm-browser/regex";

const FOOTER_ID = "layout-footer";
const BADGE_STYLE = { fontSize: "1.1rem" };

const handleLogout = (logoutType) => {
  //Instance doesn't work outside of function components.
  // window.removeEventListener("click", setLastActivity);
  // storage.removeItem("lastActivity");
  // storage.removeItem("currentUser");
  api.logData({
    eventType: constants.LOG_EVENT_TYPES.LOGOUT,
    severity: constants.LOG_SEVERITIES.INFO,
    message: `User logged intentional out.`,
    clientName: null,
    patientId: null,
    pHIFlag: false,
    resourceList: null,
  });
  storage.clear();
  if (logoutType === "microsoft") {
    instance.logoutPopup({
      securePostLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  } else if (logoutType === "redirect") {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }
  setTimeout(() => navigate("/"), 300);
};

const escapeRegExpMatch = function (s) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};
const isExactMatch = (str, match) => {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
};

function splitCoverageList(coverages) {
  if (!coverages || !coverages.length) return;
  coverages = _.reject(coverages, (c) =>
    _.some(
      constants.COVERAGE_REJECT_LIST,
      (crl) => crl.toLowerCase() === c.name.toLowerCase()
    )
  );
  let medical, vision, dental;
  [vision, medical] = _.partition(coverages, (c) =>
    _.some(
      constants.VISION_COVERAGE_TYPES,
      (v) => v === (c.name ? c.name.toLowerCase() : "")
    )
  );
  [dental, medical] = _.partition(medical, (c) =>
    _.some(
      constants.DENTAL_COVERAGE_TYPES,
      (v) => v === (c.name ? c.name.toLowerCase() : "")
    )
  );
  medical = _.orderBy(medical, [(x) => Date.parse(x.startOn)], ["desc"]);
  vision = _.orderBy(vision, [(x) => Date.parse(x.startOn)], ["desc"]);
  dental = _.orderBy(dental, [(x) => Date.parse(x.startOn)], ["desc"]);
  return {
    medical: _.first(medical),
    vision: _.first(vision),
    dental: _.first(dental),
  };
}

function toTitleCase(str) {
  return str
    .toLowerCase()
    .replace(/\.\s*([a-z])|^[a-z]/gm, (s) => s.toUpperCase());
}

function toLowerCase(str) {
  return str.toLowerCase();
}

const hideFooter = () => {
  setTimeout(() => {
    const f = document.getElementById("layout-footer");
    try {
      if (f) {
        f.style.display = "none";
      }
    } catch {}
  }, 200);
};

function formatPostalCode(country, postalValue) {
  if (!country) return;
  switch (country) {
    case "":
  }
}

const setFooterFixedBottom = () => {
  setTimeout(() => {
    const f = document.getElementById(FOOTER_ID);
    try {
      if (f) {
        f.style.display = "block";
        // const arr = f.className.split(" ");
        // if (arr.indexOf(FOOTER_FLOAT_CLASS) === -1) {
        //   f.className += " " + FOOTER_FLOAT_CLASS;
        // }
      }
    } catch {}
  }, 200);
};

const readCSVFiles = (files, onRead, onError = null) => {
  for (var i = 0; i < files.length; i++) {
    var reader = new FileReader();
    reader.onload = function (e) {
      const parseResult = Papa.parse(e.target.result);
      onRead(parseResult);
    };
    reader.onerror = onError
      ? onError
      : function (err) {
          console.error(err);
        };
    reader.readAsText(files[i]);
  }
};

const setFooterFloatBottom = () => {
  setTimeout(() => {
    const f = document.getElementById(FOOTER_ID);
    try {
      if (f) {
        f.style.display = "block";
        f.className = f.className.replace(/\bfixed-bottom\b/g, "");
      }
    } catch {}
  }, 200);
};

const setFooterHelp = (visible) => {
  setTimeout(() => {
    const fa = document.getElementById("footer-assistance");
    try {
      if (fa) {
        fa.style.display = visible ? "block" : "none";
      }
    } catch {}
  }, 200);
};

function convertBoolToYesOrNo(bool) {
  if (bool == undefined || bool == null) return "";
  if (bool) {
    return "Yes";
  } else {
    return "No";
  }
}

function getLocalStorageUser() {
  const u = storage.getItem("currentUser");
  return u ? JSON.parse(u) : null;
}

function hasRoleId(currentUser, typeOfUserRole, contextField = null) {
  const user = currentUser ? currentUser : getLocalStorageUser();
  const userRoles = user ? user.roles : null;
  return (
    userRoles &&
    _.some(
      userRoles,
      (ur) =>
        ur.typeOfUserRole === typeOfUserRole &&
        (!contextField || parseInt(ur[contextField], 10) > 0)
    )
  );
}

function toIntOrNull(v) {
  return v ? parseInt(v, 10) : null;
}

function hasAnyRoleId(currentUser, roleAndContextList) {
  if (!roleAndContextList || !roleAndContextList.length) return false;
  const user = currentUser ? currentUser : getLocalStorageUser();
  const userRoles = user ? user.roles : null;
  return (
    userRoles &&
    _.some(roleAndContextList, (rc) =>
      hasRoleId(user, rc.typeOfUserRole, rc.contextField)
    )
  );
}

const changeDateFormat = (
  date,
  changeDateFunction,
  fieldName = null,
  itemToEditId = null
) => {
  let formattedDate = null;
  if (dateHelpers.isDateValid(date)) {
    const dateString = dateHelpers.parseDatePickerDate(date, dateHelpers.MDY4);
    formattedDate = dateHelpers.getMomentFromString(dateString);
  }
  if (fieldName && itemToEditId) {
    changeDateFunction(formattedDate, fieldName, itemToEditId);
  } else if (fieldName) {
    changeDateFunction(formattedDate, fieldName);
  } else if (itemToEditId) {
    changeDateFunction(formattedDate, itemToEditId);
  } else {
    changeDateFunction(formattedDate);
  }
};

const onDatePickerKeyDown = (
  event,
  changeDateFunction,
  fieldName = null,
  itemToEditId = null
) => {
  if (event.which === 9 || event.which === 13) {
    // tab key or enter key
    const eventAction = event && event.target ? event.target.value : null;
    changeDateFormat(eventAction, changeDateFunction, fieldName, itemToEditId);
  }
};

const toggleListItemChecked = (id, list) => {
  const idx = _.findIndex(list, (x) => x.id === id);
  let newList = list.slice();
  newList[idx].checked = newList[idx].checked ? false : true;
  return list;
};

const helpers = {
  resolveRoleLabel(role) {
    return constants.ROLE_DICTIONARY[role.TypeOfUserRole];
  },
  isExactMatch,
  toTitleCase,
  isTrueOrFalse(v) {
    return v === true || v === false;
  },
  booleanIcon(value) {
    return (
      <span className={value ? "text-success" : "text-danger"}>
        <FontAwesomeIcon icon={value ? "check" : "times-circle"} />
      </span>
    );
  },
  readCSVFiles,
  yesNoList: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  PASSWORD_COMPLEXITY:
    "Passwords must be at least 8 characters long and contain at least 1 uppercase character, at least 1 lowercase character and at least 1 number.",
  ADMIN_ROLE_ID: 1,
  truncateString: (s, length) => {
    if (!s) return s;
    if (s.length > length) {
      return s.substr(0, length) + "...";
    }
    return s;
  },
  userIs: function (user, roleId) {
    return (
      user &&
      user.roles &&
      (_.includes(user.roles, roleId) ||
        _.includes(user.roles, this.SYSTEM_ADMIN_ROLE_ID))
    );
  },
  getCurrentUser: () => {
    const userString = storage.getItem("currentUser");
    return userString ? JSON.parse(userString) : null;
  },
  // browserExportCSVFile: function(csv, fileTitle) {
  //   var fname = fileTitle || "export.csv";
  //   var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   if (navigator.msSaveBlob) {
  //     // IE 10+
  //     navigator.msSaveBlob(blob, fname);
  //   } else {
  //     var link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       // Browsers that support HTML5 download attribute
  //       var url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", fname);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // },
  browserExportFile: function (csv, fileTitle, type) {
    var fname = fileTitle || "export.csv";
    var blob = new Blob([csv], { type: type });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fname);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fname);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  outcomeText(id) {
    switch (id) {
      case constants.TypeOfOutcome.FOLLOWUP:
        return "Follow Up";
      case constants.TypeOfOutcome.PASS:
        return "Pass";
      case constants.TypeOfOutcome.NOT_CLEARED:
        return "Not Cleared";
      case constants.TypeOfOutcome.UNABLE_TO_REACH:
        return "Unable to Reach";
      default:
        return "N/A";
    }
  },
  outcomeCellStyle(id) {
    switch (id) {
      case constants.TypeOfOutcome.FOLLOWUP:
        return "follow-up";
      case constants.TypeOfOutcome.PASS:
        return "pass";
      case constants.TypeOfOutcome.NOT_CLEARED:
        return "not-cleared";
      case constants.TypeOfOutcome.UNABLE_TO_REACH:
        return "unable-to-reach";
      default:
        return "";
    }
  },
  toggleListItemChecked,
  onDatePickerKeyDown,
  toIntOrNull,
  hasRoleId,
  hasAnyRoleId,
  requiredStar() {
    return <span style={{ color: "red" }}> *</span>;
  },
  booleanListEntry: function (b) {
    return b === true
      ? { label: "Yes", value: true }
      : { label: "No", value: false };
  },
  resolveValue: function (obj, id, name) {
    if (obj) return obj;
    if (id === null) return null;
    return { value: id, label: name };
  },
  resolveValues: function (objs) {
    if (objs.length === 0) {
      return [];
    }
    const formattedObjects = [];
    _.forEach(objs, (obj) => {
      if (obj.id === null) return null;
      formattedObjects.push({ value: obj.id, label: obj.name });
    });
    return formattedObjects;
  },
  yesNoOptions: function () {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  },

  addAnyOption: (list) => {
    let newList = list.slice();
    newList.unshift({ label: "(Any)", value: null });
    return newList;
  },

  formatAddress: function (address) {
    const addressArray = [address.ln1, address.ln2, address.ln3, address.ln4];
    const compactList = _.compact(addressArray, (x) => x);

    compactList.push(address.city + ", " + address.state + " " + address.zip);
    return compactList;
  },

  // formatPhoneField(v) {
  //   if (!v) return v;
  //   v = v.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g,'');
  //   if (v.length === 10) {
  //       v = v.substring(0, 3) + '-' + v.substring(3, 6) + '-' + v.substring(6, 10);
  //   }
  //   return v;
  // },

  formatPhoneNumber: function (phoneNumberString) {
    if (!phoneNumberString) return phoneNumberString;
    phoneNumberString = phoneNumberString.replace(/[^\d]+/g, "");
    if (phoneNumberString.length === 7) {
      phoneNumberString = phoneNumberString.replace(/(\d{3})(\d{4})/, "$1-$2");
    } else if (phoneNumberString.length === 10) {
      phoneNumberString = phoneNumberString.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );
    } else if (phoneNumberString.length === 11) {
      phoneNumberString = phoneNumberString.replace(
        /^(1|)?(\d{3})(\d{3})(\d{4})/,
        "($2) $3-$4"
      );
    }
    return phoneNumberString;
  },

  getKeyByValue: function (object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  },

  browserExportCSVFile: function (csv, fileTitle) {
    const fname = fileTitle || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fname);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fname);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },

  downloadExport: function (data, fileName, mime) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const file = new Blob([data], { type: mime });
      var anchor = window.document.createElement("a");
      anchor.download = fileName;
      anchor.href = (window.webkitURL || window.URL).createObjectURL(file);
      anchor.dataset.downloadurl = [mime, anchor.download, anchor.href].join(
        ":"
      );
      anchor.click();
    }
  },

  idNameToValueLabel(list, propName = null) {
    if (!list || !list.length || list.length === 0) return [];
    const valueLabelList = _.map(list, (x) => ({
      label: x.name,
      value: x.id,
    }));
    return propName
      ? {
          [propName]: valueLabelList,
        }
      : valueLabelList;
  },

  addLabelValueToList(list, propName = null) {
    if (!list || !list.length || list.length === 0) return [];
    const valueLabelList = _.map(list, (x) => {
      x.label = x.name;
      x.value = x.id;
      return x;
    });
    return propName
      ? {
          [propName]: valueLabelList,
        }
      : valueLabelList;
  },

  catchHandler: (e) => console.error(e),

  mustChangePassword: function (user) {
    if (!user) return false;
    return user.mustChangePassword;
  },

  //expecting arrayofKeysToCheck to be array of objects with keys "name" (name of the key on the object) and "label" (what you tell the user is missing)
  //example: [{name: "firstName", label: "first name"}, {name: "email", label: "e-mail address"}]
  isFormInvalid(object, arrayOfKeysToCheck) {
    const warnings = [];
    _.map(arrayOfKeysToCheck, (key) => {
      if (!object[key.name]) {
        warnings.push(key.label);
      }
    });

    if (warnings.length) {
      return "Please provide the following: " + warnings.join(", ");
    } else {
      return false;
    }
  },

  addIsEditingField(list, propName = null) {
    if (!list || !list.length || list.length === 0) {
      return [];
    }
    const isEditingList = _.map(list, (x) => {
      return {
        ...x,
        isEditing: false,
      };
    });
    return propName ? { [propName]: isEditingList } : isEditingList;
  },

  nullableString(s) {
    return s ? s : "";
  },
  socialIsValid: function (ss) {
    if (!ss) return false;
    const ssReg =
      /^(?!000|666)[0-9]{3}([- ]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;
    return ssReg.test(ss);
  },
  phoneNumIsValid: (number) => {
    const phoneReg =
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
    return phoneReg.test(number);
  },
  dobIsValid: (date) => {
    let dobReg =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return dobReg.test(date);
  },
  emailIsValid: function (email) {
    const emailRe = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRe.test(email);
  },
  isMobile: function (window) {
    const smallerOption =
      window.document &&
      window.document.defaultView &&
      window.document.defaultView.innerWidth
        ? window.document.defaultView.innerWidth
        : window.screen.width;
    return smallerOption <= 680;
  },
  cardHeaderName: function cardHeaderName(cardName, iconName) {
    return (
      <span>
        <FontAwesomeIcon icon={iconName} className="mr-2" />
        {cardName}
      </span>
    );
  },
  getStateFromLinkParams(props, key = null) {
    let paramValue = props && props.location && props.location.state;
    if (key) {
      paramValue = props.location.state[key];
    }
    return paramValue ? paramValue : props;
  },
  addChoiceVariable(payload, name, type, value) {
    payload[`${name}Type`] = type?.value;
    // payload[`${name}${type?.label}`] = value;
    return payload;
  },

  buildDashboardLinks(type, clientId, campaignId, caseId) {
    let link = `/dashboard`;
    link = link + "/" + type.label;
    if (clientId) {
      link = link + "/" + clientId;
    }
    if (campaignId) {
      link = link + "/" + campaignId;
    }
    if (caseId) {
      link = link + "/" + caseId;
    }
    return link;
  },

  formatCurrency(num) {
    return new Intl.NumberFormat("en-US", {
      notation: "compact",
      compactDisplay: "short",
      style: "currency",
      currency: "USD",
      // currency: 'en',
      // currencySign: "$"
    }).format(num);
  },

  formatUSDCurrency(num) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      notation: "compact",
      compactDisplay: "short",
    }).format(num);
  },

  buildPatientDashboardLinks(type, clientId, participantId) {
    let link = `/patient/chart/dashboard`;
    link = link + "/" + type.label;
    if (clientId) {
      link = link + "/" + clientId;
    }
    if (participantId) {
      link = link + "/" + participantId;
    }
    return link;
  },

  clientLogoStyle(id, pixelNo) {
    let logoString = `url(/api/Client/Logo/${id})`;
    return {
      borderRadius: "50%",
      // width: `${pixelNo}px`,
      width: "10%",
      // height: `${pixelNo}px`,
      height: "10%",
      display: "block",
      aspectRatio: "1/1",
      backgroundImage: logoString,
      border: "1px solid lightgray",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    };
  },
  createTreeNodeKey(name, index) {
    return `${name}@@@NODE@@@${index}`;
  },
  getCheckboxTreeNodes(parent, checked) {
    let childList = [];
    _.map(parent.children, (c, index) => {
      let key = this.createTreeNodeKey(c.parent.name, index);
      childList.push({
        key: key,
        value: key,
        name: c.parent.name,
        label: c.parent.name,
        id: c.parent ? c.parent.id : 0,
        children:
          c.children.length > 0 ? this.getCheckboxTreeNodes(c, checked) : null,
        currentNode: c,
        parentId: c.parent ? c.parent.id : 0,
        className: "nodeTreeItem",
        checked: _.some(checked, (x) => x === key),
      });
    });
    return childList;
  },
  findSelectedNodes(items) {
    if (items && items.length > 0) {
      return _.reduce(
        items,
        function (result, c, key) {
          c.index = key;
          if (c.checked) {
            return [c, ...result];
          } else {
            if (c.children && c.children.length > 0) {
              let childrenChecked = helpers.findSelectedNodes(c.children);
              if (childrenChecked && childrenChecked.length > 0) {
                return _.concat(childrenChecked, result);
              } else {
                return result;
              }
            } else {
              return result;
            }
          }
        },
        []
      );
    }
    return [];
  },
  convertBoolToYesOrNo,
  findNodeByKey(items, keyString) {
    return _.find(items, function (c) {
      if (c.children && c.children.length > 0) {
        return helpers.findNodeByKey(c.children, keyString);
      } else if (c.key === keyString) {
        return true;
      } else {
        return false;
      }
    });
  },
  findFirstParent(items, keyString = null, parentId = null) {
    let results = [];
    if (items && items.length > 0) {
      results = _.reduce(
        items,
        function (result, c, key) {
          c.index = key;
          if (c.key === keyString || c.parentId === parentId) {
            return [c, ...result];
          } else {
            if (c.children && c.children.length > 0) {
              let childrenMatched = helpers.findFirstParent(
                c.children,
                keyString,
                parentId
              );
              if (childrenMatched) {
                childrenMatched.parentSavingsLeverId =
                  childrenMatched?.currentNode?.parent?.parentSavingsLeverId;
                return [childrenMatched, ...result];
              }
              return result;
            }
            return result;
          }
        },
        []
      );
    }
    return results[0] || null;
  },
  findAllParents(items, keyString = null, parentId = null) {
    let results = [];
    if (items && items.length > 0) {
      results = _.reduce(
        items,
        function (result, c, key) {
          c.index = key;
          if (c.key === keyString || c.parentId === parentId) {
            if (c.currentNode.parent.parentSavingsLeverId === null) {
              return [c, ...result];
            } else {
              // console.log('matched', c)
              // if (c.children && c.children.length > 0) {
              //   let childrenMatched = helpers.findAllParents(c.children, keyString, parentId);
              //   if (childrenMatched) {
              //     console.log('children matches', c)
              //     // childrenMatched.parentSavingsLeverId = childrenMatched?.currentNode?.parent?.parentSavingsLeverId;
              //     // return [childrenMatched, ...result]
              //   }
              //   console.log('no children matches', c)
              //   return result
              // }
              return result;
            }
          } else {
            if (c.children && c.children.length > 0) {
              _.forEach(c.children, (x) => {
                if (x.key === keyString || x.parentId === parentId) {
                  return [x, ...result];
                } else {
                  return result;
                }
              });
            }
            return result;
          }
        },
        []
      );
    }
    return results;
  },
  appendObjectToForm(payload, field, appendee) {
    _.each(appendee, (value, key) => {
      payload.append(`${field}[${key}]`, value);
    });
    return payload;
  },
  mapToValueLabelDescription(x) {
    if (!x) return;
    return {
      ...x,
      value: x.code || x.display, //First option is the preferred choice
      label: x.display || x.code,
      description: x.text,
    };
  },
  hasView(view) {
    return _.some(
      getLocalStorageUser().views,
      (v) => v.typeOfView === view.value
    );
  },
  handleLogout,
  getUIFriendlyDate(date) {
    return dateHelpers.toMDYDateString(date, dateHelpers.YMDHMS);
  },
  resolveEligibilityStatus(hireDate, terminationDate) {
    if (!hireDate) return "Hire date not provided.";
    if (hireDate && !terminationDate) return "Active";
    let now = new Date().getTime();
    hireDate = Date.parse(dateHelpers.dateTimeFormat(hireDate));
    terminationDate = Date.parse(dateHelpers.dateTimeFormat(terminationDate));
    let isActive = now <= terminationDate && now >= hireDate;
    return isActive ? "Active" : "Inactive";
  },
  splitCoverageList,
  logResourceCreateOrUpdate(
    originalResourceId,
    resourceId,
    resourceType,
    resourceName,
    patientId,
    clientId,
    showSSN
  ) {
    let exist = originalResourceId && originalResourceId.length;
    api.logData({
      eventType: exist
        ? constants.LOG_EVENT_TYPES.UPDATE_DATA
        : constants.LOG_EVENT_TYPES.CREATE_DATA,
      severity: constants.LOG_SEVERITIES.INFO,
      message: `Patient ${resourceName} ${exist ? "updated" : "created"}.`,
      clientName: null,
      patientId: patientId,
      pHIFlag: showSSN,
      clientId: clientId,
      resourceList: [
        {
          fHIRDataSet: "",
          resourceType: resourceType,
          resourceId: exist ? originalResourceId : resourceId || null,
        },
      ],
    });
  },
  handleZipCode(zip) {
    if (!zip || !zip.length) return;
    if (zip.length > 5) {
      let temp = zip.replace("-", "").split("");
      temp.splice(5, 0, "-");
      return temp;
    }
    return zip;
  },
};

export default helpers;