import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function StyledStepper(props) {
  if (props.steps && props.steps.length) {
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={2} alternativeLabel>
          {props.steps.map((x, index) => (
            <Step key={`${x.label}${index}`}>
              <StepLabel>{x.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
  return null;
}