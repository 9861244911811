import React, {useEffect, useState} from 'react';

import {Breadcrumbs, PageContainer, StyledButton, UnderConstruction} from '.';
import {ButtonGroup, Col, Row} from "reactstrap";
import {IoReturnUpBackOutline} from "react-icons/io5";


export default function Temp(props) {
const [breadcrumbs, setBreadcrumbs] = useState(null);
const [loading, setLoading] = useState(false);
const [subtitle, setSubtitle] = useState('');


    return (
    <PageContainer>
        <Breadcrumbs
            list={breadcrumbs}
            rightAreaSize={breadcrumbs?.length >= 3 ? 3 : 4}
            rightArea={
                <Row className="m-0 p-0">
                    <Col className="m-0 p-0">
                        {/*<ButtonGroup className="float-right">*/}
                        {/*    {!showHomeScreen && navigateBack && (*/}
                        {/*        <StyledButton onClick={goBack} icon={IoReturnUpBackOutline}>*/}
                        {/*            Back*/}
                        {/*        </StyledButton>*/}
                        {/*    )}*/}
                        {/*</ButtonGroup>*/}
                    </Col>
                </Row>
            }
            loading={loading}
            subtitle={subtitle}
        />
        <UnderConstruction message={"Pending Wellnecity Specifications"}/>
    </PageContainer>
    )
}