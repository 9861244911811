import React, { useEffect, useState, useContext, Fragment } from "react";
import { api, constants, dateHelpers, UserContext, helpers } from "../utils";
import { Row, Col, Badge } from "reactstrap";
import {
  WidgetCard,
  StyledModal,
  StyledSelect,
  StyledSingleDatePicker,
  StyledInput,
  StyledSection,
  Loader,
  StyledAsyncSelect,
  PatientChartNotes,
  LinkPager,
} from ".";
import _ from "lodash";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FiEdit, FiMinusCircle } from "react-icons/fi";
import { Contactless } from "@mui/icons-material";
import ReactQuill from "react-quill";

const EMPTY_COMMUNICATION = {
  id: "",
  clientId: 0,
  status: null,
  statusReason: {},
  category: null,
  priority: null,
  medium: null,
  subjectReference: "",
  topic: "",
  sentAt: null,
  receivedAt: null,
  reasonCode: "",
  reasonReference: null,
  note: { text: "" },
  notes: [],
  duration: null,
};

const MediumTag = ({ text, type, index }) => {
  return (
    <Badge id={"total-count-badge"}>
      <span style={{ fontSize: "1.1rem", color: "white", fontWeight: "bold" }}>
        {text}
      </span>
    </Badge>
  );
};

export default function PatientChartContactLog({
  clientId,
  participantId,
  subjectOptions,
  conditionOptions,
  observationOptions,
}) {
  const userCtx = useContext(UserContext);
  const { currentUser } = useContext(UserContext);
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [contactLogs, setContactLogs] = useState(null);
  const [currentContactLog, setCurrentContactLog] = useState(
    JSON.parse(JSON.stringify(EMPTY_COMMUNICATION))
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [totalResults, setTotalResults] = useState("0");
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingNoteIndices, setEditingNoteIndices] = useState([]);
  const [previousLinks, setPreviousLinks] = useState([]);
  const [nextLink, setNextLink] = useState(null);
  const [currentLink, setCurrentLink] = useState(null);
  const [editNoteText, setEditNoteText] = useState(null);

  useEffect(() => {
    refreshData();
  }, []);

  // useEffect(() => {
  // }, [editingNoteIndices]);

  function refreshData(link) {
    if (!loading) {
      setLoading(true);
      let apiCalls = [];
      apiCalls.push(getContactLogInfo(link));

      Promise.all(apiCalls)
        .then((arrayResults) => {
          let aggResults = {};
          _.each(arrayResults, (x) => Object.assign(aggResults, x));
          if (aggResults.contactLogInfo) {
            // console.log("Contact log items", aggResults.contactLogInfo.items);
            setTotalResults(`${aggResults.contactLogInfo.totalCount ?? 0}`);
            const items = _.map(
              aggResults.contactLogInfo.items,
              (contactLog) => ({
                ...contactLog,
                status: _.find(
                  constants.FHIR_EVENT_STATUS,
                  (x) => x.value === contactLog.status
                ),
                priority: _.find(
                  constants.FHIR_REQUEST_PRIORITIES,
                  (x) => x.value === contactLog.priority
                ),
                subjectReference:
                  _.find(subjectOptions, (x) =>
                    _.includes(x.label, contactLog.subjectReference)
                  ) || subjectOptions?.length === 1
                    ? subjectOptions[0]
                    : null,
                reasonReference: _.find(
                  conditionOptions.concat(observationOptions),
                  (x) => x.value === contactLog?.reasonReference
                ),
                sentAt: contactLog?.sentAt,
                category: _.map(
                  contactLog.category,
                  helpers.mapToValueLabelDescription
                ),
                topic: helpers.mapToValueLabelDescription(contactLog.topic),
                statusReason: helpers.mapToValueLabelDescription(
                  contactLog.statusReason
                ),
                medium: _.chain(contactLog.medium)
                  .uniqBy((med) => med.code)
                  .map(helpers.mapToValueLabelDescription)
                  .value(),
                sender: {
                  ...contactLog.sender,
                  label: contactLog.sender?.resourceId,
                  value: contactLog.sender?.resourceId,
                },
                recipient: _.find(
                  subjectOptions,
                  (x) => x.label === contactLog.recipientReference
                ),
              })
            );
            setContactLogs(items);
            setCurrentLink(aggResults.contactLogInfo.currentLink);
            setNextLink(aggResults.contactLogInfo.nextLink);
          }
        })
        .catch(api.catchHandler)
        .finally(() => setLoading(false));
    }
  }

  function getContactLogInfo(link) {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
      // link: link,
      // maxResults: constants.DEFAULT_PAGE_SIZE
    };
    return api
      .securePost("Participant/ContactLogInfo", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          return { contactLogInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function validated() {
    if (!currentContactLog) {
      toast.error("No Contact Log found. Please try again");
      return false;
    }
    if (!currentContactLog.status) {
      toast.warning("Status is required");
      return false;
    }
    if (!currentContactLog.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    if (
      currentContactLog.duration &&
      (isNaN(currentContactLog.duration) ||
        Number(currentContactLog.duration) < 0)
    ) {
      toast.warning("Duration must be a valid, non-negative number");
      return false;
    }
    if (!currentContactLog.medium) {
      toast.warning("Medium is required");
      return false;
    }
    if (!currentContactLog.category || !currentContactLog.category.length) {
      toast.warning("Category is required");
      return false;
    }
    if (!currentContactLog.sentAt) {
      toast.warning("Occurred At is required");
      return false;
    }
    if (editingNoteIndices.length > 0) {
      toast.warning("You must save or cancel your notes before updating");
      return false;
    }
    return true;
  }

  function reset() {
    setEditModalOpen(false);
    setViewModalOpen(false);
    setIsDeleting(false);
    setIsEditing(false);
    setCurrentContactLog(JSON.parse(JSON.stringify(EMPTY_COMMUNICATION)));
    setEditingNoteIndices([]);
  }

  function updateContactLog(payloadChange, action) {
    setLoading(true);
    let tempSubject = _.head(subjectOptions).value;
    let tempContactLogSubject = currentContactLog?.subjectReference?.value;
    const payload = {
      ...currentContactLog,
      clientId: clientId,
      subjectReference:
        currentContactLog?.subjectReference?.value ||
        _.head(subjectOptions).value,
      recipientReference: currentContactLog?.recipient?.value,
      status: currentContactLog?.status?.value,
      medium: currentContactLog?.medium,
      priority: currentContactLog?.priority?.value,
      // reasonReference: currentContactLog?.reasonReference?.value || "",
      ...payloadChange,
    };
    api
      .securePost("Participant/UpdateContactLog", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "Communication",
            "Contact Log",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData();
          toast.success(`Contact Log successfully ${action}`);
        } else {
          toast.error(`Contact Log could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Contact Log could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveContactLog() {
    if (!validated()) return;
    let currentContactLogTemp = JSON.parse(JSON.stringify(currentContactLog));
    if (currentContactLogTemp.note && currentContactLogTemp.note.text) {
      currentContactLogTemp.notes.push(currentContactLogTemp.note);
      currentContactLogTemp.note = { text: "" };
      setCurrentContactLog(currentContactLogTemp);
    }
    updateContactLog({ notes: currentContactLogTemp.notes }, "saved");
  }

  function deleteContactLog() {
    updateContactLog(
      {
        status: _.find(constants.FHIR_EVENT_STATUS, {
          label: "Entered in Error",
        })?.value,
      },
      "deleted"
    );
  }

  function onChangeContactLog(field, value) {
    let tempContactLog = Object.assign({}, currentContactLog);
    tempContactLog[field] = value;
    setCurrentContactLog(tempContactLog);
  }

  function renderSenderReference(senderRef) {
    if (
      !senderRef ||
      (!senderRef.firstName && !senderRef.lastName && !senderRef.username)
    ) {
      return <span className="text-muted"> Legacy Reference</span>;
    }
    return `${senderRef?.firstName} ${senderRef?.lastName}, ${senderRef?.username}`;
  }

  return (
    <>
      <WidgetCard
        title="Contact Log"
        totalCount={totalResults ?? 0}
        isScrollable
        startOpen
        canCreateNew={!isReadonly}
        canEdit={!isReadonly}
        onCreateNew={() => {
          if (
            !currentContactLog.subjectReference &&
            subjectOptions?.length === 1
          ) {
            let tempContactLog = {
              ...currentContactLog,
              subjectReference: subjectOptions[0],
            };
            setCurrentContactLog(tempContactLog);
          }
          setIsEditing(false);
          setEditModalOpen(true);
        }}
        onEdit={() => setIsEditing(!isEditing)}
      >
        {loading ? (
          <Loader />
        ) : (
          <Row className="p-3 text-left">
            <Col>
              <div>
                {_.chain(contactLogs)
                  .orderBy((x) => x.sentAt, "desc")
                  .map((contactLog, idx) => (
                    <Fragment key={`cl-${idx}`}>
                      <div
                        key={`contactLogs${idx}`}
                        onClick={() => {
                          if (!isEditing) {
                            setCurrentContactLog(contactLog);
                            setViewModalOpen(true);
                          }
                        }}
                        style={{ justifyContent: "space-between" }}
                        className={classNames("mb-3 flex", {
                          clickableRow: !isEditing,
                          cursorPointer: !isEditing,
                        })}
                      >
                        <div>
                          {isEditing ? (
                            <>
                              <>
                                <FiEdit
                                  className="clickable-icon mr-1"
                                  onClick={() => {
                                    setEditModalOpen(true);
                                    setCurrentContactLog(contactLog);
                                  }}
                                />
                                <FiMinusCircle
                                  className="clickable-icon red mr-2"
                                  onClick={() => {
                                    setCurrentContactLog(contactLog);
                                    setIsDeleting(true);
                                    setViewModalOpen(true);
                                  }}
                                />
                              </>
                            </>
                          ) : null}
                          {/* <div className="flex">
                         <span className="mr-2" style={{color: "lightgray"}}>{contactLog.medium?.icon}</span>
                         {contactLog?.medium && contactLog?.medium.length
                          ? _.map(contactLog?.medium, med => <b>{contactLog?.medium?.label}</b>)
                          : null}
                      </div> */}
                          <div
                            className={"d-flex flex-row justify-items-evenly"}
                          >
                            {contactLog?.medium && contactLog?.medium.length
                              ? _.map(contactLog?.medium, (med, idx) => (
                                  <MediumTag
                                    key={`md-${idx}`}
                                    index={idx}
                                    text={med?.label}
                                  />
                                ))
                              : null}
                          </div>
                          <div className="small text-muted">
                            {contactLog?.status?.label}
                          </div>
                        </div>
                        <div className="small text-muted">
                          {dateHelpers.timeIsMidnight(contactLog?.sentAt)
                            ? "Occurred on"
                            : "Occurred at"}{" "}
                          {dateHelpers.dateWithConditionalTime(
                            contactLog?.sentAt,
                            true
                          )}
                        </div>
                      </div>
                      <div className="small text-muted">
                        {contactLog?.topic ? (
                          <b>{contactLog.topic?.label}</b>
                        ) : null}
                      </div>
                      {_.map(contactLog.notes, (note, idx) => (
                        <div key={`n-${idx}`} className="small">
                          <ReactQuill
                            theme="bubble"
                            value={note.text}
                            readOnly={true}
                          />
                        </div>
                      ))}
                      <hr />
                    </Fragment>
                  ))
                  .value()}
              </div>
              {nextLink || previousLinks ? (
                <LinkPager
                  currentLink={currentLink}
                  setCurrentLink={setCurrentLink}
                  nextLink={nextLink}
                  previousLinks={previousLinks}
                  setPreviousLinks={setPreviousLinks}
                  refreshData={(nextLink) => refreshData(nextLink)}
                />
              ) : null}
            </Col>
          </Row>
        )}
      </WidgetCard>

      {/* Contact Log View Only Modal */}
      <StyledModal
        show={viewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        scrollable
        onHide={reset}
        size="xl"
        title={isDeleting ? `Delete Contact Log?` : `Contact Log Display`}
        onSave={isDeleting ? deleteContactLog : reset}
        savePrompt={isDeleting ? "Delete Contact Log" : "Close"}
        disabled={loading}
      >
        <StyledSection title="Info">
          <div className="mb-1">
            <b>Status:&nbsp;</b>
            {currentContactLog?.status?.label}
          </div>
          <div className="mb-1">
            <b>Status Reason:&nbsp;</b>
            {currentContactLog?.statusReason?.label}
          </div>
          <div className="mb-1">
            <b>Categories&nbsp;:</b>
            {currentContactLog?.category && currentContactLog?.category.length
              ? _.map(currentContactLog?.category, (cat) => cat.label).join(
                  ", "
                )
              : null}
          </div>
          <div className="mb-1">
            <b>Priority:&nbsp;</b>
            {currentContactLog?.priority?.label ?? ""}
          </div>
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentContactLog?.subjectReference?.label ?? ""}
          </div>
          <div className="mb-1">
            <b>Duration (minutes):&nbsp;</b>
            {currentContactLog?.duration}
          </div>
        </StyledSection>
        <StyledSection title="About">
          <div className="mb-1">
            <b>Medium:&nbsp;</b>
            {currentContactLog?.medium && currentContactLog?.medium.length
              ? _.map(currentContactLog.medium, (med) => med.label).join(", ")
              : null}
          </div>
          <div className="mb-1">
            <b>Topic:&nbsp;</b>
            {currentContactLog?.topic?.label ?? ""}
          </div>
          <div className="mb-1">
            <b>Last Edited By:&nbsp;</b>
            {renderSenderReference(currentContactLog?.sender)}
          </div>
          <div className="mb-1">
            <b>
              {dateHelpers.timeIsMidnight(currentContactLog?.sentAt)
                ? "Occurred on"
                : "Occurred at"}
              :&nbsp;
            </b>
            {dateHelpers.dateWithConditionalTime(
              currentContactLog?.sentAt,
              true
            )}
          </div>

          {/* <div className="mb-1"><b>Received At:&nbsp;</b>{dateHelpers.dateTimeFormat(currentContactLog?.receivedAt, dateHelpers.MDYHMA)}</div>
            <div className="mb-1"><b>Reason Code:&nbsp;</b>{currentContactLog?.reasonCode}</div>
            <div className="mb-1"><b>Reason:&nbsp;</b>{currentContactLog?.reasonReference?.label}</div> */}
        </StyledSection>
        <StyledSection title="Notes">
          {_.map(currentContactLog?.notes, (note, i) => (
            <Fragment key={`note${i}`}>
              <ReactQuill theme="bubble" value={note.text} readOnly={true} />
              {/*<div className="mb-1">{note.text}</div>*/}
              <hr />
            </Fragment>
          ))}
        </StyledSection>
      </StyledModal>

      {/* Contact Log Edit Modal */}
      <StyledModal
        show={editModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={isEditing ? "Update Contact Log" : "Create Contact Log"}
        onSave={saveContactLog}
        savePrompt={"Save Contact Log"}
        showRequiredFieldsMessage={true}
        disabled={loading}
        isScrollable
      >
        <StyledSection title="Info">
          <Row className="mb-4">
            <Col xs="6">
              <StyledAsyncSelect
                id="sender"
                isClearable
                label="Sender"
                onChange={(e) => onChangeContactLog("sender", e)}
                value={currentContactLog?.sender}
                loadOptions={(typedValue, callback) =>
                  api.getPractitionerList(
                    typedValue,
                    callback,
                    currentUser,
                    clientId,
                    false
                  )
                }
                idName="sender"
              />
            </Col>
            <Col xs="6">
              <StyledSelect
                label="Subject"
                required
                isClearable
                options={subjectOptions}
                name="subject"
                id="subject"
                value={currentContactLog?.subjectReference || ""}
                onChange={(selection) =>
                  onChangeContactLog("subjectReference", selection)
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSelect
                id="status"
                label="Status"
                required
                onChange={(e) => onChangeContactLog("status", e)}
                value={currentContactLog?.status}
                options={constants.FHIR_EVENT_STATUS}
                isClearable
              />
            </Col>
            <Col>
              <StyledAsyncSelect
                id="StatusReason"
                isClearable
                label="Status Reason"
                onChange={(e) => onChangeContactLog("statusReason", e)}
                value={currentContactLog?.statusReason || ""}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS
                      .COMMUNICATION_STATUS_REASON,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.COMMUNICATION_STATUS_REASON
                  )
                }
                idName="statusReason"
                description={currentContactLog?.statusReason?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledAsyncSelect
                label={"Categories"}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.COMMUNICATION_CATEGORIES,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.COMMUNICATION_CATEGORIES,
                    false,
                    constants.DISPLAY
                  )
                }
                name="category"
                id="category"
                isMulti
                required
                value={currentContactLog?.category || ""}
                onChange={(selection) =>
                  onChangeContactLog("category", selection)
                }
                idName="communicationCategory"
                description={currentContactLog?.category?.description}
              />
            </Col>
            <Col>
              <StyledSelect
                id="priority"
                label="Priority"
                onChange={(e) => onChangeContactLog("priority", e)}
                value={currentContactLog?.priority}
                options={constants.FHIR_REQUEST_PRIORITIES}
                isClearable
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <StyledInput
                id="duration"
                name="duration"
                type="number"
                label="Duration (minutes)"
                value={currentContactLog?.duration}
                onChange={(e) =>
                  onChangeContactLog("duration", e.target.valueAsNumber)
                }
              />
            </Col>
          </Row>
        </StyledSection>
        <StyledSection title="About">
          <Row className="mb-4">
            <Col>
              <StyledAsyncSelect
                required
                id="Medium"
                isClearable
                label="Medium"
                onChange={(e) => onChangeContactLog("medium", e)}
                isMulti
                value={currentContactLog?.medium || ""}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.COMMUNICATION_MEDIUM,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.COMMUNICATION_MEDIUM,
                    false
                  )
                }
                idName="communicationMedium"
                description={currentContactLog?.medium?.description}
              />
            </Col>
            <Col>
              <StyledAsyncSelect
                required
                id="Topic"
                isClearable
                label="Topic"
                onChange={(e) => onChangeContactLog("topic", e)}
                value={currentContactLog?.topic}
                loadOptions={(typedValue, callback) =>
                  api.loadInternalValueSets(
                    typedValue,
                    callback,
                    constants.INTERNAL_VALUE_SET_URLS.COMMUNICATION_TOPIC,
                    clientId,
                    constants.VALUE_SET_TYPE_IDS.COMMUNICATION_TOPIC,
                    false
                  )
                }
                idName="communicationTopic"
                description={currentContactLog?.topic?.description}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs="6">
              <StyledSingleDatePicker
                label="Sent"
                name="sentAt"
                id="sentAt"
                required
                selected={currentContactLog?.sentAt}
                onChangeCallback={(e) => onChangeContactLog("sentAt", e)}
                maxDate={new Date()}
                showTimeSelect
              />
            </Col>
          </Row>
        </StyledSection>
        <PatientChartNotes
          obj={currentContactLog}
          setObj={setCurrentContactLog}
          editingNoteIndices={editingNoteIndices}
          setEditingNoteIndicies={setEditingNoteIndices}
          onChange={onChangeContactLog}
          // onEditNote={}
        />
      </StyledModal>
    </>
  );
}