import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
// import { DropdownButton, Button } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { fetchExtraUserData } from "../App";
import { api, storage, UserContext } from "../utils";
import { useContext } from "react";
import { InteractionStatus } from "@azure/msal-browser";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
const SignInButton = () => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  // const handleLogin = (loginType) => {
  //     if (loginType === "microsoft") {
  //         instance.loginPopup(loginRequest)
  //         .then(response => {
  //             if(!response) return;
  //             if(!response.account) return;  // remove this to have user redirected
  //             fetchExtraUserData(response.account.username);
  //         })
  //         .catch(e => {
  //             console.log(e);
  //         });
  //     }
  //      else if (loginType == "redirect") {
  //         instance.loginRedirect(loginRequest).catch(e => {
  //             console.log(e);
  //         });
  //     }
  // }
  //
  // function fetchExtraUserData(email) {
  //     setLoading(true);
  //     api
  //         .securePost("Auth/StartSession", {})
  //         .then((res) => {
  //             if (res.data.success) {
  //                 if (res.data.message === "Not Setup Yet") {
  //                     // setLoading(false);
  //                     return;
  //                 }
  //                 setCurrentUser(res.data.message);
  //                 storage.setItem("currentUser", JSON.stringify(res.data.message));
  //             } else {
  //                 console.error("Failed to start session: ", res.data.message);
  //                 setCurrentUser(null);
  //                 localStorage.clear();
  //                 navigate("/logout");
  //             }
  //         })
  //         .catch(api.catchHandler)
  //         // .finally(() => setTimeout(() => setLoading(false), 1000));
  //         .finally(() => setLoading(false));
  // }

  return (
    // <>
    //     <Button className="ml-auto" onClick={() => handleLogin("microsoft")}>Sign in using Microsoft</Button>
    // </>
    <div className={"w-50 d-flex justify-content-center"}>
      <Button
        color="primary"
        className={"w-100"}
        variant="outlined"
        disabled={inProgress !== InteractionStatus.None}
        onClick={() => userContext.login()}
      >
        Sign in
      </Button>
      {/* <DropdownButton variant="outline-light" className="ml-auto" drop="left" title="Sign In">
            <Dropdown.Item as="button" onClick={() => handleLogin("microsoft")}>Sign in using Microsoft</Dropdown.Item> */}
      {/* <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Item> */}
      {/* </DropdownButton> */}
    </div>
  );
};

export default SignInButton;