import { FormLabel } from "@mui/material";
import { helpers } from "../utils";
import cx from "classnames";

export default function StyledFormLabel(props) {
  return props.label || props.hiddenLabel ? (
    <FormLabel component="legend" className={"text-left float-left"}>
      {props.label ? props.label : <span>&#8203;</span>}
      {props.required && helpers.requiredStar()}
    </FormLabel>
  ) : null;
}