import { useEffect, useState, useContext, Fragment } from "react";
import { api, helpers, constants, dateHelpers, UserContext } from "../utils";
import { Row, Col } from "reactstrap";
import {
  WidgetCard,
  StyledModal,
  StyledSelect,
  StyledSingleDatePicker,
  StyledInput,
  StyledSection,
  Loader,
  StyledAsyncSelect,
  FhirChoiceVariable,
} from ".";
import _ from "lodash";
import { toast } from "react-toastify";
import classNames from "classnames";
import { FiEdit, FiMinusCircle } from "react-icons/fi";

const EMPTY_CONDITION = {
  id: "",
  clientId: 0,
  clinicalStatus: null,
  verificationStatus: null,
  category: null,
  severity: null,
  code: null,
  subjectReference: "",
  recordedDate: null,
  stageSummary: null,
  evidenceCode: null,
  bodySites: null,
  onset: null,
  abatement: null,
  onsetType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
  abatementType: constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
};

export default function PatientChartDiagnosis({
  clientId,
  participantId,
  subjectOptions,
  refreshReferenceData,
  practitionerList,
}) {
  const userCtx = useContext(UserContext);
  const { currentUser } = useContext(UserContext);
  const isReadonly = helpers.hasView(
    constants.ACCESS_VIEWS.PATIENT_CHART_READONLY
  );
  const [diagnoses, setDiagnoses] = useState(null);
  const [currentDiagnosis, setCurrentDiagnosis] = useState(
    Object.assign({}, EMPTY_CONDITION)
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedAilment, setSelectedAilment] = useState(null);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (practitionerList && practitionerList.length > 0) {
      if (!currentDiagnosis?.recorderReference) {
        let foundCurrentUser = _.find(
          practitionerList,
          (p) => p.email === currentUser.email
        );
        onChangeDiagnosis("recorderReference", foundCurrentUser ?? null);
      }
    }
  }, [practitionerList, editModalOpen]);

  function refreshData() {
    if (loading) return;
    setLoading(true);
    let apiCalls = [];
    apiCalls.push(getDiagnosisInfo());

    Promise.all(apiCalls)
      .then((arrayResults) => {
        let aggResults = {};
        _.each(arrayResults, (x) => Object.assign(aggResults, x));

        if (aggResults.diagnosisInfo) {
          aggResults.diagnosisInfo = _.map(
            aggResults.diagnosisInfo,
            (diagnosis) => {
              return {
                ...diagnosis,
                clinicalStatus: diagnosis.clinicalStatus
                  ? {
                      ...diagnosis.clinicalStatus,
                      value: diagnosis.clinicalStatus?.code,
                      label: diagnosis.clinicalStatus?.display,
                      description: diagnosis.clinicalStatus?.text,
                    }
                  : null,
                verificationStatus: diagnosis.verificationStatus
                  ? {
                      ...diagnosis.verificationStatus,
                      value: diagnosis.verificationStatus?.code,
                      label: diagnosis.verificationStatus?.display,
                      description: diagnosis.verificationStatus?.text,
                    }
                  : null,
                code: diagnosis.code
                  ? {
                      ...diagnosis.code,
                      value: diagnosis.code?.code,
                      label: `${diagnosis.code?.code} ${diagnosis.code?.display}`,
                      description: diagnosis.code?.text,
                    }
                  : null,
                severity: diagnosis.severity
                  ? {
                      ...diagnosis.severity,
                      value: diagnosis.severity?.code,
                      label: diagnosis.severity?.display,
                      description: diagnosis.severity?.text,
                    }
                  : null,
                category: diagnosis.category
                  ? {
                      ...diagnosis.category,
                      value: diagnosis.category?.code,
                      label: diagnosis.category?.display,
                      description: diagnosis.category?.text,
                    }
                  : null,
                bodySites: _.map(diagnosis.bodySites, (x) => {
                  return {
                    ...x,
                    value: x.code,
                    label: x.display,
                    description: x.text,
                  };
                }),
                subjectReference:
                  _.find(subjectOptions, (x) =>
                    _.includes(x.label, diagnosis.subjectReference)
                  ) || subjectOptions?.length === 1
                    ? subjectOptions[0]
                    : null,
                abatementType: diagnosis.abatementType
                  ? _.find(
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
                      (x) => x.value === diagnosis.abatementType
                    )
                  : constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
                onsetType: diagnosis.onsetType
                  ? _.find(
                      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS_LIST,
                      (x) => x.value === diagnosis.onsetType
                    )
                  : constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
                recorderReference: diagnosis?.recorderReference?.length
                  ? {
                      label: `${diagnosis?.recorderFirstName} ${diagnosis?.recorderLastName}`,
                      value: diagnosis?.recorderReference,
                      resourceId: diagnosis?.recorderReference,
                    }
                  : null,
                asserterReference: diagnosis?.asserterReference?.length
                  ? {
                      label: `${diagnosis?.asserterFirstName} ${diagnosis?.asserterLastName}`,
                      value: diagnosis?.asserterReference,
                      resourceId: diagnosis?.recorderReference,
                    }
                  : null,
              };
            }
          );
          setDiagnoses(aggResults.diagnosisInfo);
        }
      })
      .catch(api.catchHandler)
      .finally(() => setLoading(false));
  }

  function getDiagnosisInfo() {
    const payload = {
      clientId: clientId,
      clientIdentifierId: participantId,
    };
    return api
      .securePost("Participant/DiagnosisInfo", payload)
      .then((response) => {
        if (response && response.data) {
          return { diagnosisInfo: response.data.message };
        }
      })
      .catch(api.catchHandler);
  }

  function validated() {
    if (!currentDiagnosis) {
      toast.error("No Diagnosis found. Please try again");
      return false;
    }
    if (!currentDiagnosis.code) {
      toast.warning("ICD-10 Code is required");
      return false;
    }
    if (!currentDiagnosis.verificationStatus) {
      toast.warning("Verification Status is required");
      return false;
    }
    if (!currentDiagnosis.category) {
      toast.warning("Category is required");
      return false;
    }
    if (!currentDiagnosis.subjectReference) {
      toast.warning("Subject is required");
      return false;
    }
    if (
      currentDiagnosis.onsetType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentDiagnosis.onsetRange?.units &&
      currentDiagnosis.onsetRange?.low !== null &&
      !isNaN(currentDiagnosis.onsetRange?.low)
    ) {
      toast.warning("Units are required with Onset Range");
      return false;
    }
    if (
      currentDiagnosis.onsetType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentDiagnosis.onsetRange?.units &&
      currentDiagnosis.onsetRange?.high !== null &&
      !isNaN(currentDiagnosis.onsetRange?.high)
    ) {
      toast.warning("Units are required with Onset Range");
      return false;
    }
    if (
      currentDiagnosis.abatementType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentDiagnosis.abatementRange?.units &&
      currentDiagnosis.abatementRange?.low !== null &&
      !isNaN(currentDiagnosis.abatementRange?.low)
    ) {
      toast.warning("Units are required with Abatement Range");
      return false;
    }
    if (
      currentDiagnosis.abatementType?.value ===
        constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value &&
      !currentDiagnosis.abatementRange?.units &&
      currentDiagnosis.abatementRange?.high !== null &&
      !isNaN(currentDiagnosis.abatementRange?.high)
    ) {
      toast.warning("Units are required with Abatement Range");
      return false;
    }

    /* FHIR RULE: if abated, clinical status must be inactive, resolved, or remission */
    if (
      currentDiagnosis[`abatement${currentDiagnosis.abatementType?.label}`] &&
      currentDiagnosis.clinicalStatus?.code !== "inactive" &&
      currentDiagnosis.clinicalStatus?.code !== "resolved" &&
      currentDiagnosis.clinicalStatus?.code !== "remission"
    ) {
      toast.warning(
        "Clinical status must be either Inactive, Resolved, or Remission if Abatement is present"
      );
      return false;
    }

    /* FHIR RULE: if verification status is entered-in-error, clinical status must not be present */
    if (
      currentDiagnosis.verificationStatus &&
      currentDiagnosis.verificationStatus.code === "entered-in-error" &&
      currentDiagnosis.clinicalStatus
    ) {
      toast.warning(
        "Clinical status cannot be present if verification code is 'Entered in Error'"
      );
      return false;
    }
    return true;
  }

  function reset() {
    setEditModalOpen(false);
    setViewModalOpen(false);
    setIsEditing(false);
    setIsDeleting(false);
    setCurrentDiagnosis(Object.assign({}, EMPTY_CONDITION));
  }

  function updateDiagnosis(payloadChange, action) {
    setLoading(true);
    const payload = {
      ...currentDiagnosis,
      clientId: clientId,
      subjectReference:
        currentDiagnosis?.subjectReference?.value ||
        _.head(subjectOptions).value,
      asserterReference: currentDiagnosis?.asserterReference?.resourceId,
      recorderReference: currentDiagnosis?.recorderReference?.resourceId,
      ...payloadChange,
    };
    helpers.addChoiceVariable(
      payload,
      "onset",
      currentDiagnosis.onsetType,
      currentDiagnosis.onset
    );
    helpers.addChoiceVariable(
      payload,
      "abatement",
      currentDiagnosis.abatementType,
      currentDiagnosis.abatement
    );
    api
      .securePost("Participant/UpdateCondition", payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          helpers.logResourceCreateOrUpdate(
            payload.id,
            response.data.message.id,
            "Condition",
            "Diagnosis",
            payload.subjectReference,
            clientId,
            userCtx.showSsn
          );
          reset();
          refreshData();
          refreshReferenceData();
          toast.success(`Diagnosis successfully ${action}`);
        } else {
          toast.error(`Diagnosis could not be ${action}`);
        }
      })
      .catch(() => {
        toast.error(`Diagnosis could not be ${action}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function saveDiagnosis() {
    if (!validated()) return;
    updateDiagnosis({}, "saved");
  }

  function deleteDiagnosis() {
    api.loadInternalValueSets(
      "Entered in Error",
      (list) => {
        updateDiagnosis(
          {
            verificationStatus: list && list[0],
            clinicalStatus: null,
            abatement: null,
            [`abatement${currentDiagnosis.abatementType.label}`]: null,
          },
          "deleted"
        );
      },
      constants.INTERNAL_VALUE_SET_URLS.CONDITION_VERIFICATION_STATUSES,
      clientId,
      constants.VALUE_SET_TYPE_IDS.CONDITION_VERIFICATION_STATUSES,
      false
    );
  }

  function onChangeDiagnosis(field, value) {
    let tempDiagnosis = Object.assign({}, currentDiagnosis);
    tempDiagnosis[field] = value;
    setCurrentDiagnosis(tempDiagnosis);
  }

  function getChioceVariableValue(diagnosis, typeField, fieldName) {
    if (!typeField) return "";
    if (
      typeField.value ===
      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD.value
    ) {
      return `${dateHelpers.toMDYDateString(
        diagnosis[`${fieldName}Period`]?.start,
        dateHelpers.YMDHMS
      )}-${dateHelpers.toMDYDateString(
        diagnosis[`${fieldName}Period`]?.end,
        dateHelpers.YMDHMS
      )}`;
    } else if (
      typeField.value ===
      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME.value
    ) {
      return `${dateHelpers.toMDYDateString(
        diagnosis[`${fieldName}DateTime`],
        dateHelpers.YMDHMS
      )}`;
    } else if (
      typeField.value ===
      constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE.value
    ) {
      return `${diagnosis[`${fieldName}Range`]?.low || ""}-${
        diagnosis[`${fieldName}Range`]?.high || ""
      }`;
    }
    return "";
  }

  function getDiagnosisAbatement(diagnosis) {
    if (!diagnosis || !diagnosis.abatement) return "";
    return getChioceVariableValue(
      diagnosis,
      diagnosis.abatementType,
      "abatement"
    );
  }

  function getDiagnosisOnset(diagnosis) {
    if (!diagnosis || !diagnosis.onset) return "";
    return getChioceVariableValue(diagnosis, diagnosis.onsetType, "onset");
  }

  return (
    <>
      <WidgetCard
        title="Diagnoses"
        startOpen
        canCreateNew={!isReadonly}
        canEdit={!isReadonly}
        isScrollable
        onCreateNew={() => {
          if (
            !currentDiagnosis.subjectReference &&
            subjectOptions?.length === 1
          ) {
            let tempDiagnosis = {
              ...currentDiagnosis,
              subjectReference: subjectOptions[0],
            };
            setCurrentDiagnosis(tempDiagnosis);
          }
          setIsEditing(false);
          setEditModalOpen(true);
        }}
        onEdit={() => {
          if (!isEditing && selectedAilment === null && diagnoses) {
            setSelectedAilment(diagnoses[0]?.ailment);
          }
          setIsEditing(!isEditing);
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Row className="p-3">
            <Col>
              {_.chain(diagnoses)
                .orderBy((x) => (x.isPrimary === true ? 0 : 1))
                .groupBy((x) => x.isPrimary)
                .toArray()
                .map((list, index) => {
                  const isPrimary = list[0]?.isPrimary;
                  const label = isPrimary
                    ? "Problem List Items:"
                    : "Additional Diagnoses:";
                  return (
                    <div
                      key={`diagnosisRow${label}${index}`}
                      className={classNames("text-left", { "mb-4": isPrimary })}
                    >
                      <div className="mb-1">
                        <b>{label}</b>
                      </div>
                      <div>
                        {_.chain(list)
                          .orderBy((x) => x.recordedDate)
                          .groupBy((x) => x.ailment)
                          .toArray()
                          .map((d, idx) => {
                            const ailment = d[0]?.ailment;
                            const earliestDiagnosis = d?.reduce(function (
                              prev,
                              curr
                            ) {
                              if (prev?.recordedDate == null) return curr;
                              if (curr?.recordedDate == null) return prev;
                              return prev.recordedDate < curr.recordedDate
                                ? prev
                                : curr;
                            });
                            const earliestDiagnosisDate =
                              earliestDiagnosis?.recordedDate;
                            return (
                              <Fragment key={`diagnoses${ailment}${idx}`}>
                                <div
                                  onClick={() => {
                                    if (ailment === selectedAilment) {
                                      setSelectedAilment(null);
                                    } else {
                                      setSelectedAilment(ailment);
                                    }
                                  }}
                                  className={"mb-1 cursorPointer clickableRow"}
                                >
                                  {ailment}
                                  {earliestDiagnosisDate ? (
                                    <div
                                      className="small text-muted"
                                      style={{ float: "right" }}
                                    >
                                      Diagnosed on{" "}
                                      {dateHelpers.toMDYDateString(
                                        earliestDiagnosisDate
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                                {ailment === selectedAilment ? (
                                  <ul>
                                    {_.map(d, (diagnosis) => {
                                      return (
                                        <li
                                          key={`diagnoses${label}${idx}`}
                                          onClick={() => {
                                            if (!isEditing) {
                                              setViewModalOpen(true);
                                              setCurrentDiagnosis(diagnosis);
                                            }
                                          }}
                                          className={classNames("ml-5 small", {
                                            clickableRow: !isEditing,
                                            cursorPointer: !isEditing,
                                          })}
                                          style={{
                                            listStyleType: isEditing
                                              ? "none"
                                              : "",
                                          }}
                                        >
                                          {isEditing ? (
                                            <>
                                              <div style={{ display: "flex" }}>
                                                <>
                                                  <FiEdit
                                                    className="clickable-icon mr-1"
                                                    onClick={() => {
                                                      setEditModalOpen(true);
                                                      setCurrentDiagnosis(
                                                        diagnosis
                                                      );
                                                    }}
                                                  />
                                                  <FiMinusCircle
                                                    className="clickable-icon red mr-2"
                                                    onClick={() => {
                                                      setIsDeleting(true);
                                                      setCurrentDiagnosis(
                                                        diagnosis
                                                      );
                                                      setViewModalOpen(true);
                                                    }}
                                                  />
                                                </>
                                                <div
                                                  key={`diagnoses${label}${idx}`}
                                                >
                                                  {diagnosis.code?.display}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            diagnosis.code?.display
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : null}
                              </Fragment>
                            );
                          })
                          .value()}
                      </div>
                    </div>
                  );
                })
                .value()}
            </Col>
          </Row>
        )}
      </WidgetCard>

      {/* Condition View Only Modal */}
      <StyledModal
        show={viewModalOpen}
        showCancel={isEditing}
        showRequiredFieldsMessage={false}
        scrollable
        onHide={reset}
        size="xl"
        title={isDeleting ? `Delete Diagnosis?` : `Diagnosis Display`}
        onSave={isDeleting ? deleteDiagnosis : reset}
        savePrompt={isDeleting ? "Delete Diagnosis" : "Close"}
        disabled={loading}
      >
        <StyledSection title="Identifier">
          <div className="mb-1">
            <b>ICD10 Code:&nbsp;</b>
            {currentDiagnosis?.code?.label}
          </div>
          <div className="mb-1">
            <b>Ailment:&nbsp;</b>
            {currentDiagnosis?.ailment}
          </div>
        </StyledSection>
        <StyledSection title="About">
          <div className="mb-1">
            <b>Clinical Status:&nbsp;</b>
            {currentDiagnosis?.clinicalStatus?.label}
          </div>
          <div className="mb-1">
            <b>Verification Status:&nbsp;</b>
            {currentDiagnosis?.verificationStatus?.label}
          </div>
          <div className="mb-1">
            <b>Category:&nbsp;</b>
            {currentDiagnosis?.category?.label}
          </div>
          <div className="mb-1">
            <b>Severity:&nbsp;</b>
            {currentDiagnosis?.severity?.label}
          </div>
          <div className="mb-1">
            <b>Subject:&nbsp;</b>
            {currentDiagnosis?.subjectReference?.label}
          </div>
          <div className="mb-1">
            <b>Body Sites:&nbsp;</b>
            <ul className="ml-5">
              {currentDiagnosis?.bodySites &&
                _.map(currentDiagnosis.bodySites, (x, i) => {
                  return <li key={`bodySite${i}`}>{x.label}</li>;
                })}
            </ul>
          </div>
        </StyledSection>
        <StyledSection title="Info">
          <div className="mb-1">
            <b>Diagnosed On:&nbsp;</b>
            {dateHelpers.toMDYDateString(
              currentDiagnosis?.recordedDate,
              dateHelpers.YMDHMS
            )}
          </div>
          <div className="mb-1">
            <b>Stage:&nbsp;</b>
            {currentDiagnosis?.stageSummary}
          </div>
          <div className="mb-1">
            <b>Evidence:&nbsp;</b>
            {currentDiagnosis?.evidenceCode}
          </div>
          <div className="mb-1">
            <b>Onset:&nbsp;</b>
            {getDiagnosisOnset(currentDiagnosis)}
          </div>
          <div className="mb-1">
            <b>Abatement:&nbsp;</b>
            {getDiagnosisAbatement(currentDiagnosis)}
          </div>
          <div className="mb-1">
            <b>Recorder:&nbsp;</b>
            {currentDiagnosis?.recorderReference?.label}
          </div>
          <div className="mb-1">
            <b>Asserter:&nbsp;</b>
            {currentDiagnosis?.asserterReference?.label}
          </div>
        </StyledSection>
      </StyledModal>

      {/* Condition Edit Modal */}
      <StyledModal
        show={editModalOpen}
        showCancel
        onHide={reset}
        size="xl"
        title={isEditing ? "Update Diagnosis" : "Create Diagnosis"}
        onSave={saveDiagnosis}
        savePrompt={"Save Diagnosis"}
        showRequiredFieldsMessage={true}
        disabled={loading}
        scrollable
      >
        <form autoComplete="off">
          <StyledSection title="Identifier">
            <Row className="mb-4">
              <Col xs="12">
                <StyledAsyncSelect
                  label="ICD-10 Code"
                  required
                  placeholder="ICD-10 Code Lookup"
                  loadOptions={(typedValue, callback) =>
                    api.loadInternalValueSets(
                      typedValue,
                      callback,
                      constants.INTERNAL_VALUE_SET_URLS.DIAGNOSIS_ICD10_CODES,
                      clientId,
                      constants.VALUE_SET_TYPE_IDS.DIAGNOSIS_ICD10_CODES,
                      true
                    )
                  }
                  onChange={(e) => onChangeDiagnosis("code", e)}
                  value={currentDiagnosis?.code}
                />
              </Col>
            </Row>
          </StyledSection>
          <StyledSection title="About">
            <Row className="mb-4">
              <Col xs="6">
                <StyledAsyncSelect
                  label={"Clinical Status"}
                  isClearable
                  placeholder="Clinical Status Lookup"
                  loadOptions={(typedValue, callback) =>
                    api.loadInternalValueSets(
                      typedValue,
                      callback,
                      constants.INTERNAL_VALUE_SET_URLS.CONDITION_CLINICALS,
                      clientId,
                      constants.VALUE_SET_TYPE_IDS.CONDITION_CLINICALS,
                      false
                    )
                  }
                  onChange={(e) => onChangeDiagnosis("clinicalStatus", e)}
                  value={currentDiagnosis?.clinicalStatus}
                  idName="clinicalStatus"
                  description={currentDiagnosis?.clinicalStatus?.description}
                />
              </Col>
              <Col>
                <StyledAsyncSelect
                  label={"Verification Status"}
                  isClearable
                  required
                  placeholder="Verification Status Lookup"
                  loadOptions={(typedValue, callback) =>
                    api.loadInternalValueSets(
                      typedValue,
                      callback,
                      constants.INTERNAL_VALUE_SET_URLS
                        .CONDITION_VERIFICATION_STATUSES,
                      clientId,
                      constants.VALUE_SET_TYPE_IDS
                        .CONDITION_VERIFICATION_STATUSES,
                      false
                    )
                  }
                  onChange={(e) => onChangeDiagnosis("verificationStatus", e)}
                  value={currentDiagnosis?.verificationStatus}
                  idName="verificationStatus"
                  description={
                    currentDiagnosis?.verificationStatus?.description
                  }
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="6">
                <StyledAsyncSelect
                  label="Category"
                  loadOptions={(typedValue, callback) =>
                    api.loadInternalValueSets(
                      typedValue,
                      callback,
                      constants.INTERNAL_VALUE_SET_URLS.CONDITION_CATEGORIES,
                      clientId,
                      constants.VALUE_SET_TYPE_IDS.CONDITION_CATEGORIES,
                      false
                    )
                  }
                  name="category"
                  id="category"
                  required
                  value={currentDiagnosis?.category || ""}
                  onChange={(selection) =>
                    onChangeDiagnosis("category", selection)
                  }
                  idName="conditionCategory"
                  description={currentDiagnosis?.category?.description}
                />
              </Col>
              <Col>
                <StyledAsyncSelect
                  label={"Severity"}
                  loadOptions={(typedValue, callback) =>
                    api.loadInternalValueSets(
                      typedValue,
                      callback,
                      constants.INTERNAL_VALUE_SET_URLS.CONDITION_SEVERITIES,
                      clientId,
                      constants.VALUE_SET_TYPE_IDS.CONDITION_SEVERITIES,
                      false
                    )
                  }
                  name="severity"
                  id="severity"
                  isClearable
                  value={currentDiagnosis?.severity || ""}
                  onChange={(selection) =>
                    onChangeDiagnosis("severity", selection)
                  }
                  idName="conditionSeverity"
                  description={currentDiagnosis?.severity?.description}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="6">
                <StyledSelect
                  label={"Subject"}
                  isClearable
                  required
                  options={subjectOptions}
                  name="subject"
                  id="subject"
                  value={currentDiagnosis?.subjectReference || ""}
                  onChange={(selection) =>
                    onChangeDiagnosis("subjectReference", selection)
                  }
                />
              </Col>
              <Col>
                <StyledAsyncSelect
                  label={"Body Sites"}
                  loadOptions={(typedValue, callback) =>
                    api.loadInternalValueSets(
                      typedValue,
                      callback,
                      constants.INTERNAL_VALUE_SET_URLS.BODY_SITES,
                      clientId,
                      constants.VALUE_SET_TYPE_IDS.BODY_SITES,
                      false
                    )
                  }
                  name="bodySites"
                  id="bodySites"
                  isMulti
                  value={currentDiagnosis?.bodySites || ""}
                  onChange={(selection) =>
                    onChangeDiagnosis("bodySites", selection)
                  }
                  idName="bodySite"
                  description={currentDiagnosis?.bodySites?.description}
                />
              </Col>
            </Row>
            {/* waiting for specifications: */}
            <Row className="mb-4">
              <Col xs="6">
                {/*<StyledAsyncSelect*/}
                {/*  id="Recorder"*/}
                {/*  isClearable*/}
                {/*  label="Recorder"*/}
                {/*  onChange={(e) => onChangeDiagnosis("recorderReference", e)}*/}
                {/*  value={currentDiagnosis?.recorderReference}*/}
                {/*  loadOptions={(typedValue, callback) => api.getPractitionerList(typedValue, callback, currentUser, clientId, false)}*/}
                {/*  idName="recorder" */}
                {/*/>*/}
                <StyledSelect
                  id="Recorder"
                  isClearable
                  label="Recorder"
                  onChange={(e) => onChangeDiagnosis("recorderReference", e)}
                  value={currentDiagnosis?.recorderReference || ""}
                  options={practitionerList}
                  idName="recorder"
                />
              </Col>
              <Col>
                <StyledAsyncSelect
                  id="asserter"
                  isClearable
                  label="Asserter"
                  onChange={(e) => onChangeDiagnosis("asserterReference", e)}
                  value={currentDiagnosis?.asserterReference}
                  loadOptions={(typedValue, callback) =>
                    api.getPractitionerList(
                      typedValue,
                      callback,
                      currentUser,
                      clientId,
                      false
                    )
                  }
                  idName="asserter"
                />
              </Col>
            </Row>
          </StyledSection>

          <StyledSection title="Info">
            <Row className="mb-4">
              <Col xs="12">
                <FhirChoiceVariable
                  id={"onsetType"}
                  name={"onset"}
                  label={"Choose Onset Type"}
                  obj={currentDiagnosis}
                  valueType={currentDiagnosis?.onsetType}
                  onChangeType={(e) => onChangeDiagnosis("onsetType", e)}
                  value={currentDiagnosis?.onset}
                  onChangeCallback={(val, type) =>
                    onChangeDiagnosis(`onset${type}`, val)
                  }
                  options={[
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.AGE,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE,
                  ]}
                />
              </Col>
              <Col xs="12">
                <FhirChoiceVariable
                  id={"abatementType"}
                  name={"abatement"}
                  label={"Choose Abatement Type"}
                  obj={currentDiagnosis}
                  valueType={currentDiagnosis?.abatementType}
                  onChangeType={(e) => {
                    onChangeDiagnosis("abatementType", e);
                  }}
                  value={currentDiagnosis?.abatement}
                  onChangeCallback={(val, type) =>
                    onChangeDiagnosis(`abatement${type}`, val)
                  }
                  options={[
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.DATE_TIME,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.PERIOD,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.AGE,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.STRING,
                    constants.FHIR_CHOICE_VARIABLE_TYPE_OPTIONS.RANGE,
                  ]}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="4">
                <StyledSingleDatePicker
                  label={"Diagnosed On"}
                  debounceTimeout={300}
                  type="text"
                  name="recordedDate"
                  id="recordedDate"
                  maxLength="200"
                  selected={currentDiagnosis?.recordedDate}
                  onChangeCallback={(date) =>
                    onChangeDiagnosis("recordedDate", date)
                  }
                  maxDate={new Date()}
                />
              </Col>
              <Col xs="4">
                {/* <StyledAsyncSelect
                    label={"Stage"}
                    loadOptions={(typedValue, callback) => api.loadInternalValueSets(typedValue, callback, constants.INTERNAL_VALUE_SET_URLS.CONDITION_STAGES, false)}
                    name="stage"
                    id="stage"
                    isClearable
                    value={currentDiagnosis?.stageSummary || ""}
                    onChange={(selection) =>
                      onChangeDiagnosis("stageSummary", selection)
                    }
                    idName="conditionStage" 
                    description={currentDiagnosis?.stageSummary?.description}
                  /> */}
                <StyledInput
                  id="stage"
                  name="stage"
                  label="Stage"
                  value={currentDiagnosis?.stageSummary}
                  onChange={(e) =>
                    onChangeDiagnosis("stageSummary", e.target.value)
                  }
                />
              </Col>
              <Col>
                <StyledInput
                  id="evidence"
                  name="evidence"
                  label="Evidence"
                  value={currentDiagnosis?.evidenceCode}
                  onChange={(e) =>
                    onChangeDiagnosis("evidenceCode", e.target.value)
                  }
                />
              </Col>
            </Row>
          </StyledSection>
        </form>
      </StyledModal>
    </>
  );
}