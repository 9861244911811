import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { IoTrashOutline } from "react-icons/io5";
import { RiEditLine } from "react-icons/ri";
import { FaRegSave, FaRecycle } from "react-icons/fa";
import { Loader } from "./index";
import { Circles } from "react-loader-spinner";

// const Button = styled.button`
//         border: none;
//         padding: 0.4rem 1rem 0.5rem 0.8rem;
//         border-radius: 5px;
//         color: white;
//         font-size: 16px;
//         font-weight: 400;
//         -webkit-transition: all 0.2s ease-in;
//         transition: all 0.2s ease-in;
//         -webkit-text-decoration: none;
//         text-decoration: none;
//         background: rgb(86,86,92);
//         background: linear-gradient(0deg, rgba(86,86,92,1) 33%, rgba(94,104,113,1) 61%, rgba(164,176,192,1) 100%);
//         margin-right: 3px;
//         ${props => props.primary && `
//           background: #58539d
//         `}
//         ${props => props.orange && `
//           background: #e79e28
//         `}
//         ${props => props.primaryGradient && `
//           background: rgb(88,83,157);
//           background: linear-gradient(0deg, rgba(88,83,157,1) 50%, rgba(138,135,187,1) 85%, rgba(172,169,206,1) 100%);
//         `}
//         ${props => props.orangeGradient && `
//           background: rgb(11,92,78);
//           background: linear-gradient(0deg, rgba(11,92,78,1) 30%, rgba(14,117,100,1) 80%, rgba(17,143,122,1) 100%);
//         `}
//         ${props => props.success && `
//           background: rgb(11,92,78);
//           background: linear-gradient(0deg, rgba(11,92,78,1) 30%, rgba(14,117,100,1) 80%, rgba(17,143,122,1) 100%);
//         `}
//         ${props => props.info && `
//           background: rgb(75,154,213);
//           background: linear-gradient(0deg, rgba(75,154,213,1) 34%, rgba(127,181,221,1) 76%, rgba(140,195,236,1) 100%);
//         `}
//         ${props => props.float && `
//           float: ${props.float};
//         `}
//         ${props => props.disabled && `
//           background: rgb(86,86,92);
//           background: linear-gradient(0deg, rgba(86,86,92,1) 33%, rgba(94,104,113,1) 61%, rgba(164,176,192,1) 100%);
//           opacity: .5;
//         `}
//       `

export default function StyledButton(props) {
  const navigate = useNavigate();
  const onClick = (e) => {
    e.stopPropagation();
    if (!props.disabled) {
      if (_.isFunction(props.onClick)) {
        props.onClick(e);
      } else if (props.to) {
        navigate(props.to);
      }
    }
  };
  let ButtonIcon = props.icon || null;
  if (props.showPlusIcon) {
    ButtonIcon = IoMdAdd;
  }
  if (props.showEditIcon) {
    ButtonIcon = RiEditLine;
  }
  if (props.showDeleteIcon) {
    ButtonIcon = IoTrashOutline;
  }
  if (props.showSaveIcon) {
    ButtonIcon = FaRegSave;
  }
  if (props.showReviveIcon) {
    ButtonIcon = FaRecycle;
  }
  return (
    <Button
      size={props.size}
      disabled={props.disabled}
      onClick={(e) => onClick(e)}
      className={`wellnecityBtn wellnecityBtn${
        _.startCase(props.color) || "Secondary"
      } ${props.className || ""}`}
    >
      {ButtonIcon ? (
        <ButtonIcon className={props.iconClassName || "mr-2"} />
      ) : null}
      {props.loading ? (
        <div
          className={"d-flex justify-content-center"}
          style={{ width: "100px" }}
        >
          <Circles
            height="25"
            width="30"
            color="#fff"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        _.toUpper(props.children)
      )}
    </Button>
  );
}