import { helpers } from "./index";
import _ from "lodash";
import moment from "moment";

function formatPhoneField(v) {
  if (!v) return v;
  v = _.trim(v)
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/-/g, "")
    .replace(/ /g, "");
  if (v.length === 10) {
    v = v.substring(0, 3) + "-" + v.substring(3, 6) + "-" + v.substring(6, 10);
  }
  return v;
}

function formatSSNField(v) {
  if (!v) return v;
  v = v.replace(/[- ]/g, "");
  if (v.length === 9) {
    v = v.substring(0, 3) + "-" + v.substring(3, 5) + "-" + v.substring(5, 9);
  }
  return v;
}

function formatDateField(v) {
  var test = moment(v, ["MM/DD/YYYY", "M/D/YY", "MM/D/YY", "MM/DD/YY", "MM/D/YYYY", "M/D/YYYY", "YYYY-MM-DD"]);
  if (test.isValid()) {
      if (test.year() > new Date().getFullYear()) {
          test.year(test.year() - 100);
      }
      return test.format("MM/DD/YYYY");
  }
  return "";
}

function formatPostalCode(postalCode) {
  if (postalCode.length < 5) return postalCode;
  if (postalCode.length > 9) {
    postalCode = postalCode.substring(0, 9);
  }
  // Remove any non-digit characters from the input
  postalCode = postalCode.replace(/\D/g, "");

  // If the postal code is longer than 5 digits, truncate it

  // Add a dash and the extra 4 digits if they exist
  if (postalCode.length > 5) {
    postalCode = postalCode.substring(0, 5) + "-" + postalCode.substring(5);
  }

  return postalCode;
}

function properCaseText(text) {
  if (!text) return;
  return helpers.toTitleCase(text);
}

function lowerCaseText(text) {
  if (!text) return;
}

export { formatPhoneField, formatSSNField, formatDateField, formatPostalCode, properCaseText };