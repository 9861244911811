import React, { useRef, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classNames from "classnames";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const dataSectionsBackgroundColors = (colorArrayReq) => {
  let origColors = [
    "rgba(88, 83, 157, 1)",
    "rgba(138, 135, 187, 1)",
    "rgba(172, 169, 206, 1)",
    "rgba(205, 203, 225, 1)",
  ];
  return _.map(colorArrayReq, (q, idx) => {
    return idx < 4 ? origColors[idx] : origColors[idx % 4];
  });
};

function shapeData(data) {
  const itemCount = data.length;
  const labels = _.map(data, (c) => {
    return c.label;
  });
  return {
    labels: _.map(data, (c) => {
      return c.label;
    }),
    datasets: [
      {
        data: _.map(data, (c) => {
          return c.value;
        }),
        backgroundColor: dataSectionsBackgroundColors(data),
        borderColor: dataSectionsBackgroundColors(data),
        borderWidth: 1,
        datalabels: {
          anchor: "middle",
        },
      },
    ],
  };
}

function shapeOptions(props) {
  const { usePercentSign, data } = props;
  return {
    aspectRatio: 1,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "right",
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        color: "white",
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: "bold",
        },
        padding: 6,
        formatter: function (value, context) {
          value = Math.round(value);
          if (usePercentSign) {
            value = value + "%";
          }
          return value;
        },
      },
    },
    legend: {
      display: false,
    },
  };
}

export default function DoughnutChart(props) {
  const chartRef = useRef();
  let parentNode;
  let maxDimension;

  useEffect(() => {
    parentNode = chartRef.current?.parentNode;
  }, [chartRef]);

  useEffect(() => {
    if (parentNode) {
      // console.log('parent', parentNode.clientHeight)
    }
  }, [parentNode]);
  if (props && props.data) {
    const data = shapeData(props.data);
    const options = shapeOptions(props);
    const classes = props.classes;
    return (
      <div className={"chart-container"} ref={chartRef}>
        <Doughnut data={data} options={options} />
      </div>
    );
  } else {
    console.error("Doughnut chart requires 'data' as a prop");
  }
  return null;
}
